import React from 'react';

// return only results owned by user that I am following
const filterFollowing = (Variables, results) => {
  const filteredResults = results.filter(result =>
    Variables.user.following.some(
      followingUserId => followingUserId === result.user_id._id
    )
  );

  return filteredResults;
};

export default filterFollowing;
