import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ImageDownloadExports from '../custom-files/ImageDownloadExports';
import encodeFileName from '../global-functions/encodeFileName';
import formatDate from '../global-functions/formatDate';
import scaleDownImage from '../global-functions/scaleDownImage';
import truncateNum from '../global-functions/truncateNum';
import truncateText from '../global-functions/truncateText';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Checkbox,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  ScreenContainer,
  Spacer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Fetch } from 'react-request';

const UserProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendUpdateAvatarPOST =
    GAIMMobileBackendApi.useUpdateAvatarPOST();
  const gAIMMobileBackendMakeImageOrPromptPublicPrivatePUT =
    GAIMMobileBackendApi.useMakeImageOrPromptPublicPrivatePUT();
  const gAIMMobileBackendDeleteUserGeneratedImageByURLDELETE =
    GAIMMobileBackendApi.useDeleteUserGeneratedImageByURLDELETE();
  const gAIMMobileBackendDeleteConversationDELETE =
    GAIMMobileBackendApi.useDeleteConversationDELETE();
  const gAIMMobileBackendUpdateConvoTitlePUT =
    GAIMMobileBackendApi.useUpdateConvoTitlePUT();

  const [SelectedTopTab, setSelectedTopTab] = React.useState('images');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [convoIDtoDelete, setConvoIDtoDelete] = React.useState('');
  const [convoToUpdate, setConvoToUpdate] = React.useState({});
  const [selectedImageHeight, setSelectedImageHeight] = React.useState(300);
  const [selectedImageURL, setSelectedImageURL] = React.useState('');
  const [selectedImageWidth, setSelectedImageWidth] = React.useState(300);
  const [setSelectedImage, setSetSelectedImage] = React.useState({});
  const [showChatDeleteConf, setShowChatDeleteConf] = React.useState(false);
  const [showDeleteConf, setShowDeleteConf] = React.useState(false);
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState('images');
  const [textInputValue, setTextInputValue] = React.useState('');

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => console.log('Copying to clipboard was successful!'),
      (err) => console.error('Could not copy text: ', err)
    );
  };
  

  const addRemoteImageToMediaLibrary = async (uri, filename) => {
    // These are valid image file extensions
    const validExtensions = [".jpg", ".png", ".gif", ".heic", ".webp", ".bmp"];

    // Request permission to access the user's media library
    const permission = await ImageDownloadExports.requestPermissionsAsync();

    // Check if user granted permission
    if (permission.status !== "granted") {
      setMessage(
        "The app needs permission to access your media library to download images"
      );
      return;
    }

    // We need to make sure there's a media library available on the device
    const available = await ImageDownloadExports.isAvailableAsync();
    if (!available) {
      setMessage("The Media Library is not available on your device");
      return;
    }

    // Check if our uri ends with an allowed extension
    const fileIsValidImage =
      validExtensions.filter((extension) => {
        // Append the file extension to the file name
        if (uri.endsWith(extension)) {
          filename = `${filename}${extension}`;
        }

        return uri.endsWith(extension);
      }).length === 1;

    if (!fileIsValidImage) {
      setMessage(
        "Only image files with extensions .jpg, .png, .gif, .heic, .webp, and .bmp can be added to the media library"
      );
      return;
    }

    // Create a location to put the contents of the downloaded file
    const fileUri = `${ImageDownloadExports.documentDirectory}${filename}`;

    // Download the remote file to the fileUri location
    setMessage("Downloading image...");
    const downloadedFile = await ImageDownloadExports.downloadAsync(
      uri,
      fileUri
    );

    // Check if we have a successful response from the remote server
    if (downloadedFile.status !== 200) {
      setMessage("The image could not be downloaded. Please try again");
      return;
    }

    try {
      setMessage("Adding image to your media library...");

      // This can be passed in, retrieved from an App variable, etc.
      const albumName = "GAIM AI App";

      // Create an Asset from our downloaded file
      const asset = await ImageDownloadExports.createAssetAsync(
        downloadedFile.uri
      );

      // Check if we already have an album with the same name
      const album = await ImageDownloadExports.getAlbumAsync(albumName);

      if (album === null) {
        setMessage(`Creating new album named ${albumName}...`);

        // Create a new album and add the Asset
        await ImageDownloadExports.createAlbumAsync(albumName, asset, false);
        setMessage(`The image was added to the new ${albumName} album`);
      } else {
        // Add the Asset to the existing album
        await ImageDownloadExports.addAssetsToAlbumAsync(
          [asset],
          albumName,
          false
        );
        setMessage(`The image was added to the existing ${albumName} album`);
      }
    } catch (error) {
      // Something went wrong :(
      setMessage(
        `There was a problem adding the image to your media library: ${error}`
      );
    }
  };

  async function downloadImageWithFetch(uri, filename) {
    try {
      const response = await fetch(uri);
      if (!response.ok) throw new Error(`Failed to fetch ${uri}: ${response.statusText}`);

      const timestamp = new Date().getTime(); // Get current timestamp
      const updatedFilename = `GAIM_${timestamp}`; // Append timestamp to filename

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = updatedFilename || 'GAIM_AI_Image';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl); // Clean up to avoid memory leaks
    } catch (error) {
      console.error('Failed to download image:', error);
    }
  }

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', height: '100%',
        marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
        marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
       },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(18, 18, 18)',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('MenuScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Profile Menu Button */}
              <Image
                resizeMode={'cover'}
                source={{ uri: `${Constants['user']?.pic}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 1,
                    height: 35,
                    marginLeft: 10,
                    width: 35,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'Inter_600SemiBold',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'Profile'}
        </Text>
        {/* Notifications */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        ></View>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 10, marginBottom: 80, height: (tabSelected === 'profile') ? '10vh' : '60vh'  },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
      >
        {/* User Details */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 15, paddingLeft: 20, paddingRight: 20 },
            dimensions.width
          )}
        >
          {/* User Details */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row', height: 100 },
              dimensions.width
            )}
          >
            <View>
              <Circle
                bgColor={theme.colors.light}
                size={100}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Medium'] },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${Constants['user']?.pic}` }}
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Primary'],
                      borderRadius: 100,
                      borderWidth: 1,
                      height: 100,
                      width: 100,
                    },
                    dimensions.width
                  )}
                />
              </Circle>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { height: '100%', justifyContent: 'center', paddingLeft: 16 },
                dimensions.width
              )}
            >
              {/* Name */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color'],
                    fontFamily: 'Poppins_700Bold',
                    fontSize: 19,
                  },
                  dimensions.width
                )}
              >
                {Constants['user']?.username}
              </Text>
              {/* User Credits View */}
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 5 },
                  dimensions.width
                )}
              >
                {/* Credits Label */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Light'],
                    }),
                    dimensions.width
                  )}
                >
                  {'Image Credits: '}
                </Text>
                {/* Credits Value */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary'],
                    }),
                    dimensions.width
                  )}
                >
                  {Constants['user']?.image_credits}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <>
          {!(tabSelected === 'images') ? null : (
            <View>
              {/* Top Tabs */}
              <>
                {!(tabSelected === 'images') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Custom Color'],
                        flexDirection: 'row',
                        height: 35,
                        justifyContent: 'center',
                        marginBottom: 12,
                        marginTop: 20,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* My Images */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '50%' },
                        dimensions.width
                      )}
                    >
                      {/* selected */}
                      <>
                        {!(SelectedTopTab === 'images') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Primary'],
                                height: 35,
                              },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  setSelectedTopTab('images');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'SecularOne_400Regular',
                                    fontSize: 15,
                                    textTransform: 'uppercase',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'MY IMAGES'}
                              </Text>
                            </Touchable>
                          </View>
                        )}
                      </>
                      {/* unselected */}
                      <>
                        {!(SelectedTopTab !== 'images') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', height: 35 },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  setSelectedTopTab('images');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Custom Color'],
                                    fontFamily: 'SecularOne_400Regular',
                                    fontSize: 15,
                                    textTransform: 'uppercase',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'My Images'}
                              </Text>
                            </Touchable>
                          </View>
                        )}
                      </>
                    </View>
                    {/* Convos */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '50%' },
                        dimensions.width
                      )}
                    >
                      {/* selected */}
                      <>
                        {!(SelectedTopTab === 'convos') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                borderBottomWidth: 2,
                                borderColor: theme.colors['Primary'],
                                height: 35,
                              },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  setSelectedTopTab('convos');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'SecularOne_400Regular',
                                    fontSize: 15,
                                    textTransform: 'uppercase',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'CONVOS'}
                              </Text>
                            </Touchable>
                          </View>
                        )}
                      </>
                      {/* unselected */}
                      <>
                        {!(SelectedTopTab !== 'convos') ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: 'center', height: 35 },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  setSelectedTopTab('convos');
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <Text
                                style={StyleSheet.applyWidth(
                                  {
                                    color: theme.colors['Custom Color'],
                                    fontFamily: 'SecularOne_400Regular',
                                    fontSize: 15,
                                    textTransform: 'uppercase',
                                  },
                                  dimensions.width
                                )}
                              >
                                {'CONVOS'}
                              </Text>
                            </Touchable>
                          </View>
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
              {/* Collection View */}
              <>
                {!(SelectedTopTab === 'images') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 12, marginLeft: 20 },
                      dimensions.width
                    )}
                  >
                    <GAIMMobileBackendApi.FetchGetAllUserGeneratedImagesForUserGET
                      dynamic_id={Constants['dynamic_id']}
                    >
                      {({
                        loading,
                        error,
                        data,
                        refetchGetAllUserGeneratedImagesForUser,
                      }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <FlatList
                            contentContainerStyle={StyleSheet.applyWidth(
                              { flex: 0 },
                              dimensions.width
                            )}
                            data={fetchData?.data}
                            keyExtractor={listData =>
                              listData?.id ||
                              listData?.uuid ||
                              JSON.stringify(listData)
                            }
                            listKey={'EOQufo1R'}
                            numColumns={3}
                            renderItem={({ item }) => {
                              const listData = item;
                              return (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      setSetSelectedImage(listData);
                                      setSelectedImageHeight(
                                        scaleDownImage(listData?.height)
                                      );
                                      setSelectedImageWidth(
                                        scaleDownImage(listData?.width)
                                      );
                                      setSelectedImageURL(listData?.url);
                                      setShowImageModal(true);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      marginRight: 5,
                                      marginTop: 5,
                                      width: '30%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        marginRight: 5,
                                        width: '100%',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <Image
                                      resizeMode={'cover'}
                                      source={{ uri: `${listData?.url}` }}
                                      style={StyleSheet.applyWidth(
                                        { height: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: 100,
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: 450,
                                          },
                                        ],
                                        width: [
                                          {
                                            minWidth: Breakpoints.Mobile,
                                            value: '100%',
                                          },
                                          {
                                            minWidth: Breakpoints.Laptop,
                                            value: '100%',
                                          },
                                        ], },
                                        dimensions.width
                                      )}
                                    />
                                    {/* View 3 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          justifyContent: 'flex-end',
                                          width: '100%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!listData?.image_public ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'stretch',
                                                marginTop: -28,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Circle
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.CircleStyles(
                                                    theme
                                                  )['Circle'],
                                                  { padding: 2 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {/* Public Icon */}
                                              <Icon
                                                color={theme.colors['Strong']}
                                                name={'Octicons/globe'}
                                                size={24}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      'rgba(0, 0, 0, 0)',
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </Circle>
                                          </View>
                                        )}
                                      </>
                                      {/* View 2 */}
                                      <>
                                        {listData?.image_public ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { marginTop: -28 },
                                              dimensions.width
                                            )}
                                          >
                                            <Circle
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.CircleStyles(
                                                    theme
                                                  )['Circle'],
                                                  { padding: 2 }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {/* Private Icon */}
                                              <Icon
                                                color={theme.colors['Strong']}
                                                name={'Entypo/lock'}
                                                size={24}
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    backgroundColor:
                                                      'rgba(0, 0, 0, 0)',
                                                  },
                                                  dimensions.width
                                                )}
                                              />
                                            </Circle>
                                          </View>
                                        )}
                                      </>
                                    </View>
                                  </View>
                                </Touchable>
                              );
                            }}
                          />
                        );
                      }}
                    </GAIMMobileBackendApi.FetchGetAllUserGeneratedImagesForUserGET>
                  </View>
                )}
              </>
              {/* Convo History View */}
              <>
                {!(SelectedTopTab === 'convos') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 12, left: 20 },
                      dimensions.width
                    )}
                  >
                    <GAIMMobileBackendApi.FetchGetChatHistoryGET
                      dynamic_id={Constants['dynamic_id']}
                    >
                      {({ loading, error, data, refetchGetChatHistory }) => {
                        const fetchData = data?.json;
                        if (loading) {
                          return <ActivityIndicator />;
                        }

                        if (
                          error ||
                          data?.status < 200 ||
                          data?.status >= 300
                        ) {
                          return <ActivityIndicator />;
                        }

                        return (
                          <FlatList
                            data={fetchData?.data}
                            keyExtractor={listData =>
                              listData && listData['_id']
                            }
                            listKey={'o5nMkam7'}
                            numColumns={1}
                            onEndReachedThreshold={0.5}
                            renderItem={({ item }) => {
                              const listData = item;
                              return (
                                <Touchable
                                  onPress={() => {
                                    try {
                                      if (listData.oai_assistantId) {
                                        navigation.navigate("ChatOneScreen", {
                                          convoIDtoLoad: listData && listData["_id"],
                                          loadTool: "load",
                                          loadConvo: "load",
                                          oai_assistantId: listData?.oai_assistantId,
                                          oai_threadId: listData?.oai_threadId,
                                          type: listData?.type,
                                        });
                                      } else {
                                        navigation.navigate("ChatbotScreen", {
                                          tool_id: listData?.tool_id,
                                          convoIDtoLoad: listData && listData["_id"],
                                          loadTool: "load",
                                          loadConvo: "load",
                                          type: listData?.type,
                                        });
                                      }
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'space-between',
                                        alignItems: 'center',
                                        borderColor: theme.colors['Primary'],
                                        borderRadius: 12,
                                        borderWidth: 1,
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        marginBottom: 4,
                                        marginRight: 20,
                                        marginTop: 4,
                                        padding: 8,
                                        paddingLeft: 10,
                                        paddingRight: 10,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* View 2 */}
                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          alignItems: 'flex-start',
                                          flexDirection: 'column',
                                          justifyContent: 'space-between',
                                          width: '80%',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* First Message Text */}
                                      <>
                                        {listData?.title ? null : (
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors['Surface'],
                                                  fontFamily:
                                                    'RobotoCondensed_400Regular',
                                                  fontSize: 16,
                                                  padding: 10,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {/*truncateText(
                                              listData?.messages && listData?.messages?.[1]?.content &&
                                                (listData?.messages)[1].content,
                                              100
                                            )*/}
                                            {
                                              listData?.messages?.[1]?.content
                                                ? listData.messages[1].content.length > 100
                                                  ? truncateText(listData.messages[1].content, 100)
                                                  : listData.messages[1].content
                                                : listData.messages[0].content
                                            }

                                          </Text>
                                        )}
                                      </>
                                      {/* Convo Title Text */}
                                      <>
                                        {!listData?.title ? null : (
                                          <Text
                                            accessible={true}
                                            allowFontScaling={true}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.TextStyles(theme)[
                                                  'Text'
                                                ],
                                                {
                                                  color:
                                                    theme.colors['Surface'],
                                                  fontFamily:
                                                    'RobotoCondensed_400Regular',
                                                  fontSize: 16,
                                                  padding: 10,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          >
                                            {listData?.title}
                                          </Text>
                                        )}
                                      </>
                                      {/* Last Updated Text */}
                                      <Text
                                        accessible={true}
                                        allowFontScaling={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              'Text'
                                            ],
                                            {
                                              color: theme.colors['Light'],
                                              marginLeft: 40,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {'Last Updated: '}
                                        {formatDate(listData?.lastUpdated)}
                                      </Text>
                                    </View>

                                    <View
                                      style={StyleSheet.applyWidth(
                                        {
                                          flexDirection: 'row',
                                          justifyContent: 'flex-start',
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Touchable 2 */}
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            setConvoToUpdate(listData);
                                            setShowEditNameModal(true);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          { marginRight: 8 },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Edit Name Icon */}
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'FontAwesome/pencil'}
                                          size={24}
                                        />
                                      </Touchable>

                                      <Touchable
                                        onPress={() => {
                                          try {
                                            setShowChatDeleteConf(true);
                                            setConvoIDtoDelete(
                                              listData && listData['_id']
                                            );
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                      >
                                        {/* Delete Chat Icon */}
                                        <Icon
                                          color={theme.colors['Error']}
                                          name={'FontAwesome/trash'}
                                          size={24}
                                          style={StyleSheet.applyWidth(
                                            { marginRight: 5 },
                                            dimensions.width
                                          )}
                                        />
                                      </Touchable>
                                    </View>
                                  </View>
                                </Touchable>
                              );
                            }}
                            showsHorizontalScrollIndicator={true}
                            showsVerticalScrollIndicator={true}
                          />
                        );
                      }}
                    </GAIMMobileBackendApi.FetchGetChatHistoryGET>
                  </View>
                )}
              </>
            </View>
          )}
        </>
      </ScrollView>
      {/* View Profile Block */}
      <>
        {!(tabSelected === 'profile') ? null : (
          <KeyboardAwareScrollView
            contentContainerStyle={StyleSheet.applyWidth(
              { paddingBottom: 10, marginBottom: 80, height: (tabSelected === 'profile') ? '60vh' : 0 },
              dimensions.width
            )}
            keyboardShouldPersistTaps={'never'}
            showsVerticalScrollIndicator={true}
          >
            {/* Name */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_3'],
                  borderColor: theme.colors['Primary'],
                  borderRadius: 24,
                  height: 48,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 15,
                },
                dimensions.width
              )}
            >
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }),
                  dimensions.width
                )}
              >
                {Constants['user']?.name}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { position: 'absolute', top: -10 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      marginLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  {'Name'}
                </Text>
              </View>
            </View>
            {/* Username */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_3'],
                  borderRadius: 24,
                  height: 48,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 15,
                },
                dimensions.width
              )}
            >
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }),
                  dimensions.width
                )}
              >
                {Constants['user']?.username}
              </Text>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { position: 'absolute', top: -10 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      marginLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  {'Username'}
                </Text>
              </View>
            </View>
            {/* Location */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_3'],
                  borderRadius: 24,
                  height: 48,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 15,
                },
                dimensions.width
              )}
            >
              {/* Text 3 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }),
                  dimensions.width
                )}
              >
                {Constants['user']?.location}
              </Text>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { position: 'absolute', top: -10 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      marginLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  {'Location'}
                </Text>
              </View>
            </View>
            {/* Company Name */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_3'],
                  borderRadius: 24,
                  height: 48,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 15,
                },
                dimensions.width
              )}
            >
              {/* Text 4 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }),
                  dimensions.width
                )}
              >
                {Constants['user']?.companyName}
              </Text>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { position: 'absolute', top: -10 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      marginLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  {'Company Name'}
                </Text>
              </View>
            </View>
            {/* Website */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Custom Color_3'],
                  borderRadius: 24,
                  height: 48,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 15,
                },
                dimensions.width
              )}
            >
              {/* Text 5 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    paddingBottom: 8,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 12,
                  }),
                  dimensions.width
                )}
              >
                {Constants['user']?.website}
              </Text>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { position: 'absolute', top: -10 },
                  dimensions.width
                )}
              >
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'Poppins_400Regular',
                      fontSize: 12,
                      marginLeft: 20,
                    },
                    dimensions.width
                  )}
                >
                  {'Website'}
                </Text>
              </View>
            </View>
            {/* Edit Profile Button */}
            <Button
              onPress={() => {
                try {
                  navigation.navigate('FillProfileScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Primary'],
                  borderRadius: 24,
                  color: 'rgb(18, 18, 18)',
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 17,
                  height: 48,
                  marginBottom: 10,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 30,
                  textAlign: 'center',
                },
                dimensions.width
              )}
              title={'EDIT PROFILE'}
            />
          </KeyboardAwareScrollView>
        )}
      </>
      {/* Tabbar */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Primary'],
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            flexDirection: 'row',
            height: 80,
            justifyContent: 'space-around',
          },
          dimensions.width
        )}
      >
        {/* Images */}
        <Touchable
          onPress={() => {
            try {
              setTabSelected('images');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!(tabSelected !== 'images') ? null : (
                <Icon
                  color={theme.colors['Strong']}
                  name={'FontAwesome/image'}
                  size={24}
                  style={StyleSheet.applyWidth(
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                    dimensions.width
                  )}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(tabSelected === 'images') ? null : (
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'FontAwesome/image'}
                  size={24}
                  style={StyleSheet.applyWidth(
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                    dimensions.width
                  )}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 12,
                  marginTop: 8,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'IMAGES'}
            </Text>
          </View>
        </Touchable>
        {/* Profile */}
        <Touchable
          onPress={() => {
            try {
              setTabSelected('profile');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!(tabSelected !== 'profile') ? null : (
                <Icon
                  color={theme.colors['Strong']}
                  name={'AntDesign/user'}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(tabSelected === 'profile') ? null : (
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'AntDesign/user'}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 12,
                  marginTop: 8,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'Profile'}
            </Text>
          </View>
        </Touchable>
      </View>
      {/* View Image Modal */}
      <>
        {!showImageModal ? null : (
          <Modal animationType={'fade'} presentationStyle={'fullScreen'}>
            <ImageBackground
              backgroundColor={theme.colors['Strong']}
              resizeMode={'cover'}
              source={Images.GaimMobileBG2}
              style={StyleSheet.applyWidth(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    justifyContent: 'space-around',
                    paddingTop: 15,
                  },
                  dimensions.width
                )}
              >
                {/* Close Image Button */}
                <Button
                  icon={'AntDesign/closecircleo'}
                  onPress={() => {
                    try {
                      setShowImageModal(false);
                      setSelectedImageURL(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: 50,
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: '10%',
                        width: '70%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'CLOSE'}
                />
                {/* Image 2 */}
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${selectedImageURL}` }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: selectedImageHeight,
                        maxHeight: 450,
                        maxWidth: 300,
                        width: selectedImageWidth,
                        borderRadius: 8,
                        shadowColor: '#000',
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Prompt View */}
                  <>
                  {!setSelectedImage?.prompt ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgb(40, 40, 40)',
                          borderColor: theme.colors['Divider'],
                          borderRadius: 8,
                          borderWidth: 1,
                          marginBottom: 5,
                          marginTop: 5,
                          padding: 6,
                          paddingLeft: 12,
                          paddingRight: 12,
                          width: '70%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Prompt Text */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Divider'],
                              fontFamily: 'RobotoCondensed_400Regular',
                              fontSize: 15,
                            }
                          ),
                          dimensions.width
                        )}
                        >
                        {setSelectedImage?.prompt}
                        </Text>
                        {/* Copy Icon Touchable */}
                        <Touchable
                          onPress={() => {
                            try {
                              const promptText = setSelectedImage?.prompt;
                              copyToClipboard(promptText);
                              alert('Prompt Copied to Clipboard: ' + promptText);
                              //Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: "flex-end" },
                              dimensions.width
                            )}
                          >
                            {/* Copy Icon */}
                            <Icon
                              color={theme.colors["Primary"]}
                              name={"Feather/copy"}
                              size={24}
                            />
                          </View>
                        </Touchable>
                    </View>
                  )}
                  </>
                  {/* Tool View */}
                  <>
                  {!setSelectedImage?.tool_id.name ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: 'rgb(40, 40, 40)',
                          borderColor: theme.colors['Divider'],
                          borderRadius: 8,
                          borderWidth: 1,
                          marginBottom: 5,
                          marginTop: 5,
                          padding: 6,
                          paddingLeft: 12,
                          paddingRight: 12,
                          width: '70%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Tool Text */}
                      <Text
                        accessible={true}
                        allowFontScaling={true}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.TextStyles(theme)['Text'],
                            {
                              color: theme.colors['Divider'],
                              fontFamily: 'RobotoCondensed_400Regular',
                              fontSize: 15,
                            }
                          ),
                          dimensions.width
                        )}
                        >
                        {setSelectedImage?.tool_id.name}
                        </Text>
                    </View>
                  )}
                  </>
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Save Image Button */}
                  <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                  <Button
                    onPress={() => {
                      const handler = async () => {
                        try {
                          await addRemoteImageToMediaLibrary(
                            selectedImageURL,
                            encodeFileName(selectedImageURL)
                          );

                          showAlertUtil({
                            title: 'Saved!',
                            message:
                              'Your image has been saved to your device.',
                            buttonText: 'Close',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          borderRadius: 50,
                          color: theme.colors['Strong'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginLeft: 20,
                          marginRight: 20,
                          width: '70%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'SAVE IMAGE'}
                  />
                  )}
                  </>
                                    {/* Save Image Button 2 */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await downloadImageWithFetch(
                              selectedImageURL,
                              encodeFileName(selectedImageURL)
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)["Button"],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors["Strong"],
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: "40%",
                            },
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: "40%",
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={"SAVE IMAGE"}
                    />
                  )}
                </>

<Spacer bottom={8} left={8} right={8} top={8} />

{/* Save Image Button 2 */}

<>

{dimensions.width >= Breakpoints.Laptop ? null : (

  <Button

    onPress={() => {

      const handler = async () => {

        try {

          await openShareUtil(`${selectedImageURL}`);

        } catch (err) {

          console.error(err);

        }

      };

      handler();

    }}

    style={StyleSheet.applyWidth(

      StyleSheet.compose(

        GlobalStyles.ButtonStyles(theme)['Button'],

        {

          backgroundColor: 'rgba(0, 0, 0, 0)',

          borderColor: theme.colors['Primary'],

          borderRadius: 50,

          borderWidth: 2,

          color: theme.colors['Primary'],

          fontFamily: 'SecularOne_400Regular',

          fontSize: 16,

          width: '70%',

        }

      ),

      dimensions.width

    )}

    title={'SHARE'}

  />

)}

</>
                  {/* Spacer 2 */}
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  {/* Make Image Public View */}
                  {/*<>
                    {setSelectedImage?.image_public ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '70%' },
                          dimensions.width
                        )}
                      > */}
                        {/* Make Image Public Btn */}
                        {/*<Button
                          icon={'Feather/globe'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const updatedImage = (
                                  await gAIMMobileBackendMakeImageOrPromptPublicPrivatePUT.mutateAsync(
                                    {
                                      dynamic_id: Constants['user']?.dynamic_id,
                                      image_id: setSelectedImage?._id,
                                      image_public: true,
                                    }
                                  )
                                )?.json;
                                setShowImageModal(false);

                                showAlertUtil({
                                  title: 'Image Updated',
                                  message:
                                    'Your image preferences have been updated successfully.',
                                  buttonText: 'Close',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 50,
                                borderWidth: 2,
                                color: theme.colors['Primary'],
                                fontFamily: 'SecularOne_400Regular',
                                fontSize: 16,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'MAKE PUBLIC'}
                        />
                      </View>
                    )}
                  </> */}
                  {/* Make Image Private View */}
                  {/*<>
                    {!setSelectedImage?.image_public ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { width: '70%' },
                          dimensions.width
                        )}
                      >*/}
                        {/* Make Image Private Btn */}
                       {/* <Button
                          icon={'Entypo/lock'}
                          onPress={() => {
                            const handler = async () => {
                              try {
                                const updatedImage = (
                                  await gAIMMobileBackendMakeImageOrPromptPublicPrivatePUT.mutateAsync(
                                    {
                                      dynamic_id: Constants['user']?.dynamic_id,
                                      image_id: setSelectedImage?._id,
                                      image_public: false,
                                    }
                                  )
                                )?.json;
                                setShowImageModal(false);

                                showAlertUtil({
                                  title: 'Image Updated',
                                  message:
                                    'Your image preferences have been updated successfully.',
                                  buttonText: 'Close',
                                });
                              } catch (err) {
                                console.error(err);
                              }
                            };
                            handler();
                          }}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ButtonStyles(theme)['Button'],
                              {
                                backgroundColor: 'rgba(0, 0, 0, 0)',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 50,
                                borderWidth: 2,
                                color: theme.colors['Primary'],
                                fontFamily: 'SecularOne_400Regular',
                                fontSize: 16,
                                width: '100%',
                              }
                            ),
                            dimensions.width
                          )}
                          title={'MAKE PRIVATE'}
                        />
                      </View>
                    )}
                  </>*/}
                  {/* Spacer 3 */}
                  <Spacer bottom={8} left={8} right={8} top={8} />
                  {/* Delete Image Button */}
                  <Button
                    icon={'Feather/trash-2'}
                    onPress={() => {
                      try {
                        setShowDeleteConf(true);
                        setShowImageModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Error'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          width: '70%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'DELETE IMAGE'}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 100 },
                    dimensions.width
                  )}
                />
              </View>
            </ImageBackground>
          </Modal>
        )}
      </>
      {/* Confirm Delete Modal */}
      <>
        {!(showDeleteConf === true) ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(19, 17, 17, 0.8)',
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: 'rgb(18, 18, 18)',
                    borderColor: theme.colors['Error'],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: 'space-around',
                    marginTop: 200,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${selectedImageURL}` }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      { height: 200, width: 200 }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'Are you sure you want to delete this?'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-around' },
                    dimensions.width
                  )}
                >
                  {/* Delete Image Button 2 */}
                  <Button
                    icon={'Feather/trash-2'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setShowDeleteConf(false);
                          (
                            await gAIMMobileBackendDeleteUserGeneratedImageByURLDELETE.mutateAsync(
                              {
                                dynamic_id: Constants['dynamic_id'],
                                url: encodeFileName(selectedImageURL),
                              }
                            )
                          )?.json;

                          showAlertUtil({
                            title: 'Deleted',
                            message: 'Your image has been deleted.',
                            buttonText: 'Close',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Error'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Error'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'DELETE'}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowDeleteConf(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CANCEL'}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Confirm Delete Convo Modal */}
      <>
        {!showChatDeleteConf ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(19, 17, 17, 0.8)',
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: 'rgb(18, 18, 18)',
                    borderColor: theme.colors['Error'],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: 'space-around',
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'Are you sure you want to delete this Conversation?'}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-around' },
                    dimensions.width
                  )}
                >
                  {/* Delete Convo Button */}
                  <Button
                    icon={'Feather/trash-2'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setShowChatDeleteConf(false);
                          (
                            await gAIMMobileBackendDeleteConversationDELETE.mutateAsync(
                              {
                                convo_id: convoIDtoDelete,
                                dynamic_id: Constants['user']?.dynamic_id,
                              }
                            )
                          )?.json;

                          showAlertUtil({
                            title: 'Deleted',
                            message: 'Your conversation has been deleted.',
                            buttonText: 'Close',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Error'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Error'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'DELETE'}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowChatDeleteConf(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CANCEL'}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Edit Convo Name Modal */}
      <>
        {!showEditNameModal ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(19, 17, 17, 0.8)',
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: 'rgb(18, 18, 18)',
                    borderColor: theme.colors['Error'],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: 'space-around',
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'Update your conversation name.'}
                </Text>
                {/* Title Text Input */}
                <TextInput
                  allowFontScaling={true}
                  autoCapitalize={'none'}
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={newTitleTextInputValue => {
                    const textInputValue = newTitleTextInputValue;
                    try {
                      setTextInputValue(newTitleTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter new title...'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'],
                      { color: theme.colors['Surface'] }
                    ),
                    dimensions.width
                  )}
                  value={textInputValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-around' },
                    dimensions.width
                  )}
                >
                  {/* Save Convo Button */}
                  <Button
                    icon={'FontAwesome/save'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await gAIMMobileBackendUpdateConvoTitlePUT.mutateAsync(
                              {
                                convo_id: convoToUpdate?._id,
                                title: textInputValue,
                                user_id: Constants['user']?._id,
                              }
                            )
                          )?.json;
                          setShowEditNameModal(false);
                          setTextInputValue('');

                          showAlertUtil({
                            title: 'Updated!',
                            message:
                              'Your conversation title has been updated successfully.',
                            buttonText: 'Close',
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'SAVE'}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowEditNameModal(false);
                        setTextInputValue('');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CANCEL'}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(UserProfileScreen);
