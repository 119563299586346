import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import filterList from '../global-functions/filterList';
import formatDate from '../global-functions/formatDate';
import truncateText from '../global-functions/truncateText';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  IconButton,
  Pressable,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  ScrollView,
  Text,
  View,
} from 'react-native';
import { Fetch } from 'react-request';

const CharacterListScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [convoIDtoDelete, setConvoIDtoDelete] = React.useState('');
  const [convoToUpdate, setConvoToUpdate] = React.useState({});
  const [currentCharacter, setCurrentCharacter] = React.useState({});
  const [modalVisible, setModalVisible] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState('');
  const [showConvoDelete, setShowConvoDelete] = React.useState(false);
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  const [showExpMenu, setShowExpMenu] = React.useState(false);
  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [textInputValue2, setTextInputValue2] = React.useState('');
  const [titleInputValue, setTitleInputValue] = React.useState('');
  const [toolFilterType, setToolFilterType] =
    React.useState('character-famous');
  const gAIMMobileBackendDeleteConversationDELETE =
    GAIMMobileBackendApi.useDeleteConversationDELETE();
  const gAIMMobileBackendUpdateConvoTitlePUT =
    GAIMMobileBackendApi.useUpdateConvoTitlePUT();

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 100,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('MenuScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Profile Menu Button */}
              <Image
                resizeMode={'cover'}
                source={{ uri: `${Constants['user']?.pic}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 1,
                    height: 35,
                    marginLeft: 10,
                    width: 35,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'Inter_600SemiBold',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'AI Characters'}
        </Text>
        {/* Right Header Icons View */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: [
                { minWidth: Breakpoints.Laptop, value: 'row' },
                { minWidth: Breakpoints.Mobile, value: 'row' },
              ],
              height: 48,
              justifyContent: 'center',
            },
            dimensions.width
          )}
        >
          {/* Search View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                marginRight: 6,
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  if (showSearchBox) {
                    setShowSearchBox(false);
                    setSearchInput(null);
                  } else {
                    setShowSearchBox(true);
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Buy Credits Icon */}
              <Icon
                color={theme.colors['Primary']}
                name={'FontAwesome/search'}
                size={24}
              />
            </Touchable>
          </View>
          {/* Buy Credits View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('SubscribeScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Buy Credits Icon */}
              <Icon
                color={theme.colors['Primary']}
                name={'MaterialIcons/add-shopping-cart'}
                size={24}
              />
            </Touchable>
          </View>
        </View>
      </View>
      {/* Search Box View */}
      <>
        {!showSearchBox ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgb(40, 40, 40)',
                marginBottom: 8,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 6 },
                  { minWidth: Breakpoints.Laptop, value: '20%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: 6 },
                  { minWidth: Breakpoints.Laptop, value: '20%' },
                ],
              },
              dimensions.width
            )}
          >
            <TextInput
              allowFontScaling={true}
              autoCapitalize={'none'}
              changeTextDelay={500}
              onChangeText={newTextInputValue => {
                try {
                  setSearchInput(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={'Type to search...'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Input'],
                  {
                    borderColor: theme.colors['Primary'],
                    color: theme.colors['Primary'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                  }
                ),
                dimensions.width
              )}
              value={searchInput}
            />
          </View>
        )}
      </>
      {/* Info View */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: { minWidth: Breakpoints.Laptop, value: 'center' },
            marginBottom: 8,
            marginLeft: '8%',
            marginRight: '8%',
            width: '80%',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Light']}
          name={'Feather/info'}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        {/* Characters Casual Text */}
        <>
          {!(
            toolFilterType === 'character-casual' ||
            'character-book' ||
            'character-famous' ||
            'character-animal' ||
            'character-artifact' ||
            'character-fan' ||
            'character-monster' ||
            'character-myth' ||
            'character-place' ||
            'character-pro' ||
            'character-teacher'
          ) ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                  fontFamily: 'RobotoCondensed_400Regular',
                }),
                dimensions.width
              )}
            >
              {
                'Chat with your favorite AI character, historical figure, educator, landmark, artifact, and many others.'
              }
            </Text>
          )}
        </>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 10, marginBottom: 80, height: '55vh' },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
      >
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: 10, paddingLeft: '3%', paddingRight: '3%' },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Error'],
                fontFamily: 'RobotoCondensed_400Regular',
                textAlign: 'center',
              }),
              dimensions.width
            )}
          >
            {
              'All responses are for educational and entertainment purposes only. AI is not a replacement for a licensed professional.'
            }
          </Text>
        </View>
        {/* Chats */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderRadius: 12,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
            },
            dimensions.width
          )}
        >
          <GAIMMobileBackendApi.FetchFetchModelDataGET
            handlers={{
              onData: fetchData => {
                try {
                  setGlobalVariableValue({
                    key: 'aitools',
                    value: fetchData?.data,
                  });
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            limit={1000}
            modelName={'AITool'}
          >
            {({ loading, error, data, refetchFetchModelData }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flex: 1 },
                    dimensions.width
                  )}
                  data={filterList(
                    Variables,
                    setGlobalVariableValue,
                    fetchData?.data,
                    searchInput,
                    'name'
                  )}
                  keyExtractor={(listData, index) =>
                    listData && listData['_id']
                  }
                  listKey={'zhIJqHLa'}
                  numColumns={1}
                  renderItem={({ item, index }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Touchable Result Object */}
                        <>
                          {!(listData?.type === toolFilterType) ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  if (toolFilterType === 'character-casual') {
                                    setGlobalVariableValue({
                                      key: 'currentChatTool',
                                      value: listData,
                                    });
                                  }
                                  if (listData?.type === 'character-casual') {
                                    setModalVisible(true);
                                  }
                                  if (toolFilterType !== 'character-casual') {
                                    setGlobalVariableValue({
                                      key: 'currentChatTool',
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType !== 'character-casual') {
                                    navigation.navigate('ChatbotScreen', {
                                      toolExampleImageURL:
                                        listData?.image_data.example_url,
                                      toolName: listData?.name,
                                      toolDesc: listData?.description,
                                      toolPrePrompt: listData?.pre_prompt,
                                    });
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    borderBottomWidth: 0.5,
                                    borderColor:
                                      theme.colors['Custom Color_19'],
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    paddingBottom: 18,
                                    paddingTop: 18,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Casual View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-casual'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Image 2 */}
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Famous View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-famous'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Teacher View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-teacher'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Book View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-book'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-book'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Animal View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-animal'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-animal'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Place View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-place'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-place'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Artifact View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-artifact'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType ===
                                          'character-artifact'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Fan View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-fan'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-fan'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Pro View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-pro'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-pro'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Monster View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-monster'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-monster'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Myth View */}
                                <>
                                  {!(
                                    toolFilterType === 'character-myth'
                                  ) ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: 'flex-start' },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 1) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    'Custom Color_22'
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: 'absolute',
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!(
                                          toolFilterType === 'character-myth'
                                        ) ? null : (
                                          <Image
                                            resizeMode={'cover'}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  'Image'
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors['Primary'],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      marginLeft: 14,
                                      marginRight: 20,
                                      paddingRight: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: 'flex-start',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!(
                                        toolFilterType === 'character-casual'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'AntDesign/team'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 2 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-famous'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'AntDesign/staro'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 3 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-teacher'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'AntDesign/solution1'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 4 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-book'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'AntDesign/book'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 5 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-animal'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Ionicons/paw-outline'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 6 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-place'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'FontAwesome/map-signs'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 7 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-artifact'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'AntDesign/pushpino'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 11 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-fan'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Ionicons/ios-trophy-outline'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 10 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-pro'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Feather/coffee'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 9 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-monster'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={'Ionicons/eye-outline'}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 8 */}
                                    <>
                                      {!(
                                        toolFilterType === 'character-myth'
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors['Primary']}
                                          name={
                                            'MaterialCommunityIcons/guy-fawkes-mask'
                                          }
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Name ~ */}
                                    <>
                                      {!(
                                        (listData && listData['_id']) !==
                                        '64f35946d7b8542613e4324b'
                                      ) ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors['Custom Color_2'],
                                              fontFamily: 'Poppins_500Medium',
                                              fontSize: 15,
                                              lineHeight: 20,
                                              marginLeft: 5,
                                              marginTop: 4,
                                              opacity: 1,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.name}
                                        </Text>
                                      )}
                                    </>
                                    {/* Name ~ 2 */}
                                    <>
                                      {!(
                                        (listData && listData['_id']) ===
                                        '64f35946d7b8542613e4324b'
                                      ) ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Primary'],
                                              fontFamily: 'Poppins_500Medium',
                                              fontSize: 16,
                                              lineHeight: 20,
                                              marginLeft: 5,
                                              marginTop: 4,
                                              opacity: 1,
                                              textTransform: 'uppercase',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.name}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Message ~ */}
                                  <Text
                                    ellipsizeMode={'clip'}
                                    numberOfLines={3}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Custom Color_2'],
                                        fontFamily: 'Poppins_400Regular',
                                        fontSize: 12,
                                        lineHeight: 20,
                                        marginTop: 8,
                                        opacity: 0.5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.description}
                                  </Text>
                                </View>
                              </View>
                            </Touchable>
                          )}
                        </>
                      </>
                    );
                  }}
                />
              );
            }}
          </GAIMMobileBackendApi.FetchFetchModelDataGET>
        </View>
      </ScrollView>
      {/* Tabbar */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Primary'],
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            flexDirection: 'row',
            height: 80,
            justifyContent: 'space-evenly',
          },
          dimensions.width
        )}
      >
        {/* Casual */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType('character-casual');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== 'character-casual') ? null : (
                <Icon
                  color={theme.colors['Strong']}
                  name={'AntDesign/team'}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === 'character-casual') ? null : (
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'AntDesign/team'}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Casual'}
            </Text>
          </View>
        </Touchable>
        {/* Famous */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType('character-famous');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== 'character-famous') ? null : (
                <Icon
                  color={theme.colors['Strong']}
                  name={'AntDesign/staro'}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === 'character-famous') ? null : (
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'AntDesign/staro'}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Famous'}
            </Text>
          </View>
        </Touchable>
        {/* Teachers */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType('character-teacher');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== 'character-teacher') ? null : (
                <Icon
                  color={theme.colors['Strong']}
                  name={'AntDesign/solution1'}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === 'character-teacher') ? null : (
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'AntDesign/solution1'}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'Poppins_700Bold',
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: 'center',
                },
                dimensions.width
              )}
            >
              {'Teachers'}
            </Text>
          </View>
        </Touchable>
        {/* Up View */}
        <>
          {showExpMenu ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: -50 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowExpMenu(true);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Circle
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'],
                      { borderWidth: 2, width: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors['Strong']}
                    name={'AntDesign/up'}
                    size={24}
                  />
                </Circle>
              </Touchable>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'MORE'}
              </Text>
            </View>
          )}
        </>
        {/* Down View */}
        <>
          {!showExpMenu ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: -50 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowExpMenu(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Circle
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'],
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderColor: theme.colors['Primary'],
                        borderWidth: 2,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Icon 2 */}
                  <Icon
                    color={theme.colors['Primary']}
                    name={'AntDesign/down'}
                    size={24}
                  />
                </Circle>
              </Touchable>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Strong'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 12,
                    textAlign: 'center',
                  },
                  dimensions.width
                )}
              >
                {'LESS'}
              </Text>
            </View>
          )}
        </>
      </View>
      {/* Tabbar 2 */}
      <>
        {!showExpMenu ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Primary'],
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                flexDirection: 'row',
                height: 80,
                justifyContent: 'space-evenly',
              },
              dimensions.width
            )}
          >
            {/* Books */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-book');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-book') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'AntDesign/book'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-book') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'AntDesign/book'}
                      size={24}
                    />
                  )}
                </>
                {/* Books */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Books'}
                </Text>
              </View>
            </Touchable>
            {/* Animals */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-animal');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-animal') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'Ionicons/paw-outline'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-animal') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'Ionicons/paw-outline'}
                      size={24}
                    />
                  )}
                </>
                {/* Animals */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Animals'}
                </Text>
              </View>
            </Touchable>
            {/* Places */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-place');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-place') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'FontAwesome/map-signs'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-place') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'FontAwesome/map-signs'}
                      size={24}
                    />
                  )}
                </>
                {/* Places */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Places'}
                </Text>
              </View>
            </Touchable>
            {/* Artifacts */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-artifact');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-artifact') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'AntDesign/pushpino'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-artifact') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'AntDesign/pushpino'}
                      size={24}
                    />
                  )}
                </>
                {/* Artifacts */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Artifacts'}
                </Text>
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Tabbar 3 */}
      <>
        {!showExpMenu ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                backgroundColor: theme.colors['Primary'],
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                flexDirection: 'row',
                height: 80,
                justifyContent: 'space-evenly',
              },
              dimensions.width
            )}
          >
            {/* Fans */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-fan');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-fan') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'Ionicons/ios-trophy-outline'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-fan') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'Ionicons/ios-trophy-outline'}
                      size={24}
                    />
                  )}
                </>
                {/* Fans */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Fans'}
                </Text>
              </View>
            </Touchable>
            {/* Pros */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-pro');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-pro') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'Feather/coffee'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-pro') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'Feather/coffee'}
                      size={24}
                    />
                  )}
                </>
                {/* Pros */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Pros'}
                </Text>
              </View>
            </Touchable>
            {/* Monsters */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-monster');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-monster') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'FontAwesome/eye'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-monster') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'FontAwesome/eye'}
                      size={24}
                    />
                  )}
                </>
                {/* Monsters */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Monsters'}
                </Text>
              </View>
            </Touchable>
            {/* Myths */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType('character-myth');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== 'character-myth') ? null : (
                    <Icon
                      color={theme.colors['Strong']}
                      name={'MaterialCommunityIcons/guy-fawkes-mask'}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === 'character-myth') ? null : (
                    <Icon
                      color={theme.colors['Studily_Primary']}
                      name={'MaterialCommunityIcons/guy-fawkes-mask'}
                      size={24}
                    />
                  )}
                </>
                {/* Myths */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'Poppins_700Bold',
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: 'center',
                    },
                    dimensions.width
                  )}
                >
                  {'Myths'}
                </Text>
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Details Modal */}
      <>
        {!modalVisible ? null : (
          <Modal animationType={'slide'} presentationStyle={'pageSheet'}>
            <ScrollView
              bounces={true}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Details Modal View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Primary'],
                    height: '100%',
                    padding: 15,
                  },
                  dimensions.width
                )}
              >
                {/* Close Button View */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'flex-end',
                      paddingLeft: 10,
                      paddingRight: 10,
                    },
                    dimensions.width
                  )}
                >
                  {/* Close Button */}
                  <IconButton
                    color={theme.colors['Strong']}
                    icon={'AntDesign/closecircleo'}
                    onPress={() => {
                      try {
                        setModalVisible(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={32}
                  />
                </View>
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center' },
                    dimensions.width
                  )}
                >
                  {/* Profile Image */}
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: `${Constants['currentChatTool']?.image_data.example_url}`,
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Strong'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 125,
                          width: 125,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Name Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 18,
                          marginTop: 5,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {`${Constants['currentChatTool']?.name}`}
                  </Text>

                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'RobotoCondensed_400Regular',
                          fontSize: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Age: '}
                    {Constants['currentChatTool']?.character_data.age}
                    {', '}
                    {Constants['currentChatTool']?.character_data.gender}
                    {', '}
                    {Constants['currentChatTool']?.character_data.descent}
                  </Text>
                  {/* Chat Button */}
                  <Button
                    onPress={() => {
                      try {
                        setModalVisible(false);
                        navigation.navigate('ChatbotScreen', {
                          toolExampleImageURL:
                            Constants['currentChatTool']?.image_data
                              .example_url,
                          toolName: Constants['currentChatTool']?.name,
                          toolDesc: Constants['currentChatTool']?.description,
                          toolPrePrompt:
                            Constants['currentChatTool']?.pre_prompt,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgb(18, 18, 18)',
                          borderRadius: 100,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginBottom: 8,
                          marginTop: 8,
                          paddingLeft: 50,
                          paddingRight: 50,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CHAT'}
                  />
                </View>
                {/* Personality Traits View */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 10 },
                    dimensions.width
                  )}
                >
                  {/* Personality Traits Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'SecularOne_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Personality Traits:'}
                  </Text>
                  {/* Personality Traits View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderRadius: 8,
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Personality Traits Text 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        `${Constants['currentChatTool']?.character_data.personalityTraits}`
                      }
                    </Text>
                  </View>
                </View>
                {/* Interests View */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 10 },
                    dimensions.width
                  )}
                >
                  {/* Interests Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'SecularOne_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Interests:'}
                  </Text>
                  {/* Interests View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderRadius: 8,
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Interests Text 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        `${Constants['currentChatTool']?.character_data.interests}`
                      }
                    </Text>
                  </View>
                </View>
                {/* Background Style View */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 10 },
                    dimensions.width
                  )}
                >
                  {/* Background Style Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'SecularOne_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Background:'}
                  </Text>
                  {/* Background Style View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderRadius: 8,
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Background Style Text 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        `${Constants['currentChatTool']?.character_data.background}`
                      }
                    </Text>
                  </View>
                </View>
                {/* Communication Style View */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 10 },
                    dimensions.width
                  )}
                >
                  {/* Communication Style Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'SecularOne_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Communication Style:'}
                  </Text>
                  {/* Communication Style View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderRadius: 8,
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Communication Style Text 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        `${Constants['currentChatTool']?.character_data.communicationStyle}`
                      }
                    </Text>
                  </View>
                </View>
                {/* Purpose View */}
                <View
                  style={StyleSheet.applyWidth(
                    { padding: 10 },
                    dimensions.width
                  )}
                >
                  {/* Purpose Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        { fontFamily: 'SecularOne_400Regular' }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Purpose:'}
                  </Text>
                  {/* Purpose View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        backgroundColor: 'rgb(18, 18, 18)',
                        borderRadius: 8,
                        padding: 12,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Purpose Text 2 */}
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: 16,
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        `${Constants['currentChatTool']?.character_data.purpose}`
                      }
                    </Text>
                  </View>
                </View>
                {/* Chat Button View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', marginBottom: 10 },
                    dimensions.width
                  )}
                >
                  {/* Chat Button 2 */}
                  <Button
                    onPress={() => {
                      try {
                        setModalVisible(false);
                        navigation.navigate('ChatbotScreen', {
                          toolExampleImageURL:
                            Constants['currentChatTool']?.image_data
                              .example_url,
                          toolName: Constants['currentChatTool']?.name,
                          toolDesc: Constants['currentChatTool']?.description,
                          toolPrePrompt:
                            Constants['currentChatTool']?.pre_prompt,
                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgb(18, 18, 18)',
                          borderRadius: 100,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginBottom: 8,
                          marginTop: 8,
                          paddingLeft: 50,
                          paddingRight: 50,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CHAT'}
                  />
                </View>
              </View>
              <ImageBackground
                resizeMode={'cover'}
                source={{
                  uri: 'https://static.draftbit.com/images/placeholder-image-background.png',
                }}
                style={StyleSheet.applyWidth(
                  GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                  dimensions.width
                )}
              />
            </ScrollView>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(CharacterListScreen);
