import React, { useState, useEffect, useMemo, useRef } from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as GAIMMobileBackendApi from "../apis/GAIMMobileBackendApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import Images from "../config/Images";
import addChatPrefsToPrompt from "../global-functions/addChatPrefsToPrompt";
import copyToClipboard from "../global-functions/copyToClipboard";
import { listAvailableVoices, stopSpeaking } from "../custom-files/TextToSpeechComponent.js";
import loadingProgress from "../global-functions/loadingProgress";
import speakText from "../global-functions/speakText";
import transformModel from "../global-functions/transformModel";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import openImagePickerUtil from "../utils/openImagePicker";
import openCameraUtil from "../utils/openCamera";
import {
  Button,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  LinearProgress,
  Markdown,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from "@draftbit/ui";
import { useIsFocused } from "@react-navigation/native";
import {
  FlatList,
  Image,
  Keyboard,
  KeyboardAvoidingView,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
  TouchableWithoutFeedback,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import socket from "../utils/socket";
//import * as Haptics from 'expo-haptics';
import { set } from "react-native-reanimated";
import Mermaid from "mermaid";
import mermaid from "mermaid";
import DOMPurify from "dompurify";
import ReactMarkdown from "react-markdown";
import md5 from "md5";
import markdownIt from "markdown-it";
import hljs from "highlight.js";
import markdownItMermaid from "markdown-it-mermaid";
import taskLists from "markdown-it-task-lists";
import mdVideos from "markdown-it-video";
//import markdownItTable from 'markdown-it-table';
import miliMarkdown from "markdown-it-linkify-images";
import { speak } from "expo-speech";

const MarkdownMermaidComponent = ({ markdownText }) => {
  const [renderedHtml, setRenderedHtml] = useState("");

  useEffect(() => {
    // Initialize markdown-it
    const mdi = markdownIt({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return (
              '<pre class="hljs"><code>' +
              hljs.highlight(str, { language: lang, ignoreIllegals: true })
                .value +
              "</code></pre>"
            );
          } catch (__) {}
        }
        return (
          '<pre class="hljs"><code>' +
          mdi.utils.escapeHtml(str) +
          "</code></pre>"
        );
      },
      html: true,
      linkify: true,
      typography: true,
    });

    // Use the Mermaid plugin
    mdi.use(markdownItMermaid);
    mdi.use(taskLists, { enabled: true });
    mdi.use(mdVideos, {
      youtube: { width: 640, height: 390 },
      vimeo: { width: 500, height: 281 },
      vine: { width: 600, height: 600, embed: "simple" },
      prezi: { width: 550, height: 400 },
    });
    //mdi.use(markdownItTable);
    mdi.use(miliMarkdown, {
      target: "_blank",
    });

    // Render the markdown text
    const htmlContent = mdi.render(markdownText);

    setRenderedHtml(htmlContent);
  }, [markdownText]);
  //mermaid.initialize({ startOnLoad: true });
  useEffect(() => {
    //mermaid.init();
    //mermaid.contentLoaded();
  }, [renderedHtml]);

  return <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />;
};

const MarkdownMermaidComponentList = ({ markdownText }) => {
  const [renderedHtml, setRenderedHtml] = useState("");

  useEffect(() => {
    // Initialize markdown-it
    const mdi = markdownIt({
      highlight: function (str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return (
              '<pre class="hljs"><code>' +
              hljs.highlight(str, { language: lang, ignoreIllegals: true })
                .value +
              "</code></pre>"
            );
          } catch (__) {}
        }
        return (
          '<pre class="hljs"><code>' +
          mdi.utils.escapeHtml(str) +
          "</code></pre>"
        );
      },
    });

    // Use the Mermaid plugin
    mdi.use(markdownItMermaid);

    // Render the markdown text
    const htmlContent = mdi.render(markdownText);

    setRenderedHtml(htmlContent);
  }, [markdownText]);
  //mermaid.initialize({ startOnLoad: true });
  useEffect(() => {
    //mermaid.init();
    mermaid.contentLoaded();
  }, [renderedHtml]);

  return <div dangerouslySetInnerHTML={{ __html: renderedHtml }} />;
};

const ChatbotScreen = (props) => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [accumulating, setAccumulating] = useState(false);
  const [accumulatedMermaid, setAccumulatedMermaid] = useState("");
  const [mermaidCode, setMermaidCode] = useState("");
  const [voiceName, setVoiceName] = useState("en-US-Journey-F");
  let [result, setResult] = useState("");

  //const scrollViewRef = useRef();

  useEffect(() => {
    async function displayAvailableVoices() {
      const voices = await listAvailableVoices();
      const targetVoiceNames = ['en-US-Journey-F', 'Good News', 'com.apple.ttsbundle.Samantha-compact', 'urn:moz-tts:sapi:Microsoft Zira - English (United States)?en-US', 'Microsoft Zira - English (United States)', 'com.apple.eloquence.en-US.Reed'];
      if (voices.length > 0 && voices[0].identifier) {
        const targetVoice = voices.find(voice => targetVoiceNames.includes(voice.identifier));
        if (targetVoice) {
          setVoiceName(targetVoice.identifier);
          //alert(`Voice set by identifier to: ${targetVoice.identifier}`);
        }
      } else if (voices.length > 0 && voices[0].name) {
        const targetVoice = voices.find(voice => targetVoiceNames.includes(voice.name));
        if (targetVoice) {
          setVoiceName(targetVoice.name);
          //alert(`Voice set by name to: ${targetVoice.name}`);
        }
      } 
    }

    displayAvailableVoices();
    // Initialize mermaid
    Mermaid.initialize({
      startOnLoad: true,
      theme: "default",
      securityLevel: "loose",
      flowchart: {
        htmlLabels: true,
        curve: "linear",
      },
      sequence: {
        diagramMarginX: 50,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,

        // Message options
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,

        // Box options
        boxTextAlign: "center",
        boxBackgroundColor: "#ffffff",
        boxColor: "#000000",
        boxTextColor: "#000000",
        noteBackgroundColor: "#ffffff",
        noteBorderColor: "#000000",
        noteTextColor: "#000000",

        // Signal options
        signalColor: "#000000",
        signalTextColor: "#000000",

        // Message font options
        messageFontFamily: '"Open Sans", verdana, arial, sans-serif',
        messageFontSize: 16,
        messageFontWeight: "normal",

        // Actor font options
        actorFontFamily: '"Open Sans", verdana, arial, sans-serif',
        actorFontSize: 16,
        actorFontWeight: "normal",

        // Note font options
        noteFontFamily: '"trebuchet ms", verdana, arial, sans-serif',
        noteFontSize: 16,
        noteFontWeight: "normal",
      },
      gantt: {
        titleTopMargin: 25,
        barHeight: 20,
        barGap: 4,
        topPadding: 50,
        leftPadding: 75,
        gridLineStartPadding: 35,
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
        numberSectionStyles: 4,
      },
      class: {
        arrowMarkerAbsolute: false,
        useMaxWidth: true,
        boxTextMargin: 5,
        diagramMarginX: 10,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
      },
      state: {
        lineMargin: 10,
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
        width: 150,
        height: 65,
      },
      sequenceDiagram: {
        diagramMarginX: 50,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,
        showSequenceNumbers: false,
      },
      info: {
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
      },
      journey: {
        titleTopMargin: 25,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,
        showSequenceNumbers: false,
        titleBottomMargin: 25,
      },
      pie: {
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
        diameter: 100,
        radius: 50,
        gap: 5,
        startAngle: 0,
        total: 100,
        label: true,
        labelPosition: 50,
        labelFontFamily: '"Open-Sans", "sans-serif"',
        labelFontSize: 11,
        labelColor: "#000000",
        labelBold: true,
        labelWrap: false,
        labelMaxWidth: 0,
      },
      classDiagram: {
        arrowMarkerAbsolute: false,
        useMaxWidth: true,
        boxTextMargin: 5,
        diagramMarginX: 10,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
      },
      gitGraph: {
        arrowMarkerAbsolute: false,
        useMaxWidth: true,
        boxTextMargin: 5,
        diagramMarginX: 10,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
      },
      erDiagram: {
        arrowMarkerAbsolute: false,
        useMaxWidth: true,
        boxTextMargin: 5,
        diagramMarginX: 10,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
      },
      journeyDiagram: {
        titleTopMargin: 25,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,
        showSequenceNumbers: false,
        titleBottomMargin: 25,
      },
      sequenceDiagram: {
        diagramMarginX: 50,
        diagramMarginY: 10,
        actorMargin: 50,
        width: 150,
        height: 65,
        boxMargin: 10,
        boxTextMargin: 5,
        noteMargin: 10,
        messageMargin: 35,
        mirrorActors: true,
        bottomMarginAdj: 1,
        useMaxWidth: true,
        rightAngles: false,
        showSequenceNumbers: false,
      },
      ganttDiagram: {
        titleTopMargin: 25,
        barHeight: 20,
        barGap: 4,
        topPadding: 50,
        leftPadding: 75,
        gridLineStartPadding: 35,
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
        numberSectionStyles: 4,
      },
      stateDiagram: {
        lineMargin: 10,
        fontSize: 11,
        fontFamily: '"Open-Sans", "sans-serif"',
        width: 150,
        height: 65,
      },
    });
  }, []);

  const extractMermaidCode = (text) => {
    console.log("in extract function text", text);

    // Regex for <mermaid>...</mermaid> tags
    const mermaidTagRegex = /<mermaid>([\s\S]*?)<\/mermaid>/;

    // Regex for code blocks (e.g., ```mermaid ... ```)
    const mermaidBlockRegex = /```mermaid\n?([\s\S]*?)```/;

    // Try to match using the first regex
    let match = text.match(mermaidBlockRegex);
    if (match) {
      console.log("match1: ", match);
      return match[1];
    }

    // Try to match using the second regex
    match = text.match(mermaidTagRegex);
    if (match) {
      console.log("match2: ", match);
      return match[1];
    }

    // Return null if no Mermaid code is found
    return null;
  };

  const streamChatPostPOSTx = async (
    dynamic_id,
    messages,
    newMessage,
    pre_prompt,
    convo_id,
    model,
    tool_id,
    selectedImage
  ) => {
    try {
      // Choose the appropriate endpoint based on whether selectedImage is not null
      const endpoint = selectedImage ? "/chat/chatImage" : "/chat/chatStream";
      if (selectedImage) {
        setShowImageLoader(true);
      }

      // Prepare the body of the request
      const requestBody = {
        dynamic_id: dynamic_id,
        messages: messages,
        newMessage: newMessage,
        pre_prompt: pre_prompt,
        convo_id: convo_id,
        model: model,
        tool_id: tool_id,
        // Include base64image only if selectedImage is not null
        ...(selectedImage && { base64image: selectedImage }),
      };

      const response = await fetch(
        `https://gaim-mobile-backend-vr97s.ondigitalocean.app${endpoint}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            stytch_session_token: Constants["stytch_session_token"],
          },
          body: JSON.stringify(requestBody),
        }
      );

      const jsonResponse = await response.json(); // Parse the response body as JSON

      if (selectedImage) {
        setResult((prevResult) => prevResult + jsonResponse.data);
      }

      if (Constants['autoPlayResponses']) {
        speakText(jsonResponse.data, voiceName);
      }

      const convoId = jsonResponse.convo_id; // Access the convo_id

      setConvo_id(convoId);
      Mermaid.contentLoaded();
      const mermaidCodeX = extractMermaidCode(jsonResponse.data);
      if (mermaidCodeX) {
        // If the response contains a mermaid diagram, render it
        //const renderedMermaid = renderMermaidDiagram(mermaidCode);
        console.log("mermaidCode", mermaidCodeX);
        setMermaidCode(mermaidCodeX);
      }
      if ((!result || result === "") && jsonResponse.data) {
        setResult(jsonResponse.data);
      }
      setShowImageLoader(false);
    } catch (error) {
      console.error("Error posting chat data:", error);
      //throw error; // Re-throw the error for calling code to handle it
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      Mermaid.contentLoaded();
    }, 1500); // Delay in milliseconds (1.5 seconds)

    return () => clearTimeout(timer); // Clear the timeout if the component unmounts
  }, [currentConvo]);

  useEffect(() => {
    // Event listener for 'message' events
    const messageListener = (data) => {
      // Check if data is an object and has the necessary properties
      if (
        typeof data === "object" &&
        data.hasOwnProperty("chunkContent") &&
        data.hasOwnProperty("userId")
      ) {
        const { chunkContent, userId } = data;

        // Check if the user_id matches before processing
        if (userId === Constants["user"]?._id) {
          if (chunkContent === "--complete--") {
            return;
          }
          setResult((prevResult) => prevResult + chunkContent);
          
        }
      }
    };

    // Register the listener
    socket.on("chatStream", messageListener);

    // Cleanup the event listener
    return () => {
      socket.off("chatStream", messageListener);
    };
  }, [socket]);

  const addMessageToCurrConvo = (
    Variables,
    setGlobalVariableValue,
    newMessage
  ) => {
    setCurrentConvo((prevConvo) => [...prevConvo, newMessage]);
  };

  const addUserPromptToConvo = (userPrompt) => {
    const formatUserPrompt = { role: "user", content: userPrompt };
    setCurrentConvo((prevConvo) => [...prevConvo, formatUserPrompt]);
  };

  const initConvo = (setGlobalVariableValue, convoToLoad) => {
    if (!Array.isArray(convoToLoad) || convoToLoad.length === 0) return [];

    //const modifiedConvo = convoToLoad.slice(1);
    setCurrentConvo(convoToLoad);
    Mermaid.contentLoaded();
  };

  const addStreamResultToConvo = (
    Variables,
    setGlobalVariableValue,
    message
  ) => {
    const formatStreamResponse = { role: "assistant", content: message };
    setCurrentConvo((prevConvo) => [...prevConvo, formatStreamResponse]);
    Mermaid.contentLoaded();
  };

  const [convo_id, setConvo_id] = React.useState("");

  const gAIMMobileBackendPostChatPOST = GAIMMobileBackendApi.usePostChatPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const fetchedTool = await (async () => {
          if ((props.route?.params?.loadTool ?? "") === "load") {
            return (
              await GAIMMobileBackendApi.getAIToolByToolIDGET(Constants, {
                tool_id: props.route?.params?.tool_id ?? "",
              })
            )?.json;
          }
        })();
        if (fetchedTool?.success === "Tool not found") {
          setGlobalVariableValue({
            key: "currentChatTool",
            value: {
              _id: 'ObjectId("64f35946d7b8542613e4324b")',
              name: "AI Assistant",
              type: "chatbot",
              expertise: "general",
              date_added: 'ISODate("2023-09-02T00:00:00.000+0000")',
              image_data: {
                example_url:
                  "https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg",
              },
              pre_prompt:
                "Respond with precise, detailed, unique answers. Ask me questions when you need more information.",
              description:
                "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
              instructions : "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
              suggested_prompts : [
                  "What is the capital city of Australia?",
                  "Can you explain what Artificial Intelligence is?",
                  "What is the busiest burrow in New York City?",
                  "Who has won the most Grammys all time?"
              ],
            },
          });
        }
        if (fetchedTool?.success === true) {
          setGlobalVariableValue({
            key: "currentChatTool",
            value: fetchedTool?.data,
          });
        }
        //added
        if (
          (props.route?.params?.tool_id ?? '') === '65b0399f01cf1c3dcf333bbb' ||
          Constants["currentChatTool"]._id === '65b0399f01cf1c3dcf333bbb'
        ) {
          setShowAISight(true);
        }
        const convoToLoad = await (async () => {
          if ((props.route?.params?.loadConvo ?? "") === "load") {
            return (
              await GAIMMobileBackendApi.getConversationGET(Constants, {
                convo_id: props.route?.params?.convoIDtoLoad ?? "",
                dynamic_id: Constants["dynamic_id"],
              })
            )?.json;
          }
        })();
        if ((props.route?.params?.loadConvo ?? "") === "load") {
          initConvo(setGlobalVariableValue, convoToLoad?.data.messages);
          setConvo_id(convoToLoad?.data._id);
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  const [currentConvo, setCurrentConvo] = React.useState([
    {
      role: 'assistant',
      content: Constants['currentChatTool']?.instructions ?? 'Hello! What would you like to chat about today?',
    },
  ]);
  const [currentResponse, setCurrentResponse] = React.useState("");
  const [generator, setGenerator] = React.useState([]);
  const [isSpeaking, setIsSpeaking] = React.useState(false);
  const [isSpeakingStream, setIsSpeakingStream] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [promptToSubmit, setPromptToSubmit] = React.useState("");
  const [showAISight, setShowAISight] = React.useState(false);
  const [showImageLoader, setShowImageLoader] = React.useState(false);
  const [showLowCreditsModal, setShowLowCreditsModal] = React.useState(false);
  const [showLoadingProgress, setShowLoadingProgress] = React.useState(false);
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState("");
  const [toolFound, setToolFound] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState("");
  const [mermaidCodes, setMermaidCodes] = useState({});

  useEffect(() => {
    const newMermaidCodes = {};
    currentConvo.forEach((item, index) => {
      const extractedCode = extractMermaidCode(item.content);
      if (extractedCode) {
        newMermaidCodes[index] = extractedCode;
      }
    });
    setMermaidCodes(newMermaidCodes);
  }, [currentConvo]);

  const handleSpeak = async (message, voiceName) => {
     // Set isSpeaking to true right before calling speakText
    
    await speakText(message, voiceName).then(() => {
      setIsSpeaking(false); // Set isSpeaking to false after speakText finishes
      setIsSpeakingStream(false);
    });
  };

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: "rgb(18, 18, 18)", justifyContent: "space-between" },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            flexDirection: "row",
            height: 48,
            justifyContent: "center",
            marginBottom: 6,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: "center",
              flexDirection: "row",
              height: 48,
              justifyContent: "space-between",
              position: "absolute",
              top: 0,
              width: "90%",
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors["Custom Color_2"]}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: "rgb(153, 171, 214)" },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors["Strong"]}
                name={"Ionicons/arrow-back-sharp"}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: "rgb(153, 171, 214)" },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: "row" },
              dimensions.width
            )}
          ></View>
          {/* Settings View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Autoplay True Touchable */}
            <>
              {!Constants['autoPlayResponses'] ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      stopSpeaking();
                      setGlobalVariableValue({
                        key: 'autoPlayResponses',
                        value: false,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { marginRight: 12 },
                    dimensions.width
                  )}
                >
                  {/* Settings Icon */}
                  <>
                    {switchValue2 ? null : (
                      <Icon
                        color={theme.colors['App Green']}
                        name={'FontAwesome/volume-up'}
                        size={32}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 5 },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </Touchable>
              )}
            </>
            {/* Autoplay False Touchable */}
            <>
              {Constants['autoPlayResponses'] ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'autoPlayResponses',
                        value: true,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    { marginRight: 12 },
                    dimensions.width
                  )}
                >
                  {/* Autoplay Flase  Icon */}
                  <>
                    {switchValue2 ? null : (
                      <Icon
                        color={theme.colors['Primary']}
                        name={'Ionicons/volume-mute-outline'}
                        size={32}
                        style={StyleSheet.applyWidth(
                          { marginBottom: 5 },
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </Touchable>
              )}
            </>
            {/* Touchable Suggs True */}

            <>

              {!Constants['showSuggsGlobal'] ? null : (

                <Touchable

                  onPress={() => {

                    try {

                      setGlobalVariableValue({

                        key: 'showSuggsGlobal',

                        value: false,

                      });

                    } catch (err) {

                      console.error(err);

                    }

                  }}

                >

                  {/* Suggs Icon True */}

                  <>

                    {switchValue2 ? null : (

                      <Icon

                        color={theme.colors['Custom Color_13']}

                        name={'FontAwesome/lightbulb-o'}

                        size={32}

                        style={StyleSheet.applyWidth(

                          { marginBottom: 5 },

                          dimensions.width

                        )}

                      />

                    )}

                  </>

                </Touchable>

              )}

            </>

            {/* Touchable Suggs False */}

            <>

              {Constants['showSuggsGlobal'] ? null : (

                <Touchable

                  onPress={() => {

                    try {

                      setGlobalVariableValue({

                        key: 'showSuggsGlobal',

                        value: true,

                      });

                    } catch (err) {

                      console.error(err);

                    }

                  }}

                >

                  {/* Suggs Icon False */}

                  <>

                    {switchValue2 ? null : (

                      <Icon

                        color={theme.colors['Primary']}

                        name={'FontAwesome/lightbulb-o'}

                        size={32}

                        style={StyleSheet.applyWidth(

                          { marginBottom: 5 },

                          dimensions.width

                        )}

                      />

                    )}

                  </>

                </Touchable>

              )}

            </>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ChatSettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginLeft: 10 },
                dimensions.width
              )}
            >
              {/* Settings Icon */}
              <>
                {switchValue2 ? null : (
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Ionicons/ios-settings-outline'}
                    size={32}
                    style={StyleSheet.applyWidth(
                      { marginBottom: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </Touchable>
          </View>
        </View>
      </View>
      {/* AI Tool List Item */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            borderBottomWidth: 0.5,
            borderColor: theme.colors["Custom Color_19"],
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: 6,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingTop: 2,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: "flex-start" },
            dimensions.width
          )}
        >
          {/* Image 2 */}
          <Image
            resizeMode={"cover"}
            source={{
              uri: `${Constants["currentChatTool"]?.image_data.example_url}`,
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)["Image"], {
                borderColor: theme.colors["Primary"],
                borderRadius: 100,
                borderWidth: 1,
                height: 65,
                width: 65,
              }),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 14, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: "row", paddingRight: 8 },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors["Primary"]}
              name={"MaterialCommunityIcons/message-processing-outline"}
              size={30}
            />
            {/* Name ~ */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Custom Color_2"],
                  fontFamily: "Poppins_500Medium",
                  fontSize: 15,
                  lineHeight: 20,
                  marginLeft: 5,
                  marginTop: 4,
                  opacity: 1,
                  textTransform: "uppercase",
                },
                dimensions.width
              )}
            >
              {Constants["currentChatTool"]?.name}
            </Text>
          </View>
          {/* Message ~ */}
          <Text
            ellipsizeMode={"clip"}
            numberOfLines={3}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors["Custom Color_2"],
                fontFamily: "Poppins_400Regular",
                fontSize: 12,
                lineHeight: 20,
                opacity: 0.5,
              },
              dimensions.width
            )}
          >
            {Constants["currentChatTool"]?.description}
          </Text>
        </View>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            justifyContent: "flex-start",
            marginBottom: 20,
            paddingBottom: 80,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingTop: 30,
          },
          dimensions.width
        )}
        enableAutomaticScroll={false}
        enableOnAndroid={true}
        extraScrollHeight={155}
        keyboardShouldPersistTaps={"always"}
        showsVerticalScrollIndicator={true}
        //ref={scrollViewRef}
      >
        <FlatList
          contentContainerStyle={StyleSheet.applyWidth(
            { justifyContent: "space-between" },
            dimensions.width
          )}
          data={currentConvo}
          keyExtractor={(listData) =>
            listData?.id || listData?.uuid || JSON.stringify(listData)
          }
          listKey={"LX6Ja1sN"}
          numColumns={1}
          renderItem={({ item, index }) => {
            const listData = item;
            const extractedMermaidCode = mermaidCodes[index];
            const elementId = extractedMermaidCode
              ? "mermaid-" + md5(extractedMermaidCode)
              : null;
            //const shouldInitializeMermaid = !mermaidCodes[`${elementId}`];
            //Mermaid.contentLoaded();
            return (
              <>
                <>
                  {!(listData?.role === "assistant") ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: "stretch",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          overflow: "hidden",
                          paddingBottom: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <View>

<CircleImage size={32} source={`${Constants["currentChatTool"]?.image_data.example_url}`} />

<>

  {isSpeaking ? null : (

    <IconButton

      icon={'Ionicons/ios-play-circle-outline'}

      onPress={() => {

        try {

          setIsSpeaking(true);

          //speakText(listData?.content);
          handleSpeak(listData?.content, voiceName);

        } catch (err) {

          console.error(err);

        }

      }}

      size={32}

      style={StyleSheet.applyWidth(

        { marginTop: 4 },

        dimensions.width

      )}

    />

  )}

</>

{/* Stop Icon Button */}

<>

  {!isSpeaking ? null : (

    <IconButton

      color={theme.colors['Error']}

      icon={'FontAwesome/stop-circle'}

      onPress={() => {

        try {

          stopSpeaking();

          setIsSpeaking(false);

        } catch (err) {

          console.error(err);

        }

      }}

      size={32}

      style={StyleSheet.applyWidth(

        { marginTop: 4 },

        dimensions.width

      )}

    />

  )}

</>

</View>

                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors["Custom Color_2"],
                              borderBottomRightRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 20,
                              paddingBottom: 5,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 5,
                              fontFamily: "Inter_400Regular",
                            },
                            dimensions.width
                          )}
                        >
                          {/*<Markdown
                            style={StyleSheet.applyWidth(
                              { fontSize: 15 },
                              dimensions.width
                            )}
                          >
                            {listData?.content}
                            
                            </Markdown>*/}
                          <MarkdownMermaidComponentList
                            markdownText={listData?.content}
                          />
                          {/* Copy Icon Touchable */}
                          <>
                            {!(
                              listData?.content !== "Hi! How can I assist you?"
                            ) ? null : (
                              <Touchable
                                onPress={() => {
                                  try {
                                    copyToClipboard(listData?.content);
                                    //Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <View
                                  style={StyleSheet.applyWidth(
                                    { alignItems: "flex-end" },
                                    dimensions.width
                                  )}
                                >
                                  {/* Copy Icon */}
                                  <Icon
                                    color={theme.colors["Primary"]}
                                    name={"Feather/copy"}
                                    size={24}
                                  />
                                </View>
                              </Touchable>
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  )}
                </>
                <>
                  {!(listData?.role === "user") ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          overflow: "hidden",
                          paddingBottom: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: "rgb(153, 171, 214)",
                              borderBottomLeftRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 10,
                              paddingBottom: 5,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 5,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Message ~ */}
                          <Text
                            selectable={true}
                            style={StyleSheet.applyWidth(
                              {
                                color: "rgb(18, 18, 18)",
                                fontFamily: "Inter_400Regular",
                                fontSize: 14,
                                lineHeight: 20,
                                opacity: 0.7,
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.content}
                          </Text>
                          {/* Copy Icon Touchable */}
                      <>
                        <Touchable
                          onPress={() => {
                            try {
                              copyToClipboard(listData?.content);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: "flex-end" },

                              dimensions.width
                            )}
                          >
                            {/* Copy Icon */}

                            <Icon
                              color={theme.colors["Strong"]}
                              name={"Feather/copy"}
                              size={24}
                            />
                          </View>
                        </Touchable>
                      </>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              </>
            );
          }}
        />

        {/* Stream View */}
        <>
          {!result ? null : (
            <View
              style={StyleSheet.applyWidth(
                { flexDirection: "row" },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View>
              <CircleImage size={32} source={`${Constants["currentChatTool"]?.image_data.example_url}`} />

<>

  {isSpeakingStream ? null : (

    <IconButton

      icon={'Ionicons/ios-play-circle-outline'}

      onPress={() => {

        try {

          setIsSpeakingStream(true);

          //speakText(listData?.content);
          handleSpeak(result, voiceName);

        } catch (err) {

          console.error(err);

        }

      }}

      size={32}

      style={StyleSheet.applyWidth(

        { marginTop: 4 },

        dimensions.width

      )}

    />

  )}

</>

{/* Stop Icon Button */}

<>

  {!isSpeakingStream ? null : (

    <IconButton

      color={theme.colors['Error']}

      icon={'FontAwesome/stop-circle'}

      onPress={() => {

        try {

          stopSpeaking();

          setIsSpeakingStream(false);

        } catch (err) {

          console.error(err);

        }

      }}

      size={32}

      style={StyleSheet.applyWidth(

        { marginTop: 4 },

        dimensions.width

      )}

    />

  )}

</>
              </View>
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors["Custom Color_2"],
                    borderBottomRightRadius: 24,
                    borderTopLeftRadius: 24,
                    borderTopRightRadius: 24,
                    flex: 1,
                    marginLeft: 10,
                    marginRight: 20,
                    paddingBottom: 5,
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 5,
                    fontFamily: "Inter_400Regular",
                  },
                  dimensions.width
                )}
              >
                {/*<Markdown
                  style={StyleSheet.applyWidth(
                    { fontSize: 15 },
                    dimensions.width
                  )}
                >
                  {result}
                  </Markdown>
                {mermaidCode && <MarkdownMermaidComponent code={mermaidCode} />}*/}
                <MarkdownMermaidComponent
                  style={StyleSheet.applyWidth(
                    { fontSize: 15 },
                    dimensions.width
                  )}
                  markdownText={result}
                />
                {/* Copy Icon Touchable */}
                <Touchable
                  onPress={() => {
                    try {
                      copyToClipboard(result);
                      //Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy)
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: "flex-end" },
                      dimensions.width
                    )}
                  >
                    {/* Copy Icon */}
                    <Icon
                      color={theme.colors["Primary"]}
                      name={"Feather/copy"}
                      size={24}
                    />
                  </View>
                </Touchable>
              </View>
            </View>
          )}
        </>
        {/* Suggestions Scroll View */}

        <>

          {!Constants['showSuggsGlobal'] ? null : (

            <ScrollView

              bounces={true}

              contentContainerStyle={StyleSheet.applyWidth(

                {

                  backgroundColor: 'rgb(40, 40, 40)',

                  borderColor: theme.colors['Primary'],

                  borderRadius: 18,

                  borderWidth: 1,

                  marginBottom: 40,

                  marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },

                  marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
                  marginTop: 12,

                  padding: 8,

                },

                dimensions.width

              )}

              keyboardShouldPersistTaps={'never'}

              nestedScrollEnabled={true}

              showsHorizontalScrollIndicator={true}

              showsVerticalScrollIndicator={true}

            >

              {/* Suggestions View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    justifyContent: 'flex-end',
                    maxHeight: 400,
                  },
                  dimensions.width
                )}
              >
                <View

                  style={StyleSheet.applyWidth(

                    {

                      alignItems: 'center',

                      flexDirection: 'row',

                      justifyContent: 'center',

                    },

                    dimensions.width

                  )}

                >

                  <Icon

                    color={theme.colors['Primary']}

                    name={'FontAwesome/lightbulb-o'}

                    size={24}

                    style={StyleSheet.applyWidth(

                      { marginRight: 6 },

                      dimensions.width

                    )}

                  />

                  {/* Suggestions Header */}

                  <Text

                    accessible={true}

                    allowFontScaling={true}

                    style={StyleSheet.applyWidth(

                      StyleSheet.compose(

                        GlobalStyles.TextStyles(theme)['Text'],

                        {

                          color: theme.colors['Primary'],

                          fontFamily: 'SecularOne_400Regular',

                          marginBottom: 8,

                          paddingTop: 8,

                          textAlign: 'center',

                        }

                      ),

                      dimensions.width

                    )}

                  >

                    {'Suggestions (Click to run)'}

                  </Text>

                  <IconButton

                    icon={'AntDesign/up'}

                    onPress={() => {

                      try {

                        setGlobalVariableValue({

                          key: 'showSuggsGlobal',

                          value: false,

                        });

                      } catch (err) {

                        console.error(err);

                      }

                    }}

                    size={26}

                    style={StyleSheet.applyWidth(

                      { marginLeft: 10 },

                      dimensions.width

                    )}

                  />

                </View>

                {/* Suggestions List */}

                <FlatList

                  data={Constants['currentChatTool']?.suggested_prompts}

                  keyExtractor={suggestionsListData =>

                    suggestionsListData?.id ||

                    suggestionsListData?.uuid ||

                    JSON.stringify(suggestionsListData)

                  }

                  keyboardShouldPersistTaps={'never'}

                  listKey={'MRKTKkRN'}

                  numColumns={1}

                  onEndReachedThreshold={0.5}

                  renderItem={({ item, index }) => {

                    const suggestionsListData = item;

                    return (

                      <>

                        {!suggestionsListData ? null : (

                          <Touchable

                          onPress={() => {
                            const handler = async () => {
                                try {
                                  //setPromptToSubmit(suggestionsListData);
                                  console.log("suggestionsListData", suggestionsListData);
                                    if (parseInt(Constants["user"]?.ai_credits, 10) >= 5) {
                                        if (result) {
                                            addStreamResultToConvo(
                                                Variables,
                                                setGlobalVariableValue,
                                                result
                                            );
                                        }
                                        //Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
                                        setResult("");
                                        setMermaidCode("");
                                        addUserPromptToConvo(suggestionsListData);
                                        Keyboard.dismiss();
                                        setPromptToSubmit("");
                                        setShowLoadingProgress(true);
                                        loadingProgress(Variables, setGlobalVariableValue, 13500);
                                        Mermaid.contentLoaded();
                                        const postResp = await streamChatPostPOSTx(
                                            Constants["user"]?.dynamic_id,
                                            currentConvo,
                                            addChatPrefsToPrompt(Variables, suggestionsListData),
                                            Constants["currentChatTool"]?.pre_prompt,
                                            convo_id,
                                            transformModel(
                                                Variables,
                                                Constants["user"]?.chat_prefs.premium_responses
                                            ),
                                            Constants["currentChatTool"]?._id,
                                            selectedImage
                                        );
                                        const respMessage = postResp?.data.message;
                                        setShowLoadingProgress(false);
                                    } else {
                                        setShowLowCreditsModal(true);
                                    }
                                } catch (err) {
                                    console.error(err);
                                }
                            };
                            handler();
                        }}

                            style={StyleSheet.applyWidth(

                              { marginBottom: 5, marginTop: 5 },

                              dimensions.width

                            )}

                          >

                            <View

                              style={StyleSheet.applyWidth(

                                {

                                  alignItems: 'center',

                                  borderColor: theme.colors['Primary'],

                                  borderRadius: 8,

                                  borderWidth: 1,

                                  flexDirection: 'row',

                                  justifyContent: 'space-between',

                                  paddingBottom: 8,

                                  paddingLeft: 10,

                                  paddingRight: 10,

                                  paddingTop: 8,

                                },

                                dimensions.width

                              )}

                            >

                              {/* Suggestions Text */}

                              <Text

                                accessible={true}

                                adjustsFontSizeToFit={true}

                                allowFontScaling={true}

                                style={StyleSheet.applyWidth(

                                  StyleSheet.compose(

                                    GlobalStyles.TextStyles(theme)['Text'],

                                    {

                                      color: theme.colors['Divider'],

                                      fontFamily: 'RobotoCondensed_400Regular',

                                      fontSize: 15,

                                      marginRight: 8,

                                    }

                                  ),

                                  dimensions.width

                                )}

                              >

                                {suggestionsListData}

                              </Text>

                              {/* Suggestions Icon */}

                              <Icon

                                color={theme.colors['Primary']}

                                name={'Ionicons/send-sharp'}

                                size={24}
                              />
                            </View>
                          </Touchable>
                        )}
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              </View>
            </ScrollView>
          )}
        </>
      </KeyboardAwareScrollView>

      <KeyboardAvoidingView
          behavior={'position'}
          enabled={true}
          keyboardVerticalOffset={60}
          style={StyleSheet.applyWidth({ 
            marginBottom: 20,
            marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
            marginRight: { minWidth: Breakpoints.Laptop, value: 120 }, }, dimensions.width)}
      >
    <>
        {!showAISight ? null : (
            <View
                style={StyleSheet.applyWidth(
                    {
                        alignItems: 'flex-end',
                        backgroundColor: 'rgba(18, 18, 18, 0.2)',
                        borderTopLeftRadius: 10,
                        borderTopRightRadius: 10,
                        bottom: 52,
                        flexDirection: 'row',
                        height: 81,
                        justifyContent: 'space-between',
                        marginBottom: 8,
                        marginLeft: '7%',
                        marginRight: '7%',
                        paddingBottom: 3,
                        paddingLeft: 10,
                        paddingRight: 10,
                        paddingTop: 3,
                        position: 'absolute',
                        width: '86%',
                    },
                    dimensions.width
                )}
            >
                <View
                    style={StyleSheet.applyWidth(
                        { alignItems: 'flex-end', flexDirection: 'row' },
                        dimensions.width
                    )}
                >
                    <>
                        {selectedImage ? null : (
                            <IconButton
                                icon={'FontAwesome/photo'}
                                onPress={() => {
                                    const handler = async () => {
                                        try {
                                            const selectedImage = await openImagePickerUtil({
                                                mediaTypes: 'Images',
                                                allowsEditing: true,
                                                quality: 0.2,
                                            });
                                            setSelectedImage(selectedImage);
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    };
                                    handler();
                                }}
                                size={32}
                                style={StyleSheet.applyWidth(
                                    { marginBottom: 3 },
                                    dimensions.width
                                )}
                            />
                        )}
                    </>
                    {/* Icon Button 3 */}
                    <>
                        {selectedImage ? null : (
                            <IconButton
                                icon={'Ionicons/ios-camera'}
                                onPress={() => {
                                    const handler = async () => {
                                        try {
                                            const selectedImage = await openCameraUtil({
                                                mediaTypes: 'Images',
                                                allowsEditing: true,
                                                cameraType: 'back',
                                                videoMaxDuration: undefined,
                                                quality: 0.2,
                                            });
                                            setSelectedImage(selectedImage);
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    };
                                    handler();
                                }}
                                size={38}
                                style={StyleSheet.applyWidth(
                                    { marginLeft: 25 },
                                    dimensions.width
                                )}
                            />
                        )}
                    </>
                    <>
                        {!selectedImage ? null : (
                            <View
                                style={StyleSheet.applyWidth(
                                    { alignItems: 'flex-end', flexDirection: 'row' },
                                    dimensions.width
                                )}
                            >
                                <Image
                                    resizeMode={'cover'}
                                    source={{ uri: `${selectedImage}` }}
                                    style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                            GlobalStyles.ImageStyles(theme)['Image'],
                                            {
                                                borderColor: theme.colors['Primary'],
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                height: 75,
                                                marginLeft: 16,
                                                width: 75,
                                            }
                                        ),
                                        dimensions.width
                                    )}
                                />
                                {/* Close Button */}
                                <IconButton
                                    icon={'AntDesign/closecircleo'}
                                    onPress={() => {
                                        try {
                                            setSelectedImage(null);
                                        } catch (err) {
                                            console.error(err);
                                        }
                                    }}
                                    size={32}
                                    style={StyleSheet.applyWidth(
                                        { marginLeft: 5 },
                                        dimensions.width
                                    )}
                                />
                            </View>
                        )}
                    </>
                </View>
                <>
                    {!showImageLoader ? null : (
                        <View
                            style={StyleSheet.applyWidth(
                                { height: 75, width: 75 },
                                dimensions.width
                            )}
                        >
                            <Image
                                resizeMode={'cover'}
                                source={{
                                    uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/aippLoader.gif',
                                }}
                                style={StyleSheet.applyWidth(
                                    StyleSheet.compose(
                                        GlobalStyles.ImageStyles(theme)['Image'],
                                        { height: 75, width: 75 }
                                    ),
                                    dimensions.width
                                )}
                            />
                        </View>
                    )}
                </>
            </View>
        )}
    </>
    {/* View 2 */}
    <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: 6,
              },
              dimensions.width
            )}
          >
        <View
            style={StyleSheet.applyWidth({ marginLeft: 6 }, dimensions.width)}
        >
            <Touchable
                onPress={() => {
                    try {
                        if (showAISight === true) {
                            setShowAISight(false);
                        } else {
                            setShowAISight(true);
                        }
                        setSelectedImage(null);
                    } catch (err) {
                        console.error(err);
                    }
                }}
            >
                <Image
                    resizeMode={'cover'}
                    source={{
                        uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/AISightbyGAIMIcon2.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                        borderColor: {
                          minWidth: Breakpoints.Laptop,
                          value: theme.colors['Primary'],
                        },
                        borderRadius: 100,
                        borderWidth: { minWidth: Breakpoints.Laptop, value: 2 },
                        height: 40,
                        width: 40,
                      }),
                        dimensions.width
                    )}
                />
            </Touchable>
        </View>
        {/* Message Tray */}
        <View
            style={StyleSheet.applyWidth(
                {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Background'],
                    borderBottomWidth: 1,
                    borderColor: theme.colors['Custom Color_4'],
                    borderLeftWidth: 1,
                    borderRadius: 24,
                    borderRightWidth: 1,
                    borderTopWidth: 1,
                    bottom: 0,
                    flexDirection: 'row',
                    height: 52,
                    justifyContent: 'space-between',
                    marginRight: 4,
                    marginTop: 10,
                    paddingLeft: 8,
                    paddingRight: 8,
                    position: 'absolute',
                    right: 0,
                    width: '85%',
                },
                dimensions.width
            )}
        >
            <View
                style={StyleSheet.applyWidth(
                    { flex: 1, justifyContent: 'center' },
                    dimensions.width
                )}
            >
                {/* Prompt */}
                <TextInput
                    autoCorrect={true}
                    changeTextDelay={300}
                    editable={true}
                    enablesReturnKeyAutomatically={true}
                    keyboardAppearance={'dark'}
                    keyboardType={'default'}
                    multiline={true}
                    onBlur={() => {
                        try {
                            Keyboard.dismiss();
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                    onChangeText={newPromptValue => {
                        try {
                            setPromptToSubmit(newPromptValue);
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                    onSubmitEditing={() => {
                        try {
                            Keyboard.dismiss();
                            
                        } catch (err) {
                            console.error(err);
                        }
                    }}
                    placeholder={'Type your request'}
                    placeholderTextColor={theme.colors['Custom Color_4']}
                    returnKeyLabel={'Submit'}
                    returnKeyType={'default'}
                    scrollEnabled={true}
                    style={StyleSheet.applyWidth(
                        {
                            borderRadius: 8,
                            color: theme.colors['Strong'],
                            fontFamily: 'Inter_400Regular',
                            fontSize: 14,
                            marginLeft: 8,
                            paddingBottom: 8,
                            paddingLeft: 8,
                            paddingRight: 8,
                            paddingTop: 8,
                        },
                        dimensions.width
                    )}
                    value={promptToSubmit}
                />
            </View>
            <View
                style={StyleSheet.applyWidth(
                    {
                        alignItems: 'center',
                        height: 48,
                        justifyContent: 'center',
                        width: 48,
                    },
                    dimensions.width
                )}
            >
                <Touchable
                onPress={() => {
                    const handler = async () => {
                        try {
                            if (parseInt(Constants["user"]?.ai_credits, 10) >= 5) {
                                if (result) {
                                    addStreamResultToConvo(
                                        Variables,
                                        setGlobalVariableValue,
                                        result
                                    );
                                }
                                //Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Heavy);
                                setResult("");
                                setMermaidCode("");
                                addUserPromptToConvo(promptToSubmit);
                                Keyboard.dismiss();
                                setPromptToSubmit("");
                                setShowLoadingProgress(true);
                                loadingProgress(Variables, setGlobalVariableValue, 13500);
                                Mermaid.contentLoaded();
                                const postResp = await streamChatPostPOSTx(
                                    Constants["user"]?.dynamic_id,
                                    currentConvo,
                                    addChatPrefsToPrompt(Variables, promptToSubmit),
                                    Constants["currentChatTool"]?.pre_prompt,
                                    convo_id,
                                    transformModel(
                                        Variables,
                                        Constants["user"]?.chat_prefs.premium_responses
                                    ),
                                    Constants["currentChatTool"]?._id,
                                    selectedImage
                                );
                                const respMessage = postResp?.data.message;
                                setShowLoadingProgress(false);
                            } else {
                                setShowLowCreditsModal(true);
                            }
                        } catch (err) {
                            console.error(err);
                        }
                    };
                    handler();
                }}
            >
                <Icon
                    color={theme.colors["Strong"]}
                    name={"MaterialCommunityIcons/send-circle"}
                    size={36}
                />
            </Touchable>

            </View>
        </View>
    </View>
</KeyboardAvoidingView>


      {/* Low Credits Modal */}
      <>
        {!showLowCreditsModal ? null : (
          <Modal
            animationType={"slide"}
            presentationStyle={"fullScreen"}
            transparent={false}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: "center",
                  backgroundColor: "rgb(40, 40, 40)",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    backgroundColor: "rgb(18, 18, 18)",
                    borderColor: theme.colors["Primary"],
                    borderRadius: 5,
                    borderWidth: 1,
                    height: "50%",
                    justifyContent: "space-around",
                    width: "75%",
                  },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={"cover"}
                  source={{
                    uri: "https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/52.jpg",
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)["Image"],
                      {
                        borderColor: theme.colors["Primary"],
                        borderRadius: 100,
                        borderWidth: 1,
                        height: 100,
                        width: 100,
                      }
                    ),
                    dimensions.width
                  )}
                />
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                      color: theme.colors["Surface"],
                      fontFamily: "RobotoCondensed_400Regular",
                      paddingLeft: 12,
                      paddingRight: 12,
                      textAlign: "center",
                    }),
                    dimensions.width
                  )}
                >
                  {
                    "Looks like you are running low on credits. Please upgrade your subscription or purchase add-on credits to continue."
                  }
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { paddingLeft: 22, paddingRight: 22 },
                    dimensions.width
                  )}
                >
                  <Button
                    onPress={() => {
                      try {
                        setShowLowCreditsModal(false);
                        navigation.navigate("SubscribeScreen");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        {
                          borderRadius: 80,
                          color: theme.colors["Strong"],
                          marginBottom: 16,
                        }
                      ),
                      dimensions.width
                    )}
                    title={"Get More Credits"}
                  />
                  {/* Close Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowLowCreditsModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        { borderRadius: 80, color: theme.colors["Strong"] }
                      ),
                      dimensions.width
                    )}
                    title={"Close"}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ChatbotScreen);
