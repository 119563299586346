export default {
  Gold: require('../assets/images/Gold.jpg'),
  Sky: require('../assets/images/Sky.jpg'),
  GaimMobileBG: require('../assets/images/GaimMobileBG.jpg'),
  GaimMobileBG2: require('../assets/images/GaimMobileBG2.jpg'),
  Group162742: require('../assets/images/Group162742.png'),
  AIPPS61: require('../assets/images/AIPPS61.png'),
  Image: require('../assets/images/Image.png'),
  Camera: require('../assets/images/Camera.png'),
  Done: require('../assets/images/Done.png'),
  Sendmsg: require('../assets/images/Sendmsg.png'),
  Gallery: require('../assets/images/Gallery.png'),
  File: require('../assets/images/File.png'),
  Location: require('../assets/images/Location.png'),
  Audio: require('../assets/images/Audio.png'),
  Contact: require('../assets/images/Contact.png'),
  HomeBG: require('../assets/images/HomeBG.png'),
  UserImage: require('../assets/images/UserImage.png'),
  PortraitSmilingAfroAmericanSportsManStretchingHisMuscularArmsBeforeWorkoutBySeaUsingMusicAppHisSmartphone1: require('../assets/images/PortraitSmilingAfroAmericanSportsManStretchingHisMuscularArmsBeforeWorkoutBySeaUsingMusicAppHisSmartphone1.png'),
  CategoryHand: require('../assets/images/CategoryHand.png'),
  Rectangle22429: require('../assets/images/Rectangle22429.png'),
  Rectangle224291: require('../assets/images/Rectangle224291.png'),
  Rectangle224293: require('../assets/images/Rectangle224293.png'),
  BookbitGradient: require('../assets/images/BookbitGradient.png'),
  BookbitPreview2: require('../assets/images/BookbitPreview2.png'),
  BradyllewisAFriendlyHumanoidApeThatIsDressedInColorful01d7c3ddE56d43af9e921eaf541943a2: require('../assets/images/BradyllewisAFriendlyHumanoidApeThatIsDressedInColorful01d7c3ddE56d43af9e921eaf541943a2.png'),
  BradyllewisAFriendlyHumanoidApeThatIsDressedInColorful5f2447ca859842858094C3e6617fac8b: require('../assets/images/BradyllewisAFriendlyHumanoidApeThatIsDressedInColorful5f2447ca859842858094C3e6617fac8b.png'),
  BradyllewisAFriendlyHumanoidApeThatIsDressedInBlandCl2f3b9b895ff248e7869c00416d71b7aa: require('../assets/images/BradyllewisAFriendlyHumanoidApeThatIsDressedInBlandCl2f3b9b895ff248e7869c00416d71b7aa.png'),
  BradyllewisAFriendlyHumanoidApeThatIsDressedInColorfulA654607d057a40788d404248fe4b081e: require('../assets/images/BradyllewisAFriendlyHumanoidApeThatIsDressedInColorfulA654607d057a40788d404248fe4b081e.png'),
  BradyllewisAFriendlyHumanoidApeThatIsDressedInColorfulC1c0d04c70cd411eAf528b2fa1023a74: require('../assets/images/BradyllewisAFriendlyHumanoidApeThatIsDressedInColorfulC1c0d04c70cd411eAf528b2fa1023a74.png'),
  FPimage: require('../assets/images/FPimage.png'),
  IconSMS: require('../assets/images/IconSMS.png'),
  ViaEmail: require('../assets/images/ViaEmail.png'),
  Subs1: require('../assets/images/Subs1.png'),
  Subs2: require('../assets/images/Subs2.png'),
  Subs3: require('../assets/images/Subs3.png'),
  Sub4: require('../assets/images/Sub4.png'),
  Silver: require('../assets/images/Silver.jpg'),
  Platinum: require('../assets/images/Platinum.jpg'),
  Diamond: require('../assets/images/Diamond.jpg'),
  Loader: require('../assets/images/Loader.gif'),
  Games: require('../assets/images/Games.png'),
  BannerAIAssistant: require('../assets/images/BannerAIAssistant.jpg'),
  BannerAIChat: require('../assets/images/BannerAIChat.jpg'),
  BannerAISight: require('../assets/images/BannerAISight.jpg'),
};
