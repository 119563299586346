import React from 'react';

const formatDate = strDate => {
  const date = new Date(strDate);

  // Extracting date and time components
  const month = date.getMonth() + 1; // getMonth() returns a zero-based month
  const day = date.getDate();
  const year = date.getFullYear().toString().substr(-2); // get last two digits of year
  const hours = date.getUTCHours().toString().padStart(2, '0'); // ensuring 2 digits
  const minutes = date.getUTCMinutes().toString().padStart(2, '0'); // ensuring 2 digits

  // Constructing the formatted string
  return `${month}-${day}-${year} ${hours}:${minutes}`;
};

export default formatDate;
