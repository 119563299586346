import React from 'react';

const addChatPrefsToPrompt = (Variables, prompt) => {
  let finalPrompt = prompt;

  if (Variables.user.chat_prefs.persistent_instructions_enabled) {
    finalPrompt = `${finalPrompt} // ${Variables.user.chat_prefs.persistent_instructions}`;
  }

  if (Variables.user.chat_prefs.response_education_enabled) {
    finalPrompt = `${finalPrompt} // Respond at an average education level of ${Variables.user.chat_prefs.persistent_instructions}`;
  }

  return finalPrompt;
};

export default addChatPrefsToPrompt;
