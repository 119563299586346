import React from 'react';

// truncate a number to a max of two decimal places
const truncateNum = (longNum, decPlaces) => {
  if (typeof longNum !== 'number') return null; // return null if the provided argument is not a number

  return parseFloat(longNum.toFixed(decPlaces));
};

export default truncateNum;
