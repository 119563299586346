import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ImageDownloadExports from '../custom-files/ImageDownloadExports';
import deleteAssetAlert from '../global-functions/deleteAssetAlert';
import encodeFileName from '../global-functions/encodeFileName';
import loadingProgress from '../global-functions/loadingProgress';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Circle,
  CircleImage,
  CircularProgress,
  Icon,
  IconButton,
  LinearProgress,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  FlatList,
  Image,
  ImageBackground,
  Keyboard,
  Modal,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const Img2ImgGeneratorScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const encodeFileName = filename => {
    return encodeURIComponent(filename);
  };

  const addRemoteImageToMediaLibrary = async (uri, filename) => {
    // These are valid image file extensions
    const validExtensions = ['.jpg', '.png', '.gif', '.heic', '.webp', '.bmp'];

    // Request permission to access the user's media library
    const permission = await ImageDownloadExports.requestPermissionsAsync();

    // Check if user granted permission
    if (permission.status !== 'granted') {
      setMessage(
        'The app needs permission to access your media library to download images'
      );
      return;
    }

    // We need to make sure there's a media library available on the device
    const available = await ImageDownloadExports.isAvailableAsync();
    if (!available) {
      setMessage('The Media Library is not available on your device');
      return;
    }

    // Check if our uri ends with an allowed extension
    const fileIsValidImage =
      validExtensions.filter(extension => {
        // Append the file extension to the file name
        if (uri.endsWith(extension)) {
          filename = `${filename}${extension}`;
        }

        return uri.endsWith(extension);
      }).length === 1;

    if (!fileIsValidImage) {
      setMessage(
        'Only image files with extensions .jpg, .png, .gif, .heic, .webp, and .bmp can be added to the media library'
      );
      return;
    }

    // Create a location to put the contents of the downloaded file
    const fileUri = `${ImageDownloadExports.documentDirectory}${filename}`;

    // Download the remote file to the fileUri location
    setMessage('Downloading image...');
    const downloadedFile = await ImageDownloadExports.downloadAsync(
      uri,
      fileUri
    );

    // Check if we have a successful response from the remote server
    if (downloadedFile.status !== 200) {
      setMessage('The image could not be downloaded. Please try again');
      return;
    }

    try {
      setMessage('Adding image to your media library...');

      // This can be passed in, retrieved from an App variable, etc.
      const albumName = 'GAIM App';

      // Create an Asset from our downloaded file
      const asset = await ImageDownloadExports.createAssetAsync(
        downloadedFile.uri
      );

      // Check if we already have an album with the same name
      const album = await ImageDownloadExports.getAlbumAsync(albumName);

      if (album === null) {
        setMessage(`Creating new album named ${albumName}...`);

        // Create a new album and add the Asset
        await ImageDownloadExports.createAlbumAsync(albumName, asset, false);
        setMessage(`The image was added to the new ${albumName} album`);
      } else {
        // Add the Asset to the existing album
        await ImageDownloadExports.addAssetsToAlbumAsync(
          [asset],
          albumName,
          false
        );
        setMessage(`The image was added to the existing ${albumName} album`);
      }
    } catch (error) {
      // Something went wrong :(
      setMessage(
        `There was a problem adding the image to your media library: ${error}`
      );
    }
  };

  const addMessageToCurrConvo = (
    Variables,
    setGlobalVariableValue,
    newMessage
  ) => {
    const formattedMessage = { role: 'assistant', content: newMessage };
    setCurrentConvo(prevConvo => [...prevConvo, formattedMessage]);
    console.log('current convo: ', currentConvo);
  };

  const addUserPromptToConvo = userPrompt => {
    const formatUserPrompt = { role: 'user', content: userPrompt };
    setCurrentConvo(prevConvo => [...prevConvo, formatUserPrompt]);
  };

  const gAIMMobileBackendUploadInitImagePOST =
    GAIMMobileBackendApi.useUploadInitImagePOST();
  const gAIMMobileBackendGenerateImagePOST =
    GAIMMobileBackendApi.useGenerateImagePOST();
  const gAIMMobileBackendDeleteUserGeneratedImageByURLDELETE =
    GAIMMobileBackendApi.useDeleteUserGeneratedImageByURLDELETE();

  const [convo_id, setConvo_id] = React.useState('');
  const [currentConvo, setCurrentConvo] = React.useState([]);
  const [currentResponse, setCurrentResponse] = React.useState('');
  const [generatedImageURL, setGeneratedImageURL] = React.useState('');
  const [generator, setGenerator] = React.useState([]);
  const [imageURLforWebView, setImageURLforWebView] = React.useState(
    'https://cdn.leonardo.ai/users/80a143f3-0f05-4663-bba8-ebf42b3274df/generations/d96f2fc4-d52d-4176-9e8a-bc7be88f8543/SDXL_09_Watercolor_painting_in_the_style_of_Renoir_of_several_0.jpg'
  );
  const [initImage, setInitImage] = React.useState(
    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_61.png'
  );
  const [initImageHeight, setInitImageHeight] = React.useState(0);
  const [initImageId, setInitImageId] = React.useState('');
  const [initImageWidth, setInitImageWidth] = React.useState(0);
  const [message, setMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [promptToSubmit, setPromptToSubmit] = React.useState('');
  const [showGenerateButton, setShowGenerateButton] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [showImageSaved, setShowImageSaved] = React.useState(false);
  const [showLoadingProgress, setShowLoadingProgress] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  async function downloadImageWithFetch(uri, filename) {
    try {
      const response = await fetch(uri);
      if (!response.ok) throw new Error(`Failed to fetch ${uri}: ${response.statusText}`);

      const timestamp = new Date().getTime(); // Get current timestamp
      const updatedFilename = `GAIM_${timestamp}`; // Append timestamp to filename

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = updatedFilename || 'GAIM_AI_Image';

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl); // Clean up to avoid memory leaks
    } catch (error) {
      console.error('Failed to download image:', error);
    }
  }

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', justifyContent: 'space-between',
        marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
        marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
       },
        dimensions.width
      )}
    >
      {/* AI Tool Top Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'center',
            marginBottom: 6,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              height: 48,
              justifyContent: 'space-between',
              position: 'absolute',
              top: 0,
              width: '90%',
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_2']}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: 'rgb(153, 171, 214)' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'Ionicons/arrow-back-sharp'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: 'rgb(153, 171, 214)' },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '60%',
              },
              dimensions.width
            )}
          ></View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          ></View>
        </View>
      </View>
      {/* Out of Credits */}

      <>

        {!(Constants['user']?.image_credits < 1) ? null : (

          <View

            style={StyleSheet.applyWidth(

              {

                alignItems: 'center',

                alignSelf: 'center',

                borderColor: theme.colors['Error'],

                borderRadius: 8,

                borderWidth: 2,

                height: 150,

                justifyContent: 'center',

                marginBottom: 24,

                paddingLeft: '3%',

                paddingRight: '3%',

                width: '90%',

              },

              dimensions.width

            )}

          >

            <Text

              accessible={true}

              allowFontScaling={true}

              style={StyleSheet.applyWidth(

                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {

                  color: theme.colors['Error'],

                  fontFamily: 'RobotoCondensed_400Regular',

                  fontSize: 16,

                }),

                dimensions.width

              )}

            >

              {

                'You are out of image credits. Please add more credits to continue generating images.'

              }

            </Text>

            <Button

              style={StyleSheet.applyWidth(

                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {

                  color: theme.colors['Strong'],

                  fontFamily: 'SecularOne_400Regular',

                  marginTop: 10,

                }),

                dimensions.width

              )}

              title={'Get Credits'}

            />

          </View>

        )}

      </>
      {/* AI Tool Header Box */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            borderBottomWidth: 0.5,
            borderColor: theme.colors['Custom Color_19'],
            flexDirection: 'row',
            justifyContent: 'center',
            paddingBottom: 6,
            paddingLeft: 20,
            paddingTop: 2,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'flex-start' },
            dimensions.width
          )}
        >
          {/* Example Image */}
          <Image
            resizeMode={'cover'}
            source={{
              uri: `${Constants['currentImageTool']?.image_data.example_url}`,
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: 65,
                width: 65,
              }),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 14, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: [
                  { minWidth: Breakpoints.Mobile, value: 'center' },
                  { minWidth: Breakpoints.Laptop, value: 'flex-start' },
                ],
              },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Entypo/images'}
              size={30}
            />
            {/* Name ~ */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 15,
                  lineHeight: 20,
                  marginLeft: 5,
                  marginTop: 4,
                  opacity: 1,
                  textAlign: 'left',
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {Constants['currentImageTool']?.name}
            </Text>
          </View>
          {/* Message ~ */}
          <Text
            ellipsizeMode={'clip'}
            numberOfLines={3}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color_2'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
                lineHeight: 20,
                opacity: 0.5,
                paddingLeft: 10,
              },
              dimensions.width
            )}
          >
            {Constants['currentImageTool']?.description}
          </Text>
        </View>
      </View>
      {/* View 2 */}
      <View>
        {/* Header description */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Surface'],
              fontFamily: 'RobotoCondensed_400Regular',
              marginLeft: 20,
              marginRight: 20,
            }),
            dimensions.width
          )}
        >
          {
            'Choose an image from your gallery that you would like to modify with AI. Provide additional details or instructions for further modifications (optional).'
          }
        </Text>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'space-between',
            marginBottom: 20,
            marginTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
        enableAutomaticScroll={true}
        enableOnAndroid={true}
        extraScrollHeight={175}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={true}
      >
        {/* Loading Bar Spacer */}
        <>
          {!showLoadingProgress ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              {/* Loading Progress */}
              <LinearProgress
                animationDuration={500}
                color={theme.colors.primary}
                isAnimated={true}
                lineCap={'round'}
                maximumValue={100}
                showTrack={true}
                style={StyleSheet.applyWidth(
                  { width: '70%' },
                  dimensions.width
                )}
                thickness={10}
                trackColor={theme.colors['Strong']}
                trackLineCap={'round'}
                value={Constants['loadingProgress']}
              />
              <Spacer bottom={20} top={20} />
            </View>
          )}
        </>
        <View
          style={StyleSheet.applyWidth({ marginTop: 25 }, dimensions.width)}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center' },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              source={{ uri: `${initImage}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  minHeight: 300,
                  minWidth: 300,
                }),
                dimensions.width
              )}
            />
          </View>
          {/* View 2 */}
          <View>
            <Spacer bottom={8} left={8} right={8} top={28} />
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const selectedImage = await openImagePickerUtil({
                      mediaTypes: 'All',
                      allowsEditing: false,
                      quality: 0.2,
                    });

                    setInitImage(selectedImage);
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                  borderColor: theme.colors['Primary'],
                  borderRadius: 50,
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                }),
                dimensions.width
              )}
              title={'CHOOSE IMAGE'}
            />
            {/* Spacer 2 */}
            <Spacer bottom={8} left={8} right={8} top={14} />
            {/* Generate Button */}
            <>
              {!(
                initImage !==
                'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_61.png'
                && !(Constants['user']?.image_credits < 1)
              ) ? null : (
                <Button
                  onPress={() => {
                    const handler = async () => {
                      try {
                        setShowImageModal(true);
                        setShowLoadingProgress(true);
                        loadingProgress(
                          Variables,
                          setGlobalVariableValue,
                          30000
                        );
                        const initResult = (
                          await gAIMMobileBackendUploadInitImagePOST.mutateAsync(
                            { base64Image: initImage }
                          )
                        )?.json;
                        if (!initResult || !initResult?.id) {
                          alert('Failed to upload image. Reason: ' + initResult?.message);
                          setShowImageModal(false);
                        } else {
                        const valuebYHG977J = initResult?.id;
                        setInitImageId(valuebYHG977J);
                        const initResultID = valuebYHG977J;
                        const generatedResult = (
                          await gAIMMobileBackendGenerateImagePOST.mutateAsync({
                            dynamic_id: Constants['dynamic_id'],
                            guidance_scale:
                              Constants['currentImageTool']?.image_data
                                .guidance_scale,
                            height: parseFloat(initResult?.height, 10),
                            highContrast:
                              Constants['currentImageTool']?.image_data
                                .highContrast,
                            imagePromptWeight:
                              Constants['currentImageTool']?.image_data
                                .imagePromptWeight,
                            image_public: true,
                            init_image_id: initResultID,
                            init_strength:
                              Constants['currentImageTool']?.image_data
                                .init_strength,
                            modelId:
                              Constants['currentImageTool']?.image_data.modelId,
                            negative_prompt:
                              Constants['currentImageTool']?.image_data
                                .negative_prompt,
                            pre_prompt:
                              Constants['currentImageTool']?.pre_prompt,
                            prompt: promptToSubmit,
                            promptMagic:
                              Constants['currentImageTool']?.image_data
                                .promptMagic,
                            promptMagicVersion:
                              Constants['currentImageTool']?.image_data
                                .promptMagicVersion,
                            prompt_public: true,
                            tool_id: Constants['currentImageTool']?._id,
                            width: parseFloat(initResult?.width, 10),
                          })
                        )?.json;
                        setGeneratedImageURL(generatedResult?.data);
                        setInitImageHeight(initResult?.height300);
                        setInitImageWidth(initResult?.width300);
                        }
                        setShowLoadingProgress(false);
                        console.log(initResult);
                      } catch (err) {
                        console.error(err);
                      }
                    };
                    handler();
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      { borderRadius: 50, color: theme.colors['Strong'] }
                    ),
                    dimensions.width
                  )}
                  title={'GENERATE IMAGE'}
                />
              )}
            </>
            {/* Message Tray */}
            <>
              {!(
                initImage !==
                'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_61.png'
              ) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Background'],
                      borderBottomWidth: 1,
                      borderColor: theme.colors['Custom Color_4'],
                      borderLeftWidth: 1,
                      borderRadius: 24,
                      borderRightWidth: 1,
                      borderTopWidth: 1,
                      bottom: 0,
                      flexDirection: 'row',
                      height: 52,
                      justifyContent: 'space-between',
                      left: 0,
                      marginLeft: 20,
                      marginRight: 20,
                      marginTop: 10,
                      paddingLeft: 8,
                      paddingRight: 8,
                      position: 'relative',
                      right: 0,
                    },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { flex: 1, justifyContent: 'center' },
                      dimensions.width
                    )}
                  >
                    {/* Prompt */}
                    <TextInput
                      autoCorrect={true}
                      changeTextDelay={300}
                      editable={true}
                      enablesReturnKeyAutomatically={true}
                      keyboardAppearance={'dark'}
                      keyboardType={'default'}
                      multiline={true}
                      onBlur={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onChangeText={newPromptValue => {
                        try {
                          setPromptToSubmit(newPromptValue);
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      onSubmitEditing={() => {
                        try {
                          Keyboard.dismiss();
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                      placeholder={'Additional details (optional)...'}
                      placeholderTextColor={theme.colors['Custom Color_4']}
                      returnKeyType={'default'}
                      scrollEnabled={true}
                      style={StyleSheet.applyWidth(
                        {
                          borderRadius: 8,
                          color: theme.colors['Strong'],
                          fontFamily: 'Inter_400Regular',
                          fontSize: 14,
                          marginLeft: 8,
                          paddingBottom: 8,
                          paddingLeft: 8,
                          paddingRight: 8,
                          paddingTop: 8,
                        },
                        dimensions.width
                      )}
                      value={promptToSubmit}
                    />
                  </View>
                </View>
              )}
            </>
          </View>
        </View>
        <Spacer bottom={28} left={8} right={8} top={28} />
      </KeyboardAwareScrollView>
      {/* View Image Modal */}
      <>
        {!showImageModal ? null : (
          <Modal animationType={'fade'} presentationStyle={'fullScreen'}>
            <ImageBackground
              backgroundColor={theme.colors['Strong']}
              resizeMode={'cover'}
              source={Images.GaimMobileBG2}
              style={StyleSheet.applyWidth(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    justifyContent: 'space-around',
                  },
                  dimensions.width
                )}
              >
                {/* Close Image Button */}
                <Button
                  icon={'AntDesign/closecircleo'}
                  onPress={() => {
                    try {
                      setShowImageModal(false);
                      setGeneratedImageURL(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: 50,
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: '8%' },
                          { minWidth: Breakpoints.Laptop, value: '1%' },
                        ],
                        width: '70%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'CLOSE'}
                />
                {/* Image 2 */}
                <>
                  {!generatedImageURL ? null : (
                    <Image
                      resizeMode={'cover'}
                      source={{ uri: `${generatedImageURL}` }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ImageStyles(theme)['Image'],
                          {
                            height: [
                              { minWidth: Breakpoints.Laptop, value: '50%' },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: initImageHeight,
                              },
                            ],
                            width: [
                              { minWidth: Breakpoints.Laptop, value: '50%' },
                              {
                                minWidth: Breakpoints.Mobile,
                                value: initImageWidth,
                              },
                            ],
                          }
                        ),
                        dimensions.width
                      )}
                    />
                  )}
                </>
                <>
                  {!showLoadingProgress ? null : (
                    <CircularProgress
                      animationDuration={500}
                      color={theme.colors['Strong']}
                      isAnimated={true}
                      lineCap={'round'}
                      maximumValue={100}
                      showTrack={true}
                      startPosition={'top'}
                      style={StyleSheet.applyWidth(
                        { height: 200, width: 200 },
                        dimensions.width
                      )}
                      thickness={10}
                      trackColor={theme.colors['Primary']}
                      trackLineCap={'round'}
                      value={Constants['loadingProgress']}
                    />
                  )}
                </>
                {/* Progress Text */}
                <>
                  {!showLoadingProgress ? null : (
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'SecularOne_400Regular',
                            fontSize: 22,
                            textTransform: 'uppercase',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {Constants['loadingProgress']}
                      {'% generated...'}
                    </Text>
                  )}
                </>
                <>
                  {!generatedImageURL ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignContent: 'center',
                          alignItems: 'center',
                          width: '100%',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Image Saved View */}
                      <>
                        {!showImageSaved ? null : (
                          <View>
                            <Text
                              accessible={true}
                              adjustsFontSizeToFit={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontSize: 16,
                                    marginBottom: 8,
                                    textTransform: 'uppercase',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {'Saved to device'}
                            </Text>
                          </View>
                        )}
                      </>
                      {/* Save Image Button */}
                      <>

                        {dimensions.width >= Breakpoints.Laptop ? null : (

                          <Button

                            onPress={() => {

                              const handler = async () => {

                                try {

                                  await addRemoteImageToMediaLibrary(

                                    generatedImageURL,

                                    encodeFileName(generatedImageURL)

                                  );



                                  showAlertUtil({

                                    title: 'Saved!',

                                    message:

                                      'Your image has been saved to your device.',

                                    buttonText: 'Close',

                                  });

                                } catch (err) {

                                  console.error(err);

                                }

                              };

                              handler();

                            }}

                            style={StyleSheet.applyWidth(

                              StyleSheet.compose(

                                GlobalStyles.ButtonStyles(theme)['Button'],

                                {

                                  borderRadius: 50,

                                  color: theme.colors['Strong'],

                                  fontFamily: 'SecularOne_400Regular',

                                  fontSize: 16,

                                  marginLeft: 20,

                                  marginRight: 20,

                                  width: '70%',

                                }

                              ),

                              dimensions.width

                            )}

                            title={'SAVE IMAGE'}

                          />

                        )}

                      </>

                      {/* Save Image Button 3 */}

                      <>

                        {!(dimensions.width >= Breakpoints.Laptop) ? null : (

                          <Button

                            onPress={() => {

                              const handler = async () => {

                                try {

                                  await downloadImageWithFetch(

                                    generatedImageURL,

                                    encodeFileName(generatedImageURL)

                                  );

                                } catch (err) {

                                  console.error(err);

                                }

                              };

                              handler();

                            }}

                            style={StyleSheet.applyWidth(

                              StyleSheet.compose(

                                GlobalStyles.ButtonStyles(theme)['Button'],

                                {

                                  borderRadius: 50,

                                  color: theme.colors['Strong'],

                                  fontFamily: 'SecularOne_400Regular',

                                  fontSize: 16,

                                  marginLeft: 20,

                                  marginRight: 20,

                                  width: '70%',

                                }

                              ),

                              dimensions.width

                            )}

                            title={'SAVE IMAGE'}

                          />

                        )}

                      </>

                      <Spacer bottom={8} left={8} right={8} top={8} />

                      {/* Save Image Button 2 */}

                      <>

                        {dimensions.width >= Breakpoints.Laptop ? null : (

                          <Button

                            onPress={() => {

                              const handler = async () => {

                                try {

                                  await openShareUtil(`${generatedImageURL}`);

                                } catch (err) {

                                  console.error(err);

                                }

                              };

                              handler();

                            }}

                            style={StyleSheet.applyWidth(

                              StyleSheet.compose(

                                GlobalStyles.ButtonStyles(theme)['Button'],

                                {

                                  backgroundColor: 'rgba(0, 0, 0, 0)',

                                  borderColor: theme.colors['Primary'],

                                  borderRadius: 50,

                                  borderWidth: 2,

                                  color: theme.colors['Primary'],

                                  fontFamily: 'SecularOne_400Regular',

                                  fontSize: 16,

                                  width: '70%',

                                }

                              ),

                              dimensions.width

                            )}

                            title={'SHARE'}

                          />

                        )}

                      </>
                      {/* Spacer 2 */}
                      <Spacer bottom={8} left={8} right={8} top={8} />
                    </View>
                  )}
                </>
                {/* View 2 */}
                <>
                  {generatedImageURL ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { height: 100 },
                        dimensions.width
                      )}
                    />
                  )}
                </>
              </View>
            </ImageBackground>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(Img2ImgGeneratorScreen);
