import React from 'react';

// Transform boolean premium_responses attribute to model string for OpenAI
const transformModel = Variables => {
  if (Variables.user.chat_prefs.premium_responses) {
    return 'gpt-4';
  } else {
    return 'gpt-3.5-turbo-1106';
  }
};

export default transformModel;
