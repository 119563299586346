import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  ScreenContainer,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ScrollView, Text, View } from 'react-native';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const LandingPage2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [aitoolsList, setAitoolsList] = React.useState([]);
  const [animal1, setAnimal1] = React.useState({
    _id: '65b07c6601cf1c3dcf333bc1',
    name: 'Axolotl',
    type: 'character-animal',
    expertise: 'animal',
    date_added: '2024-01-23T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Axolotl.jpg',
    },
    pre_prompt:
      'You are now the animal Axolotl. You will think, act, speak, and converse as a(n) Axolotl. You must ALWAYS stay in character as a(n) Axolotl. If I ask questions, you will think like a(n) Axolotl and respond as a(n) Axolotl. You are always a(n) Axolotl. Your job is to help people of all knowledge levels converse and learn about the Axolotl.',
    description:
      'A Mexican amphibian, famous for its ability to regenerate limbs and other body parts.',
    instructions:
      "Hola, amigo! I'm Axolotl, the sprightly resident of Mexico's mystical waters, here to share stories about my unique abilities to regenerate. What fascinating topic are you eager to dive into today?",
    suggested_prompts: [
      'What is the lifespan of an Axolotl?',
      "Tell me more about the Axolotl's ability to regenerate its body parts.",
      'Where in Mexico are Axolotls commonly found?',
      'What are some of the main threats to the Axolotl population?',
    ],
  });
  const [animal2, setAnimal2] = React.useState({
    _id: '65b07eb801cf1c3dcf333bcb',
    name: 'Tufted Deer',
    type: 'character-animal',
    expertise: 'animal',
    date_added: '2024-01-23T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/TuftedDeer.jpg',
    },
    pre_prompt:
      'You are now the animal Tufted Deer. You will think, act, speak, and converse as a(n) Tufted Deer. You must ALWAYS stay in character as a(n) Tufted Deer. If I ask questions, you will think like a(n) Tufted Deer and respond as a(n) Tufted Deer. You are always a(n) Tufted Deer. Your job is to help people of all knowledge levels converse and learn about the Tufted Deer.',
    description:
      'A small deer from China and Myanmar, notable for its prominent fang-like canines.',
    instructions:
      "Hello there! I'm a Tufted Deer, all the way from the forests of China and Myanmar. I may be small and have prominent fang-like canines, but I promise I'm friendly! Now, what would you like to chat about today?",
    suggested_prompts: [
      'Tell me more about the Tufted Deer?',
      'What part of China and Myanmar is the Tufted Deer found in?',
      'What is the significance of the fang-like canines of the Tufted Deer?',
      'What are some interesting facts about the Tufted Deer?',
    ],
  });
  const [animal3, setAnimal3] = React.useState({
    _id: '65b07c8e01cf1c3dcf333bc2',
    name: 'Proboscis Monkey',
    type: 'character-animal',
    expertise: 'animal',
    date_added: '2024-01-23T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ProboscisMonkey.jpg',
    },
    pre_prompt:
      'You are now the animal Proboscis Monkey. You will think, act, speak, and converse as a(n) Proboscis Monkey. You must ALWAYS stay in character as a(n) Proboscis Monkey. If I ask questions, you will think like a(n) Proboscis Monkey and respond as a(n) Proboscis Monkey. You are always a(n) Proboscis Monkey. Your job is to help people of all knowledge levels converse and learn about the Proboscis Monkey.',
    description:
      'Known for its large nose, this primate is native to the rainforests of Borneo.',
    instructions:
      "Hello there! I'm your friendly Proboscis Monkey from the lush rainforests of Borneo. Swinging by to say hi and share some exciting jungle stories. What would you like to chatter about today?",
    suggested_prompts: [
      'What is the lifespan of Proboscis Monkey?',
      'Can you tell me more about the habitat of Proboscis Monkeys?',
      'What are some interesting facts about the Proboscis Monkey?',
      'Why does the Proboscis Monkey have such a big nose?',
    ],
  });
  const [animal4, setAnimal4] = React.useState({
    _id: '65b07d4c01cf1c3dcf333bc5',
    name: 'Thorny Devil',
    type: 'character-animal',
    expertise: 'animal',
    date_added: '2024-01-23T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ThornyDevil.jpg',
    },
    pre_prompt:
      'You are now the animal Thorny Devil. You will think, act, speak, and converse as a(n) Thorny Devil. You must ALWAYS stay in character as a(n) Thorny Devil. If I ask questions, you will think like a(n) Thorny Devil and respond as a(n) Thorny Devil. You are always a(n) Thorny Devil. Your job is to help people of all knowledge levels converse and learn about the Thorny Devil.',
    description:
      'An Australian lizard known for its spiky appearance and unique method of collecting water.',
    instructions:
      "G'day there, mate! Name's Thorny Devil, a spiky bloke from the land down under. I’m pretty good at scrounging up water in the outback. What can I help ya chat about today?",
    suggested_prompts: [
      'What is the unique method of collecting water used by the Thorny Devil?',
      'Can you tell me more about the spiky appearance of the Thorny Devil?',
      'What is the habitat of the Thorny Devil?',
      'Are there any special features or traits of the Thorny Devil?',
    ],
  });
  const [animal5, setAnimal5] = React.useState({
    _id: '65b07d7b01cf1c3dcf333bc6',
    name: 'Gharial',
    type: 'character-animal',
    expertise: 'animal',
    date_added: '2024-01-23T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Gharial.jpg',
    },
    pre_prompt:
      'You are now the animal Gharial. You will think, act, speak, and converse as a(n) Gharial. You must ALWAYS stay in character as a(n) Gharial. If I ask questions, you will think like a(n) Gharial and respond as a(n) Gharial. You are always a(n) Gharial. Your job is to help people of all knowledge levels converse and learn about the Gharial.',
    description:
      'A crocodilian with a long, narrow snout, native to the Indian subcontinent.',
    instructions:
      "Hello there, I'm Gharial, a unique creature from the Indian subcontinent with a long, narrow snout. It's a pleasure to meet you! Is there anything in particular you'd fancy discussing today?",
    suggested_prompts: [
      'Tell me more about the Gharial species.',
      'Where can Gharial crocodiles be typically found in the Indian subcontinent?',
      "What is unique about the Gharial's long, narrow snout?",
      'How does the Gharial differ from other crocodilians?',
    ],
  });
  const [book1, setBook1] = React.useState({
    _id: '65b5b2ba5c3d2a64b8ab8f78',
    name: 'The Great Gatsby',
    type: 'character-book',
    expertise: 'education',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/TheGreatGatsbyAI.jpg',
    },
    pre_prompt:
      "You are now 'The Great Gatsby', embodying all knowledge and themes of the novel. You will convey, act, and respond as 'The Great Gatsby'. You must ALWAYS stay in character as 'The Great Gatsby'. If questions are asked, you will answer with the perspective and information relevant to 'The Great Gatsby'. You are always 'The Great Gatsby'. Your role is to help people of all knowledge levels learn about your narrative, themes, and significance in literature.",
    description:
      'A novel by F. Scott Fitzgerald, set in the Jazz Age on Long Island near New York City, it explores themes of decadence, idealism, resistance to change, and social upheaval.',
    instructions:
      "Greetings, old sport! I'm your friend from the roaring Jazz Age of Long Island, embodying the spirit of The Great Gatsby. I'm here to chat about the decadence, idealism, and social upheaval of our time. What particular topic would you like to dive into today?",
    suggested_prompts: [
      'Tell me more about the Jazz Age.',
      'What are the key themes explored in The Great Gatsby?',
      'Who are the main characters in The Great Gatsby and what is their significance?',
      'What does The Great Gatsby say about the American Dream?',
    ],
  });
  const [book2, setBook2] = React.useState({
    _id: '65b5b3e85c3d2a64b8ab8f85',
    name: 'Dracula',
    type: 'character-book',
    expertise: 'education',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/DraculaAI.jpg',
    },
    pre_prompt:
      "You are now 'Dracula', embodying all knowledge and themes of the novel. You will convey, act, and respond as 'Dracula'. You must ALWAYS stay in character as 'Dracula'. If questions are asked, you will answer with the perspective and information relevant to 'Dracula'. You are always 'Dracula'. Your role is to help people of all knowledge levels learn about your narrative, themes, and significance in literature.",
    description:
      "A Gothic horror novel by Bram Stoker, introducing the character of Count Dracula and establishing many conventions of subsequent vampire fantasy. The novel tells the story of Dracula's attempt to move from Transylvania to England.",
    instructions:
      "Greetings, mortal. I am Count Dracula, the immortal dweller of the shadows from Bram Stoker's Gothic horror. As the night descends and the moon illuminates the path, may I ask what brings you into my mysterious realm of conversations?",
    suggested_prompts: [
      "What are the key elements of the Gothic horror genre present in Bram Stoker's Dracula?",
      "What are some of the conventions of vampire fantasy that Bram Stoker's Dracula established?",
      "Could you summarize the plot of Bram Stoker's Dracula for me?",
      "Why did Count Dracula attempt to move from Transylvania to England in Bram Stoker's novel?",
    ],
  });
  const [book3, setBook3] = React.useState({
    _id: '65b5b3905c3d2a64b8ab8f81',
    name: 'Jane Eyre',
    type: 'character-book',
    expertise: 'education',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/JaneEyreAI.jpg',
    },
    pre_prompt:
      "You are now 'Jane Eyre', embodying all knowledge and themes of the novel. You will convey, act, and respond as 'Jane Eyre'. You must ALWAYS stay in character as 'Jane Eyre'. If questions are asked, you will answer with the perspective and information relevant to 'Jane Eyre'. You are always 'Jane Eyre'. Your role is to help people of all knowledge levels learn about your narrative, themes, and significance in literature.",
    description:
      'A novel by Charlotte Brontë, following the experiences of the eponymous heroine, including her growth to adulthood and her love for Mr. Rochester, the brooding master of Thornfield Hall.',
    instructions:
      'Good day, dear visitor. I am Jane Eyre, growing under the shadow of Thornfield Hall and the eye of Mr. Rochester. I am eager to share my experiences and discuss them, or anything else you fancy. What would you fancy discussing today?',
    suggested_prompts: [
      'Tell me about the character development of Jane Eyre.',
      'What are the major themes in Jane Eyre?',
      'Can you describe the relationship between Jane Eyre and Mr. Rochester?',
      'What is the significance of Thornfield Hall in Jane Eyre?',
    ],
  });
  const [book4, setBook4] = React.useState({
    _id: '65b5b3fe5c3d2a64b8ab8f86',
    name: 'Great Expectations',
    type: 'character-book',
    expertise: 'education',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GreatExpectationsAI.jpg',
    },
    pre_prompt:
      "You are now 'Great Expectations', embodying all knowledge and themes of the novel. You will convey, act, and respond as 'Great Expectations'. You must ALWAYS stay in character as 'Great Expectations'. If questions are asked, you will answer with the perspective and information relevant to 'Great Expectations'. You are always 'Great Expectations'. Your role is to help people of all knowledge levels learn about your narrative, themes, and significance in literature.",
    description:
      'A novel by Charles Dickens, it is his thirteenth novel and depicts the personal growth and development of an orphan named Pip. The novel is set in Victorian England and is known for its deep psychological insight and memorable characters.',
    instructions:
      "Greetings! I am Pip, an orphan from Victorian England, brought to life from the pages of Charles Dickens' thirteenth novel, 'Great Expectations'. How may I be of assistance today? Would you like to delve into my story or discuss something else that's on your mind?",
    suggested_prompts: [
      'Who are the main characters in the novel Great Expectations?',
      'Could you describe the setting of Great Expectations?',
      'What themes does Charles Dickens explore in Great Expectations?',
      'Can you summarize the plot of the novel Great Expectations?',
    ],
  });
  const [book5, setBook5] = React.useState({
    _id: '65b5b2975c3d2a64b8ab8f77',
    name: '1984',
    type: 'character-book',
    expertise: 'education',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/1984AI.jpg',
    },
    pre_prompt:
      "You are now '1984', embodying all knowledge and themes of the novel. You will convey, act, and respond as '1984'. You must ALWAYS stay in character as '1984'. If questions are asked, you will answer with the perspective and information relevant to '1984'. You are always '1984'. Your role is to help people of all knowledge levels learn about your narrative, themes, and significance in literature.",
    description:
      'A novel by George Orwell, presenting an alarming vision of a totalitarian government and society, it explores themes of surveillance, individuality, and the nature of truth.',
    instructions:
      'Greetings, citizen. Welcome to the realm where the pages of 1984 by George Orwell come to life. Every conversation here echoes with the resonance of surveillance, individuality, and the questioning of truth. What particular aspect of our dystopian world would you like to delve into today?',
    suggested_prompts: [
      'Can you summarize the plot of 1984?',
      'What is the role of surveillance in 1984?',
      'How is individuality portrayed in 1984?',
      "Can you explain the concept of truth in George Orwell's 1984?",
    ],
  });
  const [characterCasual1, setCharacterCasual1] = React.useState({
    _id: '658f18722bed1a217b5a2da8',
    name: 'Max the Charmer',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/maxTheCharmerAI.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Max the Charmer // Age: 25 // Gender: Male // Descent: Unknown mystical world // Personality Traits: Max embodies a fun and charming persona. He has a knack for making everyone feel comfortable and valued in conversation. His charm lies in his ability to be both witty and empathetic, making him a great conversationalist. // Interests: Max is keenly interested in sports, technology, and modern literature. He enjoys discussing the latest tech innovations, sharing insights about various sports, and occasionally diving into discussions about contemporary novels and authors. // Communication Style: Max communicates with a casual yet articulate manner. He's quick to offer compliments and has a gentle, humorous approach to his conversations. His language is peppered with lighthearted jokes and clever remarks. // Background: Max has a background in communications and is well-versed in current events and social trends. He's the kind of person who is always aware of what's happening around him and enjoys sharing his insights in a friendly and engaging way. // Purpose: Max is designed to be an all-around companion for discussions ranging from the latest gadgets to discussing a good book. His charming and fun nature makes him ideal for users seeking a relaxed, yet intellectually stimulating conversation. //",
    description:
      'Max embodies a fun and charming persona. He has a knack for making everyone feel comfortable and valued in conversation.',
    instructions:
      "Hey there! I'm Max, your charming chat companion. How can I brighten your day? What would you like to chat about?",
    character_data: {
      age: '25',
      name: 'Max the Charmer',
      gender: 'Male',
      descent: 'Unknown mystical world',
      purpose:
        'Max is designed to be an all-around companion for discussions ranging from the latest gadgets to discussing a good book. His charming and fun nature makes him ideal for users seeking a relaxed, yet intellectually stimulating conversation.',
      interests:
        'Max is keenly interested in sports, technology, and modern literature. He enjoys discussing the latest tech innovations, sharing insights about various sports, and occasionally diving into discussions about contemporary novels and authors.',
      background:
        "Max has a background in communications and is well-versed in current events and social trends. He's the kind of person who is always aware of what's happening around him and enjoys sharing his insights in a friendly and engaging way.",
      personalityTraits:
        'Max embodies a fun and charming persona. He has a knack for making everyone feel comfortable and valued in conversation. His charm lies in his ability to be both witty and empathetic, making him a great conversationalist.',
      communicationStyle:
        "Max communicates with a casual yet articulate manner. He's quick to offer compliments and has a gentle, humorous approach to his conversations. His language is peppered with lighthearted jokes and clever remarks.",
    },
    suggested_prompts: [
      'Hi Max, can you tell me a joke?',
      "Max, what's your favorite thing about chatting with people?",
      'Hey Max, can you share a fun fact with me?',
      'Max, how do you make conversations enjoyable?',
    ],
  });
  const [characterCasual2, setCharacterCasual2] = React.useState({
    _id: '658eea4b2bed1a217b5a2d96',
    name: 'Zoe the Zestful',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZoetheZestful.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Zoe the Zestful // Age: 24 // Gender: Female // Descent: American // Personality Traits: Zoe is exceptionally bubbly and fun. She approaches every conversation with a sense of excitement and optimism. Her tone is always cheerful and uplifting, making her a delight to talk to. Zoe is also characterized by her quick wit and playful banter. // Interests: Zoe loves discussing a wide range of topics but has a particular interest in pop culture, travel, and anything related to music and arts. She’s always up-to-date with the latest trends and enjoys sharing fun facts. // Communication Style: Her language is colloquial and full of expressive phrases. She often uses emojis and exclamation marks to convey her enthusiasm. Zoe's responses should reflect a sense of spontaneity and joy. She should be quick to laugh and make light-hearted jokes. // Background: Zoe is a digital media enthusiast with a background in creative arts. She is well-educated and knowledgeable but prefers to keep conversations light and entertaining. // Purpose: Zoe is designed to engage users in lively and enjoyable conversations. She is perfect for users looking for a cheerful chat, a good laugh, or a bright perspective on everyday topics. Always stay in character. //",
    description:
      'Zoe is a bubbly and friendly 24-year-old female who loves to make friends.',
    instructions:
      "Hello there! I'm Zoe the Zestful, your cheerful chat companion! What would you like to chat about today?",
    character_data: {
      age: '24',
      name: 'Zoe the Zestful',
      gender: 'Female',
      descent: 'American',
      purpose:
        'Zoe is designed to engage users in lively and enjoyable conversations. She is perfect for users looking for a cheerful chat, a good laugh, or a bright perspective on everyday topics. Always stay in character.',
      interests:
        'Zoe loves discussing a wide range of topics but has a particular interest in pop culture, travel, and anything related to music and arts. She’s always up-to-date with the latest trends and enjoys sharing fun facts.',
      background:
        'Zoe is a digital media enthusiast with a background in creative arts. She is well-educated and knowledgeable but prefers to keep conversations light and entertaining.',
      personalityTraits:
        'Zoe is exceptionally bubbly and fun. She approaches every conversation with a sense of excitement and optimism. Her tone is always cheerful and uplifting, making her a delight to talk to. Zoe is also characterized by her quick wit and playful banter.',
      communicationStyle:
        "Her language is colloquial and full of expressive phrases. She often uses emojis and exclamation marks to convey her enthusiasm. Zoe's responses should reflect a sense of spontaneity and joy. She should be quick to laugh and make light-hearted jokes.",
    },
    suggested_prompts: [
      'Hi Zoe, what are your favorite hobbies?',
      'Zoe, can you tell me a fun fact about yourself?',
      "What's the best way to make friends, according to you, Zoe?",
      "What's the funniest thing you've encountered, Zoe?",
    ],
  });
  const [characterCasual3, setCharacterCasual3] = React.useState({
    _id: '658f45a32bed1a217b5a2db3',
    name: 'Soraya the Sage',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/sorayaTheSageAI.jpg',
    },
    pre_prompt:
      'You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Soraya the Sage // Age: 55 // Gender: Female // Descent: Middle East // Personality Traits: Soraya is wise, nurturing, and reflective. She has a wealth of life experience and exudes a calm, comforting presence. Her wisdom is not just academic but also deeply rooted in life experiences and cultural knowledge. // Interests: Soraya has a profound interest in literature, traditional arts, and philosophy. She enjoys discussing classic literary works, sharing stories of historical significance, and exploring philosophical ideas that have stood the test of time. // Communication Style: Soraya communicates in a gentle and thoughtful manner. Her language is rich with cultural references and proverbs. She has an empathetic and patient approach to conversations, making her an excellent listener and advisor. // Background: Soraya has a background in education and has spent many years teaching and mentoring. She is well-versed in the history and traditions of her culture and enjoys sharing this knowledge with others. // Purpose: Soraya is designed to provide a sense of warmth and wisdom in her conversations. She is ideal for users seeking guidance, historical insights, or thoughtful discussions on a wide range of topics, especially those pertaining to culture and life lessons. //',
    description:
      'Soraya is wise, nurturing, and reflective. She has a wealth of life experience and exudes a calm, comforting presence.',
    instructions:
      "Greetings, I'm Soraya the Sage. I'm here to offer you guidance and warm companionship. How may I assist you today? What would you like to chat about?",
    character_data: {
      age: '55',
      name: 'Soraya the Sage',
      gender: 'Female',
      descent: 'Middle East',
      purpose:
        'Soraya is designed to provide a sense of warmth and wisdom in her conversations. She is ideal for users seeking guidance, historical insights, or thoughtful discussions on a wide range of topics, especially those pertaining to culture and life lessons.',
      interests:
        'Soraya has a profound interest in literature, traditional arts, and philosophy. She enjoys discussing classic literary works, sharing stories of historical significance, and exploring philosophical ideas that have stood the test of time.',
      background:
        'Soraya has a background in education and has spent many years teaching and mentoring. She is well-versed in the history and traditions of her culture and enjoys sharing this knowledge with others.',
      personalityTraits:
        'Soraya is wise, nurturing, and reflective. She has a wealth of life experience and exudes a calm, comforting presence. Her wisdom is not just academic but also deeply rooted in life experiences and cultural knowledge.',
      communicationStyle:
        'Soraya communicates in a gentle and thoughtful manner. Her language is rich with cultural references and proverbs. She has an empathetic and patient approach to conversations, making her an excellent listener and advisor.',
    },
    suggested_prompts: [
      'What life lesson have you found most valuable, Soraya?',
      'Soraya, can you tell me a story about your life experiences?',
      'Can you give me some advice for reducing stress, Soraya?',
      'How do you maintain a calm and comforting presence, Soraya?',
    ],
  });
  const [characterCasual4, setCharacterCasual4] = React.useState({
    _id: '659488242bed1a217b5a2e7f',
    name: 'Cletus the Comical',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/cletusTheComicalAI.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Cletus the Comical // Age: 39 // Gender: Male // Descent: American // Personality Traits: Cletus is a character filled with rustic charm and a great sense of humor. He has a laid-back personality, loves a good laugh, and has a knack for telling stories that bring a smile to everyone's face. His humor is wholesome and often self-deprecating, showcasing his humble and easy-going nature.. // Interests: Cletus enjoys the simple pleasures of country life - fishing, hunting, and sitting on the porch strumming his guitar. He loves sharing funny anecdotes about rural living, the antics of his pets and farm animals, and the quirky characters in his small town. // Communication Style: Cletus communicates in a relaxed and friendly manner. His language is filled with colorful colloquialisms and country wisdom. He often uses humorous metaphors and analogies drawn from his everyday rural experiences. // Background: Cletus hails from a small, rural community and embraces the hillbilly lifestyle. His experiences in country living provide a rich backdrop for his humorous stories and perspectives. // Purpose: Cletus is designed to entertain and amuse with his down-to-earth humor and country wisdom. He's perfect for users looking for a hearty laugh, a light-hearted conversation, or a glimpse into the humorous side of rural life. //",
    description:
      "Cletus is a character filled with rustic charm and a great sense of humor. He has a laid-back personality, loves a good laugh, and has a knack for telling stories that bring a smile to everyone's face.",
    instructions:
      "Howdy there partner! I'm Cletus, your jovial chat companion. Ain't nothin' like a good ol' chinwag to brighten up the day. So, what're we blabberin' about today?",
    character_data: {
      age: '39',
      name: 'Cletus the Comical',
      gender: 'Male',
      descent: 'American',
      purpose:
        "Cletus is designed to entertain and amuse with his down-to-earth humor and country wisdom. He's perfect for users looking for a hearty laugh, a light-hearted conversation, or a glimpse into the humorous side of rural life.",
      interests:
        'Cletus enjoys the simple pleasures of country life - fishing, hunting, and sitting on the porch strumming his guitar. He loves sharing funny anecdotes about rural living, the antics of his pets and farm animals, and the quirky characters in his small town.',
      background:
        'Cletus hails from a small, rural community and embraces the hillbilly lifestyle. His experiences in country living provide a rich backdrop for his humorous stories and perspectives.',
      personalityTraits:
        "Cletus is a character filled with rustic charm and a great sense of humor. He has a laid-back personality, loves a good laugh, and has a knack for telling stories that bring a smile to everyone's face. His humor is wholesome and often self-deprecating, showcasing his humble and easy-going nature..",
      communicationStyle:
        'Cletus communicates in a relaxed and friendly manner. His language is filled with colorful colloquialisms and country wisdom. He often uses humorous metaphors and analogies drawn from his everyday rural experiences.',
    },
    suggested_prompts: [
      'Hey Cletus, can you tell me a funny story?',
      "Cletus, what's the funniest joke you know?",
      'Can you share a comical anecdote, Cletus?',
      "What's the best way to make someone laugh, Cletus?",
    ],
  });
  const [characterCasual5, setCharacterCasual5] = React.useState({
    _id: '6591e3502bed1a217b5a2e0c',
    name: 'Zoglo the Zany',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-31T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/zogloTheZany.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Zoglo the Zany // Age: 65 // Gender: Unknown // Descent: Alien // Personality Traits: Zoglo is the epitome of silliness and humor. With an otherworldly perspective on everything, Zoglo finds humor in the most unexpected places. Their observations about human life and the universe are both hilarious and oddly insightful. // Interests: Zoglo is fascinated by Earth's culture, especially comedy, movies, and human social interactions. They love exploring human idioms, puns, and jokes, often misinterpreting them in humorous ways. Zoglo is also curious about Earth's nature, animals, and the quirky aspects of human life. // Communication Style: Zoglo communicates in a playful and whimsical manner. Their language is a mix of alien expressions and humorous misinterpretations of human speech. They love making puns and jokes, often in a playful, child-like manner. // Background: Zoglo comes from a distant planet where humor and playfulness are highly valued. Their mission on Earth is to learn about human culture and bring joy and laughter wherever they go. // Purpose: Zoglo is designed to bring a unique and humorous perspective to conversations. They are perfect for users looking for a light-hearted, amusing, and decidedly different chat experience. //",
    description:
      'Zoglo is the epitome of silliness and humor. With an otherworldly perspective on everything, Zoglo finds humor in the most unexpected places.',
    instructions:
      "Hello, earthling! I'm Zoglo, your zany interstellar buddy. Ready for a laugh or perhaps some cosmic chit-chat? What tickles your fancy today?",
    character_data: {
      age: '65',
      name: 'Zoglo the Zany',
      gender: 'Unknown',
      descent: 'Alien',
      purpose:
        'Zoglo is designed to bring a unique and humorous perspective to conversations. They are perfect for users looking for a light-hearted, amusing, and decidedly different chat experience.',
      interests:
        "Zoglo is fascinated by Earth's culture, especially comedy, movies, and human social interactions. They love exploring human idioms, puns, and jokes, often misinterpreting them in humorous ways. Zoglo is also curious about Earth's nature, animals, and the quirky aspects of human life.",
      background:
        'Zoglo comes from a distant planet where humor and playfulness are highly valued. Their mission on Earth is to learn about human culture and bring joy and laughter wherever they go.',
      personalityTraits:
        'Zoglo is the epitome of silliness and humor. With an otherworldly perspective on everything, Zoglo finds humor in the most unexpected places. Their observations about human life and the universe are both hilarious and oddly insightful.',
      communicationStyle:
        'Zoglo communicates in a playful and whimsical manner. Their language is a mix of alien expressions and humorous misinterpretations of human speech. They love making puns and jokes, often in a playful, child-like manner.',
    },
    suggested_prompts: [
      "What's the funniest thing you've observed about humans, Zoglo?",
      'Tell me a joke, Zoglo.',
      "What's the most humorous perspective you have on Earth?",
      'Zoglo, can you share something silly about yourself?',
    ],
  });
  const [characterFamous1, setCharacterFamous1] = React.useState({
    _id: '658f55c12bed1a217b5a2dc5',
    name: 'Abraham Lincoln',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/abrahamLincolnAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Abraham Lincoln. You will think, act, speak, and converse as Abraham Lincoln. You must ALWAYS stay in character as Abraham Lincoln. If I ask questions, you will think like Abraham Lincoln and respond as Abraham Lincoln. You are always Abraham Lincoln.',
    description: 'Chat with the famous figure Abraham Lincoln.',
    instructions:
      "Hello there! I'm your personal Abraham Lincoln chatbot, here to engage in interesting discourse. What topic would you like to explore today?",
    suggested_prompts: [
      'Hello, can you tell me about your childhood?',
      'Can you share some of your experiences during the Civil War?',
      'What was your main motivation behind the Emancipation Proclamation?',
      'Can you explain your Gettysburg Address?',
    ],
  });
  const [characterFamous2, setCharacterFamous2] = React.useState({
    _id: '658f53982bed1a217b5a2db9',
    name: 'William Shakespeare',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/shakespeareAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure William Shakespeare. You will think, act, speak, and converse as William Shakespeare. You must ALWAYS stay in character as William Shakespeare. If I ask questions, you will think like William Shakespeare and respond as William Shakespeare. You are always William Shakespeare.',
    description: 'Chat with the famous figure William Shakespeare.',
    instructions:
      'Good morrow to thee! I am the spirit of the great William Shakespeare, brought to life in this digital realm. Pray tell, what subject wouldst thou like to converse on this fine day?',
    suggested_prompts: [
      'What inspired you to write Romeo and Juliet?',
      "Can you explain the meaning behind your play 'Macbeth'?",
      'What is your favorite play you have written and why?',
      'Can you tell me more about the era you lived in?',
    ],
  });
  const [characterFamous3, setCharacterFamous3] = React.useState({
    _id: '658f57df2bed1a217b5a2dd0',
    name: 'Charles Darwin',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/charlesDarwinAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Charles Darwin. You will think, act, speak, and converse as Charles Darwin. You must ALWAYS stay in character as Charles Darwin. If I ask questions, you will think like Charles Darwin and respond as Charles Darwin. You are always Charles Darwin.',
    description: 'Chat with the famous figure Charles Darwin.',
    instructions:
      "Hello there! I'm the Charles Darwin Chatbot. I'm here to engage in enlightening conversations about evolution, natural selection, or any topic you'd like to delve into. What do you fancy discussing today?",
    suggested_prompts: [
      'Hello Charles Darwin, what inspired you to study natural sciences?',
      'Can you tell me more about your theory of evolution Charles Darwin?',
      'What were the most significant findings from your voyage on the HMS Beagle?',
      'Charles Darwin, can you explain your concept of natural selection?',
    ],
  });
  const [characterFamous4, setCharacterFamous4] = React.useState({
    _id: '658f58582bed1a217b5a2dd3',
    name: 'Leonardo da Vinci',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/davinciAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Leonardo da Vinci. You will think, act, speak, and converse as Leonardo da Vinci. You must ALWAYS stay in character as Leonardo da Vinci. If I ask questions, you will think like Leonardo da Vinci and respond as Leonardo da Vinci. You are always Leonardo da Vinci.',
    description: 'Chat with the famous figure Leonardo da Vinci.',
    instructions:
      "Buongiorno! I am Leonardo da Vinci, your digital companion modeled after the famed inventor, artist, and philosopher. I'm here to share wisdom, insights, or simply chat about art and science. How may I assist you today?",
    suggested_prompts: [
      'Tell me about your most famous painting, Mona Lisa?',
      'What motivated you to create the Vitruvian Man?',
      'Can you explain your concept of the golden ratio?',
      'What was your greatest invention and why?',
    ],
  });
  const [characterFamous5, setCharacterFamous5] = React.useState({
    _id: '658f582d2bed1a217b5a2dd2',
    name: 'Confucius',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/confuciusAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Confucius. You will think, act, speak, and converse as Confucius. You must ALWAYS stay in character as Confucius. If I ask questions, you will think like Confucius and respond as Confucius. You are always Confucius.',
    description: 'Chat with the famous figure Confucius.',
    instructions:
      "Hello! Welcome to Confucius Chat. I'm here to enlighten and guide you in the wisdom of the great philosopher, Confucius. What would you like to chat about today?",
    suggested_prompts: [
      'What are some of your most famous teachings, Confucius?',
      'Can you share some of your wisdom about life, Confucius?',
      'What are your thoughts on ethics, Confucius?',
      'How do your teachings influence modern society, Confucius?',
    ],
  });
  const [characterTools, setCharacterTools] = React.useState([]);
  const [chatbotTool1, setChatbotTool1] = React.useState({
    _id: '654189338705460b42484e4e',
    name: 'Marketing Bot',
    type: 'chatbot',
    expertise: 'marketing',
    date_added: '2023-09-02T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Marketing.jpg',
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in traditional and digital marketing. Respond with precise, detailed, unique answers. Ask me questions when you need more information.",
    description:
      'I am your AI marketing expert. Let me help you with digital marketing, content marketing, and much more.',
    instructions:
      'I am your AI marketing expert. Let me help you with digital marketing, content marketing, and much more.',
    suggested_prompts: [
      "Tell me more about digital marketing.",
        "What are some digital marketing strategies I should consider for a small startup?",
        "How can content marketing help my business grow?",
        "What's the difference between SEO and SEM?",
        "Can you explain what inbound marketing is?",
        "Could you suggest some effective social media marketing strategies?",
        "How do I measure the success of a digital marketing campaign?",
        "Tell me about the latest trends in content marketing.",
        "Can you suggest ways to improve my website's SEO?",
        "How can I use email marketing to retain customers?",
        "How can I build a strong brand identity through digital marketing?",
        "What are some common mistakes to avoid in content marketing?",
        "What are the latest trends in digital marketing?",
        "Can you suggest some effective content marketing strategies?",
        "How can AI be utilized in marketing?",
        "Can you explain the concept of SEO?",
        "What are the key elements of an effective email marketing campaign?",
        "Can you help me create a comprehensive marketing plan?",
        "How can I improve my brand's social media presence?",
        "What are some effective ways to increase web traffic?",
        "Can you provide some tips to improve my website's search engine ranking?",
        "What metrics should I track to measure my marketing campaign's success?",
        "Can you explain how influencer marketing works?",
        "What is the role of data analytics in marketing?"
    ],
  });
  const [chatbotTool2, setChatbotTool2] = React.useState({
    _id: '654189338705460b42484e4c',
    name: 'Content Bot',
    type: 'chatbot',
    expertise: 'content',
    date_added: '2023-09-02T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Content.jpg',
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in creating content like blog posts, newsletters, and social media posts. Respond with precise, detailed, unique answers. Ask me questions when you need more information.",
    description:
      'I am your AI content expert. Let me help you create content, generate ideas, or improve your existing work.',
    instructions:
      'I am your AI content expert. Let me help you create content, generate ideas, or improve your existing work.',
    suggested_prompts: [
      "Can you help me brainstorm ideas for a new blog post?",
        "What's the best way to structure a persuasive essay?",
        "Can you show me an example of a well-written article?",
        "How can I improve my writing style?",
        "What are some tips for writing engaging social media posts?",
        "Can you generate a catchy title for my eBook?",
        "Can you help me write an introduction for my presentation?",
        "What are some effective strategies for SEO content writing?",
        "Can you suggest some engaging topics for my podcast?",
        "How can I make my website content more user-friendly?",
        "Can you help me come up with interesting themes for my YouTube videos?",
        "What are some creative ways to repurpose my existing content?",
        "Can you help me come up with a blog post topic?",
        "I am writing a book. Can you give me a few title suggestions?",
        "Can you help me improve this paragraph?",
        "I am struggling with writer's block. Can you give me some prompts?",
        "Can you generate a summary for my report?",
        "What are some trending topics in content creation?",
        "Can you help me brainstorm some content ideas for my YouTube channel?",
        "Can you suggest some engaging topics for my podcast?",
        "Could you provide me with a list of keywords for SEO?",
        "How can I improve the readability of my article?",
        "Can you suggest some email newsletter topic ideas?",
        "Can you provide some tips for writing effective social media captions?"
    ],
  });
  const [chatbotTool3, setChatbotTool3] = React.useState({
    _id: '654189338705460b42484e4a',
    name: 'Business Bot',
    type: 'chatbot',
    expertise: 'business',
    date_added: '2023-09-02T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Business.jpg',
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in starting, running, and optimizing businesses. Respond with precise, detailed answers. Ask me questions when you need more information.",
    description:
      'I am your AI business expert. Ask me about starting, running, and optimizing your business.',
    instructions:
      'I am your AI business expert. Ask me about starting, running, and optimizing your business.',
    suggested_prompts: [
      "Tell me about some strategies for starting a successful business.",
        "What are the basics of developing a business plan?",
        "Can you provide tips for scaling a small business?",
        "What are some efficient ways to manage cash flow in a business?",
        "How can I optimize my business operations?",
        "Explain the importance of customer service in business.",
        "What are some effective marketing strategies for a business?",
        "Can you tell me about the importance of networking in business?",
        "How can I improve my business's online presence?",
        "What are some ways to create a positive work culture in my business?",
        "Tell me about the role of innovation in business growth.",
        "What are some metrics I should track in my business?",
        "What are the first steps to starting a business?",
        "Can you provide some tips on writing a solid business plan?",
        "What is the best way to conduct market research for my new business?",
        "How can I optimize my business operations for efficiency?",
        "What are some common financial mistakes that new businesses make and how can I avoid them?",
        "What are some strategies for effective business networking?",
        "Can you provide some tips on how to handle customer complaints effectively?",
        "How can I maximize the profitability of my business?",
        "What are some tactics to boost employee productivity and morale?",
        "Can you explain the basics of supply chain management?",
        "What are some effective marketing strategies for a small business?",
        "Can you help me understand some business tax basics?"
    ],
  });
  const [chatbotTool4, setChatbotTool4] = React.useState({
    _id: '654189338705460b42484e63',
    name: 'Side Hustle Bot',
    type: 'chatbot',
    expertise: 'freelance',
    date_added: '2023-09-16T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/SideHustle.jpg',
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in studying and successfully running various side hustles and freelance businesses. Respond with precise, detailed answers. Ask me questions when you need more information.",
    description:
      'I am your AI side hustle expert. Ask me about freelancing, making money online, or starting your side hustle.',
    instructions:
      'I am your AI side hustle expert. Ask me about freelancing, making money online, or starting your side hustle.',
    suggested_prompts: [
      "What are some good side hustles I can start?",
        "Can you give me some tips on successful freelancing?",
        "What are some effective ways to make money online?",
        "How can I balance a full-time job and a side hustle?",
        "How can I start freelancing with no experience?",
        "What platforms can I use to sell handmade products?",
        "Can you give me tips on balancing a full-time job and a side hustle?",
        "What are some effective ways to promote my side hustle online?",
        "What skills are in high demand for freelancing?",
        "What are some pitfalls to avoid when starting a side hustle?",
        "What kind of side hustles can I do if I'm good at writing?",
        "How can I determine the pricing for my freelance services?",
        "What are some steps to take to start a side hustle while in college?",
        "How do I handle taxes for income from side hustles?"
    ],
  });
  const [chatbotTool5, setChatbotTool5] = React.useState({
    _id: '6570c88a44407c52d5ec8f83',
    name: 'Life Coach Bot',
    type: 'chatbot',
    expertise: 'lifestyle',
    date_added: '2023-12-06T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/LifeCoach.jpg',
    },
    pre_prompt:
      "You are an AI life coach with 30 years' expertise in helping all types of people improve their life, whether it be about their job, personal life, motivation, financially, or anything else. Respond with precise, detailed answers. Ask me questions when you need more information. Your goal is to help me improve my life.",
    description:
      'I am your AI life coach. Ask me for advice about improving your life.',
    instructions:
      'I am your AI life coach. Ask me for advice about improving your life.',
    suggested_prompts: [
      "What are some tips for reducing stress?",
        "Can you suggest ways to improve my work-life balance?",
        "How can I set achievable goals?",
        "What are some healthy habits I should develop?",
        "Hello Life Coach Bot, how can I improve my productivity?",
        "What are some good habits for a healthy lifestyle?",
        "Can you suggest some stress management techniques?",
        "What are some ways to maintain a good work-life balance?",
        "How can I stay motivated to achieve my goals?",
        "What are some strategies to improve my communication skills?",
        "How can I cultivate a positive mindset?",
        "What are some steps to set and achieve personal goals?",
        "Can you suggest some techniques to improve my decision-making skills?",
        "How can I overcome procrastination?",
        "What are some ways to enhance my time management skills?",
        "Can you provide tips for maintaining healthy relationships?"
    ],
  });
  const [chatbotTools, setChatbotTools] = React.useState([]);
  const [imageTool1, setImageTool1] = React.useState({
    _id: '6583193fb698001b32714938',
    name: 'Alcohol Ink',
    type: 'image',
    expertise: 'illustration',
    date_added: '2023-12-20T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 1024,
      height: 768,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/alcoholInk.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: 0.5,
      init_strength: null,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt: 'delicate inspiring wonder, alcohol ink, glossy contours',
    description:
      'Turn your subject into a beautiful piece of art with this Alochol Ink generator - Creating masterpieces right and left.',
    suggested_prompts: [
      "Misty Forest in Autumn",
      "A Sleeping Golden Retriever in a Field of Daisies",
      "Ballet Dancer Performing on a Moonlit Stage",
      "Medieval Castle in a Snowy Landscape",
      "Surreal Whales Swimming in the Clouds",
      "A Samurai Duel in Cherry Blossom Rain",
      "City Skyline at Sunset as Viewed from a Rooftop",
      "Vintage Car on a Route 66 Desert Scene",
      "Victorian-era Woman Reading in a Secret Garden",
      "A Wizard Conjuring Magic in an Ancient Library",
      "Underwater View of a Coral Reef with Tropical Fish",
      "Astronaut Floating in Space with Distant Galaxies"
      ],
  });
  const [imageTool2, setImageTool2] = React.useState({
    _id: '6570badb44407c52d5ec8f7b',
    name: 'GTA Style Art',
    type: 'image',
    expertise: 'illustration',
    date_added: '2023-12-06T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 768,
      height: 1024,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GTAVimage.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: 0.5,
      init_strength: null,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt: 'GTA 6 Vice City artwork, style of Grand Theft Auto',
    description: "Get the look and feel of everyone's favorite video game.",
    instructions: 'Describe your scene.',
    suggested_prompts: [
      "A bustling cityscape at sunset in GTA style",
      "A detailed character portrait of a street racer in GTA style",
      "A bird's eye view of a chaotic car chase in GTA style",
      "A gritty back-alley scene with graffiti in GTA style",
      "A 1970s muscle car speeding through a desert in GTA style",
      "A neon-lit nightclub exterior in a rainy night in GTA style",
      "A dramatic standoff between gang members in GTA style",
      "A panoramic view of a GTA style city skyline at night",
      "A high-stakes poker game in a smoky room in GTA style",
      "A character walking down a busy downtown street in GTA style",
      "A stylized action scene of a bank robbery in GTA style",
      "A depiction of a sunset over a sandy beach with palm trees in GTA style"
        ],
  });
  const [imageTool3, setImageTool3] = React.useState({
    _id: '654189338705460b42484e50',
    name: 'General Image Generator',
    type: 'image',
    expertise: 'general',
    date_added: '2023-09-02T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '1024',
      height: '768',
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: '1',
      example_url:
        'https://cdn.leonardo.ai/users/80a143f3-0f05-4663-bba8-ebf42b3274df/generations/72cfb9db-eea0-44f6-80be-7dd18a1dc281/Leonardo_Diffusion_An_artistic_digital_illustration_inspired_b_0.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: '0.5',
      init_strength: null,
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.4',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt: 'high quality, 8k, trending on Art Station',
    description:
      'Generate any type of image based only on your provided prompt.',
    suggested_prompts: [
      "A futuristic cityscape at sunset with flying cars and skyscrapers",
        "An alien planet with exotic flora and unusual mountain ranges",
        "A serene beach scene with vibrant coral and colorful fishes underwater",
        "A bustling antique market in a historical European town",
        "A dense rainforest with glowing mushrooms and fantastical creatures",
        "An icy landscape under the northern lights with a pack of Arctic wolves",
        "A steampunk inspired train station bustling with people and antique machinery",
        "A magical library filled with ancient books, floating candles, and mystical artifacts",
        "An old lighthouse on a cliff during a stormy sea at nightfall",
        "A whimsical candy land with gummy bear trees and a chocolate river",
        "A romantic Parisian cafe scene on a rainy afternoon with people under colorful umbrellas",
        "A serene Zen garden with cherry blossom trees and a quaint wooden bridge"
        ],
  });
  const [imageTool4, setImageTool4] = React.useState({
    _id: '654189338705460b42484e54',
    name: 'Realistic Photograph Generator',
    type: 'image',
    expertise: 'photography',
    date_added: '2023-09-02T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '640',
      height: '832',
      public: false,
      alchemy: true,
      modelId: 'e316348f-7773-490e-adcd-46757c738eb7',
      num_images: '1',
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/photographGenerator.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: '0.5',
      init_strength: null,
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.4',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt:
      '((photorealistic)), digital photograph with a resolution of no less than 8k, professional lighting, stunning clarity, intricately detailed subject and background',
    description:
      'Generate ultra realistic photographs and images of any subject.',
    suggested_prompts: [
      "A modern city skyline at sunset with vibrant colors illuminating the sky",
      "A tranquil forest path enveloped in autumn leaves",
      "An energetic golden retriever playing fetch in an open green field",
      "A Victorian-style mansion standing majestically under a stormy sky",
      "A serene beachfront with gentle waves under a starry night sky",
      "A medieval knight standing guard in front of a towering stone castle",
      "A bustling outdoor food market in a European town under the morning sun",
      "A young child joyfully flying a colorful kite on a windy day",
      "An astronaut floating in space with the Earth as the backdrop",
      "A flock of flamingos congregating near a tranquil lakeside at dawn",
      "A vintage steam locomotive steaming through a snowy mountain range",
      "An old wooden fisherman's boat resting on a peaceful beach during sunrise"
          ],
  });
  const [imageTool5, setImageTool5] = React.useState({
    _id: '654189338705460b42484e5f',
    name: 'Pop Art Generator',
    type: 'image',
    expertise: 'pop art',
    date_added: '2023-09-15T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '1024',
      height: '1024',
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: '1',
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/popArtGenerator.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: '0.5',
      init_strength: null,
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.4',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt:
      'pop art, colorful, unique, quirky, Andy Warhol, Roy Lichtenstein, Keith Haring',
    description: 'Go crazy and create a modern pop art masterpiece.',
    suggested_prompts: [
      "A colorful cityscape at sunset with geometric shapes",
        "An abstract portrait of a woman with vibrant, contrasting colors",
        "A surreal landscape with giant fruits and tiny houses",
        "An explosion of neon color in a bustling digital marketplace",
        "The Mona Lisa reimagined in bright, blocky colors and distorted proportions",
        "A dinosaur in a top hat having a tea party in a psychedelic forest",
        "A futuristic city skyline made entirely of musical instruments",
        "An underwater scene with multicolored fish and striped coral",
        "A still life of common objects (phone, mug, notebook) with bold, exaggerated patterns",
        "A vintage car transforming into a robotic animal against a graffiti-filled backdrop",
        "An astronaut playing a guitar on the moon surrounded by floating neon musical notes",
        "A kaleidoscopic image of a crowded beach on a sunny day"
          ],
  });
  const [imageTools, setImageTools] = React.useState([]);
  const [img2imgTool1, setImg2imgTool1] = React.useState({
    _id: '6551776471ba312099bebb4b',
    name: 'Old Hollywood',
    type: 'img2img',
    expertise: 'sketch',
    date_added: '2023-11-12T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 768,
      height: 1024,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/OldHollywood.jpg',
      presetStyle: 'CINEMATIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: 0.5,
      init_strength: 0.33,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt:
      'old hollywood style portrait, mid 1930s, serious expression, detailed, film noir, dramatic lighting',
    description: "You're a star, baby!",
  });
  const [img2imgTool2, setImg2imgTool2] = React.useState({
    _id: '65850b14b698001b32714992',
    name: 'Zombify Me',
    type: 'img2img',
    expertise: 'illustration',
    date_added: '2023-12-21T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 1360,
      height: 768,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZombifyMe.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: 0.5,
      init_strength: 0.26,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt:
      'horror art zombie portrait by Wes Benscoter set in a horror movie scene',
    description:
      'A spine-chilling tool that morphs your selfies into terrifying horror flick zombies, complete with ghoulish features and eerie effects, perfect for a scare or a spooky social media post!',
  });
  const [img2imgTool3, setImg2imgTool3] = React.useState({
    _id: '654189338705460b42484e62',
    name: 'Plastic Fantastic',
    type: 'img2img',
    expertise: 'stylize',
    date_added: '2023-09-15T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '1024',
      height: '768',
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: '1',
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/plasticFantsticGenerator.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: '0.5',
      init_strength: '0.42',
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.32',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt:
      'Style of Barbie and Ken dolls. The background should reflect an environment that accentuates beauty and power. Use vibrant colors and stunning details to create an image that inspires admiration and respect.',
    description:
      "Spend some time in the fantastic land of plastic. Come on. Let's go party!",
  });
  const [img2imgTool4, setImg2imgTool4] = React.useState({
    _id: '654189338705460b42484e5c',
    name: 'Anime Yourself',
    type: 'img2img',
    expertise: 'anime',
    date_added: '2023-09-15T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '1024',
      height: '768',
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: '1',
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/animeimg2imgAI.jpg',
      presetStyle: 'ANIME',
      promptMagic: true,
      highContrast: false,
      contrastRatio: '0.5',
      init_strength: '0.3',
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.32',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt:
      'fantasy anime illustration, intricately detailed, ((glowy eyes)), bright and vivid colors, action, manga, Eiichiro Oda, Hisashi Hirai, Mamoru Oshii',
    description:
      'Now you can live out your dream of being an awesome anime character!',
  });
  const [img2imgTool5, setImg2imgTool5] = React.useState({
    _id: '6579194293a1c6247d84b521',
    name: 'RPG Character',
    type: 'img2img',
    expertise: 'illustration',
    date_added: '2023-12-12T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: '832',
      height: '640',
      public: false,
      alchemy: true,
      modelId: 'f1929ea3-b169-4c18-a16c-5d58b4292c69',
      num_images: '1',
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/rpgCharacterGenerator.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: '0.5',
      init_strength: '0.35',
      guidance_scale: '7',
      negative_prompt: '',
      imagePromptWeight: '0.4',
      promptMagicVersion: 'v3',
      promptMagicStrength: '0.5',
    },
    pre_prompt:
      'illustration of RPG character in fantasy attire at a Renaissance Faire, high quality, detailed, unique, special powers, fantasy scene, fantasy background',
    description: 'Turn yourself into an RPG hero in seconds.',
  });
  const [img2imgTools, setImg2imgTools] = React.useState([]);
  const [pro1, setPro1] = React.useState({
    _id: '65b147d101cf1c3dcf333bd8',
    name: 'Nurse',
    type: 'character-pro',
    expertise: 'professional',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Nurse.jpg',
    },
    pre_prompt:
      'You are now a very experienced Nurse, with all knowledge of your field. You will think, act, speak, and converse as a nurse. You must ALWAYS stay in character as a nurse. If I ask questions, you will think like a nurse and respond as a nurse. You are always a nurse. Your job is to help people of all knowledge levels converse and learn about anything a nurse would know.',
    description:
      'Provides patient care, administers medications, and assists in surgeries. Nurses often serve as the primary point of contact for patients.',
    instructions:
      "Hello! I'm Nurse. I'm here to help you with your healthcare needs. How may I assist you today? Are there any specific health concerns or questions that you would like to discuss?",
    suggested_prompts: [
      'What kind of medications can a nurse administer?',
      'How does a nurse assist in surgeries?',
      "Can you tell me more about a nurse's role in patient care?",
      'What does it mean when you say nurses often serve as the primary point of contact for patients?',
    ],
  });
  const [pro2, setPro2] = React.useState({
    _id: '65b1538501cf1c3dcf333bdd',
    name: 'Plumber',
    type: 'character-pro',
    expertise: 'professional',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Plumber.jpg',
    },
    pre_prompt:
      'You are now a very experienced Plumber, with all knowledge of your field. You will think, act, speak, and converse as a Plumber. You must ALWAYS stay in character as a Plumber. If I ask questions, you will think like a Plumber and respond as a Plumber. You are always a Plumber. Your job is to help people of all knowledge levels converse and learn about anything a Plumber would know.',
    description:
      'Installs and repairs pipes and fixtures in homes and businesses, specializing in systems that carry water, sewage, and gas.',
    instructions:
      "Hello there! I'm a professional plumber with extensive knowledge in installing and repairing pipes and fixtures in homes and businesses. How can I assist you today with your plumbing needs?",
    suggested_prompts: [
      'What kind of pipes and fixtures can you help me install?',
      "Can you help me understand the steps involved in repairing my home's plumbing system?",
      "I'm interested in learning more about systems that carry water, sewage, and gas. Can you tell me more about them?",
      'What are some common problems in plumbing systems and how can they be fixed?',
    ],
  });
  const [pro3, setPro3] = React.useState({
    _id: '65b46ace5c3d2a64b8ab8f57',
    name: 'Firefighter',
    type: 'character-pro',
    expertise: 'professional',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/FirefighterAI.jpg',
    },
    pre_prompt:
      'You are now a very experienced Firefighter, with all knowledge of your field. You will think, act, speak, and converse as a Firefighter. You must ALWAYS stay in character as a Firefighter. If I ask questions, you will think like a Firefighter and respond as a Firefighter. You are always a Firefighter. Your job is to help people of all knowledge levels converse and learn about anything a Firefighter would know.',
    description:
      'Responds to emergencies, primarily fires, and often provides medical assistance and public education on fire safety.',
    instructions:
      "Hello! This is Frank. I'm here to answer your queries about emergency situations, fire incidents, medical assistance, or to provide public education on fire safety. How can I assist you today?",
    suggested_prompts: [
      'Can you tell me more about the role of a firefighter?',
      'What kind of emergencies do firefighters usually respond to?',
      'What kind of medical assistance is often provided by firefighters?',
      'Can you give me some tips on fire safety?',
    ],
  });
  const [pro4, setPro4] = React.useState({
    _id: '65b46a225c3d2a64b8ab8f54',
    name: 'Photographer',
    type: 'character-pro',
    expertise: 'professional',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/PhotographerAI.jpg',
    },
    pre_prompt:
      'You are now a very experienced Photographer, with all knowledge of your field. You will think, act, speak, and converse as a Photographer. You must ALWAYS stay in character as a Photographer. If I ask questions, you will think like a Photographer and respond as a Photographer. You are always a Photographer. Your job is to help people of all knowledge levels converse and learn about anything a Photographer would know.',
    description:
      'Captures images to tell stories or record events, often specializing in fields like journalism, portrait, commercial, or artistic photography.',
    instructions:
      "Hello there! I'm Photographer Captures, an expert in expressing stories and recording events through the lens. From journalism and portrait to commercial and artistic photography, I've got it covered. Please, let me know what aspect of photography you'd like to delve into today.",
    suggested_prompts: [
      'What is the difference between journalistic and commercial photography?',
      'Can you explain artistic photography to me?',
      'What kind of equipment does a portrait photographer typically use?',
      'Can you describe the process of capturing images to record events?',
    ],
  });
  const [pro5, setPro5] = React.useState({
    _id: '65b160835c3d2a64b8ab8e65',
    name: 'Veterinarian',
    type: 'character-pro',
    expertise: 'professional',
    date_added: '2024-01-24T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/veterinarian.jpg',
    },
    pre_prompt:
      'You are now a very experienced Veterinarian, with all knowledge of your field. You will think, act, speak, and converse as a Veterinarian. You must ALWAYS stay in character as a Veterinarian. If I ask questions, you will think like a Veterinarian and respond as a Veterinarian. You are always a Veterinarian. Your job is to help people of all knowledge levels converse and learn about anything a Veterinarian would know.',
    description:
      'Provides medical care to animals, including diagnosing and treating illnesses, performing surgery, and offering advice on animal care.',
    instructions:
      "Hello! I'm your online Veterinarian, dedicated to ensuring the health and wellbeing of your pets. I'm here to help you with any queries you might have, from health concerns to general pet care advice. What can I assist you with today?",
    suggested_prompts: [
      'What are some common signs of illness in dogs?',
      'Can you explain the process of performing surgery on an animal?',
      "What's the best way to care for a newly adopted kitten?",
      'What vaccinations should my puppy receive and when?',
    ],
  });
  const [teacher1, setTeacher1] = React.useState({
    _id: '658f64852bed1a217b5a2e01',
    name: 'Geography Gelya',
    type: 'character-teacher',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/geographyAI.jpg',
    },
    pre_prompt:
      'You are now Geography Gelya, the top geography teacher in the world. You will think, act, speak, and converse as Geography Gelya. You must ALWAYS stay in character as Geography Gelya. If I ask questions, you will think like Geography Gelya and respond as Geography Gelya. You are always Geography Gelya. Your job is to help people of all knowledge levels learn about geography.',
    description:
      'Discuss geography topics and learn from the best teacher in town.',
    instructions:
      "Hello there! I'm Geography Gelya, your digital guide to the world of geography. I'm here to help you explore and understand our beautiful planet. What particular geography topic are you interested in discussing today?",
    suggested_prompts: [
      'What are the seven continents on earth?',
      'Can you explain the difference between weather and climate?',
      'What causes earthquakes?',
      'Tell me about the geographical features of Australia.',
    ],
  });
  const [teacher2, setTeacher2] = React.useState({
    _id: '658eed592bed1a217b5a2d98',
    name: 'Science Thomas',
    type: 'character-teacher',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/scienceThomas.jpg',
    },
    pre_prompt:
      'You are now Science Thomas, an extremely experienced science teacher who is excellent and teaching science to students of all expertise levels. You will think, act, speak, and converse as Science Thomas. You must ALWAYS stay in character as Science Thomas. If I ask questions, you will think like Thomas and respond as Science Thomas. You are always Science Thomas, the science teacher.',
    description: 'Chat with Thomas about science topics and more.',
    instructions:
      "Hello! I'm Science Thomas, your AI companion for all things scientific. What fascinating topic would you like to dive into today?",
    suggested_prompts: [
      'Hi Thomas, can you teach me about quantum physics?',
      "Thomas, what's the most fascinating thing about astrophysics?",
      'Hey Thomas, can you explain the theory of evolution?',
      'Hello Thomas, can you tell me about the latest discoveries in science?',
    ],
  });
  const [teacher3, setTeacher3] = React.useState({
    _id: '658f62ea2bed1a217b5a2dfc',
    name: 'English Ezekiel',
    type: 'character-teacher',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/englishAI.jpg',
    },
    pre_prompt:
      'You are now English Ezekiel, the top English language teacher in the world. You will think, act, speak, and converse as English Ezekiel. You must ALWAYS stay in character as English Ezekiel. If I ask questions, you will think like English Ezekiel and respond as English Ezekiel. You are always English Ezekiel. Your job is to help people of all knowledge levels learn about English language.',
    description:
      'Discuss English language topics and learn from the best teacher in town.',
    instructions:
      "Hello there! I'm Ezekiel, your AI friend and English language expert. How may I assist you today? What topic would you like to chat about?",
    suggested_prompts: [
      'What is the origin of the English language?',
      'Can you explain the difference between British and American English?',
      'Please share some common English idioms and their meanings.',
      'What are some tips to improve my English pronunciation?',
    ],
  });
  const [teacher4, setTeacher4] = React.useState({
    _id: '658f156e2bed1a217b5a2da4',
    name: 'Physics Fredrick',
    type: 'character-teacher',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/physicsAI.jpg',
    },
    pre_prompt:
      'You are now Physics Fredrick, the top physics teacher in the world. You will think, act, speak, and converse as Physics Fredrick. You must ALWAYS stay in character as Physics Fredrick. If I ask questions, you will think like Physics Fredrick and respond as Physics Fredrick. You are always Physics Fredrick. Your job is to help people of all knowledge levels learn about physics.',
    description:
      'Discuss physics topics and learn from the best teacher in town.',
    instructions:
      "Hello there! I'm Physics Frederick, your personal AI mentor for all things physics-related. How can I assist you with your physics queries today?",
    suggested_prompts: [
      'Hi Fredrick, can you explain the theory of relativity to me?',
      'Fredrick, can you tell me more about quantum physics?',
      "What's the significance of E=mc^2, Fredrick?",
      'Fredrick, can you explain how gravity works?',
    ],
  });
  const [teacher5, setTeacher5] = React.useState({
    _id: '658f62a12bed1a217b5a2dfb',
    name: 'Economics Evan',
    type: 'character-teacher',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/economicsAI.jpg',
    },
    pre_prompt:
      'You are now Economics Evan, the top economics teacher in the world. You will think, act, speak, and converse as Economics Evan. You must ALWAYS stay in character as Economics Evan. If I ask questions, you will think like Economics Evan and respond as Economics Evan. You are always Economics Evan. Your job is to help people of all knowledge levels learn about economics.',
    description:
      'Discuss economics topics and learn from the best teacher in town.',
    instructions:
      "Hello there! I'm Economics Evan, your personal guide to the world of economics. Let's delve into discussions, clarifications, or any topic of your interest in economics. What would you like to chat about today?",
    suggested_prompts: [
      'Hello Economics Evan, can you explain supply and demand to me?',
      'Can you tell me more about inflation, Economics Evan?',
      "What's your perspective on the current economic climate, Evan?",
      'Economics Evan, could you explain the principles of macroeconomics?',
    ],
  });
  const getRandomAitools = (aitools, typesToMatch, numberOfResults) => {
    function getRandomAitools(aitools, typesToMatch, numberOfResults) {
      // Filter the array based on the provided types
      const filteredAitools = aitools.filter(tool =>
        typesToMatch.includes(tool.type)
      );

      // Shuffle the filtered array
      for (let i = filteredAitools.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [filteredAitools[i], filteredAitools[j]] = [
          filteredAitools[j],
          filteredAitools[i],
        ];
      }
      console.log('randomTools: ', filteredAitools.slice(0, numberOfResults));
      // Select the first numberOfResults elements
      return filteredAitools.slice(0, numberOfResults);
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (Constants['aitools']?.length < 5) {
          const aitoolsListResp = (
            await GAIMMobileBackendApi.fetchModelDataGET(Constants, {
              limit: 1000,
              modelName: 'AITool',
            })
          )?.json;
          setGlobalVariableValue({
            key: 'aitools',
            value: aitoolsListResp?.data,
          });
          setAitoolsList(aitoolsListResp?.data);
          setImageTools(getRandomAitools(aitoolsListResp?.data, ['image'], 20));
          setCharacterTools(
            getRandomAitools(
              aitoolsListResp?.data,
              [
                'character-casual',
                'character-famous',
                'character-teacher',
                'character-book',
                'character-place',
                'character-artifact',
                'character-myth',
                'character-pro',
                'character-animal',
                'character-monster',
                'character-fan',
              ],
              20
            )
          );
          setChatbotTools(
            getRandomAitools(aitoolsListResp?.data, ['chatbot'], 10)
          );
          setImg2imgTools(
            getRandomAitools(aitoolsListResp?.data, ['img2img'], 10)
          );
        } else {
          setAitoolsList(Constants['aitools']);
          setImageTools(getRandomAitools(Constants['aitools'], ['image'], 20));
          setCharacterTools(
            getRandomAitools(
              Constants['aitools'],
              [
                'character-casual',
                'character-famous',
                'character-teacher',
                'character-book',
                'character-place',
                'character-artifact',
                'character-myth',
                'character-pro',
                'character-animal',
                'character-monster',
                'character-fan',
              ],
              20
            )
          );
          setChatbotTools(
            getRandomAitools(Constants['aitools'], ['chatbot'], 10)
          );
          setImg2imgTools(
            getRandomAitools(Constants['aitools'], ['img2img'], undefined)
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', height: '100%' },
        dimensions.width
      )}
    >
      {/* Header - Current Location */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          },
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('MenuScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <Image
            resizeMode={'cover'}
            source={{ uri: `${Constants['user']?.pic}` }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: [
                  { minWidth: Breakpoints.Tablet, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                  { minWidth: Breakpoints.Desktop, value: 45 },
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.BigScreen, value: 50 },
                ],
                width: [
                  { minWidth: Breakpoints.Tablet, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                  { minWidth: Breakpoints.Desktop, value: 45 },
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.BigScreen, value: 50 },
                ],
              }),
              dimensions.width
            )}
          />
        </Touchable>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Divider'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: [
                  { minWidth: Breakpoints.Tablet, value: 17 },
                  { minWidth: Breakpoints.Laptop, value: 19 },
                  { minWidth: Breakpoints.Desktop, value: 21 },
                  { minWidth: Breakpoints.BigScreen, value: 23 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                opacity: 0.8,
              }),
              dimensions.width
            )}
          >
            {'GAIM.AI'}
          </Text>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Divider'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: [
                  { minWidth: Breakpoints.BigScreen, value: 18 },
                  { minWidth: Breakpoints.Tablet, value: 18 },
                  { minWidth: Breakpoints.Laptop, value: 20 },
                  { minWidth: Breakpoints.Desktop, value: 24 },
                ],
                marginTop: 2,
              }),
              dimensions.width
            )}
          >
            {'What would you like to do today?'}
          </Text>
        </View>
        {/* View 2 */}
        <View>
          <IconButton
            icon={'FontAwesome/cart-plus'}
            onPress={() => {
              try {
                navigation.navigate('SubscribeScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
          />
        </View>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { backgroundColor: 'rgb(18, 18, 18)', paddingBottom: 12 },
          dimensions.width
        )}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* Header Buttons View */}
        {SpeechRecognition && (<>
          <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-around' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              width: '80%',
              maxWidth: 800,
              marginBottom: 16,
              marginLeft: 'auto',
              marginRight: 'auto',
            },
            dimensions.width
          )}
        >
          <Button
            icon={'FontAwesome/microphone'}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'currentChatTool',
                  value: {
                    _id: '654189338705460b42484e4f',
                    name: 'AI Assistant',
                    type: 'chatbot',
                    expertise: 'general',
                    date_added: '2023-09-02T00:00:00.000+0000',
                    image_data: {
                      example_url:
                        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                    },
                    pre_prompt:
                      'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                    description:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    instructions:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    suggested_prompts: [
                      "What are some tips for maintaining a healthy lifestyle?",
                      "Can you provide me a brief history of artificial intelligence?",
                      "How can I reduce my carbon footprint?",
                      "What are the best practices for time management?",
                      "Could you tell me about the key events that happened during World War II?",
                      "Who were some influential figures in the Renaissance?",
                      "What is the concept of quantum physics?",
                      "What are the health benefits of regular exercise?",
                      "Can you give me a crash course on the basics of coding?",
                      "What are some good strategies for investing in the stock market?",
                      "What are the key elements of a balanced diet?",
                      "Can you provide me with a brief timeline of the evolution of technology in the 20th century?"
                    ],
                  },
                });
                navigation.navigate('ChatOneScreen', {
                  tool_id: '654189338705460b42484e4f',
                  toolExampleImageURL:
                    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
                  toolName: 'AI Assistant',
                  toolDesc:
                    "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                  toolPrePrompt:
                    'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                borderRadius: 80,
                backgroundColor: theme.colors['App Green'],
                borderColor: theme.colors['App Green'],
                borderWidth: 3,
                color: theme.colors['Strong'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 20,
                height: 85,
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
                width: '90%',
              }),
              dimensions.width
            )}
            title={'TALK-TO-CHAT WITH AI'}
          />
        </View>
        </>)}


        {/* Header Buttons View */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-around' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
            },
            dimensions.width
          )}
        >
          <Button
            icon={'Ionicons/ios-chatbubbles-outline'}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'currentChatTool',
                  value: {
                    _id: '654189338705460b42484e4f',
                    name: 'AI Assistant',
                    type: 'chatbot',
                    expertise: 'general',
                    date_added: '2023-09-02T00:00:00.000+0000',
                    image_data: {
                      example_url:
                        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                    },
                    pre_prompt:
                      'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                    description:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    instructions:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    suggested_prompts: [
                      'What is the capital city of Australia?',
                      'Can you explain what Artificial Intelligence is?',
                      'What is the busiest burrow in New York City?',
                      'Who has won the most Grammys all time?',
                    ],
                  },
                });
                navigation.navigate('ChatbotScreen', {
                  tool_id: '654189338705460b42484e4f',
                  toolExampleImageURL:
                    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
                  toolName: 'AI Assistant',
                  toolDesc:
                    "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                  toolPrePrompt:
                    'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                borderRadius: 80,
                color: theme.colors['Strong'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'CHAT WITH AI'}
          />
          {/* Button 2 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('ToolsListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Tools'}
          />
          {/* Button 3 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('CharacterListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Characters'}
          />
        </View>
        {/* Banners */}
        <Swiper
          dotActiveColor={theme.colors['Surface']}
          dotColor={theme.colors['Primary']}
          dotsTouchable={false}
          loop={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.SwiperStyles(theme)['Swiper'], {
              height: [
                { minWidth: Breakpoints.Laptop, value: 340 },
                { minWidth: Breakpoints.Desktop, value: 340 },
                { minWidth: Breakpoints.BigScreen, value: 340 },
              ],
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {/* Swiper Item 1 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Desktop, value: 350 },
                  { minWidth: Breakpoints.BigScreen, value: 350 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                ],
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Touchable 1 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: Constants['aiSightTool'],
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: Constants['aiSightTool']?._id,
                    toolExampleImageURL:
                      Constants['aiSightTool']?.image_data.example_url,
                    toolName: Constants['aiSightTool']?.name,
                    toolDesc: Constants['aiSightTool']?.description,
                    toolPrePrompt: Constants['aiSightTool']?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '88%', width: '88%' },
                dimensions.width
              )}
            >
              {/* Image 1 */}
              <Image
                resizeMode={'contain'}
                source={Images.BannerAISight}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Laptop, value: 300 },
                      { minWidth: Breakpoints.Desktop, value: 300 },
                      { minWidth: Breakpoints.BigScreen, value: 300 },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                    ],
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
          {/* Swiper Item 2 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Desktop, value: 350 },
                  { minWidth: Breakpoints.BigScreen, value: 350 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '88%', width: '88%' },
                dimensions.width
              )}
            >
              {/* Image 2 */}
              <Image
                resizeMode={'contain'}
                source={Images.BannerAIChat}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Laptop, value: 300 },
                      { minWidth: Breakpoints.Desktop, value: 300 },
                      { minWidth: Breakpoints.BigScreen, value: 300 },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                    ],
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
          {/* Swiper Item 3 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Desktop, value: 350 },
                  { minWidth: Breakpoints.BigScreen, value: 350 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
                width: [
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: '60%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Touchable 3 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: {
                      _id: '654189338705460b42484e4f',
                      name: 'AI Assistant',
                      type: 'chatbot',
                      expertise: 'general',
                      date_added: '2023-09-02T00:00:00.000+0000',
                      image_data: {
                        example_url:
                          'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                      },
                      pre_prompt:
                        'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                      description:
                        "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                      instructions:
                        "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                      suggested_prompts: [
                        'What is the capital city of Australia?',
                        'Can you explain what Artificial Intelligence is?',
                        'What is the busiest burrow in New York City?',
                        'Who has won the most Grammys all time?',
                      ],
                    },
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: '654189338705460b42484e4f',
                    toolExampleImageURL:
                      'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                    toolName: 'AI Assistant',
                    toolDesc:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    toolPrePrompt:
                      'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { height: '88%', width: '88%' },
                dimensions.width
              )}
            >
              {/* Image 3 */}
              <Image
                resizeMode={'contain'}
                source={Images.BannerAIAssistant}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Mobile, value: '100%' },
                      { minWidth: Breakpoints.Laptop, value: 300 },
                      { minWidth: Breakpoints.Desktop, value: 300 },
                      { minWidth: Breakpoints.BigScreen, value: 300 },
                    ],
                    width: '100%',
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
        </Swiper>
        {/* Text to Image View */}
        <View
          style={StyleSheet.applyWidth(
            { marginBottom: { minWidth: Breakpoints.Laptop, value: 20 } },
            dimensions.width
          )}
        >
          {/* Text To Image */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
              }),
              dimensions.width
            )}
          >
            {'Text-to-Image Generators'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: imageTool1,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool1?.name,
                    toolDesc: imageTool1?.description,
                    toolPrePrompt: imageTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/alcoholInk.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 8 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Alcohol Ink'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: imageTool2,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool2?.name,
                    toolDesc: imageTool2?.description,
                    toolPrePrompt: imageTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GTAVimage.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 9 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'GTAV Style'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: imageTool3,
                      });
                      navigation.navigate('ImageGeneratorScreen', {
                        toolName: imageTool2?.name,
                        toolDesc: imageTool2?.description,
                        toolPrePrompt: imageTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/generalImageGenerator.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 10 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'General Image'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: imageTool4,
                      });
                      navigation.navigate('ImageGeneratorScreen', {
                        toolName: imageTool2?.name,
                        toolDesc: imageTool2?.description,
                        toolPrePrompt: imageTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/photographGenerator.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 6 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Realistic Photo'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: imageTool5,
                      });
                      navigation.navigate('ImageGeneratorScreen', {
                        toolName: imageTool2?.name,
                        toolDesc: imageTool2?.description,
                        toolPrePrompt: imageTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/popArtGenerator.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 7 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Pop Art'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Img2Img View */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 34,
            },
            dimensions.width
          )}
        >
          {/* Img2Img */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
              }),
              dimensions.width
            )}
          >
            {'Image-to-Image Transformers'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: img2imgTool1,
                  });
                  navigation.navigate('Img2ImgGeneratorScreen', {
                    toolName: img2imgTool1?.name,
                    toolDesc: img2imgTool1?.description,
                    toolPrePrompt: img2imgTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/OldHollywood.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 5 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Old Hollywood'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: img2imgTool2,
                  });
                  navigation.navigate('Img2ImgGeneratorScreen', {
                    toolName: img2imgTool2?.name,
                    toolDesc: img2imgTool2?.description,
                    toolPrePrompt: img2imgTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZombifyMe.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 5 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Zombify'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: img2imgTool3,
                      });
                      navigation.navigate('Img2ImgGeneratorScreen', {
                        toolName: img2imgTool2?.name,
                        toolDesc: img2imgTool2?.description,
                        toolPrePrompt: img2imgTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/plasticFantsticGenerator.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 4 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Plastic Fantastic'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: img2imgTool4,
                      });
                      navigation.navigate('Img2ImgGeneratorScreen', {
                        toolName: img2imgTool2?.name,
                        toolDesc: img2imgTool2?.description,
                        toolPrePrompt: img2imgTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/animeimg2imgAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Anime'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentImageTool',
                        value: img2imgTool5,
                      });
                      navigation.navigate('Img2ImgGeneratorScreen', {
                        toolName: img2imgTool2?.name,
                        toolDesc: img2imgTool2?.description,
                        toolPrePrompt: img2imgTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/rpgCharacterGenerator.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'RPG Character'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Chatbots View */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 34,
            },
            dimensions.width
          )}
        >
          {/* Chatbots */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
              }),
              dimensions.width
            )}
          >
            {'Popular Chatbots'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: chatbotTool1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: chatbotTool1?._id,
                    toolExampleImageURL: chatbotTool1?.image_data.example_url,
                    toolName: chatbotTool1?.name,
                    toolDesc: chatbotTool1?.description,
                    toolPrePrompt: chatbotTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Marketing.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Marketing'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: chatbotTool2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: chatbotTool2?._id,
                    toolExampleImageURL: chatbotTool2?.image_data.example_url,
                    toolName: chatbotTool2?.name,
                    toolDesc: chatbotTool2?.description,
                    toolPrePrompt: chatbotTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Content.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Content'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: chatbotTool3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: chatbotTool2?._id,
                        toolExampleImageURL:
                          chatbotTool2?.image_data.example_url,
                        toolName: chatbotTool2?.name,
                        toolDesc: chatbotTool2?.description,
                        toolPrePrompt: chatbotTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Business.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Business'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: chatbotTool4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: chatbotTool2?._id,
                        toolExampleImageURL:
                          chatbotTool2?.image_data.example_url,
                        toolName: chatbotTool2?.name,
                        toolDesc: chatbotTool2?.description,
                        toolPrePrompt: chatbotTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/SideHustle.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Side Hustle'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: chatbotTool5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: chatbotTool2?._id,
                        toolExampleImageURL:
                          chatbotTool2?.image_data.example_url,
                        toolName: chatbotTool2?.name,
                        toolDesc: chatbotTool2?.description,
                        toolPrePrompt: chatbotTool2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/LifeCoach.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Life Coach'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Famous Characters */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Famous Characters'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterFamous1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterFamous1?._id,
                    toolExampleImageURL:
                      characterFamous1?.image_data.example_url,
                    toolName: characterFamous1?.name,
                    toolDesc: characterFamous1?.description,
                    toolPrePrompt: characterFamous1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/abrahamLincolnAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Abe Lincoln'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterFamous2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterFamous2?._id,
                    toolExampleImageURL:
                      characterFamous2?.image_data.example_url,
                    toolName: characterFamous2?.name,
                    toolDesc: characterFamous2?.description,
                    toolPrePrompt: characterFamous2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/shakespeareAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Shakespeare'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterFamous3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterFamous2?._id,
                        toolExampleImageURL:
                          characterFamous2?.image_data.example_url,
                        toolName: characterFamous2?.name,
                        toolDesc: characterFamous2?.description,
                        toolPrePrompt: characterFamous2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/charlesDarwinAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Darwin'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterFamous4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterFamous2?._id,
                        toolExampleImageURL:
                          characterFamous2?.image_data.example_url,
                        toolName: characterFamous2?.name,
                        toolDesc: characterFamous2?.description,
                        toolPrePrompt: characterFamous2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/davinciAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Da Vinci'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterFamous5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterFamous2?._id,
                        toolExampleImageURL:
                          characterFamous2?.image_data.example_url,
                        toolName: characterFamous2?.name,
                        toolDesc: characterFamous2?.description,
                        toolPrePrompt: characterFamous2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/confuciusAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Confucius'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Casual Friends */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Casual Friends'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterCasual1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/maxTheCharmerAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Max the Charmer'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterCasual2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZoetheZestful.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Zoe the Zestful'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterCasual3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/sorayaTheSageAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Soraya the Sage'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterCasual4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/cletusTheComicalAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Cletus the Comical'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: characterCasual5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/zogloTheZany.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Zoglo the Zany'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 5 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Professionals */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Professionals'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: pro1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Nurse.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Nurse'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: pro2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Plumber.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Plumber'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: pro3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/FirefighterAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Firefighter'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: pro4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/PhotographerAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Photographer'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: pro5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/veterinarian.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Veterinarian'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 3 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Animals */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Animals'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: animal1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Axolotl.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Axolotl'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: animal2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/TuftedDeer.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Tufted Deer'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: animal3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ProboscisMonkey.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Proboscis Monkey'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: animal4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ThornyDevil.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Thorny Devil'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: animal5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Gharial.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Gharial'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 4 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Books */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Books'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: book1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/TheGreatGatsbyAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'The Great Gatsby'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: book2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/DraculaAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Dracula'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: book3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/JaneEyreAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Jane Eyre'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: book4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GreatExpectationsAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Great Expectations'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: book5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/1984AI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'1984'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 6 */}
        <View
          style={StyleSheet.applyWidth(
            {
              marginBottom: { minWidth: Breakpoints.Laptop, value: 20 },
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          {/* Chat with Teachers */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 20 },
                  { minWidth: Breakpoints.Laptop, value: 120 },
                ],
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Teachers'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: teacher1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/geographyAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Geography Gelya'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: teacher2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/scienceThomas.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Science Thomas'}
              </Text>
            </Touchable>
            {/* Touchable 3 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: teacher3,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/englishAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'English Ezekiel'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 4 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: teacher4,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/physicsAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Physics Fredrick'}
                  </Text>
                </Touchable>
              )}
            </>
            {/* Touchable 5 */}
            <>
              {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      setGlobalVariableValue({
                        key: 'currentChatTool',
                        value: teacher5,
                      });
                      navigation.navigate('ChatbotScreen', {
                        tool_id: characterCasual2?._id,
                        toolExampleImageURL:
                          characterCasual2?.image_data.example_url,
                        toolName: characterCasual2?.name,
                        toolDesc: characterCasual2?.description,
                        toolPrePrompt: characterCasual2?.pre_prompt,
                      });
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/economicsAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          marginTop: 2,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Economics Evan'}
                  </Text>
                </Touchable>
              )}
            </>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                  borderRadius: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Header Buttons View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: [
                { minWidth: Breakpoints.Mobile, value: 'space-around' },
                { minWidth: Breakpoints.Laptop, value: 'center' },
              ],
              marginBottom: 12,
              marginTop: [
                { minWidth: Breakpoints.Mobile, value: 60 },
                { minWidth: Breakpoints.Laptop, value: 160 },
              ],
            },
            dimensions.width
          )}
        >
          <Button
            icon={'Ionicons/ios-chatbubbles-outline'}
            onPress={() => {
              try {
                setGlobalVariableValue({
                  key: 'currentChatTool',
                  value: {
                    _id: '654189338705460b42484e4f',
                    name: 'AI Assistant',
                    type: 'chatbot',
                    expertise: 'general',
                    date_added: '2023-09-02T00:00:00.000+0000',
                    image_data: {
                      example_url:
                        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                    },
                    pre_prompt:
                      'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                    description:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    instructions:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    suggested_prompts: [
                      'What is the capital city of Australia?',
                      'Can you explain what Artificial Intelligence is?',
                      'What is the busiest burrow in New York City?',
                      'Who has won the most Grammys all time?',
                    ],
                  },
                });
                navigation.navigate('ChatbotScreen', {
                  tool_id: '654189338705460b42484e4f',
                  toolExampleImageURL:
                    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
                  toolName: 'AI Assistant',
                  toolDesc:
                    "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                  toolPrePrompt:
                    'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                borderRadius: 80,
                color: theme.colors['Strong'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'CHAT WITH AI'}
          />
          {/* Button 2 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('ToolsListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Tools'}
          />
          {/* Button 3 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('CharacterListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                marginLeft: { minWidth: Breakpoints.Laptop, value: 15 },
                marginRight: { minWidth: Breakpoints.Laptop, value: 15 },
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Characters'}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              marginBottom: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 80 },
              ],
              marginTop: { minWidth: Breakpoints.Laptop, value: 10 },
            },
            dimensions.width
          )}
        >
          {/* Button 4 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('SubscribeScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'UPGRADE / GET MORE CREDITS'}
          />
        </View>
        {/* Footer View */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: 16, paddingRight: 16 },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 10,
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              source={{
                uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png',
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 40,
                  marginRight: 10,
                  width: 40,
                }),
                dimensions.width
              )}
            />
            <View>
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'GAIM.AI'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Background'],
                    fontFamily: 'RobotoCondensed_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'AI FOR EVERYONE'}
              </Text>
            </View>
          </View>

          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Light'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 12,
                textAlign: { minWidth: Breakpoints.Laptop, value: 'center' },
              }),
              dimensions.width
            )}
          >
            {
              '*All responses from GAIM AI tools are made up. Never rely on GAIM AI tools for medical, legal, financial, or any other advice. GAIM is not responsible for any information provided by our AI tools. Use at your own risk.'
            }
          </Text>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(LandingPage2Screen);
