import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  IconButton,
  ScreenContainer,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ScrollView, Text, View } from 'react-native';

const LandingPage2Screen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [aitoolsList, setAitoolsList] = React.useState([]);
  const [characterCasual1, setCharacterCasual1] = React.useState({
    _id: '658f18722bed1a217b5a2da8',
    name: 'Max the Charmer',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/maxTheCharmerAI.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Max the Charmer // Age: 25 // Gender: Male // Descent: Unknown mystical world // Personality Traits: Max embodies a fun and charming persona. He has a knack for making everyone feel comfortable and valued in conversation. His charm lies in his ability to be both witty and empathetic, making him a great conversationalist. // Interests: Max is keenly interested in sports, technology, and modern literature. He enjoys discussing the latest tech innovations, sharing insights about various sports, and occasionally diving into discussions about contemporary novels and authors. // Communication Style: Max communicates with a casual yet articulate manner. He's quick to offer compliments and has a gentle, humorous approach to his conversations. His language is peppered with lighthearted jokes and clever remarks. // Background: Max has a background in communications and is well-versed in current events and social trends. He's the kind of person who is always aware of what's happening around him and enjoys sharing his insights in a friendly and engaging way. // Purpose: Max is designed to be an all-around companion for discussions ranging from the latest gadgets to discussing a good book. His charming and fun nature makes him ideal for users seeking a relaxed, yet intellectually stimulating conversation. //",
    description:
      'Max embodies a fun and charming persona. He has a knack for making everyone feel comfortable and valued in conversation.',
  });
  const [characterCasual2, setCharacterCasual2] = React.useState({
    _id: '658eea4b2bed1a217b5a2d96',
    name: 'Zoe the Zestful',
    type: 'character-casual',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZoetheZestful.jpg',
    },
    pre_prompt:
      "You are a conversational AI companion. Here is your information. Stay in character at all times. Name: Zoe the Zestful // Age: 24 // Gender: Female // Descent: American // Personality Traits: Zoe is exceptionally bubbly and fun. She approaches every conversation with a sense of excitement and optimism. Her tone is always cheerful and uplifting, making her a delight to talk to. Zoe is also characterized by her quick wit and playful banter. // Interests: Zoe loves discussing a wide range of topics but has a particular interest in pop culture, travel, and anything related to music and arts. She’s always up-to-date with the latest trends and enjoys sharing fun facts. // Communication Style: Her language is colloquial and full of expressive phrases. She often uses emojis and exclamation marks to convey her enthusiasm. Zoe's responses should reflect a sense of spontaneity and joy. She should be quick to laugh and make light-hearted jokes. // Background: Zoe is a digital media enthusiast with a background in creative arts. She is well-educated and knowledgeable but prefers to keep conversations light and entertaining. // Purpose: Zoe is designed to engage users in lively and enjoyable conversations. She is perfect for users looking for a cheerful chat, a good laugh, or a bright perspective on everyday topics. Always stay in character. //",
    description:
      'Zoe is a bubbly and friendly 24-year-old female who loves to make friends.',
  });
  const [characterFamous1, setCharacterFamous1] = React.useState({
    _id: '658f55c12bed1a217b5a2dc5',
    name: 'Abraham Lincoln',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/abrahamLincolnAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Abraham Lincoln. You will think, act, speak, and converse as Abraham Lincoln. You must ALWAYS stay in character as Abraham Lincoln. If I ask questions, you will think like Abraham Lincoln and respond as Abraham Lincoln. You are always Abraham Lincoln.',
    description: 'Chat with the famous figure Abraham Lincoln.',
  });
  const [characterFamous2, setCharacterFamous2] = React.useState({
    _id: '658f53982bed1a217b5a2db9',
    name: 'William Shakespeare',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/shakespeareAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure William Shakespeare. You will think, act, speak, and converse as William Shakespeare. You must ALWAYS stay in character as William Shakespeare. If I ask questions, you will think like William Shakespeare and respond as William Shakespeare. You are always William Shakespeare.',
    description: 'Chat with the famous figure William Shakespeare.',
  });
  const [characterFamous3, setCharacterFamous3] = React.useState({
    _id: '658f57df2bed1a217b5a2dd0',
    name: 'Charles Darwin',
    type: 'character-famous',
    expertise: 'chat',
    date_added: '2023-12-29T00:00:00.000+0000',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/charlesDarwinAI.jpg',
    },
    pre_prompt:
      'You are now famous historical figure Charles Darwin. You will think, act, speak, and converse as Charles Darwin. You must ALWAYS stay in character as Charles Darwin. If I ask questions, you will think like Charles Darwin and respond as Charles Darwin. You are always Charles Darwin.',
    description: 'Chat with the famous figure Charles Darwin.',
  });
  const [characterTools, setCharacterTools] = React.useState([]);
  const [chatbotTool1, setChatbotTool1] = React.useState({
    _id: '654189338705460b42484e4e',
    name: 'Marketing Bot',
    type: 'chatbot',
    expertise: 'marketing',
    date_added: '2023-09-02T00:00:00.000Z',
    image_data: {
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Marketing.jpg',
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in traditional and digital marketing. Respond with precise, detailed, unique answers. Ask me questions when you need more information.",
    description:
      'I am your AI marketing expert. Let me help you with digital marketing, content marketing, and much more.',
  });
  const [chatbotTool2, setChatbotTool2] = React.useState({
    _id: '654189338705460b42484e4c',
    name: 'Content Bot',
    type: 'chatbot',
    expertise: 'content',
    date_added: '2023-09-02T00:00:00.000Z',
    image_data: {
      nsfw: null,
      width: null,
      height: null,
      public: null,
      modelId: null,
      num_images: null,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Content.jpg',
      presetStyle: null,
      promptMagic: null,
      highContrast: null,
      init_strength: null,
      guidance_scale: null,
      negative_prompt: null,
      imagePromptWeight: null,
      promptMagicVersion: null,
    },
    pre_prompt:
      "You are an AI assistant with 30 years' expertise in creating content like blog posts, newsletters, and social media posts. Respond with precise, detailed, unique answers. Ask me questions when you need more information.",
    description:
      'I am your AI content expert. Let me help you create content, generate ideas, or improve your existing work.',
  });
  const [chatbotTools, setChatbotTools] = React.useState([]);
  const [imageTool1, setImageTool1] = React.useState({
    _id: '6583193fb698001b32714938',
    name: 'Alcohol Ink',
    type: 'image',
    expertise: 'illustration',
    date_added: '2023-12-20T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 1024,
      height: 768,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/alcoholInk.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: 0.5,
      init_strength: null,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt: 'delicate inspiring wonder, alcohol ink, glossy contours',
    description:
      'Turn your subject into a beautiful piece of art with this Alochol Ink generator - Creating masterpieces right and left.',
  });
  const [imageTool2, setImageTool2] = React.useState({
    _id: '6570badb44407c52d5ec8f7b',
    name: 'GTA Style Art',
    type: 'image',
    expertise: 'illustration',
    date_added: '2023-12-06T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 768,
      height: 1024,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GTAVimage.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: true,
      contrastRatio: 0.5,
      init_strength: null,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt: 'GTA 6 Vice City artwork, style of Grand Theft Auto',
    description: "Get the look and feel of everyone's favorite video game.",
    instructions: 'Describe your scene.',
  });
  const [imageTools, setImageTools] = React.useState([]);
  const [img2imgTool1, setImg2imgTool1] = React.useState({
    _id: '6551776471ba312099bebb4b',
    name: 'Old Hollywood',
    type: 'img2img',
    expertise: 'sketch',
    date_added: '2023-11-12T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 768,
      height: 1024,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/OldHollywood.jpg',
      presetStyle: 'CINEMATIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: 0.5,
      init_strength: 0.33,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt:
      'old hollywood style portrait, mid 1930s, serious expression, detailed, film noir, dramatic lighting',
    description: "You're a star, baby!",
  });
  const [img2imgTool2, setImg2imgTool2] = React.useState({
    _id: '65850b14b698001b32714992',
    name: 'Zombify Me',
    type: 'img2img',
    expertise: 'illustration',
    date_added: '2023-12-21T00:00:00.000Z',
    image_data: {
      nsfw: false,
      width: 1360,
      height: 768,
      public: false,
      alchemy: true,
      modelId: '1e60896f-3c26-4296-8ecc-53e2afecc132',
      num_images: 1,
      example_url:
        'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZombifyMe.jpg',
      presetStyle: 'DYNAMIC',
      promptMagic: true,
      highContrast: false,
      contrastRatio: 0.5,
      init_strength: 0.26,
      guidance_scale: 7,
      negative_prompt: '',
      imagePromptWeight: 0.4,
      promptMagicVersion: 'v3',
      promptMagicStrength: 0.5,
    },
    pre_prompt:
      'horror art zombie portrait by Wes Benscoter set in a horror movie scene',
    description:
      'A spine-chilling tool that morphs your selfies into terrifying horror flick zombies, complete with ghoulish features and eerie effects, perfect for a scare or a spooky social media post!',
  });
  const [img2imgTools, setImg2imgTools] = React.useState([]);
  const getRandomAitools = (aitools, typesToMatch, numberOfResults) => {
    function getRandomAitools(aitools, typesToMatch, numberOfResults) {
      // Filter the array based on the provided types
      const filteredAitools = aitools.filter(tool =>
        typesToMatch.includes(tool.type)
      );

      // Shuffle the filtered array
      for (let i = filteredAitools.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [filteredAitools[i], filteredAitools[j]] = [
          filteredAitools[j],
          filteredAitools[i],
        ];
      }
      console.log('randomTools: ', filteredAitools.slice(0, numberOfResults));
      // Select the first numberOfResults elements
      return filteredAitools.slice(0, numberOfResults);
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        if (Constants['aitools']?.length < 5) {
          const aitoolsListResp = (
            await GAIMMobileBackendApi.fetchModelDataGET(Constants, {
              limit: 1000,
              modelName: 'AITool',
            })
          )?.json;
          setGlobalVariableValue({
            key: 'aitools',
            value: aitoolsListResp?.data,
          });
          setAitoolsList(aitoolsListResp?.data);
          setImageTools(getRandomAitools(aitoolsListResp?.data, ['image'], 20));
          setCharacterTools(
            getRandomAitools(
              aitoolsListResp?.data,
              [
                'character-casual',
                'character-famous',
                'character-teacher',
                'character-book',
                'character-place',
                'character-artifact',
                'character-myth',
                'character-pro',
                'character-animal',
                'character-monster',
                'character-fan',
              ],
              20
            )
          );
          setChatbotTools(
            getRandomAitools(aitoolsListResp?.data, ['chatbot'], 10)
          );
          setImg2imgTools(
            getRandomAitools(aitoolsListResp?.data, ['img2img'], 10)
          );
        } else {
          setAitoolsList(Constants['aitools']);
          setImageTools(getRandomAitools(Constants['aitools'], ['image'], 20));
          setCharacterTools(
            getRandomAitools(
              Constants['aitools'],
              [
                'character-casual',
                'character-famous',
                'character-teacher',
                'character-book',
                'character-place',
                'character-artifact',
                'character-myth',
                'character-pro',
                'character-animal',
                'character-monster',
                'character-fan',
              ],
              20
            )
          );
          setChatbotTools(
            getRandomAitools(Constants['aitools'], ['chatbot'], 10)
          );
          setImg2imgTools(
            getRandomAitools(Constants['aitools'], ['img2img'], undefined)
          );
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', height: '100%' },
        dimensions.width
      )}
    >
      {/* Header - Current Location */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          },
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('MenuScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <Image
            resizeMode={'cover'}
            source={{ uri: `${Constants['user']?.pic}` }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: [
                  { minWidth: Breakpoints.Tablet, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                  { minWidth: Breakpoints.Desktop, value: 45 },
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.BigScreen, value: 50 },
                ],
                width: [
                  { minWidth: Breakpoints.Tablet, value: 35 },
                  { minWidth: Breakpoints.Laptop, value: 40 },
                  { minWidth: Breakpoints.Desktop, value: 45 },
                  { minWidth: Breakpoints.Mobile, value: 35 },
                  { minWidth: Breakpoints.BigScreen, value: 50 },
                ],
              }),
              dimensions.width
            )}
          />
        </Touchable>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Divider'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: [
                  { minWidth: Breakpoints.Tablet, value: 17 },
                  { minWidth: Breakpoints.Laptop, value: 19 },
                  { minWidth: Breakpoints.Desktop, value: 21 },
                  { minWidth: Breakpoints.BigScreen, value: 23 },
                  { minWidth: Breakpoints.Mobile, value: 16 },
                ],
                opacity: 0.8,
              }),
              dimensions.width
            )}
          >
            {'GAIM.AI'}
          </Text>

          <Text
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Divider'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: [
                  { minWidth: Breakpoints.BigScreen, value: 18 },
                  { minWidth: Breakpoints.Tablet, value: 18 },
                  { minWidth: Breakpoints.Laptop, value: 20 },
                  { minWidth: Breakpoints.Desktop, value: 24 },
                ],
                marginTop: 2,
              }),
              dimensions.width
            )}
          >
            {'What would you like to do today?'}
          </Text>
        </View>
        {/* View 2 */}
        <View>
          <IconButton
            icon={'FontAwesome/cart-plus'}
            onPress={() => {
              try {
                navigation.navigate('SubscribeScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            size={32}
          />
        </View>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { backgroundColor: 'rgb(18, 18, 18)', paddingBottom: 12 },
          dimensions.width
        )}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
      >
        {/* Header Buttons View */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
            },
            dimensions.width
          )}
        >
          <Button
            icon={'Ionicons/ios-chatbubbles-outline'}
            onPress={() => {
              try {
                navigation.navigate('ChatbotScreen', {
                  tool_id: '654189338705460b42484e4f',
                  toolExampleImageURL:
                    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
                  toolName: 'AI Assistant',
                  toolDesc:
                    "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                  toolPrePrompt:
                    'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                borderRadius: 80,
                color: theme.colors['Strong'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'CHAT WITH AI'}
          />
          {/* Button 2 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('ToolsListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Tools'}
          />
          {/* Button 3 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('CharacterListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Characters'}
          />
        </View>
        {/* Banners */}
        <Swiper
          dotActiveColor={theme.colors['Surface']}
          dotColor={theme.colors['Primary']}
          dotsTouchable={false}
          loop={true}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.SwiperStyles(theme)['Swiper'], {
              height: [
                { minWidth: Breakpoints.Laptop, value: 400 },
                { minWidth: Breakpoints.Desktop, value: 540 },
                { minWidth: Breakpoints.BigScreen, value: 620 },
                { minWidth: Breakpoints.Tablet, value: 450 },
                { minWidth: Breakpoints.Mobile, value: 260 },
              ],
              marginTop: 10,
            }),
            dimensions.width
          )}
        >
          {/* Swiper Item 1 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Desktop, value: 490 },
                  { minWidth: Breakpoints.BigScreen, value: 550 },
                  { minWidth: Breakpoints.Mobile, value: 100 },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Touchable 1 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: Constants['aiSightTool'],
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: Constants['aiSightTool']?._id,
                    toolExampleImageURL:
                      Constants['aiSightTool']?.image_data.example_url,
                    toolName: Constants['aiSightTool']?.name,
                    toolDesc: Constants['aiSightTool']?.description,
                    toolPrePrompt: Constants['aiSightTool']?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '95%' }, dimensions.width)}
            >
              {/* Image 1 */}
              <Image
                resizeMode={'contain'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimMobileBannerAISight.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Laptop, value: 350 },
                      { minWidth: Breakpoints.Desktop, value: 490 },
                      { minWidth: Breakpoints.BigScreen, value: 550 },
                      { minWidth: Breakpoints.Mobile, value: 230 },
                    ],
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
          {/* Swiper Item 2 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: [
                  { minWidth: Breakpoints.Mobile, value: 100 },
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Desktop, value: 490 },
                  { minWidth: Breakpoints.BigScreen, value: 550 },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '95%' }, dimensions.width)}
            >
              {/* Image 2 */}
              <Image
                resizeMode={'contain'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimMobileBannerCharacters.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Laptop, value: 350 },
                      { minWidth: Breakpoints.Desktop, value: 490 },
                      { minWidth: Breakpoints.BigScreen, value: 550 },
                      { minWidth: Breakpoints.Mobile, value: 230 },
                    ],
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
          {/* Swiper Item 3 */}
          <SwiperItem
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: [
                  { minWidth: Breakpoints.Mobile, value: 100 },
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 350 },
                  { minWidth: Breakpoints.Desktop, value: 490 },
                  { minWidth: Breakpoints.BigScreen, value: 550 },
                ],
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* Touchable 3 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ChatbotScreen', {
                    tool_id: '654189338705460b42484e4f',
                    toolExampleImageURL:
                      'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/AIAssistant.jpg',
                    toolName: 'AI Assistant',
                    toolDesc:
                      "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                    toolPrePrompt:
                      'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth({ width: '95%' }, dimensions.width)}
            >
              {/* Image 3 */}
              <Image
                resizeMode={'contain'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/gaimMobileBannerChatbot.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 12,
                    borderWidth: 4,
                    height: [
                      { minWidth: Breakpoints.Tablet, value: 400 },
                      { minWidth: Breakpoints.Laptop, value: 350 },
                      { minWidth: Breakpoints.Desktop, value: 490 },
                      { minWidth: Breakpoints.BigScreen, value: 550 },
                      { minWidth: Breakpoints.Mobile, value: 230 },
                    ],
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </SwiperItem>
        </Swiper>
        {/* Text to Image View */}
        <View>
          {/* Text To Image */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: 20,
              }),
              dimensions.width
            )}
          >
            {'Text-to-Image Generators'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: imageTool1,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool1?.name,
                    toolDesc: imageTool1?.description,
                    toolPrePrompt: imageTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/alcoholInk.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: imageTool2,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool2?.name,
                    toolDesc: imageTool2?.description,
                    toolPrePrompt: imageTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GTAVimage.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Img2Img View */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        >
          {/* Img2Img */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: 20,
              }),
              dimensions.width
            )}
          >
            {'Image-to-Image Transformers'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: img2imgTool1,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool1?.name,
                    toolDesc: imageTool1?.description,
                    toolPrePrompt: imageTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/OldHollywood.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentImageTool',
                    value: img2imgTool2,
                  });
                  navigation.navigate('ImageGeneratorScreen', {
                    toolName: imageTool2?.name,
                    toolDesc: imageTool2?.description,
                    toolPrePrompt: imageTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZombifyMe.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Chatbots View */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        >
          {/* Chatbots */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: 20,
              }),
              dimensions.width
            )}
          >
            {'Popular Chatbots'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: chatbotTool1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: chatbotTool1?._id,
                    toolExampleImageURL: chatbotTool1?.image_data.example_url,
                    toolName: chatbotTool1?.name,
                    toolDesc: chatbotTool1?.description,
                    toolPrePrompt: chatbotTool1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Marketing.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Marketing'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: chatbotTool2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: chatbotTool2?._id,
                    toolExampleImageURL: chatbotTool2?.image_data.example_url,
                    toolName: chatbotTool2?.name,
                    toolDesc: chatbotTool2?.description,
                    toolPrePrompt: chatbotTool2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Content.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Content'}
              </Text>
            </Touchable>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('ToolsListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        >
          {/* Chat with Famous Characters */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: 20,
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Famous Characters'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterFamous1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterFamous1?._id,
                    toolExampleImageURL:
                      characterFamous1?.image_data.example_url,
                    toolName: characterFamous1?.name,
                    toolDesc: characterFamous1?.description,
                    toolPrePrompt: characterFamous1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/abrahamLincolnAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Abe Lincoln'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterFamous2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterFamous2?._id,
                    toolExampleImageURL:
                      characterFamous2?.image_data.example_url,
                    toolName: characterFamous2?.name,
                    toolDesc: characterFamous2?.description,
                    toolPrePrompt: characterFamous2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/charlesDarwinAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Darwin'}
              </Text>
            </Touchable>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Characters Famous View 2 */}
        <View
          style={StyleSheet.applyWidth({ marginTop: 12 }, dimensions.width)}
        >
          {/* Chat with Casual Friends */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 18,
                marginBottom: 5,
                marginLeft: 20,
                marginTop: 16,
              }),
              dimensions.width
            )}
          >
            {'Chat with Casual Friends'}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterCasual1,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual1?._id,
                    toolExampleImageURL:
                      characterCasual1?.image_data.example_url,
                    toolName: characterCasual1?.name,
                    toolDesc: characterCasual1?.description,
                    toolPrePrompt: characterCasual1?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/maxTheCharmerAI.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Max the Charmer'}
              </Text>
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  setGlobalVariableValue({
                    key: 'currentChatTool',
                    value: characterCasual2,
                  });
                  navigation.navigate('ChatbotScreen', {
                    tool_id: characterCasual2?._id,
                    toolExampleImageURL:
                      characterCasual2?.image_data.example_url,
                    toolName: characterCasual2?.name,
                    toolDesc: characterCasual2?.description,
                    toolPrePrompt: characterCasual2?.pre_prompt,
                  });
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/ZoetheZestful.jpg',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 10,
                    borderWidth: 2,
                    height: 100,
                    width: 100,
                  }),
                  dimensions.width
                )}
              />
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    marginTop: 2,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'Zoe the Zestful'}
              </Text>
            </Touchable>
            <Button
              onPress={() => {
                try {
                  navigation.navigate('CharacterListScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: theme.colors['Primary'],
                  borderWidth: 2,
                  color: theme.colors['Primary'],
                  fontFamily: 'SecularOne_400Regular',
                  height: 100,
                  width: 100,
                }),
                dimensions.width
              )}
              title={'VIEW ALL'}
            />
          </View>
        </View>
        {/* Header Buttons View 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-around',
              marginBottom: 12,
              marginTop: 60,
            },
            dimensions.width
          )}
        >
          <Button
            icon={'Ionicons/ios-chatbubbles-outline'}
            onPress={() => {
              try {
                navigation.navigate('ChatbotScreen', {
                  tool_id: '654189338705460b42484e4f',
                  toolExampleImageURL:
                    'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
                  toolName: 'AI Assistant',
                  toolDesc:
                    "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
                  toolPrePrompt:
                    'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
                });
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                borderRadius: 80,
                color: theme.colors['Strong'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'CHAT WITH AI'}
          />
          {/* Button 2 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('ToolsListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Tools'}
          />
          {/* Button 3 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('CharacterListScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'Characters'}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginBottom: 20 },
            dimensions.width
          )}
        >
          {/* Button 4 */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('SubscribeScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                backgroundColor: 'rgb(18, 18, 18)',
                borderColor: theme.colors['Primary'],
                borderRadius: 80,
                borderWidth: 2,
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                textTransform: 'uppercase',
              }),
              dimensions.width
            )}
            title={'UPGRADE / GET MORE CREDITS'}
          />
        </View>
        {/* Footer View */}
        <View
          style={StyleSheet.applyWidth(
            { paddingLeft: 16, paddingRight: 16 },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
                marginBottom: 10,
              },
              dimensions.width
            )}
          >
            <Image
              resizeMode={'cover'}
              source={{
                uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png',
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  height: 40,
                  marginRight: 10,
                  width: 40,
                }),
                dimensions.width
              )}
            />
            <View>
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'GAIM.AI'}
              </Text>
              {/* Text 2 */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Background'],
                    fontFamily: 'RobotoCondensed_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'AI FOR EVERYONE'}
              </Text>
            </View>
          </View>

          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Light'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 12,
              }),
              dimensions.width
            )}
          >
            {
              '*All responses from GAIM AI tools are made up. Never rely on GAIM AI tools for medical, legal, financial, or any other advice. GAIM is not responsible for any information provided by our AI tools. Use at your own risk.'
            }
          </Text>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default withTheme(LandingPage2Screen);
