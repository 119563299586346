import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import * as ImageDownloadExports from '../custom-files/ImageDownloadExports';
import loadingProgress from '../global-functions/loadingProgress';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Circle,
  CircleImage,
  Icon,
  LinearProgress,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  FlatList,
  Image,
  Keyboard,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const AIAppScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const encodeFileName = filename => {
    return encodeURIComponent(filename);
  };

  const addMessageToCurrConvo = (
    Variables,
    setGlobalVariableValue,
    newMessage
  ) => {
    const formattedMessage = { role: 'assistant', content: newMessage };
    setCurrentConvo(prevConvo => [...prevConvo, formattedMessage]);
    console.log('current convo: ', currentConvo);
  };

  const addRemoteImageToMediaLibrary = async (uri, filename) => {
    // These are valid image file extensions
    const validExtensions = ['.jpg', '.png', '.gif', '.heic', '.webp', '.bmp'];

    // Request permission to access the user's media library
    const permission = await ImageDownloadExports.requestPermissionsAsync();

    // Check if user granted permission
    if (permission.status !== 'granted') {
      setMessage(
        'The app needs permission to access your media library to download images'
      );
      return;
    }

    // We need to make sure there's a media library available on the device
    const available = await ImageDownloadExports.isAvailableAsync();
    if (!available) {
      setMessage('The Media Library is not available on your device');
      return;
    }

    // Check if our uri ends with an allowed extension
    const fileIsValidImage =
      validExtensions.filter(extension => {
        // Append the file extension to the file name
        if (uri.endsWith(extension)) {
          filename = `${filename}${extension}`;
        }

        return uri.endsWith(extension);
      }).length === 1;

    if (!fileIsValidImage) {
      setMessage(
        'Only image files with extensions .jpg, .png, .gif, .heic, .webp, and .bmp can be added to the media library'
      );
      return;
    }

    // Create a location to put the contents of the downloaded file
    const fileUri = `${ImageDownloadExports.documentDirectory}${filename}`;

    // Download the remote file to the fileUri location
    setMessage('Downloading image...');
    const downloadedFile = await ImageDownloadExports.downloadAsync(
      uri,
      fileUri
    );

    // Check if we have a successful response from the remote server
    if (downloadedFile.status !== 200) {
      setMessage('The image could not be downloaded. Please try again');
      return;
    }

    try {
      setMessage('Adding image to your media library...');

      // This can be passed in, retrieved from an App variable, etc.
      const albumName = 'Draftbit App';

      // Create an Asset from our downloaded file
      const asset = await ImageDownloadExports.createAssetAsync(
        downloadedFile.uri
      );

      // Check if we already have an album with the same name
      const album = await ImageDownloadExports.getAlbumAsync(albumName);

      if (album === null) {
        setMessage(`Creating new album named ${albumName}...`);

        // Create a new album and add the Asset
        await ImageDownloadExports.createAlbumAsync(albumName, asset, false);
        setMessage(`The image was added to the new ${albumName} album`);
      } else {
        // Add the Asset to the existing album
        await ImageDownloadExports.addAssetsToAlbumAsync(
          [asset],
          albumName,
          false
        );
        setMessage(`The image was added to the existing ${albumName} album`);
      }
    } catch (error) {
      // Something went wrong :(
      setMessage(
        `There was a problem adding the image to your media library: ${error}`
      );
    }
  };

  const addUserPromptToConvo = userPrompt => {
    const formatUserPrompt = { role: 'user', content: userPrompt };
    setCurrentConvo(prevConvo => [...prevConvo, formatUserPrompt]);
  };

  const gAIMMobileBackendGenerateImagePOST =
    GAIMMobileBackendApi.useGenerateImagePOST();

  const [convo_id, setConvo_id] = React.useState('');
  const [currentConvo, setCurrentConvo] = React.useState([]);
  const [currentResponse, setCurrentResponse] = React.useState('');
  const [generator, setGenerator] = React.useState([]);
  const [imageURLforWebView, setImageURLforWebView] = React.useState(
    'https://cdn.leonardo.ai/users/80a143f3-0f05-4663-bba8-ebf42b3274df/generations/d96f2fc4-d52d-4176-9e8a-bc7be88f8543/SDXL_09_Watercolor_painting_in_the_style_of_Renoir_of_several_0.jpg'
  );
  const [message, setMessage] = React.useState('');
  const [messages, setMessages] = React.useState([]);
  const [promptToSubmit, setPromptToSubmit] = React.useState('');
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [showLoadingProgress, setShowLoadingProgress] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', justifyContent: 'space-between' },
        dimensions.width
      )}
    >
      {/* AI Tool Top Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'center',
            marginBottom: 6,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              height: 48,
              justifyContent: 'space-between',
              position: 'absolute',
              top: 0,
              width: '90%',
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_2']}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: 'rgb(153, 171, 214)' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'Ionicons/arrow-back-sharp'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: 'rgb(153, 171, 214)' },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          ></View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Speech Off Icon */}
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/ios-settings-outline'}
              size={32}
              style={StyleSheet.applyWidth(
                { marginBottom: 5 },
                dimensions.width
              )}
            />
          </View>
        </View>
      </View>
      {/* AI Tool Header Box */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            borderBottomWidth: 0.5,
            borderColor: theme.colors['Custom Color_19'],
            flexDirection: 'row',
            justifyContent: 'center',
            paddingBottom: 6,
            paddingLeft: 20,
            paddingTop: 2,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 14, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'Ionicons/apps'}
              size={30}
            />
            {/* Name ~ */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 15,
                  lineHeight: 20,
                  marginLeft: 5,
                  marginTop: 4,
                  opacity: 1,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {Constants['currentAppTool']?.name}
            </Text>
          </View>
          {/* Message ~ */}
          <Text
            ellipsizeMode={'clip'}
            numberOfLines={3}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color_2'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
                lineHeight: 16,
                opacity: 0.5,
              },
              dimensions.width
            )}
          >
            {Constants['currentAppTool']?.description}
          </Text>
        </View>
      </View>
      {/* Info Text */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'center',
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Surface']}
          name={'Ionicons/ios-information-circle'}
          size={24}
        />
        {/* Header description */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
              color: theme.colors['Surface'],
              fontFamily: 'RobotoCondensed_400Regular',
              marginLeft: 3,
            }),
            dimensions.width
          )}
        >
          {'Be sure to fill out all questions before submitting.'}
        </Text>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'space-between',
            marginBottom: 20,
            marginTop: 0,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 20,
          },
          dimensions.width
        )}
        enableAutomaticScroll={true}
        enableOnAndroid={true}
        extraScrollHeight={175}
        keyboardShouldPersistTaps={'always'}
        showsVerticalScrollIndicator={true}
      >
        <FlatList
          data={Constants['currentAppTool']?.app_data.user_input_groups}
          keyExtractor={listData =>
            listData?.id || listData?.uuid || JSON.stringify(listData)
          }
          listKey={'79jzRej6'}
          numColumns={1}
          renderItem={({ item }) => {
            const listData = item;
            return (
              <>
                {/* Assistant Message */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      overflow: 'hidden',
                      paddingBottom: 12,
                    },
                    dimensions.width
                  )}
                >
                  <View>
                    <CircleImage size={32} source={Images.AIPPS61} />
                  </View>

                  <View
                    style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          backgroundColor: theme.colors['Custom Color_2'],
                          borderBottomRightRadius: 24,
                          borderTopLeftRadius: 24,
                          borderTopRightRadius: 24,
                          flex: 1,
                          marginLeft: 10,
                          marginRight: 20,
                          paddingBottom: 15,
                          paddingLeft: 15,
                          paddingRight: 15,
                          paddingTop: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {/* Message ~ */}
                      <Text
                        style={StyleSheet.applyWidth(
                          {
                            color: theme.colors['Strong'],
                            fontFamily: 'SecularOne_400Regular',
                            fontSize: 14,
                            lineHeight: 20,
                            marginBottom: 8,
                            opacity: 0.7,
                            textAlign: 'center',
                            textTransform: 'uppercase',
                          },
                          dimensions.width
                        )}
                      >
                        {listData?.group_title}
                      </Text>
                      <FlatList
                        data={listData?.user_inputs}
                        keyExtractor={listData =>
                          listData?.id ||
                          listData?.uuid ||
                          JSON.stringify(listData)
                        }
                        listKey={JSON.stringify(listData?.user_inputs)}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <>
                              {/* Field Label */}
                              <Text
                                accessible={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)['Text'],
                                    {
                                      fontFamily: 'RobotoCondensed_400Regular',
                                      marginBottom: 2,
                                    }
                                  ),
                                  dimensions.width
                                )}
                              >
                                {listData?.label}
                              </Text>
                              <TextInput
                                allowFontScaling={true}
                                autoCapitalize={'none'}
                                changeTextDelay={500}
                                onChangeText={newTextInputValue => {
                                  const textInputValue = newTextInputValue;
                                  try {
                                    setTextInputValue(textInputValue);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                                placeholder={(listData?.placeholder).toString()}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextInputStyles(theme)[
                                      'Text Input'
                                    ],
                                    {
                                      backgroundColor: theme.colors['Primary'],
                                      borderRadius: 25,
                                      marginBottom: 16,
                                      paddingLeft: 12,
                                    }
                                  ),
                                  dimensions.width
                                )}
                                value={textInputValue}
                              />
                            </>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    </View>
                  </View>
                </View>
                <>
                  {!(listData?.role === 'user') ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          overflow: 'hidden',
                          paddingBottom: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: 'rgb(153, 171, 214)',
                              borderBottomLeftRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 10,
                              paddingBottom: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Message ~ */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: 'rgb(18, 18, 18)',
                                fontFamily: 'Inter_400Regular',
                                fontSize: 14,
                                lineHeight: 20,
                                opacity: 0.7,
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.content}
                          </Text>
                        </View>
                      </View>
                    </View>
                  )}
                </>
              </>
            );
          }}
        />
        {/* Loading Bar Spacer */}
        <>
          {!showLoadingProgress ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              {/* Loading Progress */}
              <LinearProgress
                animationDuration={500}
                color={theme.colors.primary}
                isAnimated={true}
                lineCap={'round'}
                maximumValue={100}
                showTrack={true}
                style={StyleSheet.applyWidth(
                  { width: '70%' },
                  dimensions.width
                )}
                thickness={10}
                trackColor={theme.colors['Strong']}
                trackLineCap={'round'}
                value={Constants['loadingProgress']}
              />
              <Spacer bottom={20} top={20} />
            </View>
          )}
        </>
        {/* Save Image Button */}
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                await addRemoteImageToMediaLibrary(
                  listData?.content,
                  encodeFileName(listData?.content)
                );

                showAlertUtil({
                  title: 'Saved!',
                  message: 'Your image has been saved to your device.',
                  buttonText: 'Close',
                });
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
              color: theme.colors['Strong'],
            }),
            dimensions.width
          )}
          title={'SAVE IMAGE'}
        />
      </KeyboardAwareScrollView>
    </ScreenContainer>
  );
};

export default withTheme(AIAppScreen);
