import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import * as Linking from 'expo-linking';
import * as WebBrowser from 'expo-web-browser';
import { Image, Text, View } from 'react-native';

const HelpandSupportScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', justifyContent: 'space-between' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Back Click */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Icon
              color={theme.colors['Custom Color_2']}
              name={'Ionicons/arrow-back-sharp'}
              size={24}
            />
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Surface'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
              marginLeft: 16,
            },
            dimensions.width
          )}
        >
          {'GAIM SUPPORT'}
        </Text>
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: 'flex-start',
            paddingLeft: 20,
            paddingRight: 20,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', marginTop: 20 },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={Images.AIPPS61}
            style={StyleSheet.applyWidth(
              { height: 130, marginBottom: 20, width: 130 },
              dimensions.width
            )}
          />
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Surface'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 16,
              },
              dimensions.width
            )}
          >
            {
              'Do you have a question, comment, or concern? Please contact us via one of the methods below.'
            }
          </Text>
        </View>
        {/* Email */}
        <Touchable
          onPress={() => {
            try {
              Linking.openURL(
                'mailto:support@gaimnetwork.com?subject=Support Request'
              );
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 2,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 2,
                borderRadius: 32,
                borderRightWidth: 2,
                borderTopWidth: 2,
                flexDirection: 'row',
                height: 110,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            <Circle
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.CircleStyles(theme)['Circle'], {
                  height: 80,
                  marginLeft: 20,
                  width: 80,
                }),
                dimensions.width
              )}
            >
              <Icon name={'MaterialCommunityIcons/email'} size={36} />
            </Circle>

            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-around', marginLeft: 20 },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'via Email:'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'support@gaimnetwork.com'}
              </Text>
            </View>
          </View>
        </Touchable>
        {/* TG */}
        <Touchable
          onPress={() => {
            const handler = async () => {
              try {
                await WebBrowser.openBrowserAsync('https://t.me/GAIMJoin');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 3,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 3,
                borderRadius: 32,
                borderRightWidth: 3,
                borderTopWidth: 3,
                flexDirection: 'row',
                height: 110,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Circle 2 */}
            <Circle
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.CircleStyles(theme)['Circle'], {
                  height: 80,
                  marginLeft: 20,
                  width: 80,
                }),
                dimensions.width
              )}
            >
              <Icon name={'FontAwesome/telegram'} size={36} />
            </Circle>

            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-around', marginLeft: 20 },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'via Telegram:'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'https://t.me/GAIMJoin'}
              </Text>
            </View>
          </View>
        </Touchable>
        {/* gaimnetwork */}
        <Touchable
          onPress={() => {
            const handler = async () => {
              try {
                await WebBrowser.openBrowserAsync('https://gaimnetwork.com');
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 3,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 3,
                borderRadius: 32,
                borderRightWidth: 3,
                borderTopWidth: 3,
                flexDirection: 'row',
                height: 110,
                marginTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Circle 2 */}
            <Circle
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.CircleStyles(theme)['Circle'], {
                  height: 80,
                  marginLeft: 20,
                  width: 80,
                }),
                dimensions.width
              )}
            >
              <Icon name={'MaterialCommunityIcons/web'} size={36} />
            </Circle>

            <View
              style={StyleSheet.applyWidth(
                { justifyContent: 'space-around', marginLeft: 20 },
                dimensions.width
              )}
            >
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                  },
                  dimensions.width
                )}
              >
                {'More Information:'}
              </Text>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Surface'],
                    fontFamily: 'RobotoCondensed_400Regular',
                    fontSize: 16,
                    marginTop: 8,
                  },
                  dimensions.width
                )}
              >
                {'https://gaimnetwork.com'}
              </Text>
            </View>
          </View>
        </Touchable>
        {/* Back */}
        <Button
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors.primary,
              borderRadius: 100,
              color: theme.colors['Strong'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
              height: 58,
              marginBottom: 20,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: '20%' },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: '20%' },
              ],
              marginTop: 20,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'BACK'}
        />
      </View>
    </ScreenContainer>
  );
};

export default withTheme(HelpandSupportScreen);
