import React from 'react';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Icon,
  ScreenContainer,
  Spacer,
  Surface,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import {
  Image,
  ImageBackground,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const LearnMore4Screen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;

  return (
    <ScreenContainer hasSafeArea={true} scrollable={false}>
      <ImageBackground
        backgroundColor={theme.colors['Primary']}
        resizeMode={'cover'}
        source={Images.GaimMobileBG2}
        style={StyleSheet.applyWidth(
          {
            bottom: 0,
            flexGrow: 1,
            justifyContent: 'space-around',
            left: 0,
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 0,
          },
          dimensions.width
        )}
      >
        <View>
          <View
            style={StyleSheet.applyWidth(
              {
                flexGrow: 1,
                paddingBottom: 6,
                paddingLeft: 34,
                paddingRight: 34,
                paddingTop: 44,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center' },
                dimensions.width
              )}
            >
              <Spacer bottom={8} left={8} right={8} top={28} />
              <Surface
                elevation={3}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Primary'],
                    borderRadius: 8,
                    justifyContent: 'center',
                    minHeight: 40,
                    width: 50,
                  },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors.strong}
                  name={'AntDesign/carryout'}
                  size={44}
                />
              </Surface>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 20,
                    marginTop: 16,
                    textAlign: 'center',
                    textTransform: 'uppercase',
                    typography: theme.typography.headline4,
                  },
                  dimensions.width
                )}
              >
                {'AI Ready-Made Apps -\nResults made easy'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 64 },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={
                    Images.BradyllewisAFriendlyHumanoidApeThatIsDressedInColorfulA654607d057a40788d404248fe4b081e
                  }
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      height: 250,
                      marginBottom: 14,
                      width: 250,
                    },
                    dimensions.width
                  )}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Surface'],
                      fontFamily: 'RobotoCondensed_400Regular',
                      fontSize: 16,
                      textAlign: 'center',
                      typography: theme.typography.body1,
                    },
                    dimensions.width
                  )}
                >
                  {
                    "Answer a few relevant questions and let our Ready-Made Apps create something magnificent for you in a matter of seconds. From business plans to planning your next vacation, we've got you."
                  }
                </Text>
              </View>
            </View>
          </View>

          <View
            style={StyleSheet.applyWidth(
              {
                marginBottom: 42,
                marginTop: 42,
                paddingLeft: 34,
                paddingRight: 34,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'center',
                  marginTop: 14,
                  paddingLeft: 24,
                  paddingRight: 24,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingBottom: 24,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 24,
                    },
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors.medium}
                    name={'MaterialIcons/navigate-before'}
                    size={24}
                    style={StyleSheet.applyWidth(
                      { backgroundColor: theme.colors['Primary'] },
                      dimensions.width
                    )}
                  />
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Surface'],
                        fontFamily: 'SecularOne_400Regular',
                        marginLeft: 6,
                        typography: theme.typography.button,
                      },
                      dimensions.width
                    )}
                  >
                    {'Prev'}
                  </Text>
                </View>
              </Touchable>

              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('LearnMore5Screen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    marginLeft: 24,
                    marginRight: 24,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      paddingBottom: 24,
                      paddingLeft: 12,
                      paddingRight: 12,
                      paddingTop: 24,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Surface'],
                        fontFamily: 'SecularOne_400Regular',
                        marginRight: 6,
                        typography: theme.typography.button,
                      },
                      dimensions.width
                    )}
                  >
                    {'Next'}
                  </Text>
                  <Icon
                    color={theme.colors.medium}
                    name={'MaterialIcons/navigate-next'}
                    size={24}
                    style={StyleSheet.applyWidth(
                      { backgroundColor: theme.colors['Primary'] },
                      dimensions.width
                    )}
                  />
                </View>
              </Touchable>
            </View>

            <Surface
              elevation={3}
              style={StyleSheet.applyWidth(
                { backgroundColor: 'rgba(0, 0, 0, 0)', minHeight: 40,
                marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
                marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
               },
                dimensions.width
              )}
            >
              {/* Button Solid */}
              <Button
                color={theme.colors.strong}
                labelColor={theme.colors.background}
                onPress={() => {
                  try {
                    navigation.navigate('LoginScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    borderRadius: 50,
                    color: theme.colors['Strong'],
                    fontFamily: 'SecularOne_400Regular',
                    justifyContent: 'center',
                    minHeight: 54,
                  },
                  dimensions.width
                )}
                title={'BACK TO LOGIN'}
                type={'solid'}
              >
                {'Author'}
              </Button>
            </Surface>
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(LearnMore4Screen);
