import React from 'react';
import * as TextToSpeechComponent from '../custom-files/TextToSpeechComponent';

const speakText = async (message, voiceName)  => {
  await TextToSpeechComponent.speakText(message, voiceName);
  return true;
};

export const listAvailableVoices = () => {
  TextToSpeechComponent.listAvailableVoices();
};

export const stopSpeaking = () => {
  TextToSpeechComponent.stopSpeaking();
};

export default speakText;
