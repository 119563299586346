import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Text, View, useWindowDimensions } from 'react-native';

const ClaimCreditsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendClaimCreditCodePOST =
    GAIMMobileBackendApi.useClaimCreditCodePOST();

  const [apiResponse, setApiResponse] = React.useState('');
  const [creditValue, setCreditValue] = React.useState(0);
  const [imageCreditValue, setimageCreditValue] = React.useState(0);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasBottomSafeArea={true}
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.CircleStyles(theme)['Circle'], {
                  padding: 5,
                }),
                dimensions.width
              )}
            >
              <Icon
                name={'AntDesign/arrowleft'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Primary'] },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Primary'],
              fontFamily: 'Poppins_400Regular',
              fontSize: 15,
            },
            dimensions.width
          )}
        >
          {'CLAIM CREDITS'}
        </Text>
        {/* Blank */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        />
      </View>

      <View
        style={StyleSheet.applyWidth(
          {
            justifyContent: 'space-evenly',
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Primary'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 35,
              marginBottom: 10,
            },
            dimensions.width
          )}
        >
          {'Claim Credits'}
        </Text>
        {/* sub heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Surface'],
              fontFamily: 'RobotoCondensed_400Regular',
              fontSize: 15,
              opacity: 0.7,
              paddingBottom: 10,
              textAlign: 'left',
            },
            dimensions.width
          )}
        >
          {
            'If you have an unused credit code or voucher number, please submit it below to redeem.'
          }
        </Text>
        {/* Code */}
        <TextInput
          editable={true}
          onChangeText={newCodeValue => {
            try {
              setTextInputValue(newCodeValue);
            } catch (err) {
              console.error(err);
            }
          }}
          placeholder={'Enter code'}
          placeholderTextColor={theme.colors['Light']}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: 'rgb(40, 40, 40)',
              borderBottomWidth: 1,
              borderColor: theme.colors['Primary'],
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderTopWidth: 1,
              color: theme.colors['Primary'],
              fontFamily: 'Poppins_400Regular',
              fontSize: 15,
              height: 48,
              marginTop: 20,
              paddingBottom: 8,
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 8,
            },
            dimensions.width
          )}
          value={textInputValue}
        />
        {/* Send */}
        <Button
          onPress={() => {
            const handler = async () => {
              try {
                setApiResponse(null);
                setCreditValue(0);
                setimageCreditValue(0);
                const postResp = (
                  await gAIMMobileBackendClaimCreditCodePOST.mutateAsync({
                    creditCodeId: textInputValue,
                    user_id: Constants['user']?._id,
                  })
                )?.json;
                if (postResp?.message === 'Successfully claimed credit!') {
                  setApiResponse(postResp?.message);
                  setCreditValue(postResp?.credit_code.creditValue);
                  setimageCreditValue(postResp?.credit_code.imageCreditValue);
                  setGlobalVariableValue({
                    key: 'user',
                    value: postResp?.user,
                  });
                } else {
                  setApiResponse(postResp?.error);
                }
              } catch (err) {
                console.error(err);
              }
            };
            handler();
          }}
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Primary'],
              borderRadius: 24,
              color: theme.colors['Strong'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
              height: 46,
              marginTop: 40,
              textAlign: 'center',
            },
            dimensions.width
          )}
          title={'Redeem Code'}
        />
      </View>
      {/* Response View */}
      <>
        {!apiResponse ? null : (
          <View
            style={StyleSheet.applyWidth(
              { marginTop: 40, paddingLeft: 25, paddingRight: 25 },
              dimensions.width
            )}
          >
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Surface'],
                  fontFamily: 'RobotoCondensed_400Regular',
                  fontSize: 18,
                  textAlign: 'center',
                }),
                dimensions.width
              )}
            >
              {apiResponse}
            </Text>
            <>
              {!(creditValue > 0 || imageCreditValue > 0) ? null : (
                <View>
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_400Regular',
                          fontSize: 18,
                          marginTop: 20,
                          textAlign: 'center',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Credits Claimed: '}
                    {'Chat - ' + creditValue + ' | Image - ' + imageCreditValue}
                  </Text>
                </View>
              )}
            </>
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ClaimCreditsScreen);
