import React from 'react';

const truncateText = (content, resultLength) => {
  const truncated =
    content.length > resultLength
      ? `${content.substring(0, resultLength)}...`
      : content;
  return truncated;
};

export default truncateText;
