import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import formatNumThousands from '../global-functions/formatNumThousands';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  Spacer,
  Swiper,
  SwiperItem,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as WebBrowser from 'expo-web-browser';
import { ActivityIndicator, FlatList, Image, Text, View } from 'react-native';
import { Fetch } from 'react-request';

const SubscribeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [currentSubTier, setCurrentSubTier] = React.useState('G1');
  const [currentSubType, setCurrentSubType] = React.useState('Yearly');
  const [filteredSubs, setFilteredSubs] = React.useState([]);
  const [showLoader, setShowLoader] = React.useState(false);
  const [showRefreshBtn, setShowRefreshBtn] = React.useState(false);
  const user = Constants['user'];
  console.log('user in subscribe', user);

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(40, 40, 40)' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('MenuScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {/* Profile Menu Button */}
          <Image
            resizeMode={'cover'}
            source={{ uri: `${Constants['user']?.pic}` }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: 35,
                marginLeft: 10,
                width: 35,
              }),
              dimensions.width
            )}
          />
        </Touchable>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Surface'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 20,
              marginLeft: 16,
              textTransform: 'uppercase',
            },
            dimensions.width
          )}
        >
          {'Upgrade Your Package'}
        </Text>
      </View>
      {/* Subscriber Benefits: */}
      <>
        {!(currentSubType !== 'Add-On') ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                marginLeft: [
                  { minWidth: Breakpoints.Mobile, value: 30 },
                  { minWidth: Breakpoints.Laptop, value: '30%' },
                ],
                marginRight: [
                  { minWidth: Breakpoints.Mobile, value: 30 },
                  { minWidth: Breakpoints.Laptop, value: '30%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* heading */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: 'rgb(69, 252, 241)',
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 18,
                  lineHeight: 21,
                  marginTop: 8,
                },
                dimensions.width
              )}
            >
              {'CHOOSE YOUR PLAN'}
            </Text>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row', justifyContent: 'space-between' },
                dimensions.width
              )}
            >
              {/* Lite */}
              <Touchable
                onPress={() => {
                  try {
                    setCurrentSubTier('G1');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', paddingTop: 6 },
                    dimensions.width
                  )}
                >
                  <>
                    {!(currentSubTier === 'G1') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G1');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(69, 252, 241)',
                              borderColor: 'rgb(69, 252, 241)',
                              borderRadius: 80,
                              borderWidth: 2,
                              color: theme.colors['Strong'],
                              fontFamily: 'SecularOne_400Regular',
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'LITE'}
                      />
                    )}
                  </>
                  {/* Button 2 */}
                  <>
                    {!(currentSubTier !== 'G1') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G1');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(40, 40, 40)',
                              borderColor: theme.colors['Surface'],
                              borderRadius: 80,
                              borderWidth: 2,
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'LITE'}
                      />
                    )}
                  </>
                </View>
              </Touchable>
              {/* BASIC */}
              <Touchable
                onPress={() => {
                  try {
                    setCurrentSubTier('G2');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', paddingTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Button 3 */}
                  <>
                    {!(currentSubTier === 'G2') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G2');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(69, 252, 241)',
                              borderColor: 'rgb(69, 252, 241)',
                              borderRadius: 80,
                              borderWidth: 2,
                              color: theme.colors['Strong'],
                              fontFamily: 'SecularOne_400Regular',
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'BASIC'}
                      />
                    )}
                  </>
                  {/* Button 4 */}
                  <>
                    {!(currentSubTier !== 'G2') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G2');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(40, 40, 40)',
                              borderColor: theme.colors['Surface'],
                              borderRadius: 80,
                              borderWidth: 2,
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'BASIC'}
                      />
                    )}
                  </>
                </View>
              </Touchable>
              {/* PRO */}
              <Touchable
                onPress={() => {
                  try {
                    setCurrentSubTier('G3');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    { alignItems: 'center', paddingTop: 6 },
                    dimensions.width
                  )}
                >
                  {/* Button 3 */}
                  <>
                    {!(currentSubTier === 'G3') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G3');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(69, 252, 241)',
                              borderColor: 'rgb(69, 252, 241)',
                              borderRadius: 80,
                              borderWidth: 2,
                              color: theme.colors['Strong'],
                              fontFamily: 'SecularOne_400Regular',
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'PRO'}
                      />
                    )}
                  </>
                  {/* Button 4 */}
                  <>
                    {!(currentSubTier !== 'G3') ? null : (
                      <Button
                        onPress={() => {
                          try {
                            setCurrentSubTier('G3');
                          } catch (err) {
                            console.error(err);
                          }
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ButtonStyles(theme)['Button'],
                            {
                              backgroundColor: 'rgb(40, 40, 40)',
                              borderColor: theme.colors['Surface'],
                              borderRadius: 80,
                              borderWidth: 2,
                              width: 90,
                            }
                          ),
                          dimensions.width
                        )}
                        title={'PRO'}
                      />
                    )}
                  </>
                </View>
              </Touchable>
            </View>
          </View>
        )}
      </>
      <GAIMMobileBackendApi.FetchFetchModelDataGET
        handlers={{
          onData: fetchData => {
            try {
              const valuet6D53ism = fetchData?.data;
              setFilteredSubs(valuet6D53ism);
              const dataResp = valuet6D53ism;
              console.log(dataResp);
            } catch (err) {
              console.error(err);
            }
          },
        }}
        limit={500}
        modelName={'Subscription'}
      >
        {({ loading, error, data, refetchFetchModelData }) => {
          const fetchData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Filtering Options View 2 */}
              <>
                {!(currentSubType === 'Add-On') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        marginLeft: [
                          { minWidth: Breakpoints.Mobile, value: '7%' },
                          { minWidth: Breakpoints.Laptop, value: '30%' },
                        ],
                        marginRight: [
                          { minWidth: Breakpoints.Mobile, value: '7%' },
                          { minWidth: Breakpoints.Laptop, value: '30%' },
                        ],
                        marginTop: 16,
                      },
                      dimensions.width
                    )}
                  >
                    {/* Sub Type View */}
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          alignItems: 'center',
                          flexDirection: 'row',
                          justifyContent: 'space-around',
                        },
                        dimensions.width
                      )}
                    >
                      {/* Monthly Button Active */}
                      <>
                        {!(currentSubType === 'Standard') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Standard');
                                setCurrentSubTier('G1');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgb(69, 252, 241)',
                                  borderRadius: 50,
                                  color: theme.colors['Strong'],
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'MONTHLY'}
                          />
                        )}
                      </>
                      {/* Monthly Button Inactive */}
                      <>
                        {!(currentSubType !== 'Standard') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Standard');
                                setCurrentSubTier('G1');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  borderRadius: 50,
                                  color: 'rgb(69, 252, 241)',
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'MONTHLY'}
                          />
                        )}
                      </>
                      {/* Yearly Button Active */}
                      <>
                        {!(currentSubType === 'Yearly') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Yearly');
                                setCurrentSubTier('G1');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgb(69, 252, 241)',
                                  borderRadius: 50,
                                  color: theme.colors['Strong'],
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'YEARLY (SAVE 10%)'}
                          />
                        )}
                      </>
                      {/* Yearly Button Inactive */}
                      <>
                        {!(currentSubType !== 'Yearly') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Yearly');
                                setCurrentSubTier('G1');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  borderRadius: 50,
                                  color: 'rgb(69, 252, 241)',
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'YEARLY (SAVE 10%)'}
                          />
                        )}
                      </>
                      {/* Add Ons Button Active */}
                      <>
                        {!(currentSubType === 'Add-On') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Add-On');
                                setCurrentSubTier('GAO');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgb(69, 252, 241)',
                                  borderRadius: 50,
                                  color: theme.colors['Strong'],
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'ADD-ONS'}
                          />
                        )}
                      </>
                      {/* Add Ons Button Inactive */}
                      <>
                        {!(currentSubType !== 'Add-On') ? null : (
                          <Button
                            onPress={() => {
                              try {
                                setCurrentSubType('Add-On');
                                setCurrentSubTier('GAO');
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                            style={StyleSheet.applyWidth(
                              StyleSheet.compose(
                                GlobalStyles.ButtonStyles(theme)['Button'],
                                {
                                  backgroundColor: 'rgba(0, 0, 0, 0)',
                                  borderRadius: 50,
                                  color: 'rgb(69, 252, 241)',
                                  fontFamily: 'SecularOne_400Regular',
                                }
                              ),
                              dimensions.width
                            )}
                            title={'ADD-ONS'}
                          />
                        )}
                      </>
                    </View>
                  </View>
                )}
              </>
              {/* Add On Description View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    marginLeft: { minWidth: Breakpoints.Laptop, value: '30%' },
                    marginRight: { minWidth: Breakpoints.Laptop, value: '30%' },
                    marginTop: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                  },
                  dimensions.width
                )}
              >
                {/* Add On Desc Text */}
                <>
                  {!(currentSubType === 'Add-On') ? null : (
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Surface'],
                          fontFamily: 'RobotoCondensed_300Light',
                          fontSize: 15,
                          lineHeight: 21,
                          marginTop: 8,
                        },
                        dimensions.width
                      )}
                    >
                      {
                        'Purchase a one-time package of AI credits. These credits DO NOT refresh. For an ongoing package that gives you credits monthly, please choose a monthly or yearly package.'
                      }
                    </Text>
                  )}
                </>
              </View>
              {/* Refresh Button View */}
              <>
                {!showRefreshBtn ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginBottom: 30, marginTop: 40 },
                      dimensions.width
                    )}
                  >
                    {/* Refresh Profile Button */}
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            const respUser = (
                              await GAIMMobileBackendApi.getUserByDynamicIDGET(
                                Constants,
                                { dynamic_id: Constants['user']?.dynamic_id }
                              )
                            )?.json;
                            setGlobalVariableValue({
                              key: 'user',
                              value: respUser?.data,
                            });
                            navigation.navigate('UserProfileScreen', {
                              userImage: Constants['user']?.pic,
                              userName: Constants['user']?.username,
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)['Button'],
                          {
                            borderRadius: 100,
                            color: theme.colors['Strong'],
                            fontFamily: 'SecularOne_400Regular',
                            fontSize: 18,
                            width: '75%',
                          }
                        ),
                        dimensions.width
                      )}
                      title={'SUCCESS! Refresh Profile.'}
                    />
                  </View>
                )}
              </>
              <View
                collapsable={true}
                style={StyleSheet.applyWidth(
                  { marginTop: 20 },
                  dimensions.width
                )}
              >
                <>
                  {!showLoader ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { alignItems: 'center' },
                        dimensions.width
                      )}
                    >
                      <Circle
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.CircleStyles(theme)['Circle'],
                            { height: 60, width: 60 }
                          ),
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={Images.Loader}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.ImageStyles(theme)['Image'],
                              { height: 45, width: 45 }
                            ),
                            dimensions.width
                          )}
                        />
                      </Circle>
                    </View>
                  )}
                </>
              </View>
              <FlatList
                data={fetchData?.data}
                keyExtractor={(listData, index) => listData && listData['_id']}
                listKey={'fbv4rL1P'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item, index }) => {
                  const listData = item;
                  return (
                    <>
                      {/* View 2 */}
                      <>
                        {!(listData?.plan === currentSubTier) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                marginLeft: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '25%',
                                },
                                marginRight: {
                                  minWidth: Breakpoints.Laptop,
                                  value: '25%',
                                },
                              },
                              dimensions.width
                            )}
                          >
                            <>
                              {!(listData?.type === currentSubType) ? null : (
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      paddingLeft: 20,
                                      paddingRight: 20,
                                      paddingTop: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Title */}
                                  <>
                                    {!(currentSubType !== 'Add-On') ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Custom Color'],
                                            fontFamily: 'SecularOne_400Regular',
                                            fontSize: 26,
                                            textAlign: 'center',
                                            textTransform: 'uppercase',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.name}
                                        {' Package'}
                                      </Text>
                                    )}
                                  </>
                                  {/* AI Credits View */}
                                  <>
                                    {!(currentSubType !== 'Add-On') ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            marginTop: 10,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Monthly Price Text 2 */}
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color: 'rgb(69, 252, 241)',
                                                fontFamily:
                                                  'RobotoCondensed_400Regular',
                                                marginBottom: 10,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'$'}
                                          {listData?.price_monthly}
                                          {'/month'}
                                        </Text>
                                        {/* Good Responses */}
                                        <>
                                          {listData?.premium_responses ? null : (
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color: 'rgb(69, 252, 241)',
                                                    fontFamily:
                                                      'RobotoCondensed_400Regular',
                                                    fontSize: 24,
                                                    marginBottom: 12,
                                                    textAlign: 'center',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'GOOD RESPONSES'}
                                            </Text>
                                          )}
                                        </>
                                        {/* Premium Responses */}
                                        <>
                                          {!listData?.premium_responses ? null : (
                                            <Text
                                              accessible={true}
                                              allowFontScaling={true}
                                              style={StyleSheet.applyWidth(
                                                StyleSheet.compose(
                                                  GlobalStyles.TextStyles(
                                                    theme
                                                  )['Text'],
                                                  {
                                                    color: 'rgb(69, 252, 241)',
                                                    fontFamily:
                                                      'RobotoCondensed_400Regular',
                                                    fontSize: 24,
                                                    marginBottom: 12,
                                                    textAlign: 'center',
                                                  }
                                                ),
                                                dimensions.width
                                              )}
                                            >
                                              {'🌟 PREMIUM RESPONSES'}
                                            </Text>
                                          )}
                                        </>
                                        <Icon
                                          color={theme.colors['Surface']}
                                          name={'AntDesign/pluscircleo'}
                                          size={24}
                                          style={StyleSheet.applyWidth(
                                            { marginBottom: 12 },
                                            dimensions.width
                                          )}
                                        />
                                        {/* AI Sight Text */}
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color: 'rgb(69, 252, 241)',
                                                fontFamily:
                                                  'RobotoCondensed_400Regular',
                                                fontSize: 24,
                                                marginBottom: 12,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'AI SIGHT'}
                                        </Text>
                                        {/* Icon 2 */}
                                        <Icon
                                          color={theme.colors['Surface']}
                                          name={'AntDesign/pluscircleo'}
                                          size={24}
                                          style={StyleSheet.applyWidth(
                                            { marginBottom: 12 },
                                            dimensions.width
                                          )}
                                        />
                                        {/* Image Credits Text */}
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color: 'rgb(69, 252, 241)',
                                                fontFamily:
                                                  'RobotoCondensed_400Regular',
                                                fontSize: 24,
                                                marginBottom: 10,
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.image_credits}
                                          {' IMAGE CREDITS'}
                                        </Text>
                                      </View>
                                    )}
                                  </>
                                  {/* Add On AI Credits View */}
                                  <>
                                    {!(currentSubType === 'Add-On') ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            marginBottom: 10,
                                            marginTop: 20,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <Touchable
                                          onPress={() => {
                                            const handler = async () => {
                                              try {
                                                await WebBrowser.openBrowserAsync(
                                                  `${listData?.stripe_link}?client_reference_id=${Constants['user']?._id}_xxx`
                                                );
                                              } catch (err) {
                                                console.error(err);
                                              }
                                            };
                                            handler();
                                          }}
                                          style={StyleSheet.applyWidth(
                                            { width: '100%' },
                                            dimensions.width
                                          )}
                                        >
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                alignItems: 'center',
                                                backgroundColor:
                                                  'rgb(18, 18, 18)',
                                                borderColor:
                                                  'rgb(69, 252, 241)',
                                                borderRadius: 100,
                                                borderWidth: 2,
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                padding: 8,
                                                width: '100%',
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            {/* View 2 */}
                                            <View
                                              style={StyleSheet.applyWidth(
                                                { marginRight: 8 },
                                                dimensions.width
                                              )}
                                            >
                                              <Circle
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.CircleStyles(
                                                      theme
                                                    )['Circle'],
                                                    {
                                                      backgroundColor:
                                                        'rgb(69, 252, 241)',
                                                      height: 75,
                                                      width: 75,
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  accessible={true}
                                                  allowFontScaling={true}
                                                  style={StyleSheet.applyWidth(
                                                    StyleSheet.compose(
                                                      GlobalStyles.TextStyles(
                                                        theme
                                                      )['Text'],
                                                      {
                                                        fontFamily:
                                                          'RobotoCondensed_400Regular',
                                                        fontSize: 15,
                                                        textAlign: 'center',
                                                      }
                                                    ),
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'1-Time\nPayment'}
                                                </Text>
                                              </Circle>
                                            </View>

                                            <View>
                                              {/* Package Name Text */}
                                              <Text
                                                accessible={true}
                                                allowFontScaling={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      color:
                                                        theme.colors['Surface'],
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                      fontSize: 22,
                                                      textAlign: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'PURCHASE\n'}
                                                {formatNumThousands(
                                                  listData?.credits
                                                )}
                                                {' AI CREDITS'}
                                              </Text>
                                              {/* Price Text */}
                                              <Text
                                                accessible={true}
                                                allowFontScaling={true}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.TextStyles(
                                                      theme
                                                    )['Text'],
                                                    {
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                      fontSize: 20,
                                                      textAlign: 'center',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                              >
                                                {'for only $'}
                                                {listData?.price_monthly}
                                              </Text>
                                            </View>
                                          </View>
                                        </Touchable>
                                      </View>
                                    )}
                                  </>
                                  {/* Renews every */}
                                  <>
                                    {!(currentSubType !== 'Add-On') ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          { marginTop: 10 },
                                          dimensions.width
                                        )}
                                      >
                                        {/* heading */}
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors['Surface'],
                                              fontFamily: 'Inter_500Medium',
                                              fontSize: 18,
                                              lineHeight: 21,
                                              marginTop: 8,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {'Credits refresh every 30 days'}
                                        </Text>
                                        {/* Plans */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-between',
                                              paddingTop: 16,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* 2.99 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1, marginRight: 5 },
                                              dimensions.width
                                            )}
                                          >
                                            <Touchable>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-start',
                                                    backgroundColor:
                                                      '"rgb(18, 18, 18)"',
                                                    borderBottomWidth: 2,
                                                    borderColor:
                                                      'rgb(69, 252, 241)',
                                                    borderLeftWidth: 2,
                                                    borderRadius: 16,
                                                    borderRightWidth: 2,
                                                    borderTopWidth: 2,
                                                    height: 84,
                                                    justifyContent: 'center',
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      fontSize: 16,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'All AI Tools'}
                                                </Text>
                                              </View>
                                            </Touchable>
                                          </View>
                                          {/* 4.99 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                flex: 1,
                                                marginLeft: 5,
                                                marginRight: 5,
                                              },
                                              dimensions.width
                                            )}
                                          >
                                            <Touchable>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-start',
                                                    backgroundColor:
                                                      'rgb(18, 18, 18)',
                                                    borderBottomWidth: 2,
                                                    borderColor:
                                                      'rgb(69, 252, 241)',
                                                    borderLeftWidth: 2,
                                                    borderRadius: 16,
                                                    borderRightWidth: 2,
                                                    borderTopWidth: 2,
                                                    height: 84,
                                                    justifyContent: 'center',
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      fontSize: 16,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Endless Chat'}
                                                </Text>
                                              </View>
                                            </Touchable>
                                          </View>
                                          {/* 9.99 */}
                                          <View
                                            style={StyleSheet.applyWidth(
                                              { flex: 1, marginLeft: 5 },
                                              dimensions.width
                                            )}
                                          >
                                            <Touchable>
                                              <View
                                                style={StyleSheet.applyWidth(
                                                  {
                                                    alignItems: 'flex-start',
                                                    backgroundColor:
                                                      'rgb(18, 18, 18)',
                                                    borderBottomWidth: 2,
                                                    borderColor:
                                                      'rgb(69, 252, 241)',
                                                    borderLeftWidth: 2,
                                                    borderRadius: 16,
                                                    borderRightWidth: 2,
                                                    borderTopWidth: 2,
                                                    height: 84,
                                                    justifyContent: 'center',
                                                    paddingLeft: 10,
                                                    paddingRight: 10,
                                                  },
                                                  dimensions.width
                                                )}
                                              >
                                                <Text
                                                  style={StyleSheet.applyWidth(
                                                    {
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'Inter_600SemiBold',
                                                      fontSize: 16,
                                                    },
                                                    dimensions.width
                                                  )}
                                                >
                                                  {'Rollover Credits'}
                                                </Text>
                                              </View>
                                            </Touchable>
                                          </View>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  {/* Filtering Options View */}
                                  <>
                                    {!(currentSubType !== 'Add-On') ? null : (
                                      <View
                                        style={StyleSheet.applyWidth(
                                          {
                                            marginLeft: '7%',
                                            marginRight: '7%',
                                            marginTop: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {/* Sub Type View */}
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              flexDirection: 'row',
                                              justifyContent: 'space-around',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {/* Monthly Button Active */}
                                          <>
                                            {!(
                                              currentSubType === 'Standard'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType(
                                                      'Standard'
                                                    );
                                                    setCurrentSubTier('G1');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgb(69, 252, 241)',
                                                      borderRadius: 50,
                                                      color:
                                                        theme.colors['Strong'],
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'MONTHLY'}
                                              />
                                            )}
                                          </>
                                          {/* Monthly Button Inactive */}
                                          <>
                                            {!(
                                              currentSubType !== 'Standard'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType(
                                                      'Standard'
                                                    );
                                                    setCurrentSubTier('G1');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 50,
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'MONTHLY'}
                                              />
                                            )}
                                          </>
                                          {/* Yearly Button Active */}
                                          <>
                                            {!(
                                              currentSubType === 'Yearly'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType('Yearly');
                                                    setCurrentSubTier('G1');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgb(69, 252, 241)',
                                                      borderRadius: 50,
                                                      color:
                                                        theme.colors['Strong'],
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'YEARLY (SAVE 10%)'}
                                              />
                                            )}
                                          </>
                                          {/* Yearly Button Inactive */}
                                          <>
                                            {!(
                                              currentSubType !== 'Yearly'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType('Yearly');
                                                    setCurrentSubTier('G1');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 50,
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'YEARLY (SAVE 10%)'}
                                              />
                                            )}
                                          </>
                                          {/* Add Ons Button Active */}
                                          <>
                                            {!(
                                              currentSubType === 'Add-On'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType('Add-On');
                                                    setCurrentSubTier('GAO');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgb(69, 252, 241)',
                                                      borderRadius: 50,
                                                      color:
                                                        theme.colors['Strong'],
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'ADD-ONS'}
                                              />
                                            )}
                                          </>
                                          {/* Add Ons Button Inactive */}
                                          <>
                                            {!(
                                              currentSubType !== 'Add-On'
                                            ) ? null : (
                                              <Button
                                                onPress={() => {
                                                  try {
                                                    setCurrentSubType('Add-On');
                                                    setCurrentSubTier('GAO');
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                }}
                                                style={StyleSheet.applyWidth(
                                                  StyleSheet.compose(
                                                    GlobalStyles.ButtonStyles(
                                                      theme
                                                    )['Button'],
                                                    {
                                                      backgroundColor:
                                                        'rgba(0, 0, 0, 0)',
                                                      borderRadius: 50,
                                                      color:
                                                        'rgb(69, 252, 241)',
                                                      fontFamily:
                                                        'SecularOne_400Regular',
                                                    }
                                                  ),
                                                  dimensions.width
                                                )}
                                                title={'ADD-ONS'}
                                              />
                                            )}
                                          </>
                                        </View>
                                      </View>
                                    )}
                                  </>
                                  <>
                                    {!(currentSubType !== 'Add-On') ? null : (
                                      <View>
                                        {/* Subscribe Monthly */}
                                        <>
                                          {!(
                                            listData?.type === 'Standard'
                                          ) ? null : (
                                            <Button
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    if (
                                                      Constants['user']?._id
                                                    ) {
                                                      const userId = Constants['user']?._id;
                                                      alert('userId', userId);
                                                      await WebBrowser.openBrowserAsync(
                                                        `${listData?.stripe_link}?client_reference_id=${Constants['user']?._id}_xxx`
                                                      );
                                                    }
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    'rgb(69, 252, 241)',
                                                  borderColor:
                                                    theme.colors['Strong'],
                                                  borderRadius: 100,
                                                  borderWidth: 2,
                                                  color: theme.colors['Strong'],
                                                  fontFamily:
                                                    'SecularOne_400Regular',
                                                  fontSize: 15,
                                                  height: 58,
                                                  marginBottom: 20,
                                                  marginLeft: 20,
                                                  marginRight: 20,
                                                  marginTop: 20,
                                                  textAlign: 'center',
                                                },
                                                dimensions.width
                                              )}
                                              title={`Purchasez for USD $${listData?.price_monthly} /month`}
                                            />
                                          )}
                                        </>
                                        {/* Subscribe Yearly */}
                                        <>
                                          {!(
                                            listData?.type === 'Yearly'
                                          ) ? null : (
                                            <Button
                                              onPress={() => {
                                                const handler = async () => {
                                                  try {
                                                    if (
                                                      Constants['user']?._id
                                                    ) {
                                                      await WebBrowser.openBrowserAsync(
                                                        `${listData?.stripe_link}?client_reference_id=${Constants['user']?._id}_xxx`
                                                      );
                                                    }
                                                  } catch (err) {
                                                    console.error(err);
                                                  }
                                                };
                                                handler();
                                              }}
                                              style={StyleSheet.applyWidth(
                                                {
                                                  backgroundColor:
                                                    'rgb(69, 252, 241)',
                                                  borderColor:
                                                    theme.colors['Strong'],
                                                  borderRadius: 100,
                                                  borderWidth: 2,
                                                  color: theme.colors['Strong'],
                                                  fontFamily:
                                                    'SecularOne_400Regular',
                                                  fontSize: 15,
                                                  height: 58,
                                                  marginBottom: 20,
                                                  marginLeft: 20,
                                                  marginRight: 20,
                                                  marginTop: 20,
                                                  textAlign: 'center',
                                                },
                                                dimensions.width
                                              )}
                                              title={`Purchase for USD $${listData?.price_yearly} /year`}
                                            />
                                          )}
                                        </>
                                      </View>
                                    )}
                                  </>
                                  {/* Yearly Price View */}
                                  <View
                                    style={StyleSheet.applyWidth(
                                      { marginTop: -10 },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Yearly Price Text */}
                                    <>
                                      {!(listData?.type === 'Yearly') ? null : (
                                        <Text
                                          accessible={true}
                                          allowFontScaling={true}
                                          style={StyleSheet.applyWidth(
                                            StyleSheet.compose(
                                              GlobalStyles.TextStyles(theme)[
                                                'Text'
                                              ],
                                              {
                                                color: theme.colors['Primary'],
                                                fontFamily:
                                                  'RobotoCondensed_400Regular',
                                                textAlign: 'center',
                                              }
                                            ),
                                            dimensions.width
                                          )}
                                        >
                                          {'*Equal to $'}
                                          {listData?.price_monthly}
                                          {' /month.'}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Monthly Desc Text */}
                                  <>
                                    {!(listData?.type === 'Standard') ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Light'],
                                            fontFamily:
                                              'RobotoCondensed_300Light',
                                            fontSize: 14,
                                            lineHeight: 21,
                                            marginTop: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          '*Monthly packages will refresh your AI image credits every 30 days. Any unused credits will rollover to the next month so you never waste credits. Save money by purchasing a yearly package instead of a monthly package.'
                                        }
                                      </Text>
                                    )}
                                  </>
                                  {/* Yearly Desc Text */}
                                  <>
                                    {!(listData?.type === 'Yearly') ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Light'],
                                            fontFamily:
                                              'RobotoCondensed_300Light',
                                            fontSize: 14,
                                            lineHeight: 21,
                                            marginTop: 16,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {
                                          '*Yearly packages will refresh your AI image credits every 30 days. Any unused credits will rollover to the next month so you never waste credits. Yearly packages will charge you for the full year up front and will save you a lot of money.'
                                        }
                                      </Text>
                                    )}
                                  </>
                                </View>
                              )}
                            </>
                          </View>
                        )}
                      </>
                    </>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
            </>
          );
        }}
      </GAIMMobileBackendApi.FetchFetchModelDataGET>
      <Spacer bottom={8} left={8} right={8} top={0} />
    </ScreenContainer>
  );
};

export default withTheme(SubscribeScreen);
