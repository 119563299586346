import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, Text, View, useWindowDimensions } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const ViewProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendUpdateAvatarPOST =
    GAIMMobileBackendApi.useUpdateAvatarPOST();

  const [Passions, setPassions] = React.useState([
    'Festivals',
    'Travel',
    'Plant-based',
    'Movies',
    'Road Trips',
    'Writer',
    'Fashion',
    'Art',
    'Stand up Comedy',
    'Coffee',
    'Cycling',
    'DIY',
    'Dog lover',
    'House Parties',
    'Swimming',
    'Outdoors',
    'Astrology',
    'Board Games',
    'Trivia',
    'Tea',
    'Yoga',
    'Environmentalism',
    'Blogging',
    'Vegan',
    'Photography',
    'Ludo',
    'Athlete',
    'Bollywood',
    'Hollywood',
    'Cricket',
    'Running',
    'Music',
    'Baking',
    'Spirituality',
    'Grab a drink',
    'Foodie',
  ]);
  const [Website, setWebsite] = React.useState('');
  const [company, setCompany] = React.useState('');
  const [location, setLocation] = React.useState('');
  const [passionalModal, setPassionalModal] = React.useState(false);
  const [selectedPassion, setSelectedPassion] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable>
            <Icon
              color={theme.colors['Primary']}
              name={'AntDesign/arrowleft'}
              size={24}
            />
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Surface'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'VIEW PROFILE'}
        </Text>
        {/* Blank */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        />
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 10 },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        {/* Image */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', paddingBottom: 20, paddingTop: 20 },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              const handler = async () => {
                try {
                  const result = await openImagePickerUtil({
                    mediaTypes: 'All',
                    allowsEditing: false,
                    quality: 0.64,
                  });

                  setGlobalVariableValue({
                    key: 'UserPic',
                    value: result,
                  });
                  console.log(result);
                  (
                    await gAIMMobileBackendUpdateAvatarPOST.mutateAsync({
                      image_base64: result,
                      user_id: Constants['user']?._id,
                    })
                  )?.json;
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
          >
            <View>
              <Circle
                bgColor={theme.colors.light}
                size={140}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Medium'] },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${Constants['user']?.pic}` }}
                  style={StyleSheet.applyWidth(
                    { borderRadius: 100, height: 140, width: 140 },
                    dimensions.width
                  )}
                />
              </Circle>
            </View>
          </Touchable>
        </View>
        {/* Name */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderColor: theme.colors['Primary'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 16,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              }),
              dimensions.width
            )}
          >
            {Constants['user']?.name}
          </Text>

          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Name'}
            </Text>
          </View>
        </View>
        {/* Username */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Text 2 */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 16,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              }),
              dimensions.width
            )}
          >
            {Constants['user']?.username}
          </Text>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Username'}
            </Text>
          </View>
        </View>
        {/* Location */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Text 3 */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 16,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              }),
              dimensions.width
            )}
          >
            {Constants['user']?.location}
          </Text>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Location'}
            </Text>
          </View>
        </View>
        {/* Company Name */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Text 4 */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 16,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              }),
              dimensions.width
            )}
          >
            {Constants['user']?.companyName}
          </Text>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Company Name'}
            </Text>
          </View>
        </View>
        {/* Website */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          {/* Text 5 */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Primary'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 16,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 12,
              }),
              dimensions.width
            )}
          >
            {Constants['user']?.website}
          </Text>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 12,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Website'}
            </Text>
          </View>
        </View>
      </KeyboardAwareScrollView>
      {/* Edit Profile */}
      <Button
        onPress={() => {
          try {
            navigation.navigate('FillProfileScreen');
          } catch (err) {
            console.error(err);
          }
        }}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Primary'],
            borderRadius: 24,
            color: 'rgb(18, 18, 18)',
            fontFamily: 'SecularOne_400Regular',
            fontSize: 17,
            height: 48,
            marginBottom: 10,
            marginLeft: 20,
            marginRight: 20,
            textAlign: 'center',
          },
          dimensions.width
        )}
        title={'EDIT PROFILE'}
      />
    </ScreenContainer>
  );
};

export default withTheme(ViewProfileScreen);
