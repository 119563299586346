import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openImagePickerUtil from '../utils/openImagePicker';
import showAlertUtil from '../utils/showAlert';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { FlatList, Image, Modal, Text, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const FillProfileScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [Passions, setPassions] = React.useState([
    'Festivals',
    'Travel',
    'Plant-based',
    'Movies',
    'Road Trips',
    'Writer',
    'Fashion',
    'Art',
    'Stand up Comedy',
    'Coffee',
    'Cycling',
    'DIY',
    'Dog lover',
    'House Parties',
    'Swimming',
    'Outdoors',
    'Astrology',
    'Board Games',
    'Trivia',
    'Tea',
    'Yoga',
    'Environmentalism',
    'Blogging',
    'Vegan',
    'Photography',
    'Ludo',
    'Athlete',
    'Bollywood',
    'Hollywood',
    'Cricket',
    'Running',
    'Music',
    'Baking',
    'Spirituality',
    'Grab a drink',
    'Foodie',
  ]);
  const [Website, setWebsite] = React.useState(Constants['user']?.website);
  const [company, setCompany] = React.useState(Constants['user']?.companyName);
  const [localName, setLocalName] = React.useState(Constants['user']?.name);
  const [localUsername, setLocalUsername] = React.useState(
    Constants['user']?.username
  );
  const [location, setLocation] = React.useState(Constants['user']?.location);
  const [passionalModal, setPassionalModal] = React.useState(false);
  const [selectedPassion, setSelectedPassion] = React.useState('');
  const [textInputValue, setTextInputValue] = React.useState('');
  const gAIMMobileBackendUpdateAvatarPOST =
    GAIMMobileBackendApi.useUpdateAvatarPOST();
  const gAIMMobileBackendUpdateUserProfilePUT =
    GAIMMobileBackendApi.useUpdateUserProfilePUT();
  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setLocalName(Constants['user']?.name);
      setLocalUsername(Constants['user']?.username);
      setLocation(Constants['user']?.location);
      setCompany(Constants['user']?.companyName);
      setWebsite(Constants['user']?.website);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
          },
          dimensions.width
        )}
      >
        {/* Back */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('MenuScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            {/* Profile Menu Btn */}
            <Image
              resizeMode={'cover'}
              source={{ uri: `${Constants['user']?.pic}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  borderColor: theme.colors['Primary'],
                  borderRadius: 100,
                  borderWidth: 1,
                  height: 35,
                  marginLeft: 10,
                  width: 35,
                }),
                dimensions.width
              )}
            />
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Surface'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'UPDATE PROFILE'}
        </Text>
        {/* Blank */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        />
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
            marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
            paddingBottom: 10,
          },
          dimensions.width
        )}
        keyboardShouldPersistTaps={'never'}
        showsVerticalScrollIndicator={true}
      >
        {/* Image */}
        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center', paddingBottom: 20, paddingTop: 20 },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              const handler = async () => {
                try {
                  const result = await openImagePickerUtil({
                    mediaTypes: 'All',
                    allowsEditing: false,
                    quality: 0.64,
                  });

                  const postResult = (
                    await gAIMMobileBackendUpdateAvatarPOST.mutateAsync({
                      image_base64: result,
                      user_id: Constants['user']?._id,
                    })
                  )?.json;
                  if (parseBoolean(postResult?.success) === true) {
                    setGlobalVariableValue({
                      key: 'UserPic',
                      value: postResult?.data,
                    });
                  }
                  if (parseBoolean(postResult?.success) === true) {
                    setGlobalVariableValue({
                      key: 'ProfilePicture',
                      value: postResult?.data,
                    });
                  }
                  const newUser = (
                    await GAIMMobileBackendApi.getUserByDynamicIDGET(
                      Constants,
                      { dynamic_id: Constants['user']?.dynamic_id }
                    )
                  )?.json;
                  setGlobalVariableValue({
                    key: 'user',
                    value: newUser?.data,
                  });
                  navigation.navigate('UserProfileScreen', {
                    userImage: postResult?.data,
                    userName: Constants['user']?.username,
                  });
                } catch (err) {
                  console.error(err);
                }
              };
              handler();
            }}
          >
            <View>
              <Circle
                bgColor={theme.colors.light}
                size={140}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Medium'] },
                  dimensions.width
                )}
              >
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${Constants['user']?.pic}` }}
                  style={StyleSheet.applyWidth(
                    { height: 140, width: 140 },
                    dimensions.width
                  )}
                />
              </Circle>
              <Image
                resizeMode={'cover'}
                source={Images.Camera}
                style={StyleSheet.applyWidth(
                  {
                    bottom: 0,
                    height: 48,
                    position: 'absolute',
                    right: 0,
                    width: 48,
                    zIndex: 2,
                  },
                  dimensions.width
                )}
              />
            </View>
          </Touchable>
        </View>
        {/* Name Error View */}
        <>
          {!(localName?.length < 5) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 4 },
                dimensions.width
              )}
            >
              {/* Name Error Text */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'RobotoCondensed_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'Name must be at least five (5) characters'}
              </Text>
            </View>
          )}
        </>
        {/* Name */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderColor: theme.colors['Primary'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <TextInput
            defaultValue={localName}
            editable={true}
            onChangeText={newTextInputValue => {
              try {
                setLocalName(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Name'}
            placeholderTextColor={theme.colors['Light']}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 1,
                borderRadius: 25,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 15,
                height: 48,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
              },
              dimensions.width
            )}
          />
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Name'}
            </Text>
          </View>
        </View>
        {/* Username Error View 2 */}
        <>
          {!(localUsername?.length < 6) ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 4 },
                dimensions.width
              )}
            >
              {/* Username Error Text */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Error'],
                    fontFamily: 'RobotoCondensed_400Regular',
                  }),
                  dimensions.width
                )}
              >
                {'Username must be at least six (6) characters'}
              </Text>
            </View>
          )}
        </>
        {/* Username */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <TextInput
            defaultValue={localUsername}
            editable={true}
            onChangeText={newTextInputValue => {
              try {
                setLocalUsername(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Username*'}
            placeholderTextColor={theme.colors['Light']}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 1,
                borderRadius: 25,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 15,
                height: 48,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
              },
              dimensions.width
            )}
          />
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Username'}
            </Text>
          </View>
        </View>
        {/* Location */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <TextInput
            defaultValue={location}
            editable={true}
            onChangeText={newTextInputValue => {
              try {
                setLocation(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Location'}
            placeholderTextColor={theme.colors['Light']}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 1,
                borderRadius: 25,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 15,
                height: 48,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
              },
              dimensions.width
            )}
          />
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Location'}
            </Text>
          </View>
        </View>
        {/* Company Name */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <TextInput
            defaultValue={company}
            editable={true}
            onChangeText={newTextInputValue => {
              try {
                setCompany(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'Company Name'}
            placeholderTextColor={theme.colors['Light']}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 1,
                borderRadius: 25,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 15,
                height: 48,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
              },
              dimensions.width
            )}
          />
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Company Name'}
            </Text>
          </View>
        </View>
        {/* Website */}
        <View
          style={StyleSheet.applyWidth(
            {
              backgroundColor: theme.colors['Custom Color_3'],
              borderRadius: 24,
              height: 48,
              marginLeft: 20,
              marginRight: 20,
              marginTop: 15,
            },
            dimensions.width
          )}
        >
          <TextInput
            defaultValue={Website}
            editable={true}
            onChangeText={newTextInputValue => {
              try {
                setWebsite(newTextInputValue);
              } catch (err) {
                console.error(err);
              }
            }}
            placeholder={'https://gaim.ai'}
            placeholderTextColor={theme.colors['Light']}
            style={StyleSheet.applyWidth(
              {
                borderBottomWidth: 1,
                borderColor: theme.colors['Primary'],
                borderLeftWidth: 1,
                borderRadius: 25,
                borderRightWidth: 1,
                borderTopWidth: 1,
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 15,
                height: 48,
                paddingBottom: 8,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 8,
              },
              dimensions.width
            )}
          />
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { position: 'absolute', top: -10 },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Surface'],
                  fontFamily: 'Poppins_400Regular',
                  fontSize: 14,
                  marginLeft: 20,
                },
                dimensions.width
              )}
            >
              {'Website'}
            </Text>
          </View>
        </View>
      </KeyboardAwareScrollView>
      {/* Update Profile */}
      <Button
        onPress={() => {
          const handler = async () => {
            try {
              if (localName?.length < 5) {
                showAlertUtil({
                  title: 'Name Error',
                  message:
                    'Your name must be at least five (5) characters long. Please fix this and try again.',
                  buttonText: 'Close',
                });
              }
              if (localName?.length < 5) {
                return;
              }
              if (localUsername?.length < 6) {
                showAlertUtil({
                  title: 'Username Error',
                  message:
                    'Your username must be at least six (6) characters long. Please fix this and try again.',
                  buttonText: 'Close',
                });
              }
              if (localUsername?.length < 6) {
                return;
              }
              const updateResult = (
                await gAIMMobileBackendUpdateUserProfilePUT.mutateAsync({
                  companyName: company,
                  dynamic_id: Constants['user']?.dynamic_id,
                  location: location,
                  name: localName,
                  username: localUsername,
                  website: Website,
                })
              )?.json;
              if (updateResult?.status === false) {
                showAlertUtil({
                  title: 'Error',
                  message:
                    'There was an error updating your profile information. Please try again later.',
                  buttonText: 'Close',
                });
              }
              if (updateResult?.success !== true) {
                return;
              }
              const resultUser = updateResult?.data;
              setGlobalVariableValue({
                key: 'user',
                value: resultUser,
              });
              setGlobalVariableValue({
                key: 'Name',
                value: resultUser?.name,
              });
              setGlobalVariableValue({
                key: 'UserPic',
                value: resultUser?.pic,
              });
              setGlobalVariableValue({
                key: 'Username',
                value: resultUser?.username,
              });
              navigation.navigate('UserProfileScreen', {
                userImage: resultUser?.pic,
                userName: resultUser?.username,
              });
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Primary'],
            borderRadius: 24,
            color: 'rgb(18, 18, 18)',
            fontFamily: 'SecularOne_400Regular',
            fontSize: 17,
            height: 48,
            marginBottom: 10,
            marginLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: '20%' },
            ],
            marginRight: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: '20%' },
            ],
            textAlign: 'center',
          },
          dimensions.width
        )}
        title={'UPDATE PROFILE'}
      />
    </ScreenContainer>
  );
};

export default withTheme(FillProfileScreen);
