import io from "socket.io-client";
//const socket = io.connect("http://localhost:8080");
const socket = io.connect("https://gaim-mobile-backend-vr97s.ondigitalocean.app");
socket.on('connect', () => {
    //alert('Socket connected:', socket.id);
    console.log('Socket connected:', socket.id);
});

socket.on('connect_error', (error) => {
    //alert('Connection Error Message:', error.message);
    console.log('Connection Error Message:', error.message);
});
export default socket;