import React, { useRef } from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as GAIMMobileBackendApi from "../apis/GAIMMobileBackendApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import filterList from '../global-functions/filterList';
import formatDate from "../global-functions/formatDate";
import truncateText from "../global-functions/truncateText";
import scrollToBottom from "../global-functions/scrollToBottom.js";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import showAlertUtil from "../utils/showAlert";
import {
  Button,
  Circle,
  Icon,
  Pressable,
  ScreenContainer,
  TextInput,
  Touchable,
  withTheme,
} from "@draftbit/ui";
import { useIsFocused } from "@react-navigation/native";
import {
  ActivityIndicator,
  FlatList,
  Image,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { Fetch } from "react-request";

const ToolsListScreen = (props) => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const scrollViewRef = useRef();

  const gAIMMobileBackendDeleteConversationDELETE =
    GAIMMobileBackendApi.useDeleteConversationDELETE();
  const gAIMMobileBackendUpdateConvoTitlePUT =
    GAIMMobileBackendApi.useUpdateConvoTitlePUT();

  const [convoIDtoDelete, setConvoIDtoDelete] = React.useState("");
  const [convoToUpdate, setConvoToUpdate] = React.useState({});
  const [searchInput, setSearchInput] = React.useState('');
  const [showConvoDelete, setShowConvoDelete] = React.useState(false);
  const [showEditNameModal, setShowEditNameModal] = React.useState(false);
  const [showExpMenu, setShowExpMenu] = React.useState(false);
  const [showSearchBox, setShowSearchBox] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState("");
  const [titleInputValue, setTitleInputValue] = React.useState("");
  const [toolFilterType, setToolFilterType] = React.useState("chatbot");

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: "rgb(18, 18, 18)" },
        dimensions.width
      )}
    >
      {/* Header */}
      {/* Header */}

      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",

            flexDirection: "row",

            height: 48,

            justifyContent: "space-between",

            paddingLeft: 16,

            paddingRight: 16,
          },

          dimensions.width
        )}
      >
        {/* Menu */}

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: "center",

              height: 48,

              justifyContent: "center",

              width: 48,
            },

            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",

                borderBottomWidth: 0.5,

                borderLeftWidth: 0.5,

                borderRadius: 100,

                borderRightWidth: 0.5,

                borderTopWidth: 0.5,

                height: 35,

                justifyContent: "center",

                width: 35,
              },

              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate("MenuScreen");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Profile Menu Button */}

              <Image
                resizeMode={"cover"}
                source={{ uri: `${Constants["user"]?.pic}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)["Image"], {
                    borderColor: theme.colors["Primary"],

                    borderRadius: 100,

                    borderWidth: 1,

                    height: 35,

                    marginLeft: 10,

                    width: 35,
                  }),

                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        </View>

        {/* Screen Heading */}

        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors["Custom Color_2"],

              fontFamily: "Inter_600SemiBold",

              fontSize: 18,
            },

            dimensions.width
          )}
        >
          {"AI Tools"}
        </Text>

        {/* Buy Credits View */}

        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: "center",

              flexDirection: "row",

              height: 48,

              justifyContent: "center",
            },

            dimensions.width
          )}
        >
          {/* Search Icon View */}

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",

                borderBottomWidth: 0.5,

                borderLeftWidth: 0.5,

                borderRadius: 17.5,

                borderRightWidth: 0.5,

                borderTopWidth: 0.5,

                height: 35,

                justifyContent: "center",

                width: 35,
              },

              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  if (showSearchBox) {
                    setShowSearchBox(false);
                    setSearchInput(null);
                  } else {
                    setShowSearchBox(true);                    
                  }
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Buy Credits Icon */}

              <Icon
                color={theme.colors["Primary"]}
                name={"FontAwesome/search"}
                size={24}
              />
            </Touchable>
          </View>

          {/* Buy Credits View */}

          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",

                borderBottomWidth: 0.5,

                borderLeftWidth: 0.5,

                borderRadius: 17.5,

                borderRightWidth: 0.5,

                borderTopWidth: 0.5,

                height: 35,

                justifyContent: "center",

                width: 35,
              },

              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate("SubscribeScreen");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Buy Credits Icon */}

              <Icon
                color={theme.colors["Primary"]}
                name={"MaterialIcons/add-shopping-cart"}
                size={24}
              />
            </Touchable>
          </View>
        </View>
      </View>

      {/* Search Box View */}

      <>
        {!showSearchBox ? null : (
          <View
            style={StyleSheet.applyWidth(
              {

                marginBottom: 8,

                marginLeft: [

                  { minWidth: Breakpoints.Mobile, value: 6 },

                  { minWidth: Breakpoints.Laptop, value: '25%' },

                ],

                marginRight: [

                  { minWidth: Breakpoints.Mobile, value: 6 },

                  { minWidth: Breakpoints.Laptop, value: '25%' },

                ],

              },
              dimensions.width
            )}
          >
            <TextInput
              allowFontScaling={true}
              autoCapitalize={"none"}
              changeTextDelay={500}
              onChangeText={(newTextInputValue) => {
                try {
                  setSearchInput(newTextInputValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={"Type to search..."}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)["Text Input"],

                  {
                    backgroundColor: "rgb(40, 40, 40)",

                    borderColor: theme.colors["Primary"],

                    color: theme.colors["Primary"],

                    fontFamily: "RobotoCondensed_400Regular",

                    fontSize: 16,
                  }
                ),

                dimensions.width
              )}
              value={searchInput}
            />
          </View>
        )}
      </>
      {/* Info View */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 8,
            justifyContent: { minWidth: Breakpoints.Laptop, value: "center" },
            marginLeft: "8%",
            marginRight: "8%",
            width: "80%",
          },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors["Light"]}
          name={"Feather/info"}
          size={24}
          style={StyleSheet.applyWidth({ marginRight: 10 }, dimensions.width)}
        />
        {/* Chatbots Text */}
        <>
          {!(toolFilterType === "chatbot") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {
                "AI Chatbots allow you to have an ongoing chat conversation with a specialized expert assistant."
              }
            </Text>
          )}
        </>
        {/* txt2img text */}
        <>
          {!(toolFilterType === "image") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {
                "Text-To-Image generators allow you to provide a description prompt of your desired image, and the tool will generate the described image."
              }
            </Text>
          )}
        </>
        {/* img2img text */}
        <>
          {!(toolFilterType === "img2img") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {
                "Image-To-Image generators allow you to upload an image from your device and transform the image to a specific style."
              }
            </Text>
          )}
        </>
        {/* asset bots text */}
        <>
          {!(toolFilterType === "assetbot") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {
                "Asset Bots generate a specific type of asset based on information and details that you provide."
              }
            </Text>
          )}
        </>
        {/* gaim labs text */}
        <>
          {!(toolFilterType === "lab") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {
                "GAIM Labs is where we keep our new, unique, entertaining, and sometimes whacky AI tools. Try them all!"
              }
            </Text>
          )}
        </>
        {/* past chats text */}
        <>
          {!(toolFilterType === "pastchats") ? null : (
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Light"],
                  fontFamily: "RobotoCondensed_400Regular",
                }),
                dimensions.width
              )}
            >
              {"Load a previous conversation and pick up where you left off."}
            </Text>
          )}
        </>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 10, marginBottom: 80, height: "60vh" },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
      >
        {/* Chats */}
        <View
          style={StyleSheet.applyWidth(
            {
              borderRadius: 12,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 100 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 100 },
              ],
            },
            dimensions.width
          )}
        >
          {/* Past Chats View */}
          <>
            {!(toolFilterType === "chatbot") ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: "center", marginBottom: 10, marginTop: 10 },
                  dimensions.width
                )}
              >
                <Pressable
                  onPress={() => {
                    try {
                      setToolFilterType("pastchats");
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  <Circle
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.CircleStyles(theme)["Circle"],
                        { marginBottom: 4, padding: 12 }
                      ),
                      dimensions.width
                    )}
                  >
                    {/* Convos Icon */}
                    <Icon
                      name={"Ionicons/chatbubbles-sharp"}
                      size={34}
                      style={StyleSheet.applyWidth(
                        { backgroundColor: theme.colors["Primary"] },
                        dimensions.width
                      )}
                    />
                  </Circle>
                </Pressable>
                {/* Past Chats Text */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                      color: theme.colors["Primary"],
                      fontFamily: "RobotoCondensed_400Regular",
                    }),
                    dimensions.width
                  )}
                >
                  {"Past Chats"}
                </Text>
              </View>
            )}
          </>
          {/* Convo History View */}
          <>
            {!(toolFilterType === "pastchats") ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { borderRadius: 12, left: 20, marginTop: 10 },
                  dimensions.width
                )}
              >
                <GAIMMobileBackendApi.FetchGetChatHistoryGET
                  dynamic_id={Constants["dynamic_id"]}
                >
                  {({ loading, error, data, refetchGetChatHistory }) => {
                    const fetchData = data?.json;
                    if (loading) {
                      return <ActivityIndicator />;
                    }

                    if (error || data?.status < 200 || data?.status >= 300) {
                      return <ActivityIndicator />;
                    }

                    return (
                      <FlatList
                        data={fetchData?.data}
                        keyExtractor={(listData) => listData && listData["_id"]}
                        listKey={"UFxy6RuH"}
                        numColumns={1}
                        onEndReachedThreshold={0.5}
                        renderItem={({ item }) => {
                          const listData = item;
                          return (
                            <Touchable
                              onPress={() => {
                                try {
                                  if (listData.oai_assistantId) {
                                    navigation.navigate("ChatOneScreen", {
                                      convoIDtoLoad: listData && listData["_id"],
                                      loadTool: "load",
                                      loadConvo: "load",
                                      oai_assistantId: listData?.oai_assistantId,
                                      oai_threadId: listData?.oai_threadId,
                                      type: listData?.type,
                                    });
                                  } else {
                                    navigation.navigate("ChatbotScreen", {
                                      tool_id: listData?.tool_id,
                                      convoIDtoLoad: listData && listData["_id"],
                                      loadTool: "load",
                                      loadConvo: "load",
                                      type: listData?.type,
                                    });
                                  }
                                  
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: "center",
                                    borderColor: theme.colors["Primary"],
                                    borderRadius: 12,
                                    borderWidth: 1,
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    marginBottom: 4,
                                    marginRight: 20,
                                    marginTop: 4,
                                    padding: 8,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* View 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    { width: "80%" },
                                    dimensions.width
                                  )}
                                >
                                  {/* First Message Text */}
                                  <>
                                    {listData?.title ? null : (
                                      <Text
                                        accessible={true}
                                        allowFontScaling={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              "Text"
                                            ],
                                            {
                                              color: theme.colors["Surface"],
                                              fontFamily:
                                                "RobotoCondensed_400Regular",
                                              fontSize: 16,
                                              padding: 10,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {/*truncateText(
                                              listData?.messages && listData?.messages?.[1]?.content &&
                                                (listData?.messages)[1].content,
                                              100
                                            )*/}
                                        {listData?.messages?.[1]?.content
                                          ? listData.messages[1].content
                                              .length > 100
                                            ? truncateText(
                                                listData.messages[1].content,
                                                100
                                              )
                                            : listData.messages[1].content
                                          : listData.messages[0].content}
                                      </Text>
                                    )}
                                  </>
                                  {/* Convo Title Text */}
                                  <>
                                    {!listData?.title ? null : (
                                      <Text
                                        accessible={true}
                                        allowFontScaling={true}
                                        style={StyleSheet.applyWidth(
                                          StyleSheet.compose(
                                            GlobalStyles.TextStyles(theme)[
                                              "Text"
                                            ],
                                            {
                                              color: theme.colors["Surface"],
                                              fontFamily:
                                                "RobotoCondensed_400Regular",
                                              fontSize: 16,
                                              padding: 10,
                                            }
                                          ),
                                          dimensions.width
                                        )}
                                      >
                                        {listData?.title}
                                      </Text>
                                    )}
                                  </>
                                  {/* Last Updated Text */}
                                  <Text
                                    accessible={true}
                                    allowFontScaling={true}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.TextStyles(theme)["Text"],
                                        {
                                          color: theme.colors["Light"],
                                          marginLeft: 40,
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                  >
                                    {"Last Updated: "}
                                    {formatDate(listData?.lastUpdated)}
                                  </Text>
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    { flexDirection: "row" },
                                    dimensions.width
                                  )}
                                >
                                  {/* Touchable 2 */}
                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowEditNameModal(true);
                                        setConvoToUpdate(listData);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Edit Title Icon */}
                                    <Icon
                                      color={theme.colors["Primary"]}
                                      name={"FontAwesome/pencil"}
                                      size={24}
                                      style={StyleSheet.applyWidth(
                                        { marginRight: 10 },
                                        dimensions.width
                                      )}
                                    />
                                  </Touchable>

                                  <Touchable
                                    onPress={() => {
                                      try {
                                        setShowConvoDelete(true);
                                        setConvoIDtoDelete(
                                          listData && listData["_id"]
                                        );
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                  >
                                    {/* Delete Convo Icon */}
                                    <Icon
                                      color={theme.colors["Error"]}
                                      name={"FontAwesome/trash"}
                                      size={24}
                                    />
                                  </Touchable>
                                </View>
                              </View>
                            </Touchable>
                          );
                        }}
                        showsHorizontalScrollIndicator={true}
                        showsVerticalScrollIndicator={true}
                      />
                    );
                  }}
                </GAIMMobileBackendApi.FetchGetChatHistoryGET>
              </View>
            )}
          </>
          <GAIMMobileBackendApi.FetchFetchModelDataGET
            handlers={{
              onData: (fetchData) => {
                try {
                  setGlobalVariableValue({
                    key: "aitools",
                    value: fetchData?.data,
                  });
                } catch (err) {
                  console.error(err);
                }
              },
            }}
            modelName={"AITool"}
          >
            {({ loading, error, data, refetchFetchModelData }) => {
              const fetchData = data?.json;
              if (loading) {
                return <ActivityIndicator />;
              }

              if (error || data?.status < 200 || data?.status >= 300) {
                return <ActivityIndicator />;
              }

              return (
                <FlatList
                  contentContainerStyle={StyleSheet.applyWidth(
                    { flex: 1 },
                    dimensions.width
                  )}
                  data={filterList(
                    Variables,
                    setGlobalVariableValue,
                    fetchData?.data,
                    searchInput,
                    'name'
                  )}
                  keyExtractor={(listData) => listData && listData["_id"]}
                  listKey={"rf0Q3SrF"}
                  numColumns={1}
                  renderItem={({ item }) => {
                    const listData = item;
                    return (
                      <>
                        {/* Touchable Result Object */}
                        <>
                          {!(listData?.type === toolFilterType) ? null : (
                            <Touchable
                              onPress={() => {
                                try {
                                  if (
                                    toolFilterType === "chatbot" ||
                                    toolFilterType === "lab"
                                  ) {
                                    setGlobalVariableValue({
                                      key: "currentChatTool",
                                      value: listData,
                                    });
                                  }
                                  if (
                                    toolFilterType === "chatbot" ||
                                    toolFilterType === "lab"
                                  ) {
                                    navigation.navigate("ChatbotScreen", {
                                      toolExampleImageURL:
                                        listData?.image_data?.example_url,
                                      toolName: listData?.name,
                                      toolDesc: listData?.description,
                                      toolPrePrompt: listData?.pre_prompt,
                                    });
                                  }
                                  if (toolFilterType === "image") {
                                    setGlobalVariableValue({
                                      key: "currentImageTool",
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType === "fun-image") {
                                    setGlobalVariableValue({
                                      key: "currentImageTool",
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType === "image") {
                                    navigation.navigate(
                                      "ImageGeneratorScreen",
                                      {
                                        toolName: listData?.name,
                                        toolDesc: listData?.description,
                                        toolPrePrompt: listData?.pre_prompt,
                                      }
                                    );
                                  }
                                  if (toolFilterType === "fun-image") {
                                    navigation.navigate(
                                      "ImageGeneratorScreen",
                                      {
                                        toolName: listData?.name,
                                        toolDesc: listData?.description,
                                        toolPrePrompt: listData?.pre_prompt,
                                      }
                                    );
                                  }
                                  if (toolFilterType === "img2img") {
                                    setGlobalVariableValue({
                                      key: "currentImageTool",
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType === "img2img") {
                                    navigation.navigate(
                                      "Img2ImgGeneratorScreen",
                                      {
                                        toolName: listData?.name,
                                        toolDesc: listData?.description,
                                        toolPrePrompt: listData?.pre_prompt,
                                      }
                                    );
                                  }
                                  /*if (toolFilterType === 'lab') {
                                    setGlobalVariableValue({
                                      key: 'currentLabTool',
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType === 'lab') {
                                    navigation.navigate('LabScreen');
                                  }*/
                                  if (toolFilterType === "assetbot") {
                                    setGlobalVariableValue({
                                      key: "currentChatTool",
                                      value: listData,
                                    });
                                  }
                                  if (toolFilterType === "assetbot") {
                                    navigation.navigate("ChatbotScreen", {
                                      toolExampleImageURL:
                                        listData?.image_data?.example_url,
                                      toolName: listData?.name,
                                      toolDesc: listData?.description,
                                      toolPrePrompt: listData?.pre_prompt,
                                    });
                                  }
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: "center",
                                    borderBottomWidth: 0.5,
                                    borderColor:
                                      theme.colors["Custom Color_19"],
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    paddingBottom: 18,
                                    paddingTop: 18,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* Chatbots View */}
                                <>
                                  {!(toolFilterType === "chatbot") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      {/* Image 2 */}
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* Text 2 IMG View */}
                                <>
                                  {!(toolFilterType === "image") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    "Custom Color_22"
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: "absolute",
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* View 3 */}
                                <>
                                  {!(toolFilterType === "img2img") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    "Custom Color_22"
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: "absolute",
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* View 4 */}
                                <>
                                  {!(toolFilterType === "lab") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    "Custom Color_22"
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: "absolute",
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* View 5 */}
                                <>
                                  {!(toolFilterType === "assetbot") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    "Custom Color_22"
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: "absolute",
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                {/* View 6 */}
                                <>
                                  {!(toolFilterType === "fun-image") ? null : (
                                    <View
                                      style={StyleSheet.applyWidth(
                                        { justifyContent: "flex-start" },
                                        dimensions.width
                                      )}
                                    >
                                      <>
                                        {!(listData?.id < 5) ? null : (
                                          <View
                                            style={StyleSheet.applyWidth(
                                              {
                                                borderBottomWidth: 2,
                                                borderColor:
                                                  theme.colors[
                                                    "Custom Color_22"
                                                  ],
                                                borderLeftWidth: 2,
                                                borderRadius: 24,
                                                borderRightWidth: 2,
                                                borderTopWidth: 2,
                                                height: 48,
                                                left: 0,
                                                position: "absolute",
                                                top: 0,
                                                width: 48,
                                              },
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                      <>
                                        {!toolFilterType ? null : (
                                          <Image
                                            resizeMode={"cover"}
                                            source={{
                                              uri: `${listData?.image_data?.example_url}`,
                                            }}
                                            style={StyleSheet.applyWidth(
                                              StyleSheet.compose(
                                                GlobalStyles.ImageStyles(theme)[
                                                  "Image"
                                                ],
                                                {
                                                  borderColor:
                                                    theme.colors["Primary"],
                                                  borderRadius: 100,
                                                  borderWidth: 1,
                                                  height: 100,
                                                  width: 100,
                                                }
                                              ),
                                              dimensions.width
                                            )}
                                          />
                                        )}
                                      </>
                                    </View>
                                  )}
                                </>
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      marginLeft: 14,
                                      marginRight: 20,
                                      paddingRight: 16,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        alignContent: "flex-start",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    <>
                                      {!(
                                        toolFilterType === "chatbot"
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={
                                            "MaterialCommunityIcons/message-processing-outline"
                                          }
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 2 */}
                                    <>
                                      {!(toolFilterType === "app") ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={"Ionicons/apps-outline"}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 3 */}
                                    <>
                                      {!(toolFilterType === "image") ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={
                                            "MaterialCommunityIcons/image-edit-outline"
                                          }
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 4 */}
                                    <>
                                      {!(
                                        toolFilterType === "img2img"
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={"Entypo/images"}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 5 */}
                                    <>
                                      {!(toolFilterType === "lab") ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={"Entypo/lab-flask"}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 6 */}
                                    <>
                                      {!(
                                        toolFilterType === "assetbot"
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={
                                            "MaterialCommunityIcons/message-draw"
                                          }
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Icon 7 */}
                                    <>
                                      {!(
                                        toolFilterType === "fun-image"
                                      ) ? null : (
                                        <Icon
                                          color={theme.colors["Primary"]}
                                          name={"AntDesign/smile-circle"}
                                          size={30}
                                        />
                                      )}
                                    </>
                                    {/* Name ~ */}
                                    <>
                                      {!(
                                        (listData && listData["_id"]) !==
                                        "64f35946d7b8542613e4324b"
                                      ) ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color:
                                                theme.colors["Custom Color_2"],
                                              fontFamily: "Poppins_500Medium",
                                              fontSize: 15,
                                              lineHeight: 20,
                                              marginLeft: 5,
                                              marginTop: 4,
                                              opacity: 1,
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.name}
                                        </Text>
                                      )}
                                    </>
                                    {/* Name ~ 2 */}
                                    <>
                                      {!(
                                        (listData && listData["_id"]) ===
                                        "64f35946d7b8542613e4324b"
                                      ) ? null : (
                                        <Text
                                          style={StyleSheet.applyWidth(
                                            {
                                              color: theme.colors["Primary"],
                                              fontFamily: "Poppins_500Medium",
                                              fontSize: 16,
                                              lineHeight: 20,
                                              marginLeft: 5,
                                              marginTop: 4,
                                              opacity: 1,
                                              textTransform: "uppercase",
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          {listData?.name}
                                        </Text>
                                      )}
                                    </>
                                  </View>
                                  {/* Message ~ */}
                                  <Text
                                    ellipsizeMode={"clip"}
                                    numberOfLines={3}
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors["Custom Color_2"],
                                        fontFamily: "Poppins_400Regular",
                                        fontSize: 12,
                                        lineHeight: 20,
                                        marginTop: 8,
                                        opacity: 0.5,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.description}
                                  </Text>
                                </View>
                              </View>
                            </Touchable>
                          )}
                        </>
                      </>
                    );
                  }}
                />
              );
            }}
          </GAIMMobileBackendApi.FetchFetchModelDataGET>
        </View>
      </ScrollView>
      {/* Tabbar */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            backgroundColor: theme.colors["Primary"],
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            flexDirection: "row",
            height: 80,
            justifyContent: "space-evenly",
          },
          dimensions.width
        )}
      >
        {/* Chatbots */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType("chatbot");
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: "center" },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== "chatbot") ? null : (
                <Icon
                  color={theme.colors["Strong"]}
                  name={"Ionicons/chatbox-ellipses"}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === "chatbot") ? null : (
                <Icon
                  color={theme.colors["Studily_Primary"]}
                  name={"Ionicons/chatbox-ellipses"}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Strong"],
                  fontFamily: "Poppins_700Bold",
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: "center",
                },
                dimensions.width
              )}
            >
              {"Chatbots"}
            </Text>
          </View>
        </Touchable>
        {/* Asset Bots */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType("assetbot");
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: "center" },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== "assetbot") ? null : (
                <Icon
                  color={theme.colors["Strong"]}
                  name={"MaterialCommunityIcons/message-draw"}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === "assetbot") ? null : (
                <Icon
                  color={theme.colors["Studily_Primary"]}
                  name={"MaterialCommunityIcons/message-draw"}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Strong"],
                  fontFamily: "Poppins_700Bold",
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: "center",
                },
                dimensions.width
              )}
            >
              {"Asset Bots"}
            </Text>
          </View>
        </Touchable>
        {/* Text 2 Img */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType("image");
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: "center" },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== "image") ? null : (
                <Icon
                  color={theme.colors["Strong"]}
                  name={"MaterialCommunityIcons/image-edit"}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === "image") ? null : (
                <Icon
                  color={theme.colors["Studily_Primary"]}
                  name={"MaterialCommunityIcons/image-edit"}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Strong"],
                  fontFamily: "Poppins_700Bold",
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: "center",
                },
                dimensions.width
              )}
            >
              {"TEXT 2 IMG"}
            </Text>
          </View>
        </Touchable>
        {/* Image to Image */}
        <Touchable
          onPress={() => {
            try {
              setToolFilterType("img2img");
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              { alignItems: "center" },
              dimensions.width
            )}
          >
            <>
              {!(toolFilterType !== "img2img") ? null : (
                <Icon
                  color={theme.colors["Strong"]}
                  name={"Entypo/images"}
                  size={24}
                />
              )}
            </>
            {/* Icon 2 */}
            <>
              {!(toolFilterType === "img2img") ? null : (
                <Icon
                  color={theme.colors["Studily_Primary"]}
                  name={"Entypo/images"}
                  size={24}
                />
              )}
            </>
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Strong"],
                  fontFamily: "Poppins_700Bold",
                  fontSize: 12,
                  marginTop: 8,
                  textAlign: "center",
                },
                dimensions.width
              )}
            >
              {"IMG 2 IMG"}
            </Text>
          </View>
        </Touchable>
        {/* Up View */}
        <>
          {showExpMenu ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: "center", marginTop: -50 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowExpMenu(true);
                    scrollToBottom();
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Circle
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)["Circle"],
                      { borderWidth: 2, width: 50 }
                    ),
                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors["Strong"]}
                    name={"AntDesign/up"}
                    size={24}
                  />
                </Circle>
              </Touchable>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors["Strong"],
                    fontFamily: "Poppins_400Regular",
                    fontSize: 12,
                    textAlign: "center",
                  },
                  dimensions.width
                )}
              >
                {"MORE"}
              </Text>
            </View>
          )}
        </>
        {/* Down View */}
        <>
          {!showExpMenu ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: "center", marginTop: -50 },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    setShowExpMenu(false);
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Circle
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)["Circle"],
                      {
                        backgroundColor: "rgb(18, 18, 18)",
                        borderColor: theme.colors["Primary"],
                        borderWidth: 2,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  {/* Icon 2 */}
                  <Icon
                    color={theme.colors["Primary"]}
                    name={"AntDesign/down"}
                    size={24}
                  />
                </Circle>
              </Touchable>

              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors["Strong"],
                    fontFamily: "Poppins_400Regular",
                    fontSize: 12,
                    textAlign: "center",
                  },
                  dimensions.width
                )}
              >
                {"LESS"}
              </Text>
            </View>
          )}
        </>
      </View>
      {/* Tabbar 2 */}
      <>
        {!showExpMenu ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",
                backgroundColor: theme.colors["Primary"],
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                flexDirection: "row",
                height: 80,
                justifyContent: "space-evenly",
              },
              dimensions.width
            )}
          >
            {/* GAIM Labs */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType("lab");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: "center" },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== "lab") ? null : (
                    <Icon
                      color={theme.colors["Strong"]}
                      name={"Entypo/lab-flask"}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === "lab") ? null : (
                    <Icon
                      color={theme.colors["Studily_Primary"]}
                      name={"Entypo/lab-flask"}
                      size={24}
                    />
                  )}
                </>
                {/* GAIM Labs */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors["Strong"],
                      fontFamily: "Poppins_700Bold",
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: "center",
                    },
                    dimensions.width
                  )}
                >
                  {"GAIM Labs"}
                </Text>
              </View>
            </Touchable>
            {/* Fun IMG */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType("fun-image");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: "center" },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== "fun-image") ? null : (
                    <Icon
                      color={theme.colors["Strong"]}
                      name={"AntDesign/smile-circle"}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === "fun-image") ? null : (
                    <Icon
                      color={theme.colors["Studily_Primary"]}
                      name={"AntDesign/smile-circle"}
                      size={24}
                    />
                  )}
                </>
                {/* Fun IMG */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors["Strong"],
                      fontFamily: "Poppins_700Bold",
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: "center",
                    },
                    dimensions.width
                  )}
                >
                  {"Fun IMG"}
                </Text>
              </View>
            </Touchable>
            {/* Past Chats */}
            <Touchable
              onPress={() => {
                try {
                  setToolFilterType("pastchats");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: "center" },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== "pastchats") ? null : (
                    <Icon
                      color={theme.colors["Strong"]}
                      name={"Ionicons/chatbubbles-sharp"}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === "pastchats") ? null : (
                    <Icon
                      color={theme.colors["Studily_Primary"]}
                      name={"Ionicons/chatbubbles-sharp"}
                      size={24}
                    />
                  )}
                </>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors["Strong"],
                      fontFamily: "Poppins_700Bold",
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: "center",
                    },
                    dimensions.width
                  )}
                >
                  {"Past Chats"}
                </Text>
              </View>
            </Touchable>
            {/* My Images */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate("UserProfileScreen");
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: "center" },
                  dimensions.width
                )}
              >
                <>
                  {!(toolFilterType !== "myimages") ? null : (
                    <Icon
                      color={theme.colors["Strong"]}
                      name={"Ionicons/save"}
                      size={24}
                    />
                  )}
                </>
                {/* Icon 2 */}
                <>
                  {!(toolFilterType === "myimages") ? null : (
                    <Icon
                      color={theme.colors["Studily_Primary"]}
                      name={"Ionicons/save"}
                      size={24}
                    />
                  )}
                </>
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors["Strong"],
                      fontFamily: "Poppins_700Bold",
                      fontSize: 12,
                      marginTop: 8,
                      textAlign: "center",
                    },
                    dimensions.width
                  )}
                >
                  {"My Images"}
                </Text>
              </View>
            </Touchable>
          </View>
        )}
      </>
      {/* Confirm Delete Convo Modal */}
      <>
        {!showConvoDelete ? null : (
          <Modal
            animationType={"slide"}
            presentationStyle={"overFullScreen"}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: "rgba(19, 17, 17, 0.8)",
                  height: "100%",
                  width: "100%",
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    alignSelf: "center",
                    backgroundColor: "rgb(18, 18, 18)",
                    borderColor: theme.colors["Error"],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: "space-around",
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                      color: theme.colors["Surface"],
                      fontFamily: "SecularOne_400Regular",
                    }),
                    dimensions.width
                  )}
                >
                  {"Are you sure you want to delete this Conversation?"}
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: "row", justifyContent: "space-around" },
                    dimensions.width
                  )}
                >
                  {/* Delete Convo Button */}
                  <Button
                    icon={"Feather/trash-2"}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          setShowConvoDelete(false);
                          (
                            await gAIMMobileBackendDeleteConversationDELETE.mutateAsync(
                              {
                                convo_id: convoIDtoDelete,
                                dynamic_id: Constants["user"]?.dynamic_id,
                              }
                            )
                          )?.json;

                          showAlertUtil({
                            title: "Deleted",
                            message: "Your conversation has been deleted.",
                            buttonText: "Close",
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        {
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderColor: theme.colors["Error"],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors["Error"],
                          fontFamily: "SecularOne_400Regular",
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={"DELETE"}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowConvoDelete(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        {
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderColor: theme.colors["Primary"],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors["Primary"],
                          fontFamily: "SecularOne_400Regular",
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={"CANCEL"}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
      {/* Edit Convo Name Modal */}
      <>
        {!showEditNameModal ? null : (
          <Modal
            animationType={"slide"}
            presentationStyle={"overFullScreen"}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: "rgba(19, 17, 17, 0.8)",
                  height: "100%",
                  width: "100%",
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    alignSelf: "center",
                    backgroundColor: "rgb(18, 18, 18)",
                    borderColor: theme.colors["Error"],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: "space-around",
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                      color: theme.colors["Surface"],
                      fontFamily: "SecularOne_400Regular",
                    }),
                    dimensions.width
                  )}
                >
                  {"Update your conversation name."}
                </Text>
                {/* Title Text Input */}
                <TextInput
                  allowFontScaling={true}
                  autoCapitalize={"none"}
                  changeTextDelay={500}
                  multiline={true}
                  onChangeText={(newTitleTextInputValue) => {
                    try {
                      setTitleInputValue(newTitleTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={"Enter new title..."}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)["Text Input"],
                      { color: theme.colors["Surface"] }
                    ),
                    dimensions.width
                  )}
                  value={titleInputValue}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: "row", justifyContent: "space-around" },
                    dimensions.width
                  )}
                >
                  {/* Save Convo Button */}
                  <Button
                    icon={"FontAwesome/save"}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await gAIMMobileBackendUpdateConvoTitlePUT.mutateAsync(
                              {
                                convo_id: convoToUpdate?._id,
                                title: titleInputValue,
                                user_id: Constants["user"]?._id,
                              }
                            )
                          )?.json;

                          showAlertUtil({
                            title: "Updated!",
                            message:
                              "Your conversation title has been updated successfully.",
                            buttonText: "Close",
                          });

                          setShowEditNameModal(false);
                          setTitleInputValue("");
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        {
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderColor: theme.colors["Primary"],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors["Primary"],
                          fontFamily: "SecularOne_400Regular",
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={"SAVE"}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowEditNameModal(false);
                        setTitleInputValue("");
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)["Button"],
                        {
                          backgroundColor: "rgba(0, 0, 0, 0)",
                          borderColor: theme.colors["Primary"],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors["Primary"],
                          fontFamily: "SecularOne_400Regular",
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={"CANCEL"}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ToolsListScreen);
