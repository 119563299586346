import React from "react";
import * as GlobalStyles from "../GlobalStyles.js";
import * as GAIMMobileBackendApi from "../apis/GAIMMobileBackendApi.js";
import * as GlobalVariables from "../config/GlobalVariableContext";
import Images from "../config/Images";
import * as ImageDownloadExports from "../custom-files/ImageDownloadExports";
import loadingProgress from "../global-functions/loadingProgress";
import Breakpoints from "../utils/Breakpoints";
import * as StyleSheet from "../utils/StyleSheet";
import copyToClipboard from "../global-functions/copyToClipboard";
import openShareUtil from "../utils/openShare";
import showAlertUtil from "../utils/showAlert";
import {
  Button,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  LinearProgress,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  WebView,
  withTheme,
} from "@draftbit/ui";
import {
  FlatList,
  Image,
  Keyboard,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

const ImageGeneratorScreen = (props) => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const encodeFileName = (filename) => {
    return encodeURIComponent(filename);
  };

  const addMessageToCurrConvo = (
    Variables,
    setGlobalVariableValue,
    newMessage
  ) => {
    const formattedMessage = { role: "assistant", content: newMessage };
    setCurrentConvo((prevConvo) => [...prevConvo, formattedMessage]);
    console.log("current convo: ", currentConvo);
  };

  const addUserPromptToConvo = (userPrompt) => {
    const formatUserPrompt = { role: "user", content: userPrompt };
    setCurrentConvo((prevConvo) => [...prevConvo, formatUserPrompt]);
  };

  const addRemoteImageToMediaLibrary = async (uri, filename) => {
    // These are valid image file extensions
    const validExtensions = [".jpg", ".png", ".gif", ".heic", ".webp", ".bmp"];

    // Request permission to access the user's media library
    const permission = await ImageDownloadExports.requestPermissionsAsync();

    // Check if user granted permission
    if (permission.status !== "granted") {
      setMessage(
        "The app needs permission to access your media library to download images"
      );
      return;
    }

    // We need to make sure there's a media library available on the device
    const available = await ImageDownloadExports.isAvailableAsync();
    if (!available) {
      setMessage("The Media Library is not available on your device");
      return;
    }

    // Check if our uri ends with an allowed extension
    const fileIsValidImage =
      validExtensions.filter((extension) => {
        // Append the file extension to the file name
        if (uri.endsWith(extension)) {
          filename = `${filename}${extension}`;
        }

        return uri.endsWith(extension);
      }).length === 1;

    if (!fileIsValidImage) {
      setMessage(
        "Only image files with extensions .jpg, .png, .gif, .heic, .webp, and .bmp can be added to the media library"
      );
      return;
    }

    // Create a location to put the contents of the downloaded file
    const fileUri = `${ImageDownloadExports.documentDirectory}${filename}`;

    // Download the remote file to the fileUri location
    setMessage("Downloading image...");
    const downloadedFile = await ImageDownloadExports.downloadAsync(
      uri,
      fileUri
    );

    // Check if we have a successful response from the remote server
    if (downloadedFile.status !== 200) {
      setMessage("The image could not be downloaded. Please try again");
      return;
    }

    try {
      setMessage("Adding image to your media library...");

      // This can be passed in, retrieved from an App variable, etc.
      const albumName = "GAIM AI App";

      // Create an Asset from our downloaded file
      const asset = await ImageDownloadExports.createAssetAsync(
        downloadedFile.uri
      );

      // Check if we already have an album with the same name
      const album = await ImageDownloadExports.getAlbumAsync(albumName);

      if (album === null) {
        setMessage(`Creating new album named ${albumName}...`);

        // Create a new album and add the Asset
        await ImageDownloadExports.createAlbumAsync(albumName, asset, false);
        setMessage(`The image was added to the new ${albumName} album`);
      } else {
        // Add the Asset to the existing album
        await ImageDownloadExports.addAssetsToAlbumAsync(
          [asset],
          albumName,
          false
        );
        setMessage(`The image was added to the existing ${albumName} album`);
      }
    } catch (error) {
      // Something went wrong :(
      setMessage(
        `There was a problem adding the image to your media library: ${error}`
      );
    }
  };
  const addErrorToCurrConvo = (Variables, setGlobalVariableValue, newError) => {
    const formattedMessage = { role: "error", content: newError };
    setCurrentConvo((prevConvo) => [...prevConvo, formattedMessage]);
  };
  const gAIMMobileBackendGenerateImagePOST =
    GAIMMobileBackendApi.useGenerateImagePOST();

  const [convo_id, setConvo_id] = React.useState("");
  const [currentConvo, setCurrentConvo] = React.useState([]);
  const [currentResponse, setCurrentResponse] = React.useState("");
  const [generator, setGenerator] = React.useState([]);
  const [imageURLforWebView, setImageURLforWebView] = React.useState(
    "https://cdn.leonardo.ai/users/80a143f3-0f05-4663-bba8-ebf42b3274df/generations/d96f2fc4-d52d-4176-9e8a-bc7be88f8543/SDXL_09_Watercolor_painting_in_the_style_of_Renoir_of_several_0.jpg"
  );

  const [message, setMessage] = React.useState("");
  const [messages, setMessages] = React.useState([]);
  const [promptToSubmit, setPromptToSubmit] = React.useState("");
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [showImageSaved, setShowImageSaved] = React.useState(false);
  const [showSuggestions, setShowSuggestions] = React.useState(true);
  const [showLoadingProgress, setShowLoadingProgress] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState("");

  async function downloadImageWithFetch(uri) {
    try {
      const response = await fetch(uri);
      if (!response.ok)
        throw new Error(`Failed to fetch ${uri}: ${response.statusText}`);
  
      // Retrieve the content as a Blob
      const blob = await response.blob();
      // Create a new blob with the 'image/jpeg' MIME type
      const jpegBlob = new Blob([blob], { type: 'image/jpeg' });
  
      const timestamp = new Date().getTime(); // Get current timestamp
      const updatedFilename = `GAIM_${timestamp}.jpg`; // Append timestamp and .jpg extension to filename
  
      const blobUrl = URL.createObjectURL(jpegBlob);
  
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = updatedFilename;
  
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl); // Clean up to avoid memory leaks
    } catch (error) {
      console.error("Failed to download image:", error);
    }
  }

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: "rgb(18, 18, 18)", justifyContent: "space-between" },
        dimensions.width
      )}
    >
      {/* AI Tool Top Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            flexDirection: "row",
            height: 48,
            justifyContent: "center",
            marginBottom: 6,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: "center",
              flexDirection: "row",
              height: 48,
              justifyContent: "space-between",
              position: "absolute",
              top: 0,
              width: "90%",
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors["Custom Color_2"]}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: "rgb(153, 171, 214)" },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors["Strong"]}
                name={"Ionicons/arrow-back-sharp"}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: "rgb(153, 171, 214)" },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: "row" },
              dimensions.width
            )}
          ></View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: "flex-start",
                alignItems: "center",
                justifyContent: "space-between",
              },
              dimensions.width
            )}
          ></View>
          
          {/* Settings View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            {/* Touchable Suggs True */}

            <>

              {!Constants['showSuggsGlobal'] ? null : (

                <Touchable

                  onPress={() => {

                    try {

                      setGlobalVariableValue({

                        key: 'showSuggsGlobal',

                        value: false,

                      });

                    } catch (err) {

                      console.error(err);

                    }

                  }}

                >

                  {/* Suggs Icon True */}

                  <>

                    {switchValue2 ? null : (

                      <Icon

                        color={theme.colors['Custom Color_13']}

                        name={'FontAwesome/lightbulb-o'}

                        size={32}

                        style={StyleSheet.applyWidth(

                          { marginBottom: 5 },

                          dimensions.width

                        )}

                      />

                    )}

                  </>

                </Touchable>

              )}

            </>

            {/* Touchable Suggs False */}

            <>

              {Constants['showSuggsGlobal'] ? null : (

                <Touchable

                  onPress={() => {

                    try {

                      setGlobalVariableValue({

                        key: 'showSuggsGlobal',

                        value: true,

                      });

                    } catch (err) {

                      console.error(err);

                    }

                  }}

                >

                  {/* Suggs Icon False */}

                  <>

                    {switchValue2 ? null : (

                      <Icon

                        color={theme.colors['Primary']}

                        name={'FontAwesome/lightbulb-o'}

                        size={32}

                        style={StyleSheet.applyWidth(

                          { marginBottom: 5 },

                          dimensions.width

                        )}

                      />

                    )}

                  </>

                </Touchable>

              )}

            </>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ImageSettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                { marginLeft: 10 },
                dimensions.width
              )}
            >
              {/* Settings Icon */}
              <>
                {switchValue2 ? null : (
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Ionicons/ios-settings-outline'}
                    size={32}
                    style={StyleSheet.applyWidth(
                      { marginBottom: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </Touchable>
          </View>
        </View>
      </View>
      {/* Out of Credits */}

      <>
        {!(Constants["user"]?.image_credits < 1) ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: "center",

                alignSelf: "center",

                borderColor: theme.colors["Error"],

                borderRadius: 8,

                borderWidth: 2,

                height: 150,

                justifyContent: "center",

                marginBottom: 24,

                paddingLeft: "3%",

                paddingRight: "3%",

                width: "90%",
              },

              dimensions.width
            )}
          >
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
                  color: theme.colors["Error"],

                  fontFamily: "RobotoCondensed_400Regular",

                  fontSize: 16,
                }),

                dimensions.width
              )}
            >
              {
                "You are out of image credits. Please add more credits to continue generating images."
              }
            </Text>

            <Button
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)["Button"], {
                  color: theme.colors["Strong"],

                  fontFamily: "SecularOne_400Regular",

                  marginTop: 10,
                }),

                dimensions.width
              )}
              title={"Get Credits"}
            />
          </View>
        )}
      </>
      {/* AI Tool Header Box */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: "center",
            borderBottomWidth: 0.5,
            borderColor: theme.colors["Custom Color_19"],
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: 6,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingRight: { minWidth: Breakpoints.Laptop, value: 120 },
            paddingTop: 2,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: "flex-start" },
            dimensions.width
          )}
        >
          {/* Example Image */}
          <Image
            resizeMode={"cover"}
            source={{
              uri: `${Constants["currentImageTool"]?.image_data.example_url}`,
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)["Image"], {
                borderColor: theme.colors["Primary"],
                borderRadius: 100,
                borderWidth: 1,
                height: 65,
                width: 65,
              }),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 14, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: "row" },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors["Primary"]}
              name={"Ionicons/md-image-outline"}
              size={30}
            />
            {/* Name ~ */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors["Custom Color_2"],
                  fontFamily: "Poppins_500Medium",
                  fontSize: 15,
                  lineHeight: 20,
                  marginLeft: 5,
                  marginTop: 4,
                  opacity: 1,
                  textTransform: "uppercase",
                },
                dimensions.width
              )}
            >
              {Constants["currentImageTool"]?.name}
            </Text>
          </View>
          {/* Message ~ */}
          <Text
            ellipsizeMode={"clip"}
            numberOfLines={3}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors["Custom Color_2"],
                fontFamily: "Poppins_400Regular",
                fontSize: 12,
                lineHeight: 20,
                opacity: 0.5,
              },
              dimensions.width
            )}
          >
            {Constants["currentImageTool"]?.description}
          </Text>
        </View>
      </View>
      {/* View 2 */}
      <View>
        {/* Header description */}
        <Text
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.TextStyles(theme)["Text"], {
              color: theme.colors["Surface"],
              fontFamily: "RobotoCondensed_400Regular",
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
            }),
            dimensions.width
          )}
        >
          {
            "Describe the image you would like to generate and hit submit. The more details you provide, the better results you will get."
          }
        </Text>
      </View>

      <KeyboardAwareScrollView
        contentContainerStyle={StyleSheet.applyWidth(
          {
            flex: 1,
            justifyContent: "space-between",
            marginBottom: 20,
            marginTop: 20,
            paddingBottom: 20,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 120 },
            ],
            paddingTop: 20,
          },
          dimensions.width
        )}
        enableAutomaticScroll={true}
        enableOnAndroid={true}
        extraScrollHeight={195}
        keyboardShouldPersistTaps={"always"}
        showsVerticalScrollIndicator={true}
      >
        <FlatList
          data={currentConvo}
          keyExtractor={(listData) =>
            listData?.id || listData?.uuid || JSON.stringify(listData)
          }
          listKey={"osPZ0tST"}
          numColumns={1}
          renderItem={({ item }) => {
            const listData = item;
            return (
              <>
                <>
                  {!(listData?.role === "assistant") ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: "row",
                          justifyContent: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: "space-between",
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: "flex-start",
                            },
                          ],
                          overflow: "hidden",
                          paddingBottom: 12,
                          marginBottom: 40,
                          width: { minWidth: Breakpoints.Laptop, value: 650 },
                        },
                        dimensions.width
                      )}
                    >
                      <View>
                        <CircleImage size={32} source={`${Constants["currentImageTool"]?.image_data.example_url}`} />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            flex: [
                              { minWidth: Breakpoints.Mobile, value: 1 },
                              { minWidth: Breakpoints.Laptop, value: 1 },
                            ],
                          },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors["Custom Color_2"],
                              borderBottomRightRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 20,
                              paddingBottom: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          <Touchable
                            onPress={() => {
                              try {
                                Keyboard.dismiss();
                                setImageURLforWebView(listData?.content);
                                setShowImageModal(true);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <Image
                              resizeMode={"cover"}
                              source={{ uri: `${listData?.content}` }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ImageStyles(theme)["Image"],
                                  {
                                    height: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 200,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 500,
                                      },
                                    ],
                                    width: [
                                      {
                                        minWidth: Breakpoints.Mobile,
                                        value: 200,
                                      },
                                      {
                                        minWidth: Breakpoints.Laptop,
                                        value: 500,
                                      },
                                    ],
                                  }
                                ),
                                dimensions.width
                              )}
                            />
                          </Touchable>
                        </View>
                        {/* Save Image Button */}
                        <>
                          {dimensions.width >= Breakpoints.Laptop ? null : (
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await addRemoteImageToMediaLibrary(
                                      listData?.content,
                                      encodeFileName(listData?.content)
                                    );
                                    showAlertUtil({
                                      title: "Saved!",
                                      message:
                                        "Your image has been saved to your device.",
                                      buttonText: "Close",
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)["Button"],
                                  { color: theme.colors["Strong"] }
                                ),
                                dimensions.width
                              )}
                              title={"SAVE IMAGE"}
                            />
                          )}
                        </>
                        {/* Save Image Button 2 */}
                        <>
                          {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                            <Button
                              onPress={() => {
                                const handler = async () => {
                                  try {
                                    await downloadImageWithFetch(
                                      listData?.content,
                                      encodeFileName(listData?.content)
                                    );
                                  } catch (err) {
                                    console.error(err);
                                  }
                                };
                                handler();
                              }}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.ButtonStyles(theme)["Button"],
                                  { color: theme.colors["Strong"] }
                                ),
                                dimensions.width
                              )}
                              title={"SAVE IMAGE"}
                            />
                          )}
                        </>
                        {/* View Share Button */}

                        <View
                          style={StyleSheet.applyWidth(
                            {
                              alignItems: "flex-end",

                              justifyContent: "center",
                            },

                            dimensions.width
                          )}
                        >
                          {/* Share Button */}

                          <>
                            {dimensions.width >= Breakpoints.Laptop ? null : (
                              <IconButton
                                icon={"MaterialCommunityIcons/share"}
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      const extractedImageURL =
                                        listData?.content;

                                      await openShareUtil(
                                        `${extractedImageURL}`
                                      );
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };

                                  handler();
                                }}
                                size={32}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    </View>
                  )}
                </>

                <>
                  {!(listData?.role === "user") ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        {
                          flexDirection: "row",
                          justifyContent: "space-between",
                          overflow: "hidden",
                          paddingBottom: 12,
                        },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { flex: 1 },
                          dimensions.width
                        )}
                      >
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: "rgb(153, 171, 214)",
                              borderBottomLeftRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 10,
                              paddingBottom: 15,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {/* Message ~ */}
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: "rgb(18, 18, 18)",
                                fontFamily: "Inter_400Regular",
                                fontSize: 14,
                                lineHeight: 20,
                                opacity: 0.7,
                              },
                              dimensions.width
                            )}
                          >
                            {listData?.content}
                          </Text>
                          {/* Copy Icon Touchable */}
                      <>
                        <Touchable
                          onPress={() => {
                            try {
                              copyToClipboard(listData?.content);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <View
                            style={StyleSheet.applyWidth(
                              { alignItems: "flex-end" },

                              dimensions.width
                            )}
                          >
                            {/* Copy Icon */}

                            <Icon
                              color={theme.colors["Strong"]}
                              name={"Feather/copy"}
                              size={24}
                            />
                          </View>
                        </Touchable>
                      </>
                        </View>
                      </View>
                    </View>
                  )}
                </>
                {/* Error View */}
                <>
                  {!(listData?.role === "error") ? null : (
                    <View
                      style={StyleSheet.applyWidth(
                        { width: "100%" },

                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          {
                            borderColor: theme.colors["Error"],
                            borderRadius: 12,
                            borderWidth: 1,
                            flex: 1,
                            padding: 10,
                          },
                          dimensions.width
                        )}
                      >
                        {/* Error Text */}
                        <Text
                          accessible={true}
                          adjustsFontSizeToFit={true}
                          allowFontScaling={true}
                          style={StyleSheet.applyWidth(
                            StyleSheet.compose(
                              GlobalStyles.TextStyles(theme)["Text"],
                              {
                                color: theme.colors["Error"],
                                fontFamily: "RobotoCondensed_400Regular",
                                fontSize: 16,
                                textAlign: "center",
                              }
                            ),
                            dimensions.width
                          )}
                        >
                          {"Error: "}
                          {listData?.content}
                        </Text>
                      </View>
                    </View>
                  )}
                </>
              </>
            );
          }}
        />
        {/* Suggestions Scroll View */}

        <>
          {!Constants['showSuggsGlobal'] ? null : (
            <ScrollView
              bounces={true}
              contentContainerStyle={StyleSheet.applyWidth(
                {
                  backgroundColor: "rgb(40, 40, 40)",

                  borderColor: theme.colors["Primary"],

                  borderRadius: 18,

                  borderWidth: 1,

                  marginBottom: 40,

                  marginLeft: { minWidth: Breakpoints.Laptop, value: "25%" },

                  marginRight: { minWidth: Breakpoints.Laptop, value: "25%" },
                  marginTop: 12,

                  padding: 8,
                },

                dimensions.width
              )}
              keyboardShouldPersistTaps={"never"}
              nestedScrollEnabled={true}
              showsHorizontalScrollIndicator={true}
              showsVerticalScrollIndicator={true}
            >
              {/* Suggestions View */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    justifyContent: "flex-end",
                    maxHeight: 400,
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "center",

                      flexDirection: "row",

                      justifyContent: "center",
                    },

                    dimensions.width
                  )}
                >
                  <Icon
                    color={theme.colors["Primary"]}
                    name={"FontAwesome/lightbulb-o"}
                    size={24}
                    style={StyleSheet.applyWidth(
                      { marginRight: 6 },

                      dimensions.width
                    )}
                  />

                  {/* Suggestions Header */}

                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)["Text"],

                        {
                          color: theme.colors["Primary"],

                          fontFamily: "SecularOne_400Regular",

                          marginBottom: 8,

                          paddingTop: 8,

                          textAlign: "center",
                        }
                      ),

                      dimensions.width
                    )}
                  >
                    {"Suggestions (Click to run)"}
                  </Text>

                  <IconButton
                    icon={"AntDesign/up"}
                    onPress={() => {
                      try {
                        setGlobalVariableValue({

                          key: 'showSuggsGlobal',

                          value: false,

                        });
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    size={26}
                    style={StyleSheet.applyWidth(
                      { marginLeft: 10 },

                      dimensions.width
                    )}
                  />
                </View>

                {/* Suggestions List */}

                <FlatList
                  data={Constants["currentImageTool"]?.suggested_prompts}
                  keyExtractor={(suggestionsListData) =>
                    suggestionsListData?.id ||
                    suggestionsListData?.uuid ||
                    JSON.stringify(suggestionsListData)
                  }
                  keyboardShouldPersistTaps={"never"}
                  listKey={"MRKTKkRN"}
                  numColumns={1}
                  onEndReachedThreshold={0.5}
                  renderItem={({ item, index }) => {
                    const suggestionsListData = item;

                    return (
                      <>
                        {!suggestionsListData ? null : (
                          <Touchable
                            onPress={() => {
                              const handler = async () => {
                                try {
                                  addUserPromptToConvo(suggestionsListData);
                                  Keyboard.dismiss();
                                  setPromptToSubmit("");
                                  setShowLoadingProgress(true);
                                  loadingProgress(
                                    Variables,
                                    setGlobalVariableValue,
                                    21000
                                  );
                                  const postResp = (
                                    await gAIMMobileBackendGenerateImagePOST.mutateAsync(
                                      {
                                        dynamic_id:
                                          Constants["user"]?.dynamic_id,
                                        guidance_scale:
                                          Constants["currentImageTool"]
                                            ?.image_data.guidance_scale,
                                        height:
                                          Constants["currentImageTool"]
                                            ?.image_data.height,
                                        highContrast:
                                          Constants["currentImageTool"]
                                            ?.image_data.highContrast,
                                        imagePromptWeight:
                                          Constants["currentImageTool"]
                                            ?.image_data.imagePromptWeight,
                                        image_public: true,
                                        init_image_id: null,
                                        init_strength: null,
                                        modelId:
                                          Constants["currentImageTool"]
                                            ?.image_data.modelId,
                                        negative_prompt:
                                          Constants["currentImageTool"]
                                            ?.image_data.negative_prompt,
                                        pre_prompt:
                                          Constants["currentImageTool"]
                                            ?.pre_prompt,
                                        prompt: suggestionsListData,
                                        promptMagic:
                                          Constants["currentImageTool"]
                                            ?.image_data.promptMagic,
                                        promptMagicVersion:
                                          Constants["currentImageTool"]
                                            ?.image_data.promptMagicVersion,
                                        prompt_public: true,
                                        tool_id:
                                          Constants["currentImageTool"]?._id,
                                        width:
                                          Constants["currentImageTool"]
                                            ?.image_data.width,
                                      }
                                    )
                                  )?.json;
                                  const respMessage = postResp?.data;
                                  addMessageToCurrConvo(
                                    Variables,
                                    setGlobalVariableValue,
                                    respMessage
                                  );
                                  setShowLoadingProgress(false);
                                } catch (err) {
                                  console.error(err);
                                }
                              };
                              handler();
                            }}
                            style={StyleSheet.applyWidth(
                              { marginBottom: 5, marginTop: 5 },
                              dimensions.width
                            )}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: "center",

                                  borderColor: theme.colors["Primary"],

                                  borderRadius: 8,

                                  borderWidth: 1,

                                  flexDirection: "row",

                                  justifyContent: "space-between",

                                  paddingBottom: 8,

                                  paddingLeft: 10,

                                  paddingRight: 10,

                                  paddingTop: 8,
                                },

                                dimensions.width
                              )}
                            >
                              {/* Suggestions Text */}

                              <Text
                                accessible={true}
                                adjustsFontSizeToFit={true}
                                allowFontScaling={true}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.TextStyles(theme)["Text"],

                                    {
                                      color: theme.colors["Divider"],

                                      fontFamily: "RobotoCondensed_400Regular",

                                      fontSize: 15,

                                      marginRight: 8,
                                    }
                                  ),

                                  dimensions.width
                                )}
                              >
                                {suggestionsListData}
                              </Text>

                              {/* Suggestions Icon */}

                              <Icon
                                color={theme.colors["Primary"]}
                                name={"Ionicons/send-sharp"}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        )}
                      </>
                    );
                  }}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                />
              </View>
            </ScrollView>
          )}
        </>
        {/* Loading Bar Spacer */}
        <>
          {!showLoadingProgress ? null : (
            <View
              style={StyleSheet.applyWidth(
                { alignItems: "center" },
                dimensions.width
              )}
            >
              <Image
                resizeMode={"cover"}
                source={{
                  uri: "https://gaim.nyc3.cdn.digitaloceanspaces.com/aippLoader.gif",
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)["Image"], {
                    height: 75,
                    width: 75,
                  }),
                  dimensions.width
                )}
              />
              <Spacer bottom={20} top={20} />
            </View>
          )}
        </>
        {/* Message Tray */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: "center",
              backgroundColor: theme.colors["Background"],
              borderBottomWidth: 1,
              borderColor: theme.colors["Custom Color_4"],
              borderLeftWidth: 1,
              borderRadius: 24,
              borderRightWidth: 1,
              borderTopWidth: 1,
              bottom: 0,
              flexDirection: "row",
              height: 52,
              justifyContent: "space-between",
              left: 0,
              marginLeft: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
              marginRight: [
                { minWidth: Breakpoints.Mobile, value: 20 },
                { minWidth: Breakpoints.Laptop, value: 120 },
              ],
              marginTop: 10,
              paddingLeft: 8,
              paddingRight: 8,
              position: "absolute",
              right: 0,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              { flex: 1, justifyContent: "center" },
              dimensions.width
            )}
          >
            {/* Prompt */}
            <TextInput
              autoCorrect={true}
              changeTextDelay={300}
              editable={true}
              enablesReturnKeyAutomatically={true}
              keyboardAppearance={"dark"}
              keyboardType={"default"}
              multiline={true}
              onBlur={() => {
                try {
                  Keyboard.dismiss();
                } catch (err) {
                  console.error(err);
                }
              }}
              onChangeText={(newPromptValue) => {
                try {
                  setPromptToSubmit(newPromptValue);
                } catch (err) {
                  console.error(err);
                }
              }}
              onSubmitEditing={() => {
                try {
                  Keyboard.dismiss();
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={"Type your request"}
              placeholderTextColor={theme.colors["Custom Color_4"]}
              returnKeyType={"default"}
              scrollEnabled={true}
              style={StyleSheet.applyWidth(
                {
                  borderRadius: 8,
                  color: theme.colors["Strong"],
                  fontFamily: "Inter_400Regular",
                  fontSize: 14,
                  marginLeft: 8,
                  paddingBottom: 8,
                  paddingLeft: 8,
                  paddingRight: 8,
                  paddingTop: 8,
                },
                dimensions.width
              )}
              value={promptToSubmit}
            />
          </View>
          <>
            {showLoadingProgress ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: "center",
                    height: 48,
                    justifyContent: "center",
                    width: 48,
                  },
                  dimensions.width
                )}
              >
                <>
                  {Constants["user"]?.image_credits < 1 ? null : (
                    <Touchable
                      onPress={() => {
                        const handler = async () => {
                          try {
                            addUserPromptToConvo(promptToSubmit);
                            Keyboard.dismiss();
                            setPromptToSubmit("");
                            setShowLoadingProgress(true);
                            loadingProgress(
                              Variables,
                              setGlobalVariableValue,
                              21000
                            );
                            const postResp = (
                              await gAIMMobileBackendGenerateImagePOST.mutateAsync(
                                {
                                  dynamic_id: Constants["user"]?.dynamic_id,
                                  guidance_scale:
                                    Constants["currentImageTool"]?.image_data
                                      .guidance_scale,
                                  height:
                                    Constants["currentImageTool"]?.image_data
                                      .height,
                                  highContrast:
                                    Constants["currentImageTool"]?.image_data
                                      .highContrast,
                                  imagePromptWeight:
                                    Constants["currentImageTool"]?.image_data
                                      .imagePromptWeight,
                                  image_public: true,
                                  init_image_id: null,
                                  init_strength: null,
                                  modelId:
                                    Constants["currentImageTool"]?.image_data
                                      .modelId,
                                  negative_prompt:
                                    Constants["currentImageTool"]?.image_data
                                      .negative_prompt,
                                  pre_prompt:
                                    Constants["currentImageTool"]?.pre_prompt,
                                  prompt: promptToSubmit,
                                  promptMagic:
                                    Constants["currentImageTool"]?.image_data
                                      .promptMagic,
                                  promptMagicVersion:
                                    Constants["currentImageTool"]?.image_data
                                      .promptMagicVersion,
                                  prompt_public: true,
                                  tool_id: Constants["currentImageTool"]?._id,
                                  width:
                                    Constants["currentImageTool"]?.image_data
                                      .width,
                                }
                              )
                            )?.json;
                            if (postResp?.success === true) {
                              const respMessage = postResp?.data;
                              addMessageToCurrConvo(
                                Variables,
                                setGlobalVariableValue,
                                respMessage
                              );
                            } else {
                              const respError = postResp?.message;
                              addErrorToCurrConvo(
                                Variables,
                                setGlobalVariableValue,
                                respError
                              );
                            }
                            setShowLoadingProgress(false);
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                    >
                      <Icon
                        color={theme.colors["Strong"]}
                        name={"MaterialCommunityIcons/send-circle"}
                        size={36}
                      />
                    </Touchable>
                  )}
                </>
              </View>
            )}
          </>
        </View>
      </KeyboardAwareScrollView>
      {/* View Image Modal */}
      <>
        {!showImageModal ? null : (
          <Modal animationType={"fade"} presentationStyle={"fullScreen"}>
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: "rgb(18, 18, 18)",
                  height: "100%",
                  paddingLeft: "5%",
                  paddingRight: "5%",
                  width: "100%",
                  flex: 1,
                },
                dimensions.width
              )}
            >
              <ScrollView
                bounces={true}
                contentContainerStyle={StyleSheet.applyWidth(
                  {
                    marginBottom: {
                      minWidth: Breakpoints.Laptop,
                      value: 20,
                      alignItems: "center",
                      flex: 1,
                    },
                  },
                  dimensions.width
                )}
                keyboardShouldPersistTaps={"never"}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              >
                <Button
                  onPress={() => {
                    try {
                      setShowImageModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)["Button"],
                      {
                        color: theme.colors["Strong"],
                        marginLeft: "20%",
                        marginRight: "20%",
                        marginTop: [
                          { minWidth: Breakpoints.Mobile, value: "15%" },
                          { minWidth: Breakpoints.Laptop, value: 25 },
                        ],
                      }
                    ),
                    dimensions.width
                  )}
                  title={"CLOSE IMAGE"}
                />
                <Image
                  resizeMode={"contain"}
                  source={{ uri: `${imageURLforWebView}` }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)["Image"],

                      {
                        width: "80%",
                        minHeight: [
                          { minWidth: Breakpoints.Mobile, value: 300 },
                          { minWidth: Breakpoints.Laptop, value: 500 },
                        ],
                        maxWidth: [
                          { minWidth: Breakpoints.Mobile, value: 300 },
                          { minWidth: Breakpoints.Laptop, value: "80%" },
                        ],
                        marginTop: 20,
                        marginLeft: "10%",
                        marginRight: "10%",
                        marginBottom: 20,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* Save Image Button */}
                <>
                  {dimensions.width >= Breakpoints.Laptop ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await addRemoteImageToMediaLibrary(
                              imageURLforWebView,
                              encodeFileName(imageURLforWebView)
                            );
                            showAlertUtil({
                              title: "Saved!",
                              message:
                                "Your image has been saved to your device.",
                              buttonText: "Close",
                            });
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)["Button"],
                          { color: theme.colors["Strong"] }
                        ),
                        dimensions.width
                      )}
                      title={"SAVE IMAGE"}
                    />
                  )}
                </>
                {/* Save Image Button 2 */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <Button
                      onPress={() => {
                        const handler = async () => {
                          try {
                            await downloadImageWithFetch(
                              imageURLforWebView,
                              encodeFileName(imageURLforWebView)
                            );
                          } catch (err) {
                            console.error(err);
                          }
                        };
                        handler();
                      }}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.ButtonStyles(theme)["Button"],
                          {
                            color: {
                              minWidth: Breakpoints.Laptop,
                              value: theme.colors["Strong"],
                            },
                            marginLeft: {
                              minWidth: Breakpoints.Laptop,
                              value: "20%",
                            },
                            marginRight: {
                              minWidth: Breakpoints.Laptop,
                              value: "20%",
                            },
                          }
                        ),
                        dimensions.width
                      )}
                      title={"SAVE IMAGE"}
                    />
                  )}
                </>
                {/* View Share Button */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: "flex-end",
                      justifyContent: "center",
                    },
                    dimensions.width
                  )}
                >
                  {/* Share Button */}
                  <>
                    {dimensions.width >= Breakpoints.Laptop ? null : (
                      <IconButton
                        icon={"MaterialCommunityIcons/share"}
                        onPress={() => {
                          const handler = async () => {
                            try {
                              const extractedImageURL = imageURLforWebView;
                              await openShareUtil(`${extractedImageURL}`);
                            } catch (err) {
                              console.error(err);
                            }
                          };
                          handler();
                        }}
                        size={32}
                      />
                    )}
                  </>
                </View>

                {/*<WebView
                cacheEnabled={true}
                javaScriptEnabled={true}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                source={{ uri: `${imageURLforWebView}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.WebViewStyles(theme)['Web View'],
                    {
                      flex: 1,
                      height: '90%',
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: '10%' },
                        { minWidth: Breakpoints.Laptop, value: 20 },
                      ],
                    }
                  ),
                  dimensions.width
                )}
                  />*/}
              </ScrollView>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ImageGeneratorScreen);
