import React from 'react';

// Updates the loadingProgress variable for use in the loading bar
const loadingProgress = (Variables, setGlobalVariableValue, duration) => {
  setGlobalVariableValue({ key: 'loadingProgress', value: 0 });

  // Calculate the time interval for each increment
  const totalIncrements = 9 + 1; // 9 increments of 10 + 1 increment of 5
  const intervalDuration = duration / totalIncrements;

  // Use a closure to encapsulate the logic and state
  (function incrementProgress(currentValue) {
    if (currentValue < 90) {
      setTimeout(() => {
        currentValue += 10;
        setGlobalVariableValue({ key: 'loadingProgress', value: currentValue });
        incrementProgress(currentValue);
      }, intervalDuration);
    } else if (currentValue === 90) {
      setTimeout(() => {
        currentValue += 5;
        setGlobalVariableValue({ key: 'loadingProgress', value: currentValue });
      }, intervalDuration);
    }
  })(0);
};

export default loadingProgress;
