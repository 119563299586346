import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Circle,
  Icon,
  Picker,
  ScreenContainer,
  Switch,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Text, View, useWindowDimensions } from 'react-native';

const ImageSettingsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendUpdateUserImageSettingsPUT =
    GAIMMobileBackendApi.useUpdateUserImageSettingsPUT();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setPIEnabled(Constants['user']?.image_prefs.public_images_enabled);
      setPPEnabled(Constants['user']?.image_prefs.public_prompts_enabled);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const [PIEnabled, setPIEnabled] = React.useState(false);
  const [PIValue, setPIValue] = React.useState('');
  const [PPEnabled, setPPEnabled] = React.useState(false);
  const [PREnabled, setPREnabled] = React.useState(false);
  const [REEnabled, setREEnabled] = React.useState(false);
  const [REValue, setREValue] = React.useState('Default');
  const [pickerValue, setPickerValue] = React.useState('');
  const [showSaveBtn, setShowSaveBtn] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(40, 40, 40)',
        marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
        marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
       },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(40, 40, 40)',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'center',
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              left: 16,
              position: 'absolute',
              top: 0,
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_2']}
              size={48}
              style={StyleSheet.applyWidth(
                { backgroundColor: theme.colors['Primary'] },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'Ionicons/arrow-back-sharp'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Primary'] },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'IMAGE SETTINGS'}
        </Text>
      </View>
      {/* Security Settings */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 1,
            borderColor: theme.colors['Custom Color_6'],
            borderLeftWidth: 1,
            borderRadius: 12,
            borderRightWidth: 1,
            borderTopWidth: 1,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            paddingBottom: 6,
            paddingLeft: 12,
            paddingRight: 12,
          },
          dimensions.width
        )}
      >
        {/* Public Images */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              borderBottomWidth: 1,
              borderColor: theme.colors['Custom Color_6'],
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'Public Images'}
            </Text>
            <Switch
              activeTrackColor={theme.colors['Primary']}
              inactiveTrackColor={theme.colors['Custom Color_7']}
              onValueChange={newSwitchValue => {
                const handler = async () => {
                  try {
                    setPIEnabled(newSwitchValue);
                    (
                      await gAIMMobileBackendUpdateUserImageSettingsPUT.mutateAsync(
                        {
                          PIEnabled: newSwitchValue,
                          PPEnabled: PPEnabled,
                          dynamic_id: Constants['user']?.dynamic_id,
                        }
                      )
                    )?.json;
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              value={PIEnabled}
            />
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', paddingBottom: 3 },
              dimensions.width
            )}
          >
            {/* Description Text */}
            <Text
              accessible={true}
              allowFontScaling={true}
              selectable={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                  fontFamily: 'RobotoCondensed_400Regular',
                }),
                dimensions.width
              )}
            >
              {
                "Public images are shared on the Public Art Feed, your followers' feeds, and on your public profile page. Turn this feature off if you want your images to be private. This feature is specific to each image. For example, if you generate two public images, and then you change this option and generate two private images, the first two images will be public while the last two images will be private."
              }
            </Text>
          </View>
        </View>
        {/* Public Prompts */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              borderBottomWidth: 1,
              borderColor: theme.colors['Custom Color_6'],
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'Public Prompts'}
            </Text>
            <Switch
              activeTrackColor={theme.colors['Primary']}
              inactiveTrackColor={theme.colors['Custom Color_7']}
              onValueChange={newSwitchValue => {
                const handler = async () => {
                  try {
                    setPPEnabled(newSwitchValue);
                    (
                      await gAIMMobileBackendUpdateUserImageSettingsPUT.mutateAsync(
                        {
                          PIEnabled: PIEnabled,
                          PPEnabled: newSwitchValue,
                          dynamic_id: Constants['user']?.dynamic_id,
                        }
                      )
                    )?.json;
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              value={PPEnabled}
            />
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start' },
              dimensions.width
            )}
          >
            {/* Description Text */}
            <Text
              accessible={true}
              allowFontScaling={true}
              selectable={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                  fontFamily: 'RobotoCondensed_400Regular',
                }),
                dimensions.width
              )}
            >
              {
                'Public prompts are visible to your followers in their Following Feed. Users who do not follow you will not see your prompt regardless of this setting. This feature is specific to each image. For example, if you generate two images with public prompts, and then you change this option and generate two images with private prompts, the first two images will display the prompt while the last two images will not display the prompt.'
              }
            </Text>
          </View>
        </View>
      </View>
      {/* Save Button View */}
      <>
        {!showSaveBtn ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                alignSelf: 'center',
                marginTop: 35,
                width: '70%',
              },
              dimensions.width
            )}
          >
            {/* Save Button */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const updatedUser = await (async () => {
                      if (showSaveBtn) {
                        return (
                          await gAIMMobileBackendUpdateUserImageSettingsPUT.mutateAsync(
                            {
                              PIEnabled: PIEnabled,
                              PPEnabled: PPEnabled,
                              dynamic_id: Constants['user']?.dynamic_id,
                            }
                          )
                        )?.json;
                      }
                    })();
                    setGlobalVariableValue({
                      key: 'user',
                      value: updatedUser?.data,
                    });

                    showAlertUtil({
                      title: 'Settings Updated!',
                      message:
                        'Your chat settings have been successfully updated.',
                      buttonText: 'OK',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  borderRadius: 100,
                  color: theme.colors['Strong'],
                  fontFamily: 'SecularOne_400Regular',
                  textAlign: 'auto',
                }),
                dimensions.width
              )}
              title={'SAVE CHANGES'}
            />
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ImageSettingsScreen);
