import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Circle,
  Icon,
  Picker,
  ScreenContainer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Keyboard, Text, View, useWindowDimensions } from 'react-native';

const ChatSettingsScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendUpdateUserChatSettingsPUT =
    GAIMMobileBackendApi.useUpdateUserChatSettingsPUT();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    try {
      if (!isFocused) {
        return;
      }
      setPIEnabled(
        Constants['user']?.chat_prefs.persistent_instructions_enabled
      );
      setREEnabled(Constants['user']?.chat_prefs.response_education_enabled);
      setPIValue(Constants['user']?.chat_prefs.persistent_instructions);
      setREValue(Constants['user']?.chat_prefs.response_education);
      setPREnabled(Constants['user']?.chat_prefs.premium_responses);
    } catch (err) {
      console.error(err);
    }
  }, [isFocused]);
  const [PIEnabled, setPIEnabled] = React.useState(false);
  const [PIValue, setPIValue] = React.useState('');
  const [PREnabled, setPREnabled] = React.useState(false);
  const [REEnabled, setREEnabled] = React.useState(false);
  const [REValue, setREValue] = React.useState('Default');
  const [pickerValue, setPickerValue] = React.useState('');
  const [showSaveBtn, setShowSaveBtn] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textAreaValue, setTextAreaValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(40, 40, 40)',
        marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
        marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
       },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(40, 40, 40)',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'center',
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              left: 16,
              position: 'absolute',
              top: 0,
              width: 48,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_2']}
              size={48}
              style={StyleSheet.applyWidth(
                { backgroundColor: theme.colors['Primary'] },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'Ionicons/arrow-back-sharp'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Primary'] },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'CHAT SETTINGS'}
        </Text>
      </View>
      {/* Security Settings */}
      <View
        style={StyleSheet.applyWidth(
          {
            borderBottomWidth: 1,
            borderColor: theme.colors['Custom Color_6'],
            borderLeftWidth: 1,
            borderRadius: 12,
            borderRightWidth: 1,
            borderTopWidth: 1,
            marginLeft: 20,
            marginRight: 20,
            marginTop: 30,
            paddingLeft: 12,
            paddingRight: 12,
          },
          dimensions.width
        )}
      >
        {/* Premium Responses */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              borderBottomWidth: 1,
              borderColor: theme.colors['Custom Color_6'],
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'Premium Responses'}
            </Text>
            <>
              {!(
                Constants['user']?.subscription_id !==
                '6451434ac0267c72e2b0ddc9' && Constants['user']?.subscription_id !==
                '65b2e6b45c3d2a64b8ab8ea4' && Constants['user']?.subscription_id !==
                '65b2e8415c3d2a64b8ab8ea7'
              ) ? null : (
                <Switch
                  activeTrackColor={theme.colors['Primary']}
                  inactiveTrackColor={theme.colors['Custom Color_7']}
                  onValueChange={newSwitchValue => {
                    try {
                      setPREnabled(newSwitchValue);
                      setShowSaveBtn(true);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  value={PREnabled}
                />
              )}
            </>
            {/* Upgrade Touchable */}
            <>
              {!(
                Constants['user']?.subscription_id ===
                '6451434ac0267c72e2b0ddc9' || Constants['user']?.subscription_id ===
                '65b2e6b45c3d2a64b8ab8ea4' || Constants['user']?.subscription_id ===
                '65b2e8415c3d2a64b8ab8ea7'
              ) ? null : (
                <Touchable
                  onPress={() => {
                    try {
                      navigation.navigate('SubscribeScreen');
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                >
                  {/* Upgrade Text */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'UPGRADE'}
                  </Text>
                </Touchable>
              )}
            </>
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start', paddingBottom: 3 },
              dimensions.width
            )}
          >
            {/* Description Text */}
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                  fontFamily: 'RobotoCondensed_400Regular',
                }),
                dimensions.width
              )}
            >
              {
                'Premium responses use an AI model that produces significantly higher quality results than standard responses. However, premium responses require more credits than standard responses. You can switch your selection at any time.'
              }
            </Text>
          </View>
        </View>
        {/* Persistent Instructions */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              borderBottomWidth: 1,
              borderColor: theme.colors['Custom Color_6'],
              flexDirection: 'column',
              justifyContent: 'space-between',
              marginTop: 12,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'Persistent Instructions'}
            </Text>
            <Switch
              activeTrackColor={theme.colors['Primary']}
              inactiveTrackColor={theme.colors['Custom Color_7']}
              onValueChange={newSwitchValue => {
                try {
                  setPIEnabled(newSwitchValue);
                  setShowSaveBtn(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              value={PIEnabled}
            />
          </View>
          {/* View 2 */}
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'flex-start' },
              dimensions.width
            )}
          >
            {/* Description Text */}
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                  fontFamily: 'RobotoCondensed_400Regular',
                }),
                dimensions.width
              )}
            >
              {
                'Persistent instructions are details of any type that you want the AI tool to remember throughout all of your conversations. (ex. your business info, your preferred response format, etc.)'
              }
            </Text>
          </View>
          {/* View 3 */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'flex-end',
                alignSelf: 'auto',
                marginTop: 10,
                width: '100%',
              },
              dimensions.width
            )}
          >
            {/* PI Text Area */}
            <TextInput
              allowFontScaling={true}
              autoCorrect={true}
              changeTextDelay={500}
              multiline={true}
              numberOfLines={4}
              onBlur={() => {
                try {
                  Keyboard.dismiss();
                } catch (err) {
                  console.error(err);
                }
              }}
              onChangeText={newPITextAreaValue => {
                try {
                  setPIValue(newPITextAreaValue);
                  setShowSaveBtn(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              placeholder={
                'Type or paste your persistent instructions here. Tap the SAVE CHANGES button to save your instructions.'
              }
              placeholderTextColor={theme.colors['Medium']}
              returnKeyType={'done'}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(
                  GlobalStyles.TextInputStyles(theme)['Text Area'],
                  {
                    backgroundColor: theme.colors['Surface'],
                    marginBottom: 5,
                    width: '100%',
                  }
                ),
                dimensions.width
              )}
              textAlignVertical={'top'}
              value={PIValue}
            />
          </View>
        </View>
        {/* Response Education Level */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'flex-start',
              borderBottomWidth: 1,
              borderColor: theme.colors['Custom Color_6'],
              flexDirection: 'column',
              justifyContent: 'space-between',
              width: '100%',
            },
            dimensions.width
          )}
        >
          {/* Response Education Level */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginBottom: 3,
                paddingTop: 10,
                width: '100%',
              },
              dimensions.width
            )}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 16,
                },
                dimensions.width
              )}
            >
              {'Response Education Level'}
            </Text>
            <Switch
              activeTrackColor={theme.colors['Primary']}
              inactiveTrackColor={theme.colors['Custom Color_7']}
              onValueChange={newSwitchValue => {
                try {
                  setREEnabled(newSwitchValue);
                  setShowSaveBtn(true);
                } catch (err) {
                  console.error(err);
                }
              }}
              value={REEnabled}
            />
          </View>
          {/* Description Text 2 */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                color: theme.colors['Light'],
                fontFamily: 'RobotoCondensed_400Regular',
                marginBottom: 10,
              }),
              dimensions.width
            )}
          >
            {
              'Enable this feature and select an option to control the complexity of explanations and instructions in your responses received.'
            }
          </Text>
          <Picker
            autoDismissKeyboard={true}
            dropDownBackgroundColor={theme.colors.background}
            dropDownBorderColor={theme.colors.divider}
            dropDownBorderRadius={8}
            dropDownBorderWidth={1}
            dropDownTextColor={theme.colors.strong}
            iconSize={24}
            leftIconMode={'inset'}
            mode={'native'}
            onValueChange={newPickerValue => {
              try {
                setREValue(newPickerValue);
                setShowSaveBtn(true);
              } catch (err) {
                console.error(err);
              }
            }}
            options={[
              'Default',
              'Elementary',
              'High School',
              'College',
              'Expert',
            ]}
            placeholder={'Select an option'}
            selectedIconColor={theme.colors.strong}
            selectedIconName={'Feather/check'}
            selectedIconSize={20}
            style={StyleSheet.applyWidth(
              {
                fontFamily: 'SecularOne_400Regular',
                marginBottom: 5,
                width: '100%',
              },
              dimensions.width
            )}
            type={'solid'}
            value={REValue}
          />
        </View>
      </View>
      {/* Save Button View */}
      <>
        {!showSaveBtn ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                alignSelf: 'center',
                marginTop: 35,
                width: '70%',
              },
              dimensions.width
            )}
          >
            {/* Save Button */}
            <Button
              onPress={() => {
                const handler = async () => {
                  try {
                    const updatedUser = await (async () => {
                      if (showSaveBtn) {
                        return (
                          await gAIMMobileBackendUpdateUserChatSettingsPUT.mutateAsync(
                            {
                              PIEnabled: PIEnabled,
                              PIValue: PIValue,
                              PREnabled: PREnabled,
                              REEnabled: REEnabled,
                              REValue: REValue,
                              dynamic_id: Constants['user']?.dynamic_id,
                            }
                          )
                        )?.json;
                      }
                    })();
                    setGlobalVariableValue({
                      key: 'user',
                      value: updatedUser?.data,
                    });

                    showAlertUtil({
                      title: 'Settings Updated!',
                      message:
                        'Your chat settings have been successfully updated.',
                      buttonText: 'OK',
                    });
                  } catch (err) {
                    console.error(err);
                  }
                };
                handler();
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
                  borderRadius: 100,
                  color: theme.colors['Strong'],
                  fontFamily: 'SecularOne_400Regular',
                  textAlign: 'auto',
                }),
                dimensions.width
              )}
              title={'SAVE CHANGES'}
            />
          </View>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(ChatSettingsScreen);
