import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { Image, Modal, Text, View, useWindowDimensions } from 'react-native';

const DeleteAccountScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const gAIMMobileBackendDeleteUserAccountDELETE =
    GAIMMobileBackendApi.useDeleteUserAccountDELETE();

  const [numberInputValue, setNumberInputValue] = React.useState('');
  const [showDelModal, setShowDelModal] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Divider'] },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          { justifyContent: 'center', paddingLeft: 5 },
          dimensions.width
        )}
      >
        {/* Back btn */}
        <Touchable
          onPress={() => {
            try {
              navigation.goBack();
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                height: 44,
                justifyContent: 'center',
                width: 44,
              },
              dimensions.width
            )}
          >
            {/* Profile Menu Button */}
            <Image
              resizeMode={'cover'}
              source={{ uri: `${Constants['user']?.pic}` }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                  borderColor: theme.colors['Primary'],
                  borderRadius: 100,
                  borderWidth: 1,
                  height: 35,
                  marginLeft: 10,
                  width: 35,
                }),
                dimensions.width
              )}
            />
          </View>
        </Touchable>
      </View>
      {/* Title */}
      <Text
        style={StyleSheet.applyWidth(
          {
            color: theme.colors.strong,
            fontFamily: 'Inter_500Medium',
            fontSize: 25,
            marginLeft: 30,
            marginTop: 15,
            opacity: 0.8,
          },
          dimensions.width
        )}
      >
        {'Delete Your Account'}
      </Text>
      {/* sub title */}
      <Text
        style={StyleSheet.applyWidth(
          {
            color: 'rgb(0,0,0)',
            fontFamily: 'Inter_400Regular',
            fontSize: 15,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 15,
            opacity: 0.4,
          },
          dimensions.width
        )}
      >
        {
          'To delete your GAIM AI account, tap the DELETE MY ACCOUNT button below.\n\n*NOTE - This will delete your entire GAIM AI account, including all profile information, generated chats, generated images, and any other data or assets connected to your GAIM AI account.\n\n*NOTE - If you delete your GAIM AI account, all GAIM AI credits attributed to your account will be deleted and cannot be recovered.\n\nIf you are positive that you want to delete your GAIM AI account along with all data in your account, tap the button below.'
        }
      </Text>

      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center', marginTop: 56 },
          dimensions.width
        )}
      >
        <Icon
          color={theme.colors['Error']}
          name={'AntDesign/deleteuser'}
          size={144}
        />
      </View>
      {/* Continue enabled */}
      <Button
        onPress={() => {
          try {
            setShowDelModal(true);
          } catch (err) {
            console.error(err);
          }
        }}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Error'],
            borderRadius: 12,
            color: theme.colors['Custom Color'],
            fontFamily: 'Inter_500Medium',
            fontSize: 16,
            height: 52,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 45,
            textAlign: 'center',
          },
          dimensions.width
        )}
        title={'DELETE MY ACCOUNT'}
      />
      {/* Confirm Delete Convo Modal */}
      <>
        {!showDelModal ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(19, 17, 17, 0.8)',
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: 'rgb(18, 18, 18)',
                    borderColor: theme.colors['Error'],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: 'space-around',
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'Are you sure you want to delete your entire GAIM AI account along with all of your GAIM AI data?\n\nTHIS CANNOT BE UNDONE!'
                  }
                </Text>

                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                      width: '85%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Delete Account Button */}
                  <Button
                    icon={'Feather/trash-2'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          const delResp = (
                            await gAIMMobileBackendDeleteUserAccountDELETE.mutateAsync(
                              { dynamic_id: Constants['user']?.dynamic_id }
                            )
                          )?.json;

                          showAlertUtil({
                            title: 'Account Deleted',
                            message:
                              'Your GAIM AI account has been completely deleted.',
                            buttonText: 'Close',
                          });

                          navigation.navigate('LoginScreen');
                          setGlobalVariableValue({
                            key: 'user',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'dynamic_id',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'stytch_session_token',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'user_id',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'Username',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'Name',
                            value: null,
                          });
                          setGlobalVariableValue({
                            key: 'UserPic',
                            value: null,
                          });
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Error'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Error'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'DELETE!'}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowDelModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginTop: 16,
                          width: '100%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CANCEL'}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(DeleteAccountScreen);
