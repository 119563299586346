import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import countObjects from '../global-functions/countObjects';
import isLiked from '../global-functions/isLiked';
import scaleDownImage from '../global-functions/scaleDownImage';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Circle,
  Icon,
  IconButton,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const PublicProfileScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;

  const gAIMMobileBackendFollowAUserPOST =
    GAIMMobileBackendApi.useFollowAUserPOST();
  const gAIMMobileBackendBlockUnblockUserPOST =
    GAIMMobileBackendApi.useBlockUnblockUserPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const respPublicProfile = (
          await GAIMMobileBackendApi.getPublicProfileByUsernameGET(Constants, {
            username: props.route?.params?.userName ?? 'bradyllewis',
          })
        )?.json;
        setPublicProfile(respPublicProfile?.data);
        setIsFollowing(
          isLiked(respPublicProfile?.data.followers, Constants['user']?._id)
        );
        setIsBlocked(
          isLiked(respPublicProfile?.data.blocked_users, Constants['user']?._id)
        );
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const [SelectedTopTab, setSelectedTopTab] = React.useState('images');
  const [followerCount, setFollowerCount] = React.useState(0);
  const [followingCount, setFollowingCount] = React.useState(0);
  const [imageCount, setImageCount] = React.useState(0);
  const [isBlocked, setIsBlocked] = React.useState(false);
  const [isFollowing, setIsFollowing] = React.useState(false);
  const [publicProfile, setPublicProfile] = React.useState({});
  const [selectedImageHeight, setSelectedImageHeight] = React.useState(300);
  const [selectedImageURL, setSelectedImageURL] = React.useState('');
  const [selectedImageWidth, setSelectedImageWidth] = React.useState(300);
  const [showDeleteConf, setShowDeleteConf] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [tabSelected, setTabSelected] = React.useState('images');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', height: '100%',
        marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
        marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
       },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(18, 18, 18)',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('MenuScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Profile Menu Button */}
              <Image
                resizeMode={'cover'}
                source={{ uri: `${Constants['user']?.pic}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 1,
                    height: 35,
                    marginLeft: 10,
                    width: 35,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'Inter_600SemiBold',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'Profile'}
        </Text>
        {/* Notifications */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          ></View>
        </View>
      </View>

      <ScrollView
        bounces={true}
        contentContainerStyle={StyleSheet.applyWidth(
          { paddingBottom: 10 },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
      >
        {/* User Details */}
        <View
          style={StyleSheet.applyWidth(
            { marginTop: 15, paddingLeft: 20, paddingRight: 20 },
            dimensions.width
          )}
        >
          <>
            {!((props.route?.params?.backButton ?? 'no') === true) ? null : (
              <IconButton
                icon={'Ionicons/arrow-back-outline'}
                onPress={() => {
                  try {
                    navigation.goBack();
                  } catch (err) {
                    console.error(err);
                  }
                }}
                size={32}
                style={StyleSheet.applyWidth(
                  { marginRight: 20 },
                  dimensions.width
                )}
              />
            )}
          </>
          {/* User Details */}
          <>
            {!publicProfile ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    height: 250,
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      alignSelf: 'auto',
                      height: '100%',
                      justifyContent: 'center',
                    },
                    dimensions.width
                  )}
                >
                  <Circle
                    bgColor={theme.colors.light}
                    size={100}
                    style={StyleSheet.applyWidth(
                      { backgroundColor: theme.colors['Medium'] },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{ uri: `${publicProfile?.pic}` }}
                      style={StyleSheet.applyWidth(
                        {
                          borderColor: theme.colors['Primary'],
                          borderRadius: 100,
                          borderWidth: 1,
                          height: 100,
                          width: 100,
                        },
                        dimensions.width
                      )}
                    />
                  </Circle>
                  {/* Name */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom Color'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 18,
                      },
                      dimensions.width
                    )}
                  >
                    {publicProfile?.username}
                  </Text>
                  {/* Role */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom Color_30'],
                        fontFamily: 'Poppins_400Regular',
                      },
                      dimensions.width
                    )}
                  >
                    {publicProfile?.location}
                  </Text>
                  {/* View 3 */}
                  <>
                    {!(
                      (props.route?.params?.userName ?? 'bradyllewis') !==
                      Constants['user']?.username
                    ) ? null : (
                      <View>
                        <>
                          {isBlocked ? null : (
                            <View>
                              {/* Follow Button */}
                              <>
                                {isFollowing ? null : (
                                  <Button
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          setIsFollowing(true);
                                          (
                                            await gAIMMobileBackendFollowAUserPOST.mutateAsync(
                                              {
                                                action: 'follow',
                                                user_id: Constants['user']?._id,
                                                username:
                                                  props.route?.params
                                                    ?.userName ?? 'bradyllewis',
                                              }
                                            )
                                          )?.json;
                                          const respPubProfile = (
                                            await GAIMMobileBackendApi.getPublicProfileByUsernameGET(
                                              Constants,
                                              {
                                                username:
                                                  props.route?.params
                                                    ?.userName ?? 'bradyllewis',
                                              }
                                            )
                                          )?.json;
                                          setPublicProfile(
                                            respPubProfile?.data
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ],
                                        {
                                          borderRadius: 100,
                                          color: theme.colors['Strong'],
                                          fontFamily: 'SecularOne_400Regular',
                                          marginTop: 5,
                                          textTransform: 'uppercase',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'FOLLOW'}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* View 2 */}
                        <>
                          {isBlocked ? null : (
                            <View>
                              {/* Unfollow Button */}
                              <>
                                {!isFollowing ? null : (
                                  <Button
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          (
                                            await gAIMMobileBackendFollowAUserPOST.mutateAsync(
                                              {
                                                action: 'unfollow',
                                                user_id: Constants['user']?._id,
                                                username:
                                                  props.route?.params
                                                    ?.userName ?? 'bradyllewis',
                                              }
                                            )
                                          )?.json;
                                          const respPubProfile = (
                                            await GAIMMobileBackendApi.getPublicProfileByUsernameGET(
                                              Constants,
                                              {
                                                username:
                                                  props.route?.params
                                                    ?.userName ?? 'bradyllewis',
                                              }
                                            )
                                          )?.json;
                                          setPublicProfile(
                                            respPubProfile?.data
                                          );
                                          setIsFollowing(false);
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    style={StyleSheet.applyWidth(
                                      StyleSheet.compose(
                                        GlobalStyles.ButtonStyles(theme)[
                                          'Button'
                                        ],
                                        {
                                          backgroundColor: 'rgba(0, 0, 0, 0)',
                                          borderColor: theme.colors['Primary'],
                                          borderRadius: 100,
                                          borderWidth: 2,
                                          color: theme.colors['Primary'],
                                          fontFamily: 'SecularOne_400Regular',
                                          marginTop: 5,
                                          textTransform: 'uppercase',
                                        }
                                      ),
                                      dimensions.width
                                    )}
                                    title={'UNFOLLOW'}
                                  />
                                )}
                              </>
                            </View>
                          )}
                        </>
                        {/* View 3 */}
                        <View>
                          {/* Block Button */}
                          <>
                            {isBlocked ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      setIsBlocked(true);
                                      (
                                        await gAIMMobileBackendBlockUnblockUserPOST.mutateAsync(
                                          {
                                            action: 'block',
                                            user_id: Constants['user']?._id,
                                            username:
                                              props.route?.params?.userName ??
                                              'bradyllewis',
                                          }
                                        )
                                      )?.json;
                                      const respPubProfile = (
                                        await GAIMMobileBackendApi.getPublicProfileByUsernameGET(
                                          Constants,
                                          {
                                            username:
                                              props.route?.params?.userName ??
                                              'bradyllewis',
                                          }
                                        )
                                      )?.json;
                                      setPublicProfile(respPubProfile?.data);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor: 'rgba(0, 0, 0, 0)',
                                      borderColor: theme.colors['Primary'],
                                      borderRadius: 100,
                                      color: theme.colors['Primary'],
                                      fontFamily: 'SecularOne_400Regular',
                                      marginTop: 5,
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'BLOCK USER'}
                              />
                            )}
                          </>
                        </View>
                        {/* View 4 */}
                        <View>
                          {/* Unblock Button */}
                          <>
                            {!isBlocked ? null : (
                              <Button
                                onPress={() => {
                                  const handler = async () => {
                                    try {
                                      (
                                        await gAIMMobileBackendBlockUnblockUserPOST.mutateAsync(
                                          {
                                            action: 'unblock',
                                            user_id: Constants['user']?._id,
                                            username:
                                              props.route?.params?.userName ??
                                              'bradyllewis',
                                          }
                                        )
                                      )?.json;
                                      const respPubProfile = (
                                        await GAIMMobileBackendApi.getPublicProfileByUsernameGET(
                                          Constants,
                                          {
                                            username:
                                              props.route?.params?.userName ??
                                              'bradyllewis',
                                          }
                                        )
                                      )?.json;
                                      setPublicProfile(respPubProfile?.data);
                                      setIsBlocked(false);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  };
                                  handler();
                                }}
                                style={StyleSheet.applyWidth(
                                  StyleSheet.compose(
                                    GlobalStyles.ButtonStyles(theme)['Button'],
                                    {
                                      backgroundColor: theme.colors['Primary'],
                                      borderColor: theme.colors['Primary'],
                                      borderRadius: 100,
                                      borderWidth: 2,
                                      color: theme.colors['Strong'],
                                      fontFamily: 'SecularOne_400Regular',
                                      marginTop: 5,
                                      textTransform: 'uppercase',
                                    }
                                  ),
                                  dimensions.width
                                )}
                                title={'UNBLOCK'}
                              />
                            )}
                          </>
                        </View>
                      </View>
                    )}
                  </>
                </View>
              </View>
            )}
          </>
          {/* Stats */}
          <>
            {isBlocked ? null : (
              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'row',
                    marginBottom: 8,
                    marginTop: 20,
                    paddingBottom: 10,
                    paddingTop: 10,
                  },
                  dimensions.width
                )}
              >
                {/* Post */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Custom Color_33'],
                      borderRightWidth: 1,
                      flex: 1,
                      marginRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {/* Images Label */}
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Primary'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Images'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom Color'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {imageCount}
                  </Text>
                </View>
                {/* Followers */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      borderColor: theme.colors['Custom Color_33'],
                      borderRightWidth: 1,
                      flex: 1,
                      marginLeft: 10,
                      marginRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Primary'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Followers'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom Color'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {publicProfile?.follower_count}
                  </Text>
                </View>
                {/* Following */}
                <View
                  style={StyleSheet.applyWidth(
                    { flex: 1, marginLeft: 10 },
                    dimensions.width
                  )}
                >
                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Primary'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Following'}
                  </Text>

                  <Text
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Custom Color'],
                        fontFamily: 'Poppins_600SemiBold',
                        fontSize: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {publicProfile?.following_count}
                  </Text>
                </View>
              </View>
            )}
          </>
        </View>
        <>
          {!(tabSelected === 'images') ? null : (
            <View>
              {/* Top Tabs */}
              <>
                {!(tabSelected === 'images') ? null : (
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        borderBottomWidth: 2,
                        borderColor: theme.colors['Custom Color'],
                        flexDirection: 'row',
                        height: 35,
                        justifyContent: 'center',
                        marginBottom: 12,
                        paddingLeft: 20,
                        paddingRight: 20,
                      },
                      dimensions.width
                    )}
                  >
                    {/* My Images */}
                    <View
                      style={StyleSheet.applyWidth(
                        { width: '50%' },
                        dimensions.width
                      )}
                    >
                      {/* unselected */}
                      <View
                        style={StyleSheet.applyWidth(
                          { alignItems: 'center', height: 35 },
                          dimensions.width
                        )}
                      >
                        <Touchable
                          onPress={() => {
                            try {
                              setSelectedTopTab('images');
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <Text
                            style={StyleSheet.applyWidth(
                              {
                                color: theme.colors['Custom Color'],
                                fontFamily: 'SecularOne_400Regular',
                                fontSize: 15,
                                textTransform: 'uppercase',
                              },
                              dimensions.width
                            )}
                          >
                            {'IMAGES'}
                          </Text>
                        </Touchable>
                      </View>
                    </View>
                  </View>
                )}
              </>
              <>
                {isBlocked ? null : (
                  <View>
                    {/* Collection View */}
                    <>
                      {!(SelectedTopTab === 'images') ? null : (
                        <View
                          style={StyleSheet.applyWidth(
                            { borderRadius: 12, marginLeft: 20 },
                            dimensions.width
                          )}
                        >
                          <GAIMMobileBackendApi.FetchGetImagesByUsernameGET
                            handlers={{
                              onData: fetchData => {
                                try {
                                  setImageCount(countObjects(fetchData?.data));
                                } catch (err) {
                                  console.error(err);
                                }
                              },
                            }}
                            username={
                              props.route?.params?.userName ?? 'bradyllewis'
                            }
                          >
                            {({
                              loading,
                              error,
                              data,
                              refetchGetImagesByUsername,
                            }) => {
                              const fetchData = data?.json;
                              if (loading) {
                                return <ActivityIndicator />;
                              }

                              if (
                                error ||
                                data?.status < 200 ||
                                data?.status >= 300
                              ) {
                                return <ActivityIndicator />;
                              }

                              return (
                                <FlatList
                                  contentContainerStyle={StyleSheet.applyWidth(
                                    { flex: 1 },
                                    dimensions.width
                                  )}
                                  data={fetchData?.data}
                                  keyExtractor={listData =>
                                    listData?.id ||
                                    listData?.uuid ||
                                    JSON.stringify(listData)
                                  }
                                  listKey={'YKvAvd6p'}
                                  numColumns={3}
                                  renderItem={({ item }) => {
                                    const listData = item;
                                    return (
                                      <Touchable
                                        onPress={() => {
                                          try {
                                            setSelectedImageHeight(
                                              scaleDownImage(listData?.height)
                                            );
                                            setSelectedImageWidth(
                                              scaleDownImage(listData?.width)
                                            );
                                            setSelectedImageURL(listData?.url);
                                            setShowImageModal(true);
                                          } catch (err) {
                                            console.error(err);
                                          }
                                        }}
                                        style={StyleSheet.applyWidth(
                                          {
                                            marginRight: 5,
                                            marginTop: 5,
                                            width: '30%',
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        <View
                                          style={StyleSheet.applyWidth(
                                            {
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              marginRight: 5,
                                              width: '100%',
                                            },
                                            dimensions.width
                                          )}
                                        >
                                          <Image
                                            resizeMode={'cover'}
                                            source={{ uri: `${listData?.url}` }}
                                            style={StyleSheet.applyWidth(
                                              { height: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: 100,
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: 450,
                                                },
                                              ],
                                              width: [
                                                {
                                                  minWidth: Breakpoints.Mobile,
                                                  value: '100%',
                                                },
                                                {
                                                  minWidth: Breakpoints.Laptop,
                                                  value: '100%',
                                                },
                                              ], },
                                              dimensions.width
                                            )}
                                          />
                                        </View>
                                      </Touchable>
                                    );
                                  }}
                                />
                              );
                            }}
                          </GAIMMobileBackendApi.FetchGetImagesByUsernameGET>
                        </View>
                      )}
                    </>
                  </View>
                )}
              </>
            </View>
          )}
        </>
      </ScrollView>
      {/* View Image Modal */}
      <>
        {!showImageModal ? null : (
          <Modal animationType={'fade'} presentationStyle={'fullScreen'}>
            <ImageBackground
              backgroundColor={theme.colors['Strong']}
              resizeMode={'cover'}
              source={Images.GaimMobileBG2}
              style={StyleSheet.applyWidth(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    height: '100%',
                    justifyContent: 'space-around',
                  },
                  dimensions.width
                )}
              >
                {/* Close Image Button */}
                <Button
                  icon={'AntDesign/closecircleo'}
                  onPress={() => {
                    try {
                      setShowImageModal(false);
                      setSelectedImageURL(null);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: 50,
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        marginLeft: 20,
                        marginRight: 20,
                        marginTop: '5%',
                        width: '70%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'CLOSE'}
                />
                {/* Image 2 */}
                <Image
                  resizeMode={'cover'}
                  source={{ uri: `${selectedImageURL}` }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ImageStyles(theme)['Image'],
                      {
                        height: selectedImageHeight,
                        maxHeight: 450,
                        maxWidth: 300,
                        width: selectedImageWidth,
                      }
                    ),
                    dimensions.width
                  )}
                />
                {/* View 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    { height: 100 },
                    dimensions.width
                  )}
                />
              </View>
            </ImageBackground>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(PublicProfileScreen);
