import React from 'react';
import { Alert } from 'react-native';

const deleteAssetAlert = async (asset_type, dynamic_id, url) => {
  console.log('delete');

  const deleteUserGeneratedImageByDynamicIdAndUrl = async (dynamic_id, url) => {
    try {
      const response = await axios.delete(
        `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/deleteByUrl/${url}/${dynamic_id}`
      );
      return true;
    } catch (error) {
      return error;
    }
  };

  await Alert.alert(
    'Delete?!',
    `Are you sure you want to delete this ${asset_type}?`,
    [
      {
        text: 'Cancel',
        onPress: () => {
          return false;
        },
        style: 'cancel',
      },
      {
        text: 'DELETE',
        onPress: () => {
          (async () => {
            console.log('delete btn was pressed');
            await deleteUserGeneratedImageByDynamicIdAndUrl(
              dynamic_id,
              encodeURIComponent(url)
            );
            console.log('image deleted successfully');
          })();
          return true;
        },
      },
    ],
    { cancelable: false }
  );
};

export default deleteAssetAlert;
