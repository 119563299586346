import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import addChatPrefsToPrompt from '../global-functions/addChatPrefsToPrompt';
import copyToClipboard from '../global-functions/copyToClipboard';
import loadingProgress from '../global-functions/loadingProgress';
import transformModel from '../global-functions/transformModel';
import { parseBoolean } from '../utils';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  BottomSheet,
  Button,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  LinearProgress,
  Markdown,
  ScreenContainer,
  Spacer,
  Switch,
  TextInput,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  FlatList,
  Image,
  Keyboard,
  Modal,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

const StacksScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const addMessageToCurrConvo = (
    Variables,
    setGlobalVariableValue,
    newMessage
  ) => {
    const formatResponse = { role: 'assistant', content: newMessage };
    setCurrentConvo(prevConvo => [...prevConvo, formatResponse]);
  };

  const addUserPromptToConvo = userPrompt => {
    const formatUserPrompt = { role: 'user', content: userPrompt };
    setCurrentConvo(prevConvo => [...prevConvo, formatUserPrompt]);
  };

  const initConvo = (setGlobalVariableValue, convoToLoad) => {
    if (!Array.isArray(convoToLoad) || convoToLoad.length === 0) return [];

    const modifiedConvo = convoToLoad.slice(1);
    setCurrentConvo(modifiedConvo);
  };

  const addStreamResultToConvo = (
    Variables,
    setGlobalVariableValue,
    message
  ) => {
    const formatStreamResponse = { role: 'assistant', content: message };
    setCurrentConvo(prevConvo => [...prevConvo, formatStreamResponse]);
  };

  const addSelectedStacker = (Variables, setGlobalVariableValue, stacker) => {
    const formatResponse = {
      id: stacker._id,
      name: stacker.name,
      pre_prompt: stacker.pre_prompt,
    };
    setSelectedStackers(prevConvo => [...prevConvo, formatResponse]);
  };

  const postStack = async (
    Variables,
    setGlobalVariableValue,
    promptToSubmit
  ) => {
    const promptList = [selectedStackers];
  };

  const gAIMMobileBackendStreamChatPostPOST =
    GAIMMobileBackendApi.useStreamChatPostPOST();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const fetchedTool = await (async () => {
          if ((props.route?.params?.loadTool ?? '') === 'load') {
            return (
              await GAIMMobileBackendApi.getAIToolByToolIDGET(Constants, {
                tool_id: props.route?.params?.tool_id ?? '',
              })
            )?.json;
          }
        })();
        console.log(fetchedTool);
        if (fetchedTool?.success === 'Tool not found') {
          setGlobalVariableValue({
            key: 'currentChatTool',
            value: {
              _id: 'ObjectId("64f35946d7b8542613e4324b")',
              name: 'AI Assistant',
              type: 'chatbot',
              expertise: 'general',
              date_added: 'ISODate("2023-09-02T00:00:00.000+0000")',
              image_data: {
                example_url:
                  'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/8.jpg',
              },
              pre_prompt:
                'Respond with precise, detailed, unique answers. Ask me questions when you need more information.',
              description:
                "I am your AI assistant. Ask me any general questions about any topic. I'm here to help!",
            },
          });
        }
        if (fetchedTool?.success === true) {
          setGlobalVariableValue({
            key: 'currentChatTool',
            value: fetchedTool?.data,
          });
        }
        const convoToLoad = await (async () => {
          if ((props.route?.params?.loadConvo ?? '') === 'load') {
            return (
              await GAIMMobileBackendApi.getConversationGET(Constants, {
                convo_id: props.route?.params?.convoIDtoLoad ?? '',
                dynamic_id: Constants['dynamic_id'],
              })
            )?.json;
          }
        })();
        if ((props.route?.params?.loadConvo ?? '') === 'load') {
          initConvo(setGlobalVariableValue, convoToLoad?.data.messages);
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);
  const [convo_id, setConvo_id] = React.useState('');
  const [currentConvo, setCurrentConvo] = React.useState([
    { role: 'assistant', content: 'Hi! How can I assist you today?' },
  ]);
  const [currentResponse, setCurrentResponse] = React.useState('');
  const [generator, setGenerator] = React.useState([]);
  const [isSpeaking, setIsSpeaking] = React.useState(false);
  const [messages, setMessages] = React.useState([]);
  const [promptToSubmit, setPromptToSubmit] = React.useState('');
  const [result, setResult] = React.useState('');
  const [selectedStackers, setSelectedStackers] = React.useState([]);
  const [showBottomPage, setShowBottomPage] = React.useState(true);
  const [showLoadingProgress, setShowLoadingProgress] = React.useState(false);
  const [showStackerView, setShowStackerView] = React.useState(true);
  const [showStreamResponse, setShowStreamResponse] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [switchValue2, setSwitchValue2] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const [toolFound, setToolFound] = React.useState(false);

  return (
    <ScreenContainer
      hasBottomSafeArea={false}
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)', justifyContent: 'space-between' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'center',
            marginBottom: 6,
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              flexDirection: 'row',
              height: 48,
              justifyContent: 'space-between',
              position: 'absolute',
              top: 0,
              width: '90%',
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.goBack();
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_2']}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: 'rgb(153, 171, 214)' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'Ionicons/arrow-back-sharp'}
                size={24}
                style={StyleSheet.applyWidth(
                  { backgroundColor: 'rgb(153, 171, 214)' },
                  dimensions.width
                )}
              />
            </Circle>
          </Touchable>

          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          ></View>
          {/* Settings View */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignContent: 'flex-start',
                alignItems: 'center',
                justifyContent: 'space-between',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('ChatSettingsScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Settings Icon */}
              <>
                {switchValue2 ? null : (
                  <Icon
                    color={theme.colors['Primary']}
                    name={'Ionicons/ios-settings-outline'}
                    size={32}
                    style={StyleSheet.applyWidth(
                      { marginBottom: 5 },
                      dimensions.width
                    )}
                  />
                )}
              </>
            </Touchable>
          </View>
        </View>
      </View>
      {/* AI Tool List Item */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            borderBottomWidth: 0.5,
            borderColor: theme.colors['Custom Color_19'],
            flexDirection: 'row',
            justifyContent: 'center',
            paddingBottom: 6,
            paddingLeft: 20,
            paddingTop: 2,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth(
            { justifyContent: 'flex-start' },
            dimensions.width
          )}
        >
          {/* Image 2 */}
          <Image
            resizeMode={'cover'}
            source={{
              uri: `${Constants['currentChatTool']?.image_data.example_url}`,
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: 65,
                width: 65,
              }),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            { flex: 1, marginLeft: 14, marginRight: 20 },
            dimensions.width
          )}
        >
          {/* View 4 */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row' },
              dimensions.width
            )}
          >
            <Icon
              color={theme.colors['Primary']}
              name={'AntDesign/switcher'}
              size={30}
            />
            {/* Name ~ */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'Poppins_500Medium',
                  fontSize: 15,
                  lineHeight: 20,
                  marginLeft: 5,
                  marginTop: 4,
                  opacity: 1,
                  textTransform: 'uppercase',
                },
                dimensions.width
              )}
            >
              {'AI STACKS'}
            </Text>
          </View>
          {/* Message ~ */}
          <Text
            ellipsizeMode={'clip'}
            numberOfLines={3}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color_2'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
                lineHeight: 20,
                opacity: 0.5,
              },
              dimensions.width
            )}
          >
            {Constants['currentChatTool']?.description}
          </Text>
        </View>
      </View>
      <>
        {!showStackerView ? null : (
          <KeyboardAvoidingView
            behavior={'height'}
            enabled={true}
            keyboardVerticalOffset={60}
            style={StyleSheet.applyWidth({ marginTop: 70 }, dimensions.width)}
          >
            {/* Message Tray */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: theme.colors['Background'],
                  borderBottomWidth: 1,
                  borderColor: theme.colors['Custom Color_4'],
                  borderLeftWidth: 1,
                  borderRadius: 24,
                  borderRightWidth: 1,
                  borderTopWidth: 1,
                  bottom: 0,
                  flexDirection: 'row',
                  height: 52,
                  justifyContent: 'space-between',
                  left: 0,
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 10,
                  paddingLeft: 8,
                  paddingRight: 8,
                  position: 'absolute',
                  right: 0,
                },
                dimensions.width
              )}
            >
              {/* View 2 */}
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', marginTop: 10 },
                  dimensions.width
                )}
              />
              <View
                style={StyleSheet.applyWidth(
                  { flex: 1, justifyContent: 'center' },
                  dimensions.width
                )}
              >
                {/* Prompt */}
                <TextInput
                  autoCorrect={true}
                  changeTextDelay={300}
                  editable={true}
                  enablesReturnKeyAutomatically={true}
                  keyboardAppearance={'dark'}
                  keyboardType={'default'}
                  multiline={true}
                  onBlur={() => {
                    try {
                      Keyboard.dismiss();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onChangeText={newPromptValue => {
                    try {
                      setPromptToSubmit(newPromptValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  onSubmitEditing={() => {
                    try {
                      Keyboard.dismiss();
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter details about your request'}
                  placeholderTextColor={theme.colors['Custom Color_4']}
                  returnKeyLabel={'Submit'}
                  returnKeyType={'default'}
                  scrollEnabled={true}
                  style={StyleSheet.applyWidth(
                    {
                      borderRadius: 8,
                      color: theme.colors['Strong'],
                      fontFamily: 'Inter_400Regular',
                      fontSize: 14,
                      marginLeft: 8,
                      paddingBottom: 8,
                      paddingLeft: 8,
                      paddingRight: 8,
                      paddingTop: 8,
                    },
                    dimensions.width
                  )}
                  value={promptToSubmit}
                />
              </View>
            </View>
          </KeyboardAvoidingView>
        )}
      </>
      {/* Plus Button Icon 2 */}
      <>
        {!(selectedStackers?.length < 1) ? null : (
          <View
            style={StyleSheet.applyWidth(
              { alignItems: 'center', marginTop: 16 },
              dimensions.width
            )}
          >
            <IconButton icon={'FontAwesome/plus-square'} size={32} />
          </View>
        )}
      </>
      {/* Selected Stackers List 2 */}
      <>
        {!showStackerView ? null : (
          <FlatList
            data={selectedStackers}
            keyExtractor={selectedStackersList2Data =>
              selectedStackersList2Data?._id
            }
            listKey={'tBgSB8Wb'}
            numColumns={1}
            onEndReachedThreshold={0.5}
            renderItem={({ item }) => {
              const selectedStackersList2Data = item;
              return (
                <>
                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        backgroundColor: 'rgb(40, 40, 40)',
                        borderColor: theme.colors['Primary'],
                        borderRadius: 80,
                        borderWidth: 2,
                        padding: 10,
                        width: '100%',
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      accessible={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Primary'],
                            fontFamily: 'SecularOne_400Regular',
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {selectedStackersList2Data?.name}
                    </Text>
                  </View>
                  {/* Plus Button Icon */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', marginBottom: 16, marginTop: 16 },
                      dimensions.width
                    )}
                  >
                    <IconButton icon={'FontAwesome/plus-square'} size={32} />
                  </View>
                </>
              );
            }}
            showsHorizontalScrollIndicator={false}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { height: 325, width: '100%' },
              dimensions.width
            )}
            contentContainerStyle={StyleSheet.applyWidth(
              {
                alignItems: 'stretch',
                marginTop: 10,
                paddingLeft: '15%',
                paddingRight: '15%',
              },
              dimensions.width
            )}
          />
        )}
      </>
      <>
        {!showStackerView ? null : (
          <View>
            {/* Submit View */}
            <>
              {!(selectedStackers?.length >= 1) ? null : (
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderRadius: 80,
                      marginBottom: 150,
                      marginTop: 8,
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  {/* Submit Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowStackerView(false);
                        setShowStreamResponse(true);
                        setShowBottomPage(false);
                        setResult(null);
                        addUserPromptToConvo(promptToSubmit);
                        Keyboard.dismiss();
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: theme.colors['Primary'],
                          borderColor: theme.colors['Medium'],
                          borderRadius: 80,
                          borderWidth: 2,
                          color: theme.colors['Strong'],
                          fontFamily: 'SecularOne_400Regular',
                          width: '80%',
                        }
                      ),
                      dimensions.width
                    )}
                    title={'SUBMIT STACK'}
                  />
                </View>
              )}
            </>
          </View>
        )}
      </>
      <>
        {!showStreamResponse ? null : (
          <KeyboardAwareScrollView
            contentContainerStyle={StyleSheet.applyWidth(
              {
                justifyContent: 'flex-start',
                marginBottom: 20,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: 20,
              },
              dimensions.width
            )}
            enableAutomaticScroll={false}
            enableOnAndroid={true}
            extraScrollHeight={155}
            keyboardShouldPersistTaps={'always'}
            showsVerticalScrollIndicator={true}
          >
            <>
              {!showStreamResponse ? null : (
                <View
                  style={StyleSheet.applyWidth({ flex: 1 }, dimensions.width)}
                >
                  {/* Stream View */}
                  <>
                    {!result ? null : (
                      <View
                        style={StyleSheet.applyWidth(
                          { flexDirection: 'row' },
                          dimensions.width
                        )}
                      >
                        {/* View 2 */}
                        <View>
                          <CircleImage size={32} source={Images.AIPPS61} />
                        </View>
                        {/* View 2 */}
                        <View
                          style={StyleSheet.applyWidth(
                            {
                              backgroundColor: theme.colors['Custom Color_2'],
                              borderBottomRightRadius: 24,
                              borderTopLeftRadius: 24,
                              borderTopRightRadius: 24,
                              flex: 1,
                              marginLeft: 10,
                              marginRight: 20,
                              paddingBottom: 5,
                              paddingLeft: 15,
                              paddingRight: 15,
                              paddingTop: 5,
                            },
                            dimensions.width
                          )}
                        >
                          <Markdown
                            style={StyleSheet.applyWidth(
                              { fontSize: 15 },
                              dimensions.width
                            )}
                          >
                            {null}
                          </Markdown>
                          {/* Copy Icon Touchable */}
                          <Touchable
                            onPress={() => {
                              try {
                                copyToClipboard(undefined);
                              } catch (err) {
                                console.error(err);
                              }
                            }}
                          >
                            <View
                              style={StyleSheet.applyWidth(
                                { alignItems: 'flex-end' },
                                dimensions.width
                              )}
                            >
                              {/* Copy Icon */}
                              <Icon
                                color={theme.colors['Primary']}
                                name={'Feather/copy'}
                                size={24}
                              />
                            </View>
                          </Touchable>
                        </View>
                      </View>
                    )}
                  </>
                </View>
              )}
            </>
          </KeyboardAwareScrollView>
        )}
      </>
      <>
        {!showBottomPage ? null : (
          <BottomSheet
            borderColor={theme.colors['Primary']}
            borderWidth={1}
            bottomSnapPosition={'85%'}
            bounces={true}
            friction={0.95}
            handleColor={theme.colors['Primary']}
            initialSnapPosition={'bottom'}
            middleSnapPosition={'50%'}
            showHandle={true}
            showsVerticalScrollIndicator={false}
            style={StyleSheet.applyWidth(
              { backgroundColor: 'rgb(40, 40, 40)' },
              dimensions.width
            )}
            topBorderRadius={20}
            topSnapPosition={'10%'}
          >
            <View
              style={StyleSheet.applyWidth(
                { borderColor: theme.colors['Strong'] },
                dimensions.width
              )}
            >
              {/* Stackers List */}
              <FlatList
                contentContainerStyle={StyleSheet.applyWidth(
                  { borderColor: theme.colors['Medium'] },
                  dimensions.width
                )}
                data={Constants['aitools']}
                keyExtractor={stackersListData =>
                  stackersListData?.id ||
                  stackersListData?.uuid ||
                  JSON.stringify(stackersListData)
                }
                listKey={'hRqoI4OF'}
                numColumns={1}
                onEndReachedThreshold={0.5}
                renderItem={({ item }) => {
                  const stackersListData = item;
                  return (
                    <Touchable
                      onPress={() => {
                        try {
                          addSelectedStacker(
                            Variables,
                            setGlobalVariableValue,
                            stackersListData
                          );
                        } catch (err) {
                          console.error(err);
                        }
                      }}
                    >
                      <>
                        {!(
                          parseBoolean(stackersListData?.stackable) === true
                        ) ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'center',
                                backgroundColor: 'rgb(40, 40, 40)',
                                borderColor: theme.colors['Primary'],
                                borderRadius: 80,
                                borderWidth: 2,
                                marginBottom: 8,
                                padding: 10,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Text
                              accessible={true}
                              allowFontScaling={true}
                              style={StyleSheet.applyWidth(
                                StyleSheet.compose(
                                  GlobalStyles.TextStyles(theme)['Text'],
                                  {
                                    color: theme.colors['Primary'],
                                    fontFamily: 'SecularOne_400Regular',
                                    fontSize: 16,
                                    textAlign: 'center',
                                  }
                                ),
                                dimensions.width
                              )}
                            >
                              {stackersListData?.name}
                            </Text>
                          </View>
                        )}
                      </>
                    </Touchable>
                  );
                }}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
              />
              {/* Bottom Header Text */}
              <Text
                accessible={true}
                allowFontScaling={true}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                    color: theme.colors['Surface'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: 16,
                    marginBottom: 8,
                    textAlign: 'center',
                  }),
                  dimensions.width
                )}
              >
                {'STACKABLE ASSETS'}
              </Text>
            </View>
          </BottomSheet>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(StacksScreen);
