import * as React from 'react';
import {
  useQuery,
  useMutation,
  useIsFetching,
  useQueryClient,
} from 'react-query';
import useFetch from 'react-fetch-hook';
import { useIsFocused } from '@react-navigation/native';
import { handleResponse, isOkStatus } from '../utils/handleRestApiResponse';
import usePrevious from '../utils/usePrevious';
import * as GlobalVariables from '../config/GlobalVariableContext';

export const authAuthenticateOTPPOST = (
  Constants,
  { code, email_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/authenticate`,
    {
      body: JSON.stringify({ method_id: email_id, code: code }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useAuthAuthenticateOTPPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      authAuthenticateOTPPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('authAuthenticateOTP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('authAuthenticateOTP');
        queryClient.invalidateQueries('authAuthenticateOTPS');
      },
    }
  );
};

export const FetchAuthAuthenticateOTPPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  code,
  email_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAuthAuthenticateOTPPOST(
    { code, email_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchAuthAuthenticateOTP: refetch,
  });
};

export const authGenerateOTPPOST = (Constants, { email }, handlers = {}) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/generateOTP`,
    {
      body: JSON.stringify({ email: email }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useAuthGenerateOTPPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      authGenerateOTPPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('authOTP', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('authOTP');
        queryClient.invalidateQueries('authOTPS');
      },
    }
  );
};

export const FetchAuthGenerateOTPPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  email,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useAuthGenerateOTPPOST(
    { email },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuthGenerateOTP: refetch });
};

export const authSessionTokenGET = (
  Constants,
  { stytch_session_token },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/authSession/${
      typeof stytch_session_token === 'string'
        ? stytch_session_token
        : JSON.stringify(stytch_session_token ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useAuthSessionTokenGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['authAuthenticateOTP', args],
    () => authSessionTokenGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['authAuthenticateOTPS']),
    }
  );
};

export const FetchAuthSessionTokenGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  stytch_session_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useAuthSessionTokenGET(
    { stytch_session_token },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchAuthSessionToken: refetch });
};

export const blockUnblockUserPOST = (
  Constants,
  { action, user_id, username },
  handlers = {}
) =>
  fetch(`https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/block`, {
    body: JSON.stringify({
      user_id: user_id,
      username: username,
      action: action,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'stytch_session_token': Constants['stytch_session_token'] },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useBlockUnblockUserPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      blockUnblockUserPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('publicProfile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('publicProfile');
        queryClient.invalidateQueries('publicProfiles');
      },
    }
  );
};

export const FetchBlockUnblockUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action,
  user_id,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useBlockUnblockUserPOST(
    { action, user_id, username },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchBlockUnblockUser: refetch });
};

export const claimCreditCodePOST = (
  Constants,
  { creditCodeId, user_id },
  handlers = {}
) =>
  fetch(`https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/claim`, {
    body: JSON.stringify({ user_id: user_id, creditCodeId: creditCodeId }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useClaimCreditCodePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      claimCreditCodePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchClaimCreditCodePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  creditCodeId,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useClaimCreditCodePOST(
    { creditCodeId, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchClaimCreditCode: refetch });
};

export const deleteConversationDELETE = (
  Constants,
  { convo_id, dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }/${
      typeof convo_id === 'string' ? convo_id : JSON.stringify(convo_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteConversationDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteConversationDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('convo', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('convo');
        queryClient.invalidateQueries('convos');
      },
    }
  );
};

export const deleteUserAccountDELETE = (
  Constants,
  { dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteUserAccountDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUserAccountDELETE(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const deleteUserGeneratedImageByURLDELETE = (
  Constants,
  { dynamic_id, url },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/deleteByUrl/${
      typeof url === 'string' ? url : JSON.stringify(url ?? '')
    }/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteUserGeneratedImageByURLDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUserGeneratedImageByURLDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const deleteUserGeneratedImagesByIDDELETE = (
  Constants,
  { dynamic_id, image_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/delete/${
      typeof image_id === 'string' ? image_id : JSON.stringify(image_id ?? '')
    }/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'DELETE',
    }
  ).then(res => handleResponse(res, handlers));

export const useDeleteUserGeneratedImagesByIDDELETE = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      deleteUserGeneratedImagesByIDDELETE(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const fetchModelDataGET = (
  Constants,
  { limit, modelName },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/fetch/${
      typeof modelName === 'string'
        ? modelName
        : JSON.stringify(modelName ?? '')
    }/${typeof limit === 'string' ? limit : JSON.stringify(limit ?? '')}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useFetchModelDataGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['modelData', args],
    () => fetchModelDataGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchFetchModelDataGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  limit,
  modelName,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useFetchModelDataGET(
    { limit, modelName },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFetchModelData: refetch });
};

export const followAUserPOST = (
  Constants,
  { action, user_id, username },
  handlers = {}
) =>
  fetch(`https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/follow`, {
    body: JSON.stringify({
      username: username,
      user_id: user_id,
      action: action,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'stytch_session_token': Constants['stytch_session_token'] },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const useFollowAUserPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => followAUserPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('publicProfile', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('publicProfile');
        queryClient.invalidateQueries('publicProfiles');
      },
    }
  );
};

export const FetchFollowAUserPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action,
  user_id,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useFollowAUserPOST(
    { action, user_id, username },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchFollowAUser: refetch });
};

export const generateImagePOST = (
  Constants,
  {
    dynamic_id,
    guidance_scale,
    height,
    highContrast,
    imagePromptWeight,
    image_public,
    init_image_id,
    init_strength,
    modelId,
    negative_prompt,
    pre_prompt,
    prompt,
    promptMagic,
    promptMagicVersion,
    prompt_public,
    tool_id,
    width,
  },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/generate`,
    {
      body: JSON.stringify({
        prompt: prompt,
        pre_prompt: pre_prompt,
        negative_prompt: negative_prompt,
        modelId: modelId,
        promptMagic: promptMagic,
        promptMagicVersion: promptMagicVersion,
        imagePromptWeight: imagePromptWeight,
        width: width,
        height: height,
        num_images: '1',
        guidance_scale: guidance_scale,
        highContrast: highContrast,
        public: false,
        nsfw: true,
        dynamic_id: dynamic_id,
        init_image_id: init_image_id,
        init_strength: init_strength,
        image_public: image_public,
        prompt_public: prompt_public,
        tool_id: tool_id,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useGenerateImagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => generateImagePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const FetchGenerateImagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dynamic_id,
  guidance_scale,
  height,
  highContrast,
  imagePromptWeight,
  image_public,
  init_image_id,
  init_strength,
  modelId,
  negative_prompt,
  pre_prompt,
  prompt,
  promptMagic,
  promptMagicVersion,
  prompt_public,
  tool_id,
  width,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useGenerateImagePOST(
    {
      dynamic_id,
      guidance_scale,
      height,
      highContrast,
      imagePromptWeight,
      image_public,
      init_image_id,
      init_strength,
      modelId,
      negative_prompt,
      pre_prompt,
      prompt,
      promptMagic,
      promptMagicVersion,
      prompt_public,
      tool_id,
      width,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGenerateImage: refetch });
};

export const getAIToolByToolIDGET = (Constants, { tool_id }, handlers = {}) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/tool/${
      typeof tool_id === 'string' ? tool_id : JSON.stringify(tool_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAIToolByToolIDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['modelDatum', args],
    () => getAIToolByToolIDGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['modelData']),
    }
  );
};

export const FetchGetAIToolByToolIDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  tool_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAIToolByToolIDGET(
    { tool_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetAIToolByToolID: refetch });
};

export const getAllUserGeneratedImagesForUserGET = (
  Constants,
  { dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetAllUserGeneratedImagesForUserGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['userGeneratedImages', args],
    () => getAllUserGeneratedImagesForUserGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetAllUserGeneratedImagesForUserGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dynamic_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetAllUserGeneratedImagesForUserGET(
    { dynamic_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetAllUserGeneratedImagesForUser: refetch,
  });
};

export const getChatHistoryGET = (Constants, { dynamic_id }, handlers = {}) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/history/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetChatHistoryGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['convos', args],
    () => getChatHistoryGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetChatHistoryGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dynamic_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetChatHistoryGET(
    { dynamic_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetChatHistory: refetch });
};

export const getConversationGET = (
  Constants,
  { convo_id, dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/convo/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }/${
      typeof convo_id === 'string' ? convo_id : JSON.stringify(convo_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetConversationGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['convo', args],
    () => getConversationGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['convos']),
    }
  );
};

export const FetchGetConversationGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  convo_id,
  dynamic_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetConversationGET(
    { convo_id, dynamic_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetConversation: refetch });
};

export const getImagesByUsernameGET = (
  Constants,
  { username },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/username/${
      typeof username === 'string' ? username : JSON.stringify(username ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetImagesByUsernameGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['userGeneratedImages', args],
    () => getImagesByUsernameGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetImagesByUsernameGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetImagesByUsernameGET(
    { username },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetImagesByUsername: refetch,
  });
};

export const getProfileFeedImagesGET = (
  Constants,
  { limit, user_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/fetch/UserGeneratedImage/${
      typeof user_id === 'string' ? user_id : JSON.stringify(user_id ?? '')
    }/${typeof limit === 'string' ? limit : JSON.stringify(limit ?? '')}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetProfileFeedImagesGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  return useQuery(
    ['userGeneratedImages', args],
    () => getProfileFeedImagesGET(Constants, args, handlers),
    {
      refetchInterval,
    }
  );
};

export const FetchGetProfileFeedImagesGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  limit,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetProfileFeedImagesGET(
    { limit, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetProfileFeedImages: refetch,
  });
};

export const getPublicProfileByUsernameGET = (
  Constants,
  { username },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/public/username/${
      typeof username === 'string' ? username : JSON.stringify(username ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetPublicProfileByUsernameGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['publicProfile', args],
    () => getPublicProfileByUsernameGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['publicProfiles']),
    }
  );
};

export const FetchGetPublicProfileByUsernameGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  username,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetPublicProfileByUsernameGET(
    { username },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetPublicProfileByUsername: refetch,
  });
};

export const getUserGeneratedImageByIDGET = (
  Constants,
  { image_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/id/${
      typeof image_id === 'string' ? image_id : JSON.stringify(image_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserGeneratedImageByIDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['userGeneratedImage', args],
    () => getUserGeneratedImageByIDGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['userGeneratedImages']),
    }
  );
};

export const FetchGetUserGeneratedImageByIDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  image_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserGeneratedImageByIDGET(
    { image_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchGetUserGeneratedImageByID: refetch,
  });
};

export const getUserByDynamicIDGET = (
  Constants,
  { dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useGetUserByDynamicIDGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['user', args],
    () => getUserByDynamicIDGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['users']),
    }
  );
};

export const FetchGetUserByDynamicIDGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  dynamic_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useGetUserByDynamicIDGET(
    { dynamic_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchGetUserByDynamicID: refetch });
};

export const likeUnlikeImagePOST = (
  Constants,
  { image_id, user_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/likeUnlikeImage`,
    {
      body: JSON.stringify({ user_id: user_id, image_id: image_id }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useLikeUnlikeImagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      likeUnlikeImagePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const FetchLikeUnlikeImagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  image_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLikeUnlikeImagePOST(
    { image_id, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLikeUnlikeImage: refetch });
};

export const likeAUserGeneratedImagePOST = (
  Constants,
  { action, image_id, user_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/likeImage`,
    {
      body: JSON.stringify({
        image_id: image_id,
        user_id: user_id,
        action: action,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useLikeAUserGeneratedImagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      likeAUserGeneratedImagePOST(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const FetchLikeAUserGeneratedImagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  action,
  image_id,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useLikeAUserGeneratedImagePOST(
    { action, image_id, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({
    loading,
    data,
    error,
    refetchLikeAUserGeneratedImage: refetch,
  });
};

export const logoutGET = (Constants, { stytch_session_token }, handlers = {}) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/logout/${
      typeof stytch_session_token === 'string'
        ? stytch_session_token
        : JSON.stringify(stytch_session_token ?? '')
    }`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
    }
  ).then(res => handleResponse(res, handlers));

export const useLogoutGET = (
  args = {},
  { refetchInterval, handlers = {} } = {}
) => {
  const Constants = GlobalVariables.useValues();
  const queryClient = useQueryClient();
  return useQuery(
    ['authAuthenticateOTP', args],
    () => logoutGET(Constants, args, handlers),
    {
      refetchInterval,
      onSuccess: () => queryClient.invalidateQueries(['authAuthenticateOTPS']),
    }
  );
};

export const FetchLogoutGET = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  stytch_session_token,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    refetch,
  } = useLogoutGET(
    { stytch_session_token },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchLogout: refetch });
};

export const makeImageOrPromptPublicPrivatePUT = (
  Constants,
  { dynamic_id, image_id, image_public, prompt_public },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/updateImage`,
    {
      body: JSON.stringify({
        dynamic_id: dynamic_id,
        image_id: image_id,
        prompt_public: prompt_public,
        image_public: image_public,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useMakeImageOrPromptPublicPrivatePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      makeImageOrPromptPublicPrivatePUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('userGeneratedImages', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('userGeneratedImage');
        queryClient.invalidateQueries('userGeneratedImages');
      },
    }
  );
};

export const postChatPOST = (
  Constants,
  { convo_id, dynamic_id, messages, model, newMessage, pre_prompt, tool_id },
  handlers = {}
) =>
  fetch(`https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/chat`, {
    body: JSON.stringify({
      dynamic_id: dynamic_id,
      messages: messages,
      newMessage: newMessage,
      pre_prompt: pre_prompt,
      convo_id: convo_id,
      tool_id: tool_id,
      model: model,
    }),
    headers: { Accept: 'application/json', 'Content-Type': 'application/json', 'stytch_session_token': Constants['stytch_session_token'] },
    method: 'POST',
  }).then(res => handleResponse(res, handlers));

export const usePostChatPOST = (initialArgs = {}, { handlers = {} } = {}) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => postChatPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('postChat', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('postChat');
        queryClient.invalidateQueries('postChats');
      },
    }
  );
};

export const FetchPostChatPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  convo_id,
  dynamic_id,
  messages,
  model,
  newMessage,
  pre_prompt,
  tool_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = usePostChatPOST(
    { convo_id, dynamic_id, messages, model, newMessage, pre_prompt, tool_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchPostChat: refetch });
};

export const streamChatPostPOST = (
  Constants,
  { convo_id, dynamic_id, messages, model, newMessage, pre_prompt, tool_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/chatStream`,
    {
      body: JSON.stringify({
        dynamic_id: dynamic_id,
        messages: messages,
        newMessage: newMessage,
        pre_prompt: pre_prompt,
        convo_id: convo_id,
        model: model,
        tool_id: tool_id,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useStreamChatPostPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      streamChatPostPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('postChat', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('postChat');
        queryClient.invalidateQueries('postChats');
      },
    }
  );
};

export const FetchStreamChatPostPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  convo_id,
  dynamic_id,
  messages,
  model,
  newMessage,
  pre_prompt,
  tool_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useStreamChatPostPOST(
    { convo_id, dynamic_id, messages, model, newMessage, pre_prompt, tool_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchStreamChatPost: refetch });
};

export const submitComplaintPOST = (
  Constants,
  {
    asset_owner_id,
    asset_type,
    complaining_user_id,
    complaint_comments,
    offending_asset_id,
  },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/complaints`,
    {
      body: JSON.stringify({
        complaining_user_id: complaining_user_id,
        offending_asset_id: offending_asset_id,
        asset_type: asset_type,
        asset_owner_id: asset_owner_id,
        complaint_comments: complaint_comments,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useSubmitComplaintPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      submitComplaintPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('complaint', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('complaint');
        queryClient.invalidateQueries('complaints');
      },
    }
  );
};

export const FetchSubmitComplaintPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  asset_owner_id,
  asset_type,
  complaining_user_id,
  complaint_comments,
  offending_asset_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useSubmitComplaintPOST(
    {
      asset_owner_id,
      asset_type,
      complaining_user_id,
      complaint_comments,
      offending_asset_id,
    },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchSubmitComplaint: refetch });
};

export const updateAvatarPOST = (
  Constants,
  { image_base64, user_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/profile/avatar`,
    {
      body: JSON.stringify({ user_id: user_id, image: image_base64 }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateAvatarPOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args => updateAvatarPOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const FetchUpdateAvatarPOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  image_base64,
  user_id,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUpdateAvatarPOST(
    { image_base64, user_id },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUpdateAvatar: refetch });
};

export const updateConvoTitlePUT = (
  Constants,
  { convo_id, title, user_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/chat/updateTitle`,
    {
      body: JSON.stringify({
        user_id: user_id,
        convo_id: convo_id,
        title: title,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateConvoTitlePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateConvoTitlePUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('convo', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('convo');
        queryClient.invalidateQueries('convos');
      },
    }
  );
};

export const updateUserChatSettingsPUT = (
  Constants,
  { PIEnabled, PIValue, PREnabled, REEnabled, REValue, dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      body: JSON.stringify({
        chat_prefs: {
          response_education_enabled: REEnabled,
          response_education: REValue,
          persistent_instructions_enabled: PIEnabled,
          persistent_instructions: PIValue,
          premium_responses: PREnabled,
        },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateUserChatSettingsPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUserChatSettingsPUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const updateUserImageSettingsPUT = (
  Constants,
  { PIEnabled, PPEnabled, dynamic_id },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      body: JSON.stringify({
        image_prefs: {
          public_images_enabled: PIEnabled,
          public_prompts_enabled: PPEnabled,
        },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateUserImageSettingsPUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUserImageSettingsPUT(
        Constants,
        { ...initialArgs, ...args },
        handlers
      ),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const updateUserProfilePUT = (
  Constants,
  { companyName, dynamic_id, location, name, username, website },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/user/${
      typeof dynamic_id === 'string'
        ? dynamic_id
        : JSON.stringify(dynamic_id ?? '')
    }`,
    {
      body: JSON.stringify({
        name: name,
        username: username,
        companyName: companyName,
        location: location,
        website: website,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'PUT',
    }
  ).then(res => handleResponse(res, handlers));

export const useUpdateUserProfilePUT = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      updateUserProfilePUT(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('user', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('user');
        queryClient.invalidateQueries('users');
      },
    }
  );
};

export const uploadInitImagePOST = (
  Constants,
  { base64Image },
  handlers = {}
) =>
  fetch(
    `https://gaim-mobile-backend-vr97s.ondigitalocean.app/images/upload-image`,
    {
      body: JSON.stringify({ base64image: base64Image }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'stytch_session_token': Constants['stytch_session_token'],
      },
      method: 'POST',
    }
  ).then(res => handleResponse(res, handlers));

export const useUploadInitImagePOST = (
  initialArgs = {},
  { handlers = {} } = {}
) => {
  const queryClient = useQueryClient();
  const Constants = GlobalVariables.useValues();
  return useMutation(
    args =>
      uploadInitImagePOST(Constants, { ...initialArgs, ...args }, handlers),
    {
      onError: (err, variables, { previousValue }) => {
        if (previousValue) {
          return queryClient.setQueryData('initImage', previousValue);
        }
      },
      onSettled: () => {
        queryClient.invalidateQueries('initImage');
        queryClient.invalidateQueries('initImages');
      },
    }
  );
};

export const FetchUploadInitImagePOST = ({
  children,
  onData = () => {},
  handlers = {},
  refetchInterval,
  base64Image,
}) => {
  const Constants = GlobalVariables.useValues();
  const isFocused = useIsFocused();
  const prevIsFocused = usePrevious(isFocused);

  const {
    isLoading: loading,
    data,
    error,
    mutate: refetch,
  } = useUploadInitImagePOST(
    { base64Image },
    { refetchInterval, handlers: { onData, ...handlers } }
  );

  React.useEffect(() => {
    if (!prevIsFocused && isFocused) {
      refetch();
    }
  }, [isFocused, prevIsFocused]);

  React.useEffect(() => {
    if (error) {
      console.error('Fetch error: ' + error.status + ' ' + error.statusText);
      console.error(error);
    }
  }, [error]);
  return children({ loading, data, error, refetchUploadInitImage: refetch });
};
