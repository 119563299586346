import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import truncateNum from '../global-functions/truncateNum';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Button,
  Circle,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ScrollView, Text, View } from 'react-native';

const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;

const MenuScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const postUser = (
          await GAIMMobileBackendApi.getUserByDynamicIDGET(Constants, {
            dynamic_id: Constants['user']?.dynamic_id,
          })
        )?.json;
        setGlobalVariableValue({
          key: 'user',
          value: postUser?.data,
        });
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={true}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(40, 40, 40)', height: '100%' },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: 'rgb(40, 40, 40)',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            marginTop: 12,
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Close btn */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              backgroundColor: 'rgba(0, 0, 0, 0)',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.goBack();
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Circle
                bgColor={theme.colors['Background']}
                size={30}
                style={StyleSheet.applyWidth(
                  { backgroundColor: theme.colors['Primary'] },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'Entypo/cross'}
                  size={24}
                  style={StyleSheet.applyWidth(
                    { backgroundColor: 'rgba(0, 0, 0, 0)' },
                    dimensions.width
                  )}
                />
              </Circle>
            </Touchable>
          </View>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'Inter_600SemiBold',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {'MENU'}
        </Text>
        {/* Notifications */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        ></View>
      </View>
      {/* User Details */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            borderRadius: 24,
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 50 },
            ],
            marginRight: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 50 },
            ],
            paddingTop: 20,
          },
          dimensions.width
        )}
      >
        {/* Details */}
        <View
          style={StyleSheet.applyWidth(
            { flex: 1, justifyContent: 'center' },
            dimensions.width
          )}
        >
          {/* Name */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color'],
                fontFamily: 'Poppins_500Medium',
                fontSize: 15,
              },
              dimensions.width
            )}
          >
            {Constants['user']?.username}
          </Text>
          {/* Email */}
          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color_37'],
                fontFamily: 'Poppins_400Regular',
                fontSize: 12,
                marginTop: 6,
              },
              dimensions.width
            )}
          >
            {Constants['user']?.email}
          </Text>
          {/* User Credits View */}
          <View
            style={StyleSheet.applyWidth(
              { flexDirection: 'row', marginTop: 5 },
              dimensions.width
            )}
          >
            {/* Credits Label */}
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Light'],
                }),
                dimensions.width
              )}
            >
              {'Image Credits: '}
            </Text>
            {/* Credits Value */}
            <Text
              accessible={true}
              allowFontScaling={true}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                  color: theme.colors['Primary'],
                }),
                dimensions.width
              )}
            >
              {truncateNum(Constants['user']?.image_credits, 2)}
            </Text>
          </View>
          {/* User AI Credits View */}
          <>
            {!(
              Constants['user']?.subscription_id === '6451434ac0267c72e2b0ddc9'
            ) ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', marginTop: 5 },
                  dimensions.width
                )}
              >
                {/* Credits Label */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Light'],
                    }),
                    dimensions.width
                  )}
                >
                  {'Chat/Text Credits: '}
                </Text>
                {/* Credits Value */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Primary'],
                    }),
                    dimensions.width
                  )}
                >
                  {truncateNum(Constants['user']?.ai_credits, 2)}
                </Text>
              </View>
            )}
          </>
        </View>
        {/* User Image */}
        <Circle
          bgColor={theme.colors.light}
          size={50}
          style={StyleSheet.applyWidth(
            { backgroundColor: 'rgba(19, 17, 17, 0.8)' },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={{ uri: `${Constants['user']?.pic}` }}
            style={StyleSheet.applyWidth(
              {
                borderColor: theme.colors['Primary'],
                borderRadius: 100,
                borderWidth: 1,
                height: [
                  { minWidth: Breakpoints.Mobile, value: 50 },
                  { minWidth: Breakpoints.Laptop, value: 100 },
                ],
                width: [
                  { minWidth: Breakpoints.Mobile, value: 50 },
                  { minWidth: Breakpoints.Laptop, value: 100 },
                ],
              },
              dimensions.width
            )}
          />
        </Circle>
      </View>

      <View
        style={StyleSheet.applyWidth(
          { alignItems: 'center' },
          dimensions.width
        )}
      >
        <Button
          onPress={() => {
            try {
              navigation.navigate('SubscribeScreen');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
              borderRadius: 50,
              color: theme.colors['Strong'],
              marginBottom: 15,
              marginTop: 15,
              width: [
                { minWidth: Breakpoints.Mobile, value: '75%' },
                { minWidth: Breakpoints.Laptop, value: '50%' },
              ],
            }),
            dimensions.width
          )}
          title={'UPGRADE / GET MORE CREDITS'}
        />
      </View>
      {/* Menu Scroll */}
      <ScrollView
        bounces={false}
        contentContainerStyle={StyleSheet.applyWidth(
          {
            marginBottom: 20,
            paddingBottom: 20,
            paddingLeft: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 100 },
            ],
            paddingRight: [
              { minWidth: Breakpoints.Mobile, value: 20 },
              { minWidth: Breakpoints.Laptop, value: 100 },
            ],
          },
          dimensions.width
        )}
        showsVerticalScrollIndicator={true}
      >
        {/* GAIM Home */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('LandingPageScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'Ionicons/home-outline'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'GAIM Home'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Back to your home screen'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>

        {/* AI ONE */}
        {SpeechRecognition && (<>
          <Touchable
          onPress={() => {
            try {
              navigation.navigate('ChatOneScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'FontAwesome/microphone'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Chat ONE'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Talk-To-Chat with AI about anything'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        </>)}

        
        {/* AI Tools */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('ToolsListScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'Ionicons/chatbox-ellipses-outline'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'AI Tools'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Chatbots, Image Generators, and more'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        {/* AI Characters */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('CharacterListScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'AntDesign/staro'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Chat with AI Characters'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Casual, fun, and entertaining convos'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        
        {/* Feeds */}
        {/*<Touchable
          onPress={() => {
            try {
              navigation.navigate('PublicArtFeedScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'Ionicons/images-outline'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >*/}
              {/* Title */}
              {/*<Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Art Feeds'}
              </Text>*/}
              {/* Description */}
              {/*<Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Public Feed, Following Feed'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>*/}
        {/* Profile */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('UserProfileScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'FontAwesome/user-o'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Profile'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Your saved images and more'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        {/* Edit Account */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('FillProfileScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'MaterialIcons/people-outline'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Edit Account Details'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Update your user information'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        {/* Help */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('HelpandSupportScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'AntDesign/questioncircleo'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Help'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Support, contact us, privacy policy'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        {/* Delete Account */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('DeleteAccountScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'AntDesign/deleteuser'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Delete My Account'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Delete your account and data'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
        {/* Claim Credits */}
        <Touchable
          onPress={() => {
            try {
              navigation.navigate('ClaimCreditsScreen');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderColor: theme.colors['Custom Color_27'],
                flexDirection: 'row',
                justifyContent: 'space-between',
                paddingBottom: 18,
                paddingTop: 18,
              },
              dimensions.width
            )}
          >
            <View>
              <Icon
                color={theme.colors['Custom Color']}
                name={'MaterialIcons/add-task'}
                size={24}
              />
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flex: 1, marginLeft: 21, marginRight: 20 },
                dimensions.width
              )}
            >
              {/* Title */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_500Medium',
                    fontSize: 15,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 1,
                  },
                  dimensions.width
                )}
              >
                {'Redeem Credits Code'}
              </Text>
              {/* Description */}
              <Text
                numberOfLines={1}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Custom Color_2'],
                    fontFamily: 'Poppins_400Regular',
                    fontSize: 13,
                    lineHeight: 20,
                    marginTop: 4,
                    opacity: 0.5,
                  },
                  dimensions.width
                )}
              >
                {'Redeem a code and claim your credits'}
              </Text>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'flex-end' },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Custom Color_36']}
                name={'Entypo/chevron-right'}
                size={24}
              />
            </View>
          </View>
        </Touchable>
      </ScrollView>
      <Button
        icon={'AntDesign/logout'}
        onPress={() => {
          const handler = async () => {
            try {
              (
                await GAIMMobileBackendApi.logoutGET(Constants, {
                  stytch_session_token: Constants['stytch_session_token'],
                })
              )?.json;
              navigation.navigate('LoginScreen');
              setGlobalVariableValue({
                key: 'stytch_session_token',
                value: null,
              });
            } catch (err) {
              console.error(err);
            }
          };
          handler();
        }}
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
            color: theme.colors['Strong'],
          }),
          dimensions.width
        )}
        title={'LOG OUT'}
      />
    </ScreenContainer>
  );
};

export default withTheme(MenuScreen);
