import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';
import { View, useWindowDimensions } from 'react-native';

const PODTestScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme } = props;

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      <View
        style={StyleSheet.applyWidth(
          {
            backgroundColor: 'rgb(161, 151, 151)',
            height: '80%',
            width: '80%',
          },
          dimensions.width
        )}
      />
      <WebView
        cacheEnabled={true}
        javaScriptEnabled={true}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        source={{ uri: 'https://reactnative.dev' }}
        style={StyleSheet.applyWidth(
          GlobalStyles.WebViewStyles(theme)['Web View'],
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(PODTestScreen);
