import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Swiper, SwiperItem, Touchable, withTheme } from '@draftbit/ui';
import { Image } from 'react-native';

const LandingSwiperBlock = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const getRandomAitools = (aitools, typesToMatch, numberOfResults) => {
    function getRandomAitools(aitools, typesToMatch, numberOfResults) {
      // Filter the array based on the provided types
      const filteredAitools = aitools.filter(tool =>
        typesToMatch.includes(tool.type)
      );

      // Shuffle the filtered array
      for (let i = filteredAitools.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [filteredAitools[i], filteredAitools[j]] = [
          filteredAitools[j],
          filteredAitools[i],
        ];
      }
      console.log('randomTools: ', filteredAitools.slice(0, numberOfResults));
      // Select the first numberOfResults elements
      return filteredAitools.slice(0, numberOfResults);
    }
  };

  return (
    <Swiper
      dotActiveColor={theme.colors['Surface']}
      dotColor={theme.colors['Primary']}
      dotsTouchable={false}
      loop={true}
      style={StyleSheet.applyWidth(
        StyleSheet.compose(GlobalStyles.SwiperStyles(theme)['Swiper'], {
          height: [
            { minWidth: Breakpoints.Laptop, value: 340 },
            { minWidth: Breakpoints.Desktop, value: 340 },
            { minWidth: Breakpoints.BigScreen, value: 340 },
          ],
          marginTop: 10,
        }),
        dimensions.width
      )}
    >
      {/* Swiper Item 1 */}
      <SwiperItem
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            height: [
              { minWidth: Breakpoints.Tablet, value: 400 },
              { minWidth: Breakpoints.Desktop, value: 350 },
              { minWidth: Breakpoints.BigScreen, value: 350 },
              { minWidth: Breakpoints.Mobile, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: 350 },
            ],
            width: [
              { minWidth: Breakpoints.Mobile, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '60%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* Touchable 1 */}
        <Touchable
          style={StyleSheet.applyWidth(
            { height: '88%', width: '88%' },
            dimensions.width
          )}
        >
          {/* Image 1 */}
          <Image
            resizeMode={'contain'}
            source={Images.BannerAISight}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 12,
                borderWidth: 4,
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Desktop, value: 300 },
                  { minWidth: Breakpoints.BigScreen, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
                width: '100%',
              }),
              dimensions.width
            )}
          />
        </Touchable>
      </SwiperItem>
      {/* Swiper Item 2 */}
      <SwiperItem
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            height: [
              { minWidth: Breakpoints.Tablet, value: 400 },
              { minWidth: Breakpoints.Laptop, value: 350 },
              { minWidth: Breakpoints.Desktop, value: 350 },
              { minWidth: Breakpoints.BigScreen, value: 350 },
              { minWidth: Breakpoints.Mobile, value: '100%' },
            ],
            width: [
              { minWidth: Breakpoints.Mobile, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '60%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* Touchable 2 */}
        <Touchable
          style={StyleSheet.applyWidth(
            { height: '88%', width: '88%' },
            dimensions.width
          )}
        >
          {/* Image 2 */}
          <Image
            resizeMode={'contain'}
            source={Images.BannerAIChat}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 12,
                borderWidth: 4,
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Desktop, value: 300 },
                  { minWidth: Breakpoints.BigScreen, value: 300 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                ],
                width: '100%',
              }),
              dimensions.width
            )}
          />
        </Touchable>
      </SwiperItem>
      {/* Swiper Item 3 */}
      <SwiperItem
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            alignSelf: { minWidth: Breakpoints.Laptop, value: 'center' },
            height: [
              { minWidth: Breakpoints.Tablet, value: 400 },
              { minWidth: Breakpoints.Laptop, value: 350 },
              { minWidth: Breakpoints.Desktop, value: 350 },
              { minWidth: Breakpoints.BigScreen, value: 350 },
              { minWidth: Breakpoints.Mobile, value: '100%' },
            ],
            width: [
              { minWidth: Breakpoints.Mobile, value: '100%' },
              { minWidth: Breakpoints.Laptop, value: '60%' },
            ],
          },
          dimensions.width
        )}
      >
        {/* Touchable 3 */}
        <Touchable
          style={StyleSheet.applyWidth(
            { height: '88%', width: '88%' },
            dimensions.width
          )}
        >
          {/* Image 3 */}
          <Image
            resizeMode={'contain'}
            source={Images.BannerAIAssistant}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                borderColor: theme.colors['Primary'],
                borderRadius: 12,
                borderWidth: 4,
                height: [
                  { minWidth: Breakpoints.Tablet, value: 400 },
                  { minWidth: Breakpoints.Mobile, value: '100%' },
                  { minWidth: Breakpoints.Laptop, value: 300 },
                  { minWidth: Breakpoints.Desktop, value: 300 },
                  { minWidth: Breakpoints.BigScreen, value: 300 },
                ],
                width: '100%',
              }),
              dimensions.width
            )}
          />
        </Touchable>
      </SwiperItem>
    </Swiper>
  );
};

export default withTheme(LandingSwiperBlock);
