import * as React from 'react';
import {
  I18nManager,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { systemWeights } from 'react-native-typography';
import { Icon, Touchable } from '@draftbit/ui';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import theme from './themes/Draftbit.js';
import LinkingConfiguration from './LinkingConfiguration.js';

import AIAppScreen from './screens/AIAppScreen';
import CharacterListScreen from './screens/CharacterListScreen.js';
import ChatSettingsScreen from './screens/ChatSettingsScreen';
import ChatbotScreen from './screens/ChatbotScreen';
import ChatbotScreenStreamingMobileScreen from './screens/ChatbotScreenStreamingMobileScreen';
import ChatOneScreen from './screens/ChatOneScreen.js';
import ClaimCreditsScreen from './screens/ClaimCreditsScreen';
import DeleteAccountScreen from './screens/DeleteAccountScreen';
import FillProfileScreen from './screens/FillProfileScreen';
import FollowedChannelsScreen from './screens/FollowedChannelsScreen';
import HelpandSupportScreen from './screens/HelpandSupportScreen';
import HomeScreen from './screens/HomeScreen';
import ImageGeneratorScreen from './screens/ImageGeneratorScreen';
import ImageSettingsScreen from './screens/ImageSettingsScreen';
import Img2ImgGeneratorScreen from './screens/Img2ImgGeneratorScreen';
import LabScreen from './screens/LabScreen';
import LandingPageScreen from './screens/LandingPageScreen.js';
import LandingPageScreen2 from './screens/LandingPageScreen2.js';
import LearnMore1Screen from './screens/LearnMore1Screen';
import LearnMore2Screen from './screens/LearnMore2Screen';
import LearnMore3Screen from './screens/LearnMore3Screen';
import LearnMore4Screen from './screens/LearnMore4Screen';
import LearnMore5Screen from './screens/LearnMore5Screen';
import LoginScreen from './screens/LoginScreen';
import MenuScreen from './screens/MenuScreen';
import PODTestScreen from './screens/PODTestScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import PublicArtFeedScreen from './screens/PublicArtFeedScreen';
import PublicProfileScreen from './screens/PublicProfileScreen';
import StacksScreen from './screens/StacksScreen';
import StytchAuthScreen from './screens/StytchAuthScreen';
import SubscribeScreen from './screens/SubscribeScreen';
import TermsofServiceScreen from './screens/TermsofServiceScreen';
import ToolsListScreen from './screens/ToolsListScreen';
import UserProfileScreen from './screens/UserProfileScreen';
import ViewProfileScreen from './screens/ViewProfileScreen';

const Stack = createStackNavigator();
const Tab = createBottomTabNavigator();

export default function RootAppNavigator() {
  return (
    <NavigationContainer linking={LinkingConfiguration}>
      <Stack.Navigator
        initialRouteName="HomeScreen"
        screenOptions={{ headerMode: 'none', cardStyle: { backgroundColor: '#121212' } }}
      >
        <Stack.Screen
          name="LoginScreen"
          component={LoginScreen}
          options={{
            cardStyle: { backgroundColor: theme.colors['Custom Color_38'] },
            title: 'Login to GAIM.AI',
          }}
        />
        <Stack.Screen
          name="ChatbotScreen"
          component={ChatbotScreen}
          options={{
            title: 'Chat with GAIM.AI',
          }}
        />
        <Stack.Screen
          name="ChatOneScreen"
          component={ChatOneScreen}
          options={{
            title: 'Talk-To-Chat with AI',
          }}
        />
        <Stack.Screen
          name="FillProfileScreen"
          component={FillProfileScreen}
          options={{
            title: 'Edit Profile',
          }}
        />
        <Stack.Screen
          name="StytchAuthScreen"
          component={StytchAuthScreen}
          options={{
            title: 'Login to GAIM.AI',
          }}
        />
        <Stack.Screen
          name="ToolsListScreen"
          component={ToolsListScreen}
          options={{
            title: 'AI Tools List',
          }}
        />
        <Stack.Screen
          name="ImageGeneratorScreen"
          component={ImageGeneratorScreen}
          options={{
            title: 'Image Generator',
          }}
        />
        <Stack.Screen
          name="ViewProfileScreen"
          component={ViewProfileScreen}
          options={{
            title: 'View Profile',
          }}
        />
        <Stack.Screen
          name="PrivacyPolicyScreen"
          component={PrivacyPolicyScreen}
          options={{
            title: 'Privacy Policy',
          }}
        />
        <Stack.Screen
          name="TermsofServiceScreen"
          component={TermsofServiceScreen}
          options={{
            title: 'Terms of Service',
          }}
        />
        <Stack.Screen
          name="Img2ImgGeneratorScreen"
          component={Img2ImgGeneratorScreen}
          options={{
            title: 'Img2Img Generator',
          }}
        />
        <Stack.Screen
          name="HomeScreen"
          component={HomeScreen}
          options={{
            title: 'GAIM.AI',
          }}
        />
        <Stack.Screen
          name="LearnMore1Screen"
          component={LearnMore1Screen}
          options={{
            title: 'Learn More 1',
          }}
        />
        <Stack.Screen
          name="LearnMore2Screen"
          component={LearnMore2Screen}
          options={{
            title: 'Learn More 2',
          }}
        />
        <Stack.Screen
          name="LearnMore3Screen"
          component={LearnMore3Screen}
          options={{
            title: 'Learn More 3',
          }}
        />
        <Stack.Screen
          name="LearnMore4Screen"
          component={LearnMore4Screen}
          options={{
            title: 'Learn More 4',
          }}
        />
        <Stack.Screen
          name="LearnMore5Screen"
          component={LearnMore5Screen}
          options={{
            title: 'Learn More 5',
          }}
        />
        <Stack.Screen
          name="UserProfileScreen"
          component={UserProfileScreen}
          options={{
            title: 'User Profile',
          }}
        />
        <Stack.Screen
          name="MenuScreen"
          component={MenuScreen}
          options={{
            cardStyle: { backgroundColor: theme.colors['Custom Color_38'] },
            title: 'Menu',
          }}
        />
        <Stack.Screen
          name="AIAppScreen"
          component={AIAppScreen}
          options={{
            title: 'AI Apps',
          }}
        />
        <Stack.Screen
          name="LabScreen"
          component={LabScreen}
          options={{
            title: 'GAIM Labs',
          }}
        />
        <Stack.Screen
          name="HelpandSupportScreen"
          component={HelpandSupportScreen}
          options={{
            title: 'Help and Support',
          }}
        />
        <Stack.Screen
          name="PublicArtFeedScreen"
          component={PublicArtFeedScreen}
          options={{
            title: 'Public Art Feed',
          }}
        />
        <Stack.Screen
          name="ChatSettingsScreen"
          component={ChatSettingsScreen}
          options={{
            title: 'Chat Settings',
          }}
        />
        <Stack.Screen
          name="PublicProfileScreen"
          component={PublicProfileScreen}
          options={{
            title: 'Public Profile',
          }}
        />
        <Stack.Screen
          name="ImageSettingsScreen"
          component={ImageSettingsScreen}
          options={{
            title: 'Image Settings',
          }}
        />
        <Stack.Screen
          name="SubscribeScreen"
          component={SubscribeScreen}
          options={{
            title: 'Plans and Pricing',
          }}
        />
        <Stack.Screen
          name="DeleteAccountScreen"
          component={DeleteAccountScreen}
          options={{
            title: 'Delete Account',
          }}
        />
        <Stack.Screen
          name="FollowedChannelsScreen"
          component={FollowedChannelsScreen}
          options={{
            title: 'Followed Channels',
          }}
        />
        <Stack.Screen
          name="StacksScreen"
          component={StacksScreen}
          options={{
            title: 'Stacks',
          }}
        />
        <Stack.Screen
          name="ChatbotScreenStreamingMobileScreen"
          component={ChatbotScreenStreamingMobileScreen}
          options={{
            title: 'Chatbot Screen Streaming Mobile',
          }}
        />
        <Stack.Screen
          name="ClaimCreditsScreen"
          component={ClaimCreditsScreen}
          options={{
            title: 'Claim Credits',
          }}
        />
        <Stack.Screen
          name="CharacterListScreen"
          component={CharacterListScreen}
          options={{
            title: 'AI Character Chat',
          }}
        />
        <Stack.Screen
          name="LandingPageScreen"
          component={LandingPageScreen}
          options={{
            cardStyle: { backgroundColor: theme.colors['Custom Color_38'] },
            title: 'GAIM.AI Home',
          }}
        />
        <Stack.Screen
          name="LandingPageScreen2"
          component={LandingPageScreen2}
          options={{
            cardStyle: { backgroundColor: theme.colors['Custom Color_38'] },
            title: 'GAIM.AI Home',
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
