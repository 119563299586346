import React from 'react';
import { View, StyleSheet, Button } from 'react-native';
import * as Speech from 'expo-speech';

export const TextToSpeech = () => {
  const speak = () => {
    const thingToSay = 'Welcome to Game dot AI';
    Speech.speak(thingToSay);
  };

  return (
    <View>
      <Button title="Press to hear some words" onPress={speak} />
    </View>
  );
}

export const speakText = async (message, voiceName) => {
  const thingToSay = typeof message === 'string' ? message : String(message);
  return new Promise((resolve) => {
    Speech.speak(thingToSay, {
      voice: voiceName,
      rate: 1,
      onDone: () => resolve(true),
    });
  });
};

export async function listAvailableVoices() {
  const voices = await Speech.getAvailableVoicesAsync();
  console.log(voices);
  // You can then set the voices to state and display them in your app as needed
  return voices;
}

export function stopSpeaking() {
  Speech.stop();
}
// You can use components exported from this file within a Custom Code component as
// <CustomCode.MyExampleComponent />
