import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as DraftbitExampleDataApi from '../apis/DraftbitExampleDataApi.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import filterFollowing from '../global-functions/filterFollowing';
import isLiked from '../global-functions/isLiked';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import openShareUtil from '../utils/openShare';
import showAlertUtil from '../utils/showAlert';
import {
  Button,
  Checkbox,
  Circle,
  CircleImage,
  Icon,
  IconButton,
  ScreenContainer,
  TextInput,
  Touchable,
  WebView,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import {
  ActivityIndicator,
  FlatList,
  Image,
  ImageBackground,
  Modal,
  RefreshControl,
  ScrollView,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';
import { Fetch } from 'react-request';

const PublicArtFeedScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const loadMore = (Variables, setGlobalVariableValue, amount, allResults) => {
    console.log('current index: ', currentIndex);
    if (currentIndex >= allResults.length) return; // boundary check
    const startIndex = currentIndex;
    const endIndex = startIndex + amount;
    setDisplayedResults(prevResults => [
      ...prevResults,
      ...allResults.slice(startIndex, endIndex),
    ]);
    setCurrentIndex(endIndex);
  };

  const gAIMMobileBackendLikeUnlikeImagePOST =
    GAIMMobileBackendApi.useLikeUnlikeImagePOST();
  const gAIMMobileBackendLikeAUserGeneratedImagePOST =
    GAIMMobileBackendApi.useLikeAUserGeneratedImagePOST();
  const gAIMMobileBackendSubmitComplaintPOST =
    GAIMMobileBackendApi.useSubmitComplaintPOST();

  const [bottomTabSelected, setBottomTabSelected] = React.useState('public');
  const [checkboxValue, setCheckboxValue] = React.useState(false);
  const [complaintComment, setComplaintComment] = React.useState('');
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [displayedResults, setDisplayedResults] = React.useState([]);
  const [refreshingA4xBqXbe, setRefreshingA4xBqXbe] = React.useState(false);
  const [refreshingcwUxGmpA, setRefreshingcwUxGmpA] = React.useState(false);
  const [screenTitle, setScreenTitle] = React.useState('PUBLIC ART FEED');
  const [selectedImageURL, setSelectedImageURL] = React.useState('');
  const [setComplaintItem, setSetComplaintItem] = React.useState({});
  const [showComplaintModal, setShowComplaintModal] = React.useState(false);
  const [showImageModal, setShowImageModal] = React.useState(false);
  const [switchValue, setSwitchValue] = React.useState(false);
  const [textInputValue, setTextInputValue] = React.useState('');

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          backgroundColor: 'rgb(18, 18, 18)',
          height: '100%',
          justifyContent: 'space-between',
          marginLeft: { minWidth: Breakpoints.Laptop, value: 120 },
          marginRight: { minWidth: Breakpoints.Laptop, value: 120 },
        },
        dimensions.width
      )}
    >
      {/* Header */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            flexDirection: 'row',
            height: 48,
            justifyContent: 'space-between',
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        {/* Menu */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        >
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                borderBottomWidth: 0.5,
                borderLeftWidth: 0.5,
                borderRadius: 17.5,
                borderRightWidth: 0.5,
                borderTopWidth: 0.5,
                height: 35,
                justifyContent: 'center',
                width: 35,
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  navigation.navigate('MenuScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              {/* Profile Menu Button */}
              <Image
                resizeMode={'cover'}
                source={{ uri: `${Constants['user']?.pic}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    borderColor: theme.colors['Primary'],
                    borderRadius: 100,
                    borderWidth: 1,
                    height: 35,
                    marginLeft: 10,
                    width: 35,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
        </View>
        {/* Screen Heading */}
        <Text
          style={StyleSheet.applyWidth(
            {
              color: theme.colors['Custom Color_2'],
              fontFamily: 'SecularOne_400Regular',
              fontSize: 18,
            },
            dimensions.width
          )}
        >
          {screenTitle}
        </Text>
        {/* Notifications */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              height: 48,
              justifyContent: 'center',
              width: 48,
            },
            dimensions.width
          )}
        ></View>
      </View>
      {/* Feeds */}
      <GAIMMobileBackendApi.FetchGetProfileFeedImagesGET
        limit={250}
        user_id={Constants['user']?._id}
      >
        {({ loading, error, data, refetchGetProfileFeedImages }) => {
          const feedsData = data?.json;
          if (loading) {
            return <ActivityIndicator />;
          }

          if (error || data?.status < 200 || data?.status >= 300) {
            return <ActivityIndicator />;
          }

          return (
            <>
              {/* Public Feed View */}
                <ScrollView
                  bounces={true}
                  keyboardShouldPersistTaps={'never'}
                  showsHorizontalScrollIndicator={true}
                  showsVerticalScrollIndicator={true}
                  style={StyleSheet.applyWidth({ height: (bottomTabSelected === 'following') ? 0 : '60vh' }, dimensions.width)}
                >
                <>
                {!(bottomTabSelected === 'public') ? null : (
                  <FlatList
                    data={feedsData?.data}
                    initialNumToRender={20}
                    keyExtractor={listData => listData && listData['_id']}
                    listKey={'cwUxGmpA'}
                    numColumns={1}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshingcwUxGmpA}
                        onRefresh={() => {
                          const handler = async () => {
                            try {
                              setRefreshingcwUxGmpA(true);
                              await refetchFetchModelData();
                              setRefreshingcwUxGmpA(false);
                            } catch (err) {
                              console.error(err);
                              setRefreshingcwUxGmpA(false);
                            }
                          };
                          handler();
                        }}
                      />
                    }
                    
                    renderItem={({ item }) => {
                      const listData = item;
                      console.log('listData', listData);
                      return (
                        <>
                          {/* Public Feed */}
                          <>
                              {!listData?.user_id ? null : (
                          <View
                            style={StyleSheet.applyWidth(
                              {
                                alignItems: 'flex-start',
                                borderBottomWidth: 2,
                                borderColor: theme.colors['BG Gray'],
                                justifyContent: 'space-between',
                                paddingBottom: 18,
                                paddingLeft: [
                                  { minWidth: Breakpoints.Mobile, value: 20 },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '25%',
                                  },
                                ],
                                paddingRight: [
                                  { minWidth: Breakpoints.Mobile, value: 20 },
                                  {
                                    minWidth: Breakpoints.Laptop,
                                    value: '25%',
                                  },
                                ],
                                paddingTop: 16,
                                width: '100%',
                              },
                              dimensions.width
                            )}
                          >
                            <Touchable
                              onPress={() => {
                                try {
                                  navigation.navigate('PublicProfileScreen', {
                                    userName: listData?.user_id?.username,
                                    backButton: 'yes',
                                  });
                                } catch (err) {
                                  console.error(err);
                                }
                              }}
                            >
                              {/* Author */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    width: '100%',
                                  },
                                  dimensions.width
                                )}
                              >
                                <View>
                                  <CircleImage
                                    size={35}
                                    source={{
                                      uri: `${listData?.user_id?.pic}`,
                                    }}
                                    style={StyleSheet.applyWidth(
                                      { marginLeft: 4, marginTop: 4 },
                                      dimensions.width
                                    )}
                                  />
                                </View>

                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      flex: 1,
                                      marginLeft: 14,
                                      marginRight: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Name ~ */}
                                  <Text
                                    style={StyleSheet.applyWidth(
                                      {
                                        color: theme.colors['Custom Color_2'],
                                        fontFamily: 'SecularOne_400Regular',
                                        fontSize: 15,
                                        lineHeight: 20,
                                        marginTop: 4,
                                        opacity: 1,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {listData?.user_id.username}
                                  </Text>
                                </View>
                              </View>
                            </Touchable>
                            {/* Feed Content */}
                            <View
                              style={StyleSheet.applyWidth(
                                { width: '100%' },
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  try {
                                    setSelectedImageURL(listData?.url);
                                    setShowImageModal(true);
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  source={{ uri: `${listData?.url}` }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderRadius: 12,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 250,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 500,
                                        },
                                      ],
                                      left: 0,
                                      marginTop: 8,
                                      overflow: 'hidden',
                                      right: 0,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                />
                              </Touchable>
                            </View>
                            {/* Reactions */}
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'center',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  marginLeft: '5%',
                                  paddingBottom: 15,
                                  paddingTop: 15,
                                  width: '90%',
                                },
                                dimensions.width
                              )}
                            >
                              {/* likes */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                <Checkbox
                                  checkedIcon={'AntDesign/like1'}
                                  color={theme.colors['Primary']}
                                  defaultValue={isLiked(
                                    listData?.liked_users,
                                    Constants['user']?._id
                                  )}
                                  onPress={newCheckboxValue => {
                                    const handler = async () => {
                                      const checkboxValue = newCheckboxValue;
                                      try {
                                        const respLiked = (
                                          await gAIMMobileBackendLikeUnlikeImagePOST.mutateAsync(
                                            {
                                              image_id:
                                                listData && listData['_id'],
                                              user_id: Constants['user']?._id,
                                            }
                                          )
                                        )?.json;
                                        await refetchFetchModelData();
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  size={22}
                                  uncheckedColor={
                                    theme.colors['Custom Color_28']
                                  }
                                  uncheckedIcon={'AntDesign/like2'}
                                />
                                <>
                                  {!(
                                    parseInt(listData?.liked_count, 10) > 0
                                  ) ? null : (
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Primary'],
                                          fontFamily:
                                            'RobotoCondensed_400Regular',
                                          fontSize: 18,
                                          marginLeft: 8,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {listData?.liked_count}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* share */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* share */}
                                <IconButton
                                  color={theme.colors['Primary']}
                                  icon={'MaterialCommunityIcons/share'}
                                  onPress={() => {
                                    const handler = async () => {
                                      try {
                                        await openShareUtil(`${listData?.url}`);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    };
                                    handler();
                                  }}
                                  size={28}
                                />
                              </View>
                              {/* complain */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    marginRight: 20,
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* complain */}
                                <IconButton
                                  color={theme.colors['Primary']}
                                  icon={'AntDesign/frown'}
                                  onPress={() => {
                                    try {
                                      setShowComplaintModal(true);
                                      setSetComplaintItem(listData);
                                    } catch (err) {
                                      console.error(err);
                                    }
                                  }}
                                  size={22}
                                />
                              </View>
                            </View>
                          </View>
                          )}
                          </>
                        </>
                      );
                    }}
                    style={StyleSheet.applyWidth(
                      { width: '100%' },
                      dimensions.width
                    )}
                  />
                )}
              </>
              </ScrollView>
              {/* Following Feed View */}
              <>
                {!(bottomTabSelected === 'following') ? null : (
                  <ScrollView
                    bounces={true}
                    refreshControl={
                      <RefreshControl
                        refreshing={refreshingA4xBqXbe}
                        onRefresh={() => {
                          const handler = async () => {
                            try {
                              setRefreshingA4xBqXbe(true);
                              await refetchFetchModelData();
                              setRefreshingA4xBqXbe(false);
                            } catch (err) {
                              console.error(err);
                              setRefreshingA4xBqXbe(false);
                            }
                          };
                          handler();
                        }}
                      />
                    }
                    showsHorizontalScrollIndicator={true}
                    showsVerticalScrollIndicator={true}
                    style={StyleSheet.applyWidth({ height: (bottomTabSelected !== 'following') ? 0 : '60vh' }, dimensions.width)}
                  >
                    {/* List 2 */}
                    <FlatList
                      data={filterFollowing(Variables, feedsData?.data)}
                      keyExtractor={list2Data => list2Data && list2Data['_id']}
                      listKey={'xMNaD6bC'}
                      numColumns={1}
                      renderItem={({ item }) => {
                        const list2Data = item;
                        return (
                          <>
                            {/* Feed */}
                            <>
                              {!list2Data?.user_id ? null : (
                            <View
                              style={StyleSheet.applyWidth(
                                {
                                  alignItems: 'flex-start',
                                  borderBottomWidth: 2,
                                  borderColor: theme.colors['BG Gray'],
                                  justifyContent: 'space-between',
                                  paddingBottom: 18,
                                  paddingLeft: [
                                    { minWidth: Breakpoints.Mobile, value: 20 },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '25%',
                                    },
                                  ],
                                  paddingRight: [
                                    { minWidth: Breakpoints.Mobile, value: 20 },
                                    {
                                      minWidth: Breakpoints.Laptop,
                                      value: '25%',
                                    },
                                  ],
                                  paddingTop: 16,
                                  width: '100%',
                                },
                                dimensions.width
                              )}
                            >
                              <Touchable
                                onPress={() => {
                                  try {
                                    navigation.navigate('PublicProfileScreen', {
                                      userName: list2Data?.user_id?.username,
                                      backButton: 'yes',
                                    });
                                  } catch (err) {
                                    console.error(err);
                                  }
                                }}
                              >
                                {/* Author */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                >
                                  <View>
                                    <CircleImage
                                      size={35}
                                      source={{
                                        uri: `${list2Data?.user_id?.pic}`,
                                      }}
                                      style={StyleSheet.applyWidth(
                                        { marginLeft: 4, marginTop: 4 },
                                        dimensions.width
                                      )}
                                    />
                                  </View>

                                  <View
                                    style={StyleSheet.applyWidth(
                                      {
                                        flex: 1,
                                        marginLeft: 14,
                                        marginRight: 20,
                                      },
                                      dimensions.width
                                    )}
                                  >
                                    {/* Name ~ */}
                                    <Text
                                      style={StyleSheet.applyWidth(
                                        {
                                          color: theme.colors['Custom Color_2'],
                                          fontFamily: 'SecularOne_400Regular',
                                          fontSize: 15,
                                          lineHeight: 20,
                                          marginTop: 4,
                                          opacity: 1,
                                        },
                                        dimensions.width
                                      )}
                                    >
                                      {list2Data?.user_id?.username}
                                    </Text>
                                  </View>
                                </View>
                              </Touchable>
                              {/* Feed Content */}
                              <View
                                style={StyleSheet.applyWidth(
                                  { width: '100%' },
                                  dimensions.width
                                )}
                              >
                                <Image
                                  resizeMode={'cover'}
                                  source={{ uri: `${list2Data?.url}` }}
                                  style={StyleSheet.applyWidth(
                                    {
                                      borderRadius: 12,
                                      height: [
                                        {
                                          minWidth: Breakpoints.Mobile,
                                          value: 250,
                                        },
                                        {
                                          minWidth: Breakpoints.Laptop,
                                          value: 500,
                                        },
                                      ],
                                      left: 0,
                                      marginTop: 8,
                                      overflow: 'hidden',
                                      right: 0,
                                      width: '100%',
                                    },
                                    dimensions.width
                                  )}
                                />
                                {/* Prompt Text */}
                                <>
                                  {!list2Data?.prompt_public ? null : (
                                    <Text
                                      accessible={true}
                                      allowFontScaling={true}
                                      selectable={true}
                                      style={StyleSheet.applyWidth(
                                        StyleSheet.compose(
                                          GlobalStyles.TextStyles(theme)[
                                            'Text'
                                          ],
                                          {
                                            color: theme.colors['Light'],
                                            marginBottom: 2,
                                            marginTop: 5,
                                            paddingLeft: 10,
                                            paddingRight: 10,
                                          }
                                        ),
                                        dimensions.width
                                      )}
                                    >
                                      {list2Data?.prompt}
                                    </Text>
                                  )}
                                </>
                              </View>
                              {/* Reactions */}
                              <View
                                style={StyleSheet.applyWidth(
                                  {
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    marginLeft: '5%',
                                    paddingBottom: 15,
                                    paddingTop: 5,
                                    width: '90%',
                                  },
                                  dimensions.width
                                )}
                              >
                                {/* likes */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      marginRight: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* Checkbox 2 */}
                                  <Checkbox
                                    checkedIcon={'AntDesign/like1'}
                                    color={theme.colors['Primary']}
                                    defaultValue={isLiked(
                                      list2Data?.liked_users,
                                      Constants['user']?._id
                                    )}
                                    onPress={newCheckbox2Value => {
                                      const handler = async () => {
                                        const checkboxValue = newCheckbox2Value;
                                        try {
                                          const respLiked = (
                                            await gAIMMobileBackendLikeUnlikeImagePOST.mutateAsync(
                                              {
                                                image_id:
                                                  list2Data && list2Data['_id'],
                                                user_id: Constants['user']?._id,
                                              }
                                            )
                                          )?.json;
                                          await refetchFetchModelData();
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    size={22}
                                    uncheckedColor={
                                      theme.colors['Custom Color_28']
                                    }
                                    uncheckedIcon={'AntDesign/like2'}
                                  />
                                  <>
                                    {!(
                                      parseInt(list2Data?.liked_count, 10) > 0
                                    ) ? null : (
                                      <Text
                                        style={StyleSheet.applyWidth(
                                          {
                                            color: theme.colors['Primary'],
                                            fontFamily:
                                              'RobotoCondensed_400Regular',
                                            fontSize: 18,
                                            marginLeft: 8,
                                          },
                                          dimensions.width
                                        )}
                                      >
                                        {list2Data?.liked_count}
                                      </Text>
                                    )}
                                  </>
                                </View>
                                {/* share */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      marginRight: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* comments */}
                                  <IconButton
                                    color={theme.colors['Primary']}
                                    icon={'MaterialCommunityIcons/share'}
                                    onPress={() => {
                                      const handler = async () => {
                                        try {
                                          await openShareUtil(
                                            `${list2Data?.url}`
                                          );
                                        } catch (err) {
                                          console.error(err);
                                        }
                                      };
                                      handler();
                                    }}
                                    size={28}
                                  />
                                </View>
                                {/* complain 2 */}
                                <View
                                  style={StyleSheet.applyWidth(
                                    {
                                      alignItems: 'center',
                                      flexDirection: 'row',
                                      marginRight: 20,
                                    },
                                    dimensions.width
                                  )}
                                >
                                  {/* complain */}
                                  <IconButton
                                    color={theme.colors['Primary']}
                                    icon={'AntDesign/frown'}
                                    onPress={() => {
                                      try {
                                        setShowComplaintModal(true);
                                        setSetComplaintItem(list2Data);
                                      } catch (err) {
                                        console.error(err);
                                      }
                                    }}
                                    size={22}
                                  />
                                </View>
                              </View>
                            </View>
                            )}
                            </>
                          </>
                        );
                      }}
                      style={StyleSheet.applyWidth(
                        { width: '100%' },
                        dimensions.width
                      )}
                    />
                  </ScrollView>
                )}
              </>
            </>
          );
        }}
      </GAIMMobileBackendApi.FetchGetProfileFeedImagesGET>
      {/* Tabbar */}
      <View
        style={StyleSheet.applyWidth(
          {
            alignItems: 'center',
            backgroundColor: theme.colors['Primary'],
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            flexDirection: 'row',
            height: 70,
            justifyContent: 'space-around',
          },
          dimensions.width
        )}
      >
        {/* Feed */}
        <Touchable
          onPress={() => {
            try {
              setBottomTabSelected('public');
              setScreenTitle('PUBLIC ART FEED');
            } catch (err) {
              console.error(err);
            }
          }}
        >
          {/* Active View */}
          <>
            {!(bottomTabSelected === 'public') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'Feather/home'}
                  size={24}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Studily_Primary'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: 12,
                      marginTop: 8,
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'PUBLIC'}
                </Text>
              </View>
            )}
          </>
          {/* View 2 */}
          <>
            {!(bottomTabSelected !== 'public') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'Feather/home'}
                  size={24}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: 12,
                      marginTop: 8,
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'PUBLIC'}
                </Text>
              </View>
            )}
          </>
        </Touchable>
        {/* Following */}
        <Touchable
          onPress={() => {
            try {
              setBottomTabSelected('following');
              setScreenTitle('FOLLOWING FEED');
            } catch (err) {
              console.error(err);
            }
          }}
          style={StyleSheet.applyWidth(
            { borderColor: theme.colors['Strong'] },
            dimensions.width
          )}
        >
          {/* Active View */}
          <>
            {!(bottomTabSelected === 'following') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Studily_Primary']}
                  name={'Feather/users'}
                  size={24}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Studily_Primary'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: 12,
                      marginTop: 8,
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'Following'}
                </Text>
              </View>
            )}
          </>
          {/* View 2 */}
          <>
            {!(bottomTabSelected !== 'following') ? null : (
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center' },
                  dimensions.width
                )}
              >
                <Icon
                  color={theme.colors['Strong']}
                  name={'Feather/users'}
                  size={24}
                />
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: 12,
                      marginTop: 8,
                      textTransform: 'uppercase',
                    },
                    dimensions.width
                  )}
                >
                  {'Following'}
                </Text>
              </View>
            )}
          </>
        </Touchable>
      </View>
      {/* View Image Webview Modal */}
      <>
        {!showImageModal ? null : (
          <Modal animationType={'fade'} presentationStyle={'fullScreen'}>
            <ImageBackground
              resizeMode={'cover'}
              source={Images.GaimMobileBG2}
              style={StyleSheet.applyWidth(
                GlobalStyles.ImageBackgroundStyles(theme)['Image Background'],
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  { alignItems: 'center', width: '100%' },
                  dimensions.width
                )}
              >
                <Button
                  onPress={() => {
                    try {
                      setShowImageModal(false);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.ButtonStyles(theme)['Button'],
                      {
                        borderRadius: 50,
                        color: theme.colors['Strong'],
                        marginTop: '15%',
                        width: '80%',
                      }
                    ),
                    dimensions.width
                  )}
                  title={'Close Image'}
                />
              </View>
              <WebView
                cacheEnabled={true}
                javaScriptEnabled={true}
                showsHorizontalScrollIndicator={true}
                showsVerticalScrollIndicator={true}
                source={{ uri: `${selectedImageURL}` }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(
                    GlobalStyles.WebViewStyles(theme)['Web View'],
                    { marginTop: '10%' }
                  ),
                  dimensions.width
                )}
              />
            </ImageBackground>
          </Modal>
        )}
      </>
      {/* Complaint Modal */}
      <>
        {!showComplaintModal ? null : (
          <Modal
            animationType={'slide'}
            presentationStyle={'overFullScreen'}
            transparent={true}
          >
            {/* View 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: 'rgba(19, 17, 17, 0.8)',
                  height: '100%',
                  width: '100%',
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    alignSelf: 'center',
                    backgroundColor: 'rgb(18, 18, 18)',
                    borderColor: theme.colors['Error'],
                    borderRadius: 5,
                    borderWidth: 2,
                    height: 300,
                    justifyContent: 'space-around',
                    marginTop: 200,
                    paddingLeft: 30,
                    paddingRight: 30,
                    width: 300,
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'Please tell us why you find this image offensive.'}
                </Text>
                {/* Title Text Input */}
                <TextInput
                  allowFontScaling={true}
                  autoCapitalize={'none'}
                  changeTextDelay={500}
                  defaultValue={complaintComment}
                  multiline={true}
                  onChangeText={newTitleTextInputValue => {
                    const textInputValue = newTitleTextInputValue;
                    try {
                      setComplaintComment(newTitleTextInputValue);
                    } catch (err) {
                      console.error(err);
                    }
                  }}
                  placeholder={'Enter new title...'}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.TextInputStyles(theme)['Text Input'],
                      { color: theme.colors['Surface'] }
                    ),
                    dimensions.width
                  )}
                />
                <View
                  style={StyleSheet.applyWidth(
                    { flexDirection: 'row', justifyContent: 'space-around' },
                    dimensions.width
                  )}
                >
                  {/* Submit Complaint Button */}
                  <Button
                    icon={'Ionicons/send'}
                    onPress={() => {
                      const handler = async () => {
                        try {
                          (
                            await gAIMMobileBackendSubmitComplaintPOST.mutateAsync(
                              {
                                asset_owner_id: setComplaintItem?.user_id,
                                asset_type: 'image',
                                complaining_user_id: Constants['user']?._id,
                                complaint_comments: complaintComment,
                                offending_asset_id: setComplaintItem?._id,
                              }
                            )
                          )?.json;

                          showAlertUtil({
                            title: 'Complaint Submitted',
                            message:
                              'Your complaint has been submitted and recorded. Our team will look into the complaint.',
                            buttonText: 'Close',
                          });

                          setShowComplaintModal(false);
                        } catch (err) {
                          console.error(err);
                        }
                      };
                      handler();
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginRight: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'SUBMIT'}
                  />
                  {/* Cancel Button */}
                  <Button
                    onPress={() => {
                      try {
                        setShowComplaintModal(false);
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ButtonStyles(theme)['Button'],
                        {
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                          borderColor: theme.colors['Primary'],
                          borderRadius: 50,
                          borderWidth: 2,
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 16,
                          marginLeft: 10,
                        }
                      ),
                      dimensions.width
                    )}
                    title={'CANCEL'}
                  />
                </View>
              </View>
            </View>
          </Modal>
        )}
      </>
    </ScreenContainer>
  );
};

export default withTheme(PublicArtFeedScreen);
