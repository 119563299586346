import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import LandingSwiperBlock from '../components/LandingSwiperBlock';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import {
  Circle,
  CircleImage,
  Divider,
  Icon,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import { Image, ImageBackground, ScrollView, Text, View } from 'react-native';
import axios from 'axios';

const HomeScreen = props => {
  const { theme, navigation } = props;
  const dimensions = useWindowDimensions();
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();
  const authenticateOAuth = async (token) => {
    const codeVerifier = localStorage.getItem('stytch_verifier');
    if (!codeVerifier) {
      console.log('No code verifier found');
    }
    const url = 'https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/authenticateOAuth';
    const payload = {
      token: token,
      code_verifier: codeVerifier,
    };
    try {
      const response = await fetch(url, {
        method: 'POST', // or 'PUT'
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload), // Convert the JavaScript object to a JSON string before sending it.
      });
      if (!response.ok) {
        console.log('Network response was not ok');
        return false;
      }
      const responseData = await response.json(); // Assuming the response is JSON. Adjust if it's text or another format.
      return responseData; // Return the response data for further processing or use.
    } catch (error) {
      console.error('There was an error!', error);
    }
  }

  const validateToken = async (token) => {
    try {
      console.log('Validating token in home screen:', token)
      const response = await axios.get(`https://gaim-mobile-backend-vr97s.ondigitalocean.app/auth/authSession/${token}`, {
        headers: {
          'Content-Type': 'application/json',
        }
      });
      const json = response.data;
      console.log('Token validation response:', json);
      if (json.success === true) {
        // Token is valid, redirect to Dashboard
        //navigation.navigate('LandingPageScreen');
        console.log('Token is valid');
        return json;
      } else {
        console.error('Token validation failed:', json.message);
        navigation.navigate('LoginScreen');
      }
    } catch (error) {
      console.error('Error during token validation:', error);
      //navigation.navigate('LoginScreen');
    }
  };
  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const stytchRedirectToken = localStorage.getItem('stytch_redirect_token');
        if (stytchRedirectToken) {
          const authToken = await authenticateOAuth(stytchRedirectToken);
          console.log('authToken.session_token in home: ', authToken.session_token);
          localStorage.setItem('stytch_session_token', authToken.session_token);
          if (authToken.session_token) {
            const localToken = authToken.session_token;
            console.log('localToken in home: ', localToken);
            const respAuth2 = (
              await validateToken(localToken)
            );
            console.log('respAuth in home: ', respAuth2);
            const respUser = await (async () => {
              if (respAuth2?.success === true) {
                return (
                  await GAIMMobileBackendApi.getUserByDynamicIDGET(Constants, {
                    dynamic_id: respAuth2?.data.user.user_id,
                  })
                )?.json;
              }
            })();
            if (respAuth2?.success === true) {
              setGlobalVariableValue({
                key: 'user',
                value: respUser?.data,
              });
            }
            if (respAuth2?.success === true) {
              setGlobalVariableValue({
                key: 'stytch_session_token',
                value: authToken.session_token,
              });
              navigation.navigate('LandingPageScreen');
            }
          }
        }
        const respAuth = (
          await GAIMMobileBackendApi.authSessionTokenGET(Constants, {
            stytch_session_token: Constants['stytch_session_token'],
          })
        )?.json;
        const respUser = await (async () => {
          if (respAuth?.success === true) {
            return (
              await GAIMMobileBackendApi.getUserByDynamicIDGET(Constants, {
                dynamic_id: respAuth?.data.user.user_id,
              })
            )?.json;
          }
        })();
        if (respAuth?.success === true) {
          setGlobalVariableValue({
            key: 'user',
            value: respUser?.data,
          });
        }
        if (respAuth?.success === true) {
          navigation.navigate('LandingPageScreen');
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasSafeArea={true}
      hasTopSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { backgroundColor: theme.colors['Custom Color'] },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        source={{
          uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/homeBG5.jpg',
        }}
        style={StyleSheet.applyWidth(
          {
            backgroundColor: theme.colors['Strong'],
            height: '100%',
            opacity: 1,
            width: '100%',
          },
          dimensions.width
        )}
      >
        <ScrollView
          bounces={true}
          contentContainerStyle={StyleSheet.applyWidth(
            { paddingBottom: 25 },
            dimensions.width
          )}
          showsVerticalScrollIndicator={false}
        >
          {/* Header */}
          <View
            style={StyleSheet.applyWidth(
              {
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginTop: 16,
                paddingLeft: 16,
                paddingRight: 16,
              },
              dimensions.width
            )}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  backgroundColor: 'rgb(18, 18, 18)',
                  borderColor: 'rgba(0, 0, 0, 0)',
                  borderRadius: 100,
                  flexDirection: 'row',
                  paddingRight: 30,
                },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('StytchAuthScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <Circle
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(
                      GlobalStyles.CircleStyles(theme)['Circle'],
                      {
                        backgroundColor: theme.colors['Strong'],
                        borderRadius: 100,
                        height: 50,
                        width: 50,
                      }
                    ),
                    dimensions.width
                  )}
                >
                  <CircleImage
                    size={40}
                    source={{
                      uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/logo100x100.png',
                    }}
                    style={StyleSheet.applyWidth(
                      { position: 'absolute' },
                      dimensions.width
                    )}
                  />
                </Circle>
              </Touchable>

              <View
                style={StyleSheet.applyWidth(
                  { paddingLeft: 10 },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'SecularOne_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'GAIM.AI'}
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'RobotoCondensed_400Regular',
                    }),
                    dimensions.width
                  )}
                >
                  {'AI FOR EVERYONE'}
                </Text>
              </View>
            </View>

            <View
              style={StyleSheet.applyWidth(
                { flexDirection: 'row' },
                dimensions.width
              )}
            >
              <Touchable
                onPress={() => {
                  try {
                    navigation.navigate('StytchAuthScreen');
                  } catch (err) {
                    console.error(err);
                  }
                }}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: theme.colors['Primary'],
                      borderRadius: 80,
                      flexDirection: 'row',
                      height: 48,
                      justifyContent: 'center',
                      width: 100,
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          fontFamily: 'SecularOne_400Regular',
                          marginRight: 8,
                          marginTop: 4,
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'LOGIN'}
                  </Text>
                  <Icon
                    color={theme.colors['Strong']}
                    name={'AntDesign/login'}
                    size={24}
                  />
                </View>
              </Touchable>
            </View>
          </View>
          {/* GreetView */}
          <View
            style={StyleSheet.applyWidth(
              {
                marginTop: 31,
                paddingLeft: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Mobile, value: 16 },
                  { minWidth: Breakpoints.Laptop, value: '10%' },
                ],
              },
              dimensions.width
            )}
          >
            {/* Question */}
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Custom Color_2'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 50,
                  lineHeight: 50,
                  marginTop: 16,
                },
                dimensions.width
              )}
            >
              {'TRY AI\nFOR FREE!'}
            </Text>
          </View>
          {/* Stats View */}
          <View
            style={StyleSheet.applyWidth({ marginTop: 25 }, dimensions.width)}
          >
            <View
              style={StyleSheet.applyWidth(
                {
                  backgroundColor: theme.colors['Primary'],
                  borderRadius: 16,
                  height: 109,
                  marginLeft: 16,
                  marginRight: 16,
                  opacity: 0.7,
                },
                dimensions.width
              )}
            />
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  borderRadius: 16,
                  flexDirection: 'row',
                  height: 109,
                  justifyContent: 'space-evenly',
                  left: 0,
                  marginLeft: 16,
                  marginRight: 16,
                  opacity: 1,
                  position: 'absolute',
                  right: 0,
                  top: 0,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Text
                    adjustsFontSizeToFit={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Chatbots'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong'],
                          fontFamily: 'Inter_700Bold',
                          fontSize: 30,
                        },
                        dimensions.width
                      )}
                    >
                      {'72+'}
                    </Text>
                  </View>
                </View>
                <Divider
                  color={theme.colors['Custom Color_2']}
                  style={StyleSheet.applyWidth(
                    { height: 40, position: 'absolute', right: 0, width: 1 },
                    dimensions.width
                  )}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Text
                    adjustsFontSizeToFit={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Image Tools'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong'],
                          fontFamily: 'Inter_700Bold',
                          fontSize: 30,
                        },
                        dimensions.width
                      )}
                    >
                      {'68+'}
                    </Text>
                  </View>
                </View>
                <Divider
                  color={theme.colors['Custom Color_2']}
                  style={StyleSheet.applyWidth(
                    { height: 40, position: 'absolute', right: 0, width: 1 },
                    dimensions.width
                  )}
                />
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flex: 1,
                    flexDirection: 'row',
                    justifyContent: 'center',
                  },
                  dimensions.width
                )}
              >
                <View>
                  <Text
                    adjustsFontSizeToFit={true}
                    style={StyleSheet.applyWidth(
                      {
                        color: theme.colors['Strong'],
                        fontFamily: 'SecularOne_400Regular',
                        textTransform: 'uppercase',
                      },
                      dimensions.width
                    )}
                  >
                    {'Characters'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        flexDirection: 'row',
                        marginTop: 8,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      adjustsFontSizeToFit={true}
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Strong'],
                          fontFamily: 'Inter_700Bold',
                          fontSize: 30,
                        },
                        dimensions.width
                      )}
                    >
                      {'187+'}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
          {/* Content */}
          <View
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgb(18, 18, 18)',
                borderRadius: 0,
                borderTopLeftRadius: 30,
                borderTopRightRadius: 30,
                flex: 1,
                marginTop: 25,
                paddingBottom: 40,
                paddingLeft: [
                  { minWidth: Breakpoints.Desktop, value: '18%' },
                  { minWidth: Breakpoints.BigScreen, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '15%' },
                ],
                paddingRight: [
                  { minWidth: Breakpoints.Desktop, value: '18%' },
                  { minWidth: Breakpoints.BigScreen, value: '20%' },
                  { minWidth: Breakpoints.Laptop, value: '15%' },
                ],
                paddingTop: 20,
              },
              dimensions.width
            )}
          >
            {/* Banner */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Primary'],
                    borderRadius: 15,
                    height: 170,
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingTop: 20,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'RobotoCondensed_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'RobotoCondensed_400Regular',
                        },
                      ],
                      fontSize: 18,
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Get 10,000 Text Credits\nand 25 AI Image Credits\nfor Free!'
                  }
                </Text>
                {/* Start Exercising */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      backgroundColor: 'rgb(40, 40, 40)',
                      borderRadius: 20,
                      height: 32,
                      justifyContent: 'center',
                      marginTop: 20,
                      width: 144,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('StytchAuthScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 14,
                        },
                        dimensions.width
                      )}
                    >
                      {'SIGNUP FOR FREE'}
                    </Text>
                  </Touchable>
                </View>
              </View>
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/logo400x400Black.png',
                }}
                style={StyleSheet.applyWidth(
                  {
                    bottom: 16,
                    height: 100,
                    position: 'absolute',
                    right: 10,
                    width: 100,
                  },
                  dimensions.width
                )}
              />
            </View>
            {/* AI Sight View */}
            <View
              style={StyleSheet.applyWidth(
                { alignItems: 'center', marginTop: 20 },
                dimensions.width
              )}
            >
              <LandingSwiperBlock />
            </View>
            {/* Category */}
            <View
              style={StyleSheet.applyWidth(
                { marginTop: 20, paddingLeft: 16, paddingRight: 16 },
                dimensions.width
              )}
            >
              {/* Section Heading */}
              <Text
                adjustsFontSizeToFit={true}
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Desktop, value: 22 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {'SO MANY EASY-TO-USE TOOLS'}
              </Text>

              <View
                style={StyleSheet.applyWidth(
                  { flexDirection: 'row', justifyContent: 'space-evenly' },
                  dimensions.width
                )}
              >
                <Touchable
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 10 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/RisographArtPrintAI.jpg',
                      }}
                      style={StyleSheet.applyWidth(
                        { height: 100, width: 74 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        borderRadius: 15,
                        justifyContent: 'center',
                        left: 0,
                        top: 0,
                        width: 74,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'RobotoCondensed_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'RobotoCondensed_400Regular',
                            },
                          ],
                          fontSize: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {'AI ART'}
                    </Text>
                  </View>
                </Touchable>

                <Touchable
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 15 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Marketing.jpg',
                      }}
                      style={StyleSheet.applyWidth(
                        { height: 100, width: 74 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        left: 0,
                        top: 0,
                        width: 74,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'RobotoCondensed_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'RobotoCondensed_400Regular',
                            },
                          ],
                          fontSize: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {'CONTENT'}
                    </Text>
                  </View>
                </Touchable>

                <Touchable
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 15 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/maxTheCharmerAI.jpg',
                      }}
                      style={StyleSheet.applyWidth(
                        { height: 100, width: 74 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        left: 0,
                        top: 0,
                        width: 74,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'RobotoCondensed_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'RobotoCondensed_400Regular',
                            },
                          ],
                          fontSize: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {'CHAT'}
                    </Text>
                  </View>
                </Touchable>

                <Touchable
                  style={StyleSheet.applyWidth(
                    { marginTop: 16 },
                    dimensions.width
                  )}
                >
                  <View
                    style={StyleSheet.applyWidth(
                      { borderRadius: 15 },
                      dimensions.width
                    )}
                  >
                    <Image
                      resizeMode={'cover'}
                      source={{
                        uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/GTAVimage.jpg',
                      }}
                      style={StyleSheet.applyWidth(
                        { height: 100, width: 74 },
                        dimensions.width
                      )}
                    />
                  </View>

                  <View
                    style={StyleSheet.applyWidth(
                      {
                        alignItems: 'center',
                        justifyContent: 'center',
                        left: 0,
                        top: 0,
                        width: 74,
                      },
                      dimensions.width
                    )}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: [
                            {
                              minWidth: Breakpoints.Mobile,
                              value: 'RobotoCondensed_400Regular',
                            },
                            {
                              minWidth: Breakpoints.Laptop,
                              value: 'RobotoCondensed_400Regular',
                            },
                          ],
                          fontSize: 15,
                        },
                        dimensions.width
                      )}
                    >
                      {'FUN'}
                    </Text>
                  </View>
                </Touchable>
                {/* Touchable 2 */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <Touchable
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { borderRadius: 15 },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={{
                            uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/nursingAI.jpg',
                          }}
                          style={StyleSheet.applyWidth(
                            { height: 100, width: 74 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            justifyContent: 'center',
                            left: 0,
                            top: 0,
                            width: 74,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color_2'],
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'RobotoCondensed_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'RobotoCondensed_400Regular',
                                },
                              ],
                              fontSize: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {'LIFE'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
                {/* Touchable 3 */}
                <>
                  {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                    <Touchable
                      style={StyleSheet.applyWidth(
                        { marginTop: 16 },
                        dimensions.width
                      )}
                    >
                      <View
                        style={StyleSheet.applyWidth(
                          { borderRadius: 15 },
                          dimensions.width
                        )}
                      >
                        <Image
                          resizeMode={'cover'}
                          source={{
                            uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/religiousStudiesAI.jpg',
                          }}
                          style={StyleSheet.applyWidth(
                            { height: 100, width: 74 },
                            dimensions.width
                          )}
                        />
                      </View>

                      <View
                        style={StyleSheet.applyWidth(
                          {
                            alignItems: 'center',
                            justifyContent: 'center',
                            left: 0,
                            top: 0,
                            width: 74,
                          },
                          dimensions.width
                        )}
                      >
                        <Text
                          style={StyleSheet.applyWidth(
                            {
                              color: theme.colors['Custom Color_2'],
                              fontFamily: [
                                {
                                  minWidth: Breakpoints.Mobile,
                                  value: 'RobotoCondensed_400Regular',
                                },
                                {
                                  minWidth: Breakpoints.Laptop,
                                  value: 'RobotoCondensed_400Regular',
                                },
                              ],
                              fontSize: 15,
                            },
                            dimensions.width
                          )}
                        >
                          {'LEARN'}
                        </Text>
                      </View>
                    </Touchable>
                  )}
                </>
              </View>
            </View>
            {/* Header */}
            <View
              style={StyleSheet.applyWidth(
                {
                  alignItems: 'center',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginTop: 20,
                  paddingLeft: 16,
                  paddingRight: 24,
                },
                dimensions.width
              )}
            >
              {/* Section Heading */}
              <Text
                style={StyleSheet.applyWidth(
                  {
                    color: theme.colors['Primary'],
                    fontFamily: 'SecularOne_400Regular',
                    fontSize: [
                      { minWidth: Breakpoints.Mobile, value: 18 },
                      { minWidth: Breakpoints.Desktop, value: 22 },
                    ],
                  },
                  dimensions.width
                )}
              >
                {'RECLAIM YOUR FREE TIME'}
              </Text>
            </View>
            {/* Banner 2 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    backgroundColor: theme.colors['Primary'],
                    borderRadius: 15,
                    height: 195,
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingLeft: 20,
                    paddingTop: 20,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                {/* Title */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'RobotoCondensed_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'RobotoCondensed_400Regular',
                        },
                      ],
                      fontSize: 18,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {
                    'While you are stressing through ten hours to write a blog post, your competitors are using GAIM to generate 25 times MORE content that is high quality and engaging.'
                  }
                </Text>
                {/* Start Exercising */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: 'rgb(40, 40, 40)',
                      borderRadius: 20,
                      height: 32,
                      justifyContent: 'center',
                      marginRight: 20,
                      marginTop: 20,
                      width: 144,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('StytchAuthScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 14,
                        },
                        dimensions.width
                      )}
                    >
                      {'TRY IT TODAY'}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
            {/* Try Everything For Free */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Header 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingLeft: 16,
                    paddingRight: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Section Heading */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Primary'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {'TRY EVERYTHING FOR FREE!'}
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    marginTop: 8,
                    overflow: 'hidden',
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '4%' },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '4%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  adjustsFontSizeToFit={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'RobotoCondensed_400Regular',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                      marginBottom: 12,
                    }),
                    dimensions.width
                  )}
                >
                  {
                    "Every user of our app is awarded 10,000 free text/chat credits and 25 free image credits upon signup, ensuring you have the resources to explore and utilize our extensive range of AI tools. It's our way of saying thank you for joining us in this revolutionary journey."
                  }
                </Text>
                {/* Text 2 */}
                <Text
                  accessible={true}
                  adjustsFontSizeToFit={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'RobotoCondensed_400Regular',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                    }),
                    dimensions.width
                  )}
                >
                  {
                    'Whether you want to\n- Write your next blog post\n- Create incredible art and photos\n- Chat with awesome AI characters\nor anything else you can dream of, try GAIM.AI for free and join the AI revolution!'
                  }
                </Text>
              </View>
            </View>
            {/* Banner 3 */}
            <View
              style={StyleSheet.applyWidth(
                {
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  marginLeft: 20,
                  marginRight: 20,
                  marginTop: 20,
                },
                dimensions.width
              )}
            >
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    backgroundColor: theme.colors['Primary'],
                    borderRadius: 15,
                    height: 245,
                    justifyContent: 'center',
                    paddingBottom: 10,
                    paddingTop: 20,
                    width: '100%',
                  },
                  dimensions.width
                )}
              >
                <View
                  style={StyleSheet.applyWidth(
                    {
                      flexDirection: 'row',
                      justifyContent: 'space-around',
                      width: '100%',
                    },
                    dimensions.width
                  )}
                >
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/confuciusAI.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* Image 2 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Axolotl.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* Image 3 */}
                  <Image
                    resizeMode={'cover'}
                    source={{
                      uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/cletusTheComicalAI.jpg',
                    }}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.ImageStyles(theme)['Image'],
                        {
                          borderColor: theme.colors['Strong'],
                          borderRadius: 100,
                          borderWidth: 2,
                          height: 100,
                          width: 100,
                        }
                      ),
                      dimensions.width
                    )}
                  />
                  {/* Image 4 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Frankenstein.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* Image 5 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Laptop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/StatueofLiberty.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* Image 6 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Desktop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Zeus.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                  {/* Image 7 */}
                  <>
                    {!(dimensions.width >= Breakpoints.Desktop) ? null : (
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/Nurse.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Strong'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: 100,
                              width: 100,
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    )}
                  </>
                </View>
                {/* Title */}
                <Text
                  adjustsFontSizeToFit={true}
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Strong'],
                      fontFamily: [
                        {
                          minWidth: Breakpoints.Mobile,
                          value: 'RobotoCondensed_400Regular',
                        },
                        {
                          minWidth: Breakpoints.Laptop,
                          value: 'RobotoCondensed_400Regular',
                        },
                      ],
                      fontSize: 18,
                      marginTop: [
                        { minWidth: Breakpoints.Mobile, value: 10 },
                        { minWidth: Breakpoints.Laptop, value: 16 },
                      ],
                      paddingLeft: 20,
                      paddingRight: 20,
                    },
                    dimensions.width
                  )}
                >
                  {
                    'Chat with hundreds of AI characters, books, teachers, artifacts and more.'
                  }
                </Text>
                {/* Start Exercising */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      alignSelf: 'center',
                      backgroundColor: 'rgb(40, 40, 40)',
                      borderRadius: 20,
                      height: 32,
                      justifyContent: 'center',
                      marginRight: 20,
                      marginTop: 20,
                      width: 144,
                    },
                    dimensions.width
                  )}
                >
                  <Touchable
                    onPress={() => {
                      try {
                        navigation.navigate('StytchAuthScreen');
                      } catch (err) {
                        console.error(err);
                      }
                    }}
                  >
                    <Text
                      style={StyleSheet.applyWidth(
                        {
                          color: theme.colors['Custom Color_2'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: 14,
                        },
                        dimensions.width
                      )}
                    >
                      {'CHAT WITH ME'}
                    </Text>
                  </Touchable>
                </View>
              </View>
            </View>
            {/* Chat Characters Info */}
            <View
              style={StyleSheet.applyWidth({ marginTop: 20 }, dimensions.width)}
            >
              {/* Header 2 */}
              <View
                style={StyleSheet.applyWidth(
                  {
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    paddingLeft: 16,
                    paddingRight: 24,
                  },
                  dimensions.width
                )}
              >
                {/* Section Heading */}
                <Text
                  style={StyleSheet.applyWidth(
                    {
                      color: theme.colors['Primary'],
                      fontFamily: 'SecularOne_400Regular',
                      fontSize: [
                        { minWidth: Breakpoints.Mobile, value: 18 },
                        { minWidth: Breakpoints.Desktop, value: 22 },
                      ],
                    },
                    dimensions.width
                  )}
                >
                  {'CHAT LIKE NEVER BEFORE!'}
                </Text>
              </View>

              <View
                style={StyleSheet.applyWidth(
                  {
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    marginTop: 8,
                    overflow: 'hidden',
                    paddingLeft: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '4%' },
                    ],
                    paddingRight: [
                      { minWidth: Breakpoints.Mobile, value: '5%' },
                      { minWidth: Breakpoints.Laptop, value: '4%' },
                    ],
                  },
                  dimensions.width
                )}
              >
                <Text
                  accessible={true}
                  adjustsFontSizeToFit={true}
                  allowFontScaling={true}
                  style={StyleSheet.applyWidth(
                    StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                      color: theme.colors['Surface'],
                      fontFamily: 'RobotoCondensed_400Regular',
                      fontSize: { minWidth: Breakpoints.Desktop, value: 18 },
                      marginBottom: 12,
                    }),
                    dimensions.width
                  )}
                >
                  {
                    "Engage, Explore, Educate - with GAIM.AI's revolutionary AI character chat tools. Immerse yourself in conversations that are not just chats but gateways to endless worlds of knowledge, imagination, and interaction. \n\nWhether you're looking to casually converse with AI friends, delve into discussions with famous figures, or embark on exploratory dialogues with books, artifacts, and places, GAIM.AI offers an unparalleled chat experience that educates, entertains, and enlightens."
                  }
                </Text>
                {/* Character Detail View Left 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary'],
                      borderRadius: 12,
                      borderWidth: 2,
                      flexDirection: 'column',
                      marginBottom: 8,
                      padding: 10,
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                          marginBottom: 8,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Casual AI Friends'}
                  </Text>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Image 8 */}
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/isaiahTheInnovatorAI.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Primary'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Character Type Detail Text */}
                    <Text
                      accessible={true}
                      adjustsFontSizeToFit={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Surface'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Looking for a chat that's light, friendly, and just like talking to another human? Our AI friends are here to share a joke, provide companionship, or simply engage in a casual conversation that brightens your day."
                      }
                    </Text>
                  </View>
                </View>
                {/* Character Detail View Right 1 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary'],
                      borderRadius: 12,
                      borderWidth: 2,
                      flexDirection: 'column',
                      marginBottom: 8,
                      padding: 10,
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 3 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                          marginBottom: 8,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Famous Figures'}
                  </Text>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* Character Type Detail Text */}
                    <Text
                      accessible={true}
                      adjustsFontSizeToFit={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Surface'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Ever wondered what it would be like to talk to historical icons, literary geniuses, or pop culture legends? Our AI brings these figures to life, offering you the chance to interact with personalities from the past and present like never before.'
                      }
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Image 8 */}
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/davinciAI.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Primary'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                </View>
                {/* Character Detail View Left 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary'],
                      borderRadius: 12,
                      borderWidth: 2,
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      marginBottom: 8,
                      padding: 10,
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                          marginBottom: 8,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Books, Artifacts, and Places'}
                  </Text>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Image 8 */}
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/SydneyOperaHouseAI.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Primary'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Character Type Detail Text */}
                    <Text
                      accessible={true}
                      adjustsFontSizeToFit={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Surface'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        "Dive into the stories behind the world's most fascinating books, explore the mysteries of ancient artifacts, or take a virtual tour of iconic places through engaging dialogues that bring knowledge to life."
                      }
                    </Text>
                  </View>
                </View>
                {/* Character Detail View Right 2 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary'],
                      borderRadius: 12,
                      borderWidth: 2,
                      flexDirection: 'column',
                      marginBottom: 8,
                      padding: 10,
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                    },
                    dimensions.width
                  )}
                >
                  {/* Text 2 */}
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                          marginBottom: 8,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Monsters and Myths'}
                  </Text>
                  {/* View 2 */}
                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    {/* Character Type Detail Text */}
                    <Text
                      accessible={true}
                      adjustsFontSizeToFit={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Surface'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Enter the realms of fantasy and folklore where you can converse with creatures of legend and lore. Learn their stories, uncover their secrets, and experience the thrill of mythological worlds.'
                      }
                    </Text>

                    <View
                      style={StyleSheet.applyWidth(
                        { marginLeft: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Image 8 */}
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/werewolf.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Primary'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                  </View>
                </View>
                {/* Character Detail View Left 3 */}
                <View
                  style={StyleSheet.applyWidth(
                    {
                      alignItems: 'center',
                      borderColor: theme.colors['Primary'],
                      borderRadius: 12,
                      borderWidth: 2,
                      flexDirection: 'column',
                      marginBottom: 8,
                      padding: 10,
                      paddingLeft: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                      paddingRight: {
                        minWidth: Breakpoints.Laptop,
                        value: '10%',
                      },
                    },
                    dimensions.width
                  )}
                >
                  <Text
                    accessible={true}
                    allowFontScaling={true}
                    style={StyleSheet.applyWidth(
                      StyleSheet.compose(
                        GlobalStyles.TextStyles(theme)['Text'],
                        {
                          color: theme.colors['Primary'],
                          fontFamily: 'SecularOne_400Regular',
                          fontSize: {
                            minWidth: Breakpoints.Desktop,
                            value: 20,
                          },
                          marginBottom: 8,
                          textAlign: 'center',
                          textTransform: 'uppercase',
                        }
                      ),
                      dimensions.width
                    )}
                  >
                    {'Professionals and Experts'}
                  </Text>

                  <View
                    style={StyleSheet.applyWidth(
                      { alignItems: 'center', flexDirection: 'row' },
                      dimensions.width
                    )}
                  >
                    <View
                      style={StyleSheet.applyWidth(
                        { marginRight: 10 },
                        dimensions.width
                      )}
                    >
                      {/* Image 8 */}
                      <Image
                        resizeMode={'cover'}
                        source={{
                          uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_chatbots/DentistAI.jpg',
                        }}
                        style={StyleSheet.applyWidth(
                          StyleSheet.compose(
                            GlobalStyles.ImageStyles(theme)['Image'],
                            {
                              borderColor: theme.colors['Primary'],
                              borderRadius: 100,
                              borderWidth: 2,
                              height: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                              width: [
                                { minWidth: Breakpoints.Mobile, value: 60 },
                                { minWidth: Breakpoints.Laptop, value: 100 },
                              ],
                            }
                          ),
                          dimensions.width
                        )}
                      />
                    </View>
                    {/* Character Type Detail Text */}
                    <Text
                      accessible={true}
                      adjustsFontSizeToFit={true}
                      allowFontScaling={true}
                      style={StyleSheet.applyWidth(
                        StyleSheet.compose(
                          GlobalStyles.TextStyles(theme)['Text'],
                          {
                            color: theme.colors['Surface'],
                            fontFamily: 'RobotoCondensed_400Regular',
                            fontSize: {
                              minWidth: Breakpoints.Desktop,
                              value: 18,
                            },
                          }
                        ),
                        dimensions.width
                      )}
                    >
                      {
                        'Need advice or insights from a specific field of expertise? Chat with our AI professionals, from tech wizards to business moguls, and get the guidance you need, anytime, anywhere.'
                      }
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
        {/* Start Exercising 2 */}
        <View
          style={StyleSheet.applyWidth(
            {
              alignItems: 'center',
              alignSelf: 'center',
              backgroundColor: [
                {
                  minWidth: Breakpoints.Mobile,
                  value: theme.colors['Primary'],
                },
                {
                  minWidth: Breakpoints.Desktop,
                  value: theme.colors['Primary'],
                },
              ],
              borderRadius: 20,
              height: 32,
              justifyContent: 'center',
              marginBottom: 10,
              marginRight: 20,
              marginTop: 10,
              width: 144,
            },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('StytchAuthScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Text
              style={StyleSheet.applyWidth(
                {
                  color: theme.colors['Strong'],
                  fontFamily: 'SecularOne_400Regular',
                  fontSize: 14,
                },
                dimensions.width
              )}
            >
              {'TRY FOR FREE'}
            </Text>
          </Touchable>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { bottom: 20, position: 'absolute', right: 20 },
            dimensions.width
          )}
        >
          <Touchable
            onPress={() => {
              try {
                navigation.navigate('StytchAuthScreen');
              } catch (err) {
                console.error(err);
              }
            }}
          >
            <Circle
              bgColor={theme.colors['Custom Color_5']}
              size={50}
              style={StyleSheet.applyWidth(
                { backgroundColor: theme.colors['Primary'] },
                dimensions.width
              )}
            >
              <Icon
                color={theme.colors['Strong']}
                name={'AntDesign/login'}
                size={24}
              />
            </Circle>
          </Touchable>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(HomeScreen);
