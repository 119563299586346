import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import * as GAIMMobileBackendApi from '../apis/GAIMMobileBackendApi.js';
import * as GlobalVariables from '../config/GlobalVariableContext';
import Images from '../config/Images';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import {
  Button,
  Link,
  ScreenContainer,
  Touchable,
  withTheme,
} from '@draftbit/ui';
import { useIsFocused } from '@react-navigation/native';
import * as Linking from 'expo-linking';
import {
  Image,
  ImageBackground,
  Text,
  View,
  useWindowDimensions,
} from 'react-native';

const LoginScreen = props => {
  const dimensions = useWindowDimensions();
  const { theme, navigation } = props;
  const Constants = GlobalVariables.useValues();
  const Variables = Constants;
  const setGlobalVariableValue = GlobalVariables.useSetValue();

  const isFocused = useIsFocused();
  React.useEffect(() => {
    const handler = async () => {
      try {
        if (!isFocused) {
          return;
        }
        const respAuth = (
          await GAIMMobileBackendApi.authSessionTokenGET(Constants, {
            stytch_session_token: Constants['stytch_session_token'],
          })
        )?.json;
        const respUser = await (async () => {
          if (respAuth?.success === true) {
            return (
              await GAIMMobileBackendApi.getUserByDynamicIDGET(Constants, {
                dynamic_id: respAuth?.data.user.user_id,
              })
            )?.json;
          }
        })();
        if (respAuth?.success === true) {
          setGlobalVariableValue({
            key: 'user',
            value: respUser?.data,
          });
        }
        if (respAuth?.success === true) {
          navigation.navigate('LandingPageScreen');
        }
      } catch (err) {
        console.error(err);
      }
    };
    handler();
  }, [isFocused]);

  return (
    <ScreenContainer
      hasBottomSafeArea={true}
      hasSafeArea={false}
      hasTopSafeArea={true}
      scrollable={false}
      style={StyleSheet.applyWidth(
        { alignContent: 'stretch', backgroundColor: 'rgb(18, 18, 18)' },
        dimensions.width
      )}
    >
      <ImageBackground
        resizeMode={'cover'}
        style={StyleSheet.applyWidth(
          {
            height: '100%',
            justifyContent: 'space-around',
            paddingLeft: 16,
            paddingRight: 16,
          },
          dimensions.width
        )}
      >
        <View
          style={StyleSheet.applyWidth({ marginTop: 26 }, dimensions.width)}
        >
          <Text
            adjustsFontSizeToFit={true}
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color'],
                fontFamily: 'SecularOne_400Regular',
                fontSize: 50,
                marginBottom: -10,
                textAlign: 'center',
              },
              dimensions.width
            )}
          >
            {'GAIM.AI'}
          </Text>

          <Text
            style={StyleSheet.applyWidth(
              {
                color: theme.colors['Custom Color'],
                fontFamily: 'RobotoCondensed_400Regular',
                fontSize: 16,
                textAlign: 'center',
                textTransform: 'uppercase',
              },
              dimensions.width
            )}
          >
            {'AI for everyone.'}
          </Text>
        </View>

        <View
          style={StyleSheet.applyWidth(
            { alignItems: 'center' },
            dimensions.width
          )}
        >
          <Image
            resizeMode={'cover'}
            source={{
              uri: 'https://aipps.nyc3.cdn.digitaloceanspaces.com/AIPPS_61.png',
            }}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                height: 275,
                width: 275,
              }),
              dimensions.width
            )}
          />
        </View>

        <View
          style={StyleSheet.applyWidth(
            {
              marginLeft: { minWidth: Breakpoints.Laptop, value: '25%' },
              marginRight: { minWidth: Breakpoints.Laptop, value: '25%' },
            },
            dimensions.width
          )}
        >
          {/* App Links View */}
          <>
            {dimensions.width >= Breakpoints.Laptop ? null : (
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                height: 100,
                justifyContent: 'space-around',
              },
              dimensions.width
            )}
          >
            <Touchable
              onPress={() => {
                try {
                  Linking.openURL(
                    'itms-apps://itunes.apple.com/app/id6467152704'
                  );
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/appStoreBadge150x75.png',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: 75,
                    width: 150,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
            {/* Touchable 2 */}
            <Touchable
              onPress={() => {
                try {
                  Linking.openURL('https://play.google.com/store/apps/details?id=com.gaimnetwork.gaimai');
                } catch (err) {
                  console.error(err);
                }
              }}
            >
              <Image
                resizeMode={'cover'}
                source={{
                  uri: 'https://gaim.nyc3.cdn.digitaloceanspaces.com/playStoreBadge150x75.png',
                }}
                style={StyleSheet.applyWidth(
                  StyleSheet.compose(GlobalStyles.ImageStyles(theme)['Image'], {
                    height: 75,
                    width: 150,
                  }),
                  dimensions.width
                )}
              />
            </Touchable>
          </View>
          )}
          </>
          {/* Button Solid */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('StytchAuthScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'rgb(153, 171, 214)',
                borderRadius: 64,
                color: 'rgb(18, 18, 18)',
                fontFamily: 'System',
                fontWeight: '700',
                height: 48,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'SIGN UP'}
          >
            {'Sign Up'}
          </Button>
          {/* Button Outline */}
          <Button
            onPress={() => {
              try {
                navigation.navigate('StytchAuthScreen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'transparent',
                borderColor: 'rgb(153, 171, 214)',
                borderRadius: 64,
                borderWidth: 1,
                color: 'rgb(153, 171, 214)',
                fontFamily: 'System',
                fontWeight: '700',
                height: 48,
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'LOG IN'}
          >
            {'Log In'}
          </Button>
          {/* Button Outline 2 
          <Button
            onPress={() => {
              try {
                navigation.navigate('LearnMore1Screen');
              } catch (err) {
                console.error(err);
              }
            }}
            style={StyleSheet.applyWidth(
              {
                backgroundColor: 'transparent',
                borderColor: 'rgb(153, 171, 214)',
                borderRadius: 64,
                borderWidth: 1,
                color: 'rgb(153, 171, 214)',
                fontFamily: 'System',
                fontWeight: '700',
                height: 48,
                marginTop: 16,
                textAlign: 'center',
              },
              dimensions.width
            )}
            title={'LEARN MORE'}
          >
            {'Log In'}
          </Button>*/}
        </View>
        {/* Login Footer */}
        <View>
          {/* Copyright */}
          <Text
            accessible={true}
            allowFontScaling={true}
            style={StyleSheet.applyWidth(
              StyleSheet.compose(GlobalStyles.TextStyles(theme)['Text'], {
                alignSelf: 'center',
                color: theme.colors['Divider'],
              }),
              dimensions.width
            )}
          >
            {'Copyright GAIM Network LLC. All rights reserved.'}
          </Text>
          {/* Policy Links */}
          <View
            style={StyleSheet.applyWidth(
              {
                flexDirection: 'row',
                justifyContent: 'space-evenly',
                marginTop: 6,
              },
              dimensions.width
            )}
          >
            {/* Privacy Link */}
            <Link
              accessible={true}
              allowFontScaling={true}
              onPress={() => {
                try {
                  navigation.navigate('PrivacyPolicyScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                  textDecorationLine: 'underline',
                }),
                dimensions.width
              )}
              title={'Privacy Policy'}
            />
            {/* Terms Link */}
            <Link
              accessible={true}
              allowFontScaling={true}
              onPress={() => {
                try {
                  navigation.navigate('TermsofServiceScreen');
                } catch (err) {
                  console.error(err);
                }
              }}
              style={StyleSheet.applyWidth(
                StyleSheet.compose(GlobalStyles.LinkStyles(theme)['Link'], {
                  textDecorationLine: 'underline',
                }),
                dimensions.width
              )}
              title={'Terms of Service'}
            />
          </View>
        </View>
      </ImageBackground>
    </ScreenContainer>
  );
};

export default withTheme(LoginScreen);
