import React from 'react';

const filterList = (
  Variables,
  setGlobalVariableValue,
  list,
  searchValue,
  searchAttribute
) => {
  
  if (!searchValue || searchValue.trim() === '') {
    return list;
  }
  
  const newList = list.filter(item =>
    item[searchAttribute].toLowerCase().includes(searchValue)
  );
  return newList;
};

export default filterList;
